import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CommonUtil from "../../util/CommonUtils";
import axios from "axios";

export default function SundialAuthentication() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const code = queryParams.get("code");

  useEffect(() => {
    const user = CommonUtil.getLoggedUser();
    try {
      if (user && user?.exp > Date.now() / 1000) {
        let payload = {
          token: localStorage.getItem("token"),
          id: user?.id,
          companyId: user?.companyId,
        };
        let url = process.env.REACT_APP_SERVER_URL?.replace("web", "api");
        axios
          .put(
            url + `/v1/users/${code}/sundial_auth`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            window.history.replace("/dashboard");
          });
      } else {
        localStorage.setItem("sundial-auth-code", code);
        window.location.replace("/");
      }
    } catch (e) {
      window.history.replace("/dashboard");
    }
  }, []);

  return <div></div>;
}
