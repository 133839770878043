import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../signup//Signup.scss";
import OnboardTextField from "../../components/OnboardTextField";
import { enqueueSnackbar } from "notistack";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../components/PasswordField";
import SignupLayout from "../signup/SignupLayout";
import { styled } from "@mui/material/styles";
import { activateMember } from "../../services/MemberService";
import { toast } from "sonner";

export default function ActivateMember() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [payload, setPayload] = useState({
    userId: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const encodedCredentials = searchParams.get("code");
    const userId = atob(encodedCredentials);
    setPayload({
      ...payload,
      userId: userId,
    });
  }, []);

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (e) => {
    if (validate()) {
      var state = {
        ...payload,
      };
      activateMember(state).then((res) => {
        if (res.data.code === "RCI0012") {
          navigate("/");
          toast.success(res.data?.message);
          // enqueueSnackbar(res.data?.message, { variant: "success" });
        } else {
          toast.error(res.data?.message);
          // enqueueSnackbar(res.data?.message, { variant: "error" });
        }
      });
    }
  };

  const validate = () => {
    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      return false;
    }

    if (!CommonUtil.isAlphaNumericPassword(payload.password)) {
      setError({
        ...error,
        password: t("RAL0010"),
      });
      return false;
    }

    if (!payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: t("RAL0001"),
      });
      return false;
    }

    if (payload.confirmPassword !== payload.password) {
      setError({
        ...error,
        confirmPassword: t("RAL0011"),
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  return (
    <>
      <SignupLayout>
        <div className="forgot_password_001">
          <div>
            <span className="forgot_password_002">{t("RAL0390")}</span>
          </div>

          <Grid item sm={12} pt={4}>
            <PasswordField
              style={{ width: "500px" }}
              required
              fullWidth
              label={t("RAL0111")}
              value={payload.password}
              name="password"
              onChange={handleChange}
              error={error.password}
              helperText={error.password}
              inputProps={{
                maxLength: 25,
              }}
            />
          </Grid>

          <Grid item sm={12} pt={2}>
            <PasswordField
              required
              fullWidth
              label={t("RAL0112")}
              value={payload.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
              error={error.confirmPassword}
              helperText={error.confirmPassword}
              inputProps={{
                maxLength: 25,
              }}
            />
          </Grid>

          <Grid item lg={12} minWidth="100%" className="submitButton">
            <CustomButton
              id="btn-activate-member"
              name="btn-activate-member"
              variant="contained"
              size="medium"
              fullWidth="fullWidth"
              onClick={handleSubmit}
            >
              <span className="signUp" id="btn-activate-member">
                {t("RAL0077")}
              </span>
            </CustomButton>
          </Grid>

          <Box className="forgot_password_009" sx={{ margin: "1.875rem" }}>
            <span>{t("RAL0072")}</span> &nbsp;
            <span
              className="forgot_password_010"
              onClick={() => navigate("/")}
              id="btn-forgot-member"
            >
              {t("RAL0006")}
            </span>
          </Box>
        </div>
      </SignupLayout>
    </>
  );
}
