import React, { useContext, useEffect, useRef, useState } from "react";
import SignupLayout from "./SignupLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OnboardTextField from "../../components/OnboardTextField";
import { Autocomplete, Grid, Link } from "@mui/material";
import PasswordField from "../../components/PasswordField";
import CustomButton from "../../components/CustomButton";
import CommonUtil from "../../util/CommonUtils";
import OnboardService from "../../services/OnboardService";
import "./Signup.scss";
import "./LoginUser.scss";
import { toast } from "sonner";
import { jwtDecode } from "jwt-decode";
import { UserContext } from "../../util/context";
import axios from "axios";
import CustomTextField from "../../components/CustomTextField";

export default function LoginUser() {
  const { setTokenRefreshTimer } = useContext(UserContext);
  const { t, ready } = useTranslation(); 
  let navigate = useNavigate();

  const [payload, setPayload] = useState({
    userName: "",
    password: "",
    companyId: "",
  });

  const [error, setError] = useState({
    userName: "",
    password: "",
    companyId: "",
  });

  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const userNameRef = useRef(null);

  useEffect(() => {
    if (ready && userNameRef.current) {
      userNameRef.current.focus();
    }
  }, [ready]);

  const handleSubmit = (e) => {
    if (validate()) {
      OnboardService.login(payload).then((res) => {
        if (res?.data?.code === "UASI0011") {
          if (res?.data?.data?.access_token !== null) {
            localStorage.setItem(
              "token",
              "Bearer " + res?.data?.data?.access_token
            );
            CommonUtil.decodeToken();

            localStorage.setItem(
              "refresh_token",
              "Bearer " + res?.data?.data?.refresh_token
            );

            const decoded = jwtDecode(res?.data?.data?.access_token);
            if (decoded.roleCode === "CLIENT") {
              setError({
                ...error,
                userName: "You don't have permission to login",
              });
              return;
            }
            var currentSeconds = Math.floor(Date.now() / 1000);
            console.warn("decoded.exp", decoded.exp);
            console.warn("currentSeconds", currentSeconds);
            var secondsLeft = decoded.exp - currentSeconds;
            console.warn("secondsLeft", secondsLeft);
            var timer = Math.floor(secondsLeft / 60) - 5;
            console.warn("timer", timer);
            console.warn("Next token generation after", timer + " mins");

            timer = timer * 60 * 1000;
            setTokenRefreshTimer(timer);
            try {
              if (localStorage.getItem("sundial-auth-code")) {
                let url = process.env.REACT_APP_SERVER_URL?.replace(
                  "web",
                  "api"
                );
                axios
                  .put(
                    url +
                      `/v1/users/${localStorage.getItem(
                        "sundial-auth-code"
                      )}/sundial_auth`,
                    {},
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("sundial-auth-code");
                    window.history.replace("/dashboard");
                  });
              }
            } catch (e) {}

            navigate("/dashboard");
          }
        } else if (res.data.code === "RCE0003" || res.data.code === "RCE0024") {
          localStorage.setItem("token", res.data?.data);
          navigate("/pages/company-industry", {
            state: { email: payload.email },
          });
        } else if (res.data.code === "RCE0002") {
          setError({ ...error, userName: t("RAL0143") });
        } else if (res.data.code === "RCE0001") {
          setError({ ...error, password: res.data?.message });
        } else if (res.data.code === "RCW00001") {
          // localStorage.setItem("token", res?.data?.data?.access_token);
          // setCompanies(res.data.data?.companies);
          navigate("/select-organization", {
            state: {
              companies: res.data.data?.companies,
              userName: payload.userName,
              password: payload.password,
            },
          });
          toast.warning(res.data?.message);
        } else {
          toast.error(res.data?.message);
        }
      });
    }
  };

  const validate = () => {
    if (!payload.userName) {
      setError({
        ...error,
        userName: t("RAL0001"),
      });
      return false;
    }
    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <>
      <SignupLayout>
        <div className="LoginUser">
          <div className="w-100Email">
            <div>
              <span className="login_user_002">{t("RAL0067")}</span>
            </div>

            <div className="login_user_003" style={{ marginTop: "15px" }}>
              <span>
                {t("RAL0117")}&nbsp;
                <span
                  className="login_user_clickSignIn_004"
                  onClick={() => navigate("/pages/verify-email")}
                >
                  {t("RAL0118")}
                </span>
              </span>
            </div>

            <Grid item sm={12} pt={3} className="inputBorderNone">
              <OnboardTextField
                autoComplete={false}
                required
                fullWidth
                label={t("RAL0014")}
                value={payload.userName}
                name="userName"
                onChange={handleChange}
                error={error.userName}
                helperText={error.userName}
                onKeyDown={(e)=>handleKeyDown(e)}
                inputRef={userNameRef}
                inputProps={{
                  maxLength: 36,
                  autoComplete: "on", 
                }}
                validation="email"
              />
            </Grid>
            <Grid
              item
              sm={12}
              pt={3}
              className="inputBorderNone">
              <PasswordField
                className="changePasswordError"
                id="textfield-singup-view-password"
                required
                fullWidth
                label={t("RAL0017")}
                value={payload.password}
                name="password"
                onChange={handleChange}
                error={error.password}
                helperText={error.password}
                onKeyDown={(e)=>handleKeyDown(e)}
                inputProps={{
                  maxLength: 25,
                }}
                customPlaceholder={t("RAL0017")}
              />
            </Grid>
            {companies?.length > 0 && (
              <Grid item sm={12} pb={3}>
                <Autocomplete
                  fullWidth
                  size="small"
                  getOptionLabel={(option) => option.name}
                  clearText="Clear"
                  value={company}
                  options={companies}
                  renderInput={(params) => (
                    <CustomTextField
                      id="dropdown-member-create-department"
                      name="dropdown-member-create-department"
                      dropdown={true}
                      onChange={(e) => console.log()}
                      placeholder={"Company"}
                      error={!CommonUtil.isEmptyString(error.companyId)}
                      helperText={error.companyId}
                      {...params}
                      label={"Company"}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setPayload({
                        ...payload,
                        companyId: newValue.id,
                      });
                      setCompany(newValue);
                    } else {
                      setPayload({
                        ...payload,
                        companyId: null,
                      });
                      setCompany(null);
                    }
                    setError({
                      ...error,
                      companyId: "",
                    });
                  }}
                />
              </Grid>
            )}

            <div className="login_user_click_forgot_password_006">
              <Link
                id={"link-login-view-forgotpassword"}
                className="login_user_click_forgot_password_005"
                onClick={() => navigate("/pages/verify-user")}
              >
                {t("RAL0005")}
              </Link>
            </div>
            <Grid item sm={12} className="login_user_submit_button_007">
              <CustomButton
                id="btn_login_user_signin"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleSubmit}
              >
                <span className="login_user_sign_in_text_008">
                  {t("RAL0006")}
                </span>
              </CustomButton>
            </Grid>
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
