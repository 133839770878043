import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function CustomSelectButton({
  text,
  subText,
  onClick,
  width,
  isSelected,
  icon,
  icons,
  place,
}) {
  const hasSubText = subText && subText.trim() !== "";
  function btnStyle(isSelected, place) {
    switch (place) {
      case "CompanyIndustry":
        return {
          borderColor: isSelected ? "#FA9C2B" : "var(--custom-btn-background)",
          backgroundColor: isSelected
            ? "var(--custom-btn-background-7)"
            : "var(--custom-btn-background-6)",
          boxShadow: isSelected ? "#FFFAF3" : "",
          borderRadius: isSelected ? "5px" : "5px",
        };
        break;
      case "SelectWorkspace":
        return {
          position: "relative",
          borderColor: isSelected
            ? "#FA9C2B"
            : "var(--custom-btn-border-color)",
          backgroundColor: isSelected ? "#FA9C2B1A" : "var(--background-color)",
          boxShadow: isSelected ? "#FFFAF3" : "",
          borderRadius: hasSubText ? "10px" : "10px",
        };
        break;
      case "trackActivity":
        return {
          padding: "1.875rem",
          borderColor: isSelected ? "#FA9C2B" : "var(--custom-btn-background)",
          backgroundColor: isSelected
            ? "#FA9C2B1A"
            : "var(--background-color-41)",
          boxShadow: isSelected ? "#FFFAF3" : "",
          borderRadius: hasSubText ? "5px" : "5px",
        };
        break;
      default:
        return {
          borderColor: isSelected ? "#FA9C2B" : "var(--custom-btn-background)",
          backgroundColor: isSelected
            ? "#FA9C2B1A"
            : "var(--custom-btn-background)",
          boxShadow: isSelected ? "#FFFAF3" : "",
          borderRadius: hasSubText ? "5px" : "5px",
        };
        break;
    }
  }

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            position:
              isSelected && place === "SelectWorkspace" ? "relative" : "static",
          }}
        >
          <button
            className={`customButton ${width} customButton-${isSelected}`}
            onClick={() => onClick(text)}
            style={btnStyle(isSelected, place)}
          >
            {isSelected && place === "SelectWorkspace" && (
              <label
                style={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <input
                  type="checkbox"
                  checked={isSelected}
                  readOnly
                  style={{ display: "none" }}
                />
                <span
                  style={{
                    display: "inline-block",
                    width: "22px",
                    height: "22px",
                    borderRadius: "5px",
                    border: "2px solid #FA9C2B",
                    position: "relative",
                    backgroundColor: isSelected ? "#FA9C2B" : "transparent",
                  }}
                >
                  {isSelected && (
                    <svg
                      viewBox="0 0 24 24"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "14px",
                        height: "14px",
                        fill: "none",
                        stroke: "var(--background-color)",
                        strokeWidth: "2",
                      }}
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                  )}
                </span>
              </label>
            )}

            <div className="customSelectAuthBtns">
              <div className="logo">
                {icon && <div>{icon}</div>}
                {icons && <div>{icons}</div>}
              </div>

              <div className="textTitleDiv">
                <span className="text-title">{text}</span>
                {subText && (
                  <div style={{ marginTop: "5px" }} className="text-subtitle">
                    {subText}
                  </div>
                )}
              </div>
            </div>
          </button>
        </Grid>
      </Grid>
    </Box>
  );
}

function CustomSelectButtons({
  selected,
  onClick,
  buttonWidths,
  place,
  selectedItem,
}) {
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (text) => {
    onClick(text);
    setSelectedButton(text);
  };

  useEffect(() => {
    if (selectedItem) {
      setSelectedButton(selectedItem);
    }
  }, [selectedItem]);

  return (
    <div className="boxesSpacing">
      {selected.map((select, index) => (
        <CustomSelectButton
          key={select.id}
          place={place}
          text={select.text}
          onClick={handleButtonClick}
          width={buttonWidths && buttonWidths[index]}
          isSelected={selectedButton === select.text}
          icon={select.icon}
          icons={select.icons}
          subText={select.subText}
        />
      ))}
    </div>
  );
}

export default CustomSelectButtons;
