import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Typography,
  alpha,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import Selecto from "react-selecto";
import { toast } from "sonner";
import { Scheduler } from "zk-timesheet";
import { ReactComponent as AppleIcon } from "../../assets/images/ralvie/icon_apple.svg";
import { ReactComponent as AppleIconDark } from "../../assets/images/ralvie/icon_apple_dark.svg";
import { ReactComponent as WindowsIcon } from "../../assets/images/ralvie/icon_windows.svg";
import { ReactComponent as WorklogCaptionDark } from "../../assets/images/ralvie/worklog-caption-dark.svg";
import { ReactComponent as WorklogCaption } from "../../assets/images/ralvie/worklog-caption.svg";
import AppContext from "../../config/AppContext";
import { timeZoneList } from "../../constants/TimeZone";
import {
  eventFilter,
  getSystemSettings,
  userEventsCount,
} from "../../services/EventService";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import "../signup/ActivityTrack.scss";
import Activity from "./Activity";
export default function Day(props) {
  const {
    reload,
    setReload,
    eventsToLog,
    setEventsToLog,
    timesheetEvents,
    setTimesheetEvents,
  } = useContext(TimesheetContext);
  const { t } = useTranslation();
  const [step, setStep] = useState(30.1);
  const [isLoading, setIsLoading] = useState(true);
  const [isSelecting, setIsSelecting] = useState(false);
  const isSelectingRef = useRef(isSelecting);
  const [appColor, setAppColor] = useState(new Map());
  const [formatedEventsList, setFormatedEvents] = useState([]);
  const [day, setDay] = useState("today");
  const [selectedDayByTz, setSelectedDayByTz] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date().getHours());
  const [startMinute, setStartMinute] = useState(0);
  const appColorRef = useRef(appColor);
  const calendarRef = useRef(null);
  const stepRef = useRef(step);
  const [eventInterval, setEventInterval] = useState(null);
  const eventIntervalRef = useRef(eventInterval);
  const timelineEventsRef = useRef([]);
  const { mode } = useContext(AppContext);
  const selectoRef = React.useRef(null);
  const scrollerRef = React.useRef(null);
  const [userEventCount, setUserEventCount] = useState(0);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (props.zoomLevel !== -1) {
      setIsLoading(true);
      setStep(props.zoomLevel);
      stepRef.current = props.zoomLevel;
    }
  }, [props.zoomLevel]);

  const getTimeZoneId = () => {
    if (getSystemSettings("time_zone")) {
      let timeZone = timeZoneList.find(
        (e) => e.value === getSystemSettings("time_zone")
      );
      return timeZone ? timeZone?.zoneId : undefined;
    }
  };
  const getRandomColorVariantBasedOnAppName = (param) => {
    // Ensure that the parameter is a string and not an empty string
    const firstLetter =
      typeof param === "string" && param.length > 0
        ? param.charAt(0).toUpperCase()
        : "A";

    // Mapping letters to color indices
    const letterToColorIndex = {
      A: 0,
      B: 1,
      C: 2,
      D: 3,
      E: 4,
      F: 5,
      G: 6,
      H: 7,
      I: 8,
      J: 9,
      K: 10,
      L: 11,
      M: 12,
      N: 13,
      O: 14,
      P: 15,
      Q: 16,
      R: 17,
      S: 18,
      T: 19,
      U: 20,
      V: 21,
      W: 22,
      X: 23,
      Y: 24,
      Z: 25,
      // Add more mappings as needed
    };

    const fixedColors = [
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      // Add more colors as needed
    ];

    // Pick a random color from the fixedColors array based on the first letter
    const randomColorIndex = letterToColorIndex[firstLetter] || 0;
    let randomColor = "";

    if (param?.includes("teams")) {
      randomColor = "#5059C9";
    } else if (param?.toLowerCase().includes("xd")) {
      randomColor = "#FF61F6";
    } else if (param?.toLowerCase().includes("excel")) {
      randomColor = "#207245";
    } else if (param?.toLowerCase().includes("chrome")) {
      randomColor = "#1A73E8";
    } else if (param?.toLowerCase().includes("outlook")) {
      randomColor = "#5059C9";
    } else {
      randomColor = fixedColors[randomColorIndex];
    }

    // Create a Material-UI theme for the randomly picked color
    const theme = createTheme({
      palette: {
        primary: {
          main: randomColor,
          light: alpha(randomColor, 0.05),
          dark: alpha(randomColor, 0.9),
        },
      },
    });

    // Access the light and dark versions of the primary color
    const lightColor = theme.palette.primary.light;
    const darkColor = theme.palette.primary.dark;
    const contrastText = theme.palette.primary.contrastText;

    return {
      main: randomColor,
      light: lightColor,
      dark: darkColor,
      contrastText: contrastText,
    };
  };

  const roundToNearestMinutes = (minute) => {
    if (step === 1) {
      return minute;
    }
    let roundedMinutes = Math.round(minute / step) * step;
    if (minute < roundedMinutes) {
      roundedMinutes = roundedMinutes - step;
    }
    return roundedMinutes;
  };

  const setEventsInterval = (e) => {
    if (e !== null || eventIntervalRef.current !== null) {
      return;
    }
    const eventInterval_ = setInterval(function () {
      loadActivities();
    }, 60 * 1000);
    setEventInterval(eventInterval_);
    eventIntervalRef.current = eventInterval_;
    return () => clearInterval(eventInterval_);
  };

  useEffect(() => {
    getUserEventsCount();
  }, []);

  useEffect(() => {
    // setDateByTimezone();
    setFormatedEvents([]);
    setIsLoading(true);
    cleanupFunction();
    if (props.filter?.selectedDate) {
      loadActivities();
      if (props.filter?.selectedDate?.isSame(dayjs(), "day"))
        setEventsInterval(null);
    }
  }, [props.filter]);

  useEffect(() => {
    // setDateByTimezone();
    if (reload) {
      setFormatedEvents([]);
      setIsLoading(true);
      cleanupFunction();
      if (props.filter?.selectedDate) {
        loadActivities();
        if (props.filter?.selectedDate?.isSame(dayjs(), "day"))
          setEventsInterval(null);
      }
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setTimeout(() => {
      // setIsLoading(false);
      const element = document.getElementById("currentTimeBar");
      const myElement = document.getElementsByClassName(
        mode == "light" ? "css-15awfqw" : "css-123scgp"
      );
      const topPos = element?.offsetTop;
      if (myElement && myElement.length > 0)
        myElement[0].scrollTop =
          topPos -
          (window.innerHeight > 900
            ? window.innerHeight - 300
            : window.innerHeight - 250);
    }, 10);
  }, [startTime]);

  useEffect(() => {
    // Function to be called on unmount
    return () => {
      cleanupFunction();
    };
  }, []); // Empty dependency array to run effect only once on mount

  // Function to be called on unmount
  function cleanupFunction() {
    clearInterval(eventIntervalRef.current);
    eventIntervalRef.current = null;
    setEventInterval(null);
  }

  const loadSundialPackages = () => {
    OnboardService.listSundialVersions()
      .then((res) => {
        if (res.data.code === "RCI0000") {
          const sundialPackages = res.data.data;
          if (sundialPackages instanceof Array && sundialPackages.length > 0) {
            setPackages(sundialPackages);
            return;
          }
        }
      })
      .catch((error) => {});
  };

  const getUserEventsCount = () => {
    userEventsCount().then((res) => {
      setUserEventCount(res.data?.data);
    });
  };

  useEffect(() => {
    if (!userEventCount || userEventCount === 0) {
      loadSundialPackages();
    }
  }, [userEventCount]);

  function foramtEvents(events) {
    setIsLoading(true);
    events = events?.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    // events?.forEach((event, index) => {
    //   const currentTime = new Date();
    //   const eventEndTime = new Date(event.end);
    //   const minuteDifference = (currentTime - eventEndTime) / (60 * 1000);
    //   if (minuteDifference <= 5) {
    //     // Set the end time and duration such that the end time is 5 min less than the current time
    //     const newEndTime = new Date(currentTime - 5 * 60 * 1000).toISOString();
    //     const newDuration =
    //       (new Date(newEndTime) - new Date(event.start)) / 1000; // convert milliseconds to seconds

    //     event.end = newEndTime;
    //     event.duration = newDuration;
    //   }
    // });

    events = events?.filter((event) => event.duration >= 30);
    const formatedEvents = [];

    events?.forEach((e) => {
      const eventStart = new Date(e.start);
      const eventEnd = new Date(e.end);
      let key = e.application?.name;
      let color = getRandomColorVariantBasedOnAppName(key);
      const newEvent = {
        ...e,
        start: eventStart,
        end: eventEnd,
        event_id: e.eventId,
        light: color?.light,
        dark: color?.dark,
      };
      formatedEvents.push(newEvent);
    });
    if (stepRef.current === 30.1) {
      setStep(30);
      stepRef.current = 30;
    }
    setIsLoading(false);

    return formatedEvents;
  }

  const loadActivities = () => {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(
      props.filter?.selectedDate,
      CommonUtil.getTimeZoneId()
    );
    let startDate = dateTz.startOf("day");
    let endDate = dateTz.endOf("day");
    let projectTaggedStatus = null;
    if (props.filter?.logged && props.filter?.unlogged) {
      projectTaggedStatus = "ALL";
    } else if (props.filter?.logged) {
      projectTaggedStatus = "LOGGED";
    } else if (props.filter?.unlogged) {
      projectTaggedStatus = "UNLOGGED";
    }
    if (
      !props.filter?.logged &&
      !props.filter?.unlogged &&
      !props.filter?.hidden
    ) {
      projectTaggedStatus = "ALL";
    } else if (
      !props.filter?.logged &&
      !props.filter?.unlogged &&
      props.filter?.hidden
    ) {
      projectTaggedStatus = "NONE";
    }
    let payload = {
      startTime: startDate.isUTC()
        ? startDate.format()
        : startDate.utc().format(),
      endTime: endDate.isUTC() ? endDate.format() : endDate.utc().format(),
      isHidden: props.filter?.hidden,
      projectTaggedStatus: projectTaggedStatus,
      windowTitle: props.filter.title,
      applicationName: props.filter.applicationName,
    };
    eventFilter(payload).then((res) => {
      if (res.data?.data) {
        let startDateTime = new Date(
          new Date(res.data?.data?.startDateTime).toLocaleString("en-US", {
            timeZone: CommonUtil.getTimeZoneId(),
          })
        );

        setSelectedDayByTz(startDateTime);

        setStartTime(new Date(startDateTime).getHours());
        setStartMinute(
          roundToNearestMinutes(new Date(startDateTime).getMinutes())
        );
        timelineEventsRef.current = res.data?.data?.events;
        // setStartMinute(roundToNearestMinutes(Number(res.data?.data?.startMin)));
        setFormatedEvents(foramtEvents(res.data?.data?.events));
        setUserEventCount(res.data?.data?.events?.length);
        setIsLoading(false);
      } else {
        setFormatedEvents([]);
        setIsLoading(false);
      }
    });
  };

  const ItemTypes = {
    ACTIVITY: "activity",
  };

  function DraggableActivity({ event, stepRef, isSelecting }) {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.ACTIVITY,
      item: { event },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
    return (
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1, cursor: "move" }}
        className="cube"
        key={event?.id}
        id={JSON.stringify(event)}
      >
        <Activity
          event={event}
          stepRef={stepRef}
          isSelecting={isSelecting}
          // logEvent={(event) => logEvent(event)}
        />
      </div>
    );
  }

  const mergeAndRemoveDuplicates = (arrays, key) => {
    if (arrays[0].length === 0) {
      return arrays[1];
    }

    return arrays.reduce((acc, curr) => {
      curr.forEach((item) => {
        // Check if the item already exists in the accumulator
        if (!acc.find((obj) => obj[key] === item[key])) {
          acc.push(item);
        }
      });
      return acc;
    }, []);
  };

  const handleSelectedEvents = (events) => {
    if (timesheetEvents?.length > 0) {
      let newEvents = [];
      events?.forEach((e) => {
        e?.events?.forEach((ce) => {
          newEvents.push(ce);
        });
      });
      if (newEvents.length > 0) {
        let uniqueEvents = timesheetEvents.filter(
          (oe) => !newEvents.some((ne) => ne.id === oe.id)
        );

        const updatedEvents = [...uniqueEvents, ...newEvents];
        setTimesheetEvents(updatedEvents);
      }
      return;
    }
    let mergedEvents = mergeAndRemoveDuplicates([eventsToLog, events], "id");
    setEventsToLog(mergedEvents);
  };

  const downloadSundial = (type) => {
    if (type && packages?.length > 0) {
      const link = document.createElement("a");
      let pkgs = packages.find((pkg) => pkg?.type?.toLowerCase() === type);
      if (pkgs) {
        link.href = pkgs?.downloadLink;
        link.download = "sundial_package";
        link.setAttribute("download", "sundial_package");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      {formatedEventsList.length === 0 ? (
        <div className="noActivity">
          {isLoading && (
            <Box
              sx={{ display: "flex" }}
              width={"100%"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              width={"80%"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                pb={"15%"}
              >
                {mode == "light" ? <WorklogCaption /> : <WorklogCaptionDark />}
                <p
                  style={{
                    color: "var(--text-color-6) !important",
                    font: "normal normal normal 12px/22px Gotham",
                  }}
                >
                {props.filter?.title.length > 0 ? t("RAL0397") : (userEventCount > 0 ? t("RAL0038") : t("RAL0388"))}
                </p>
                {userEventCount < 1 && (
                  <p
                    style={{
                      color: "var(--text-color-6) !important",
                      font: "normal normal normal 12px/22px Gotham",
                    }}
                  >
                    {t("RAL0389")}
                  </p>
                )}
              </Box>
              {userEventCount < 1 && packages && packages.length > 0 && (
                <Box className="ActivityTrack" justifyContent={"space-between"}>
                  {packages?.find(
                    (pkg) => pkg?.type?.toLowerCase() === "dmg"
                  ) && (
                    <Box
                      onClick={() => downloadSundial("dmg")}
                      className={
                        mode === "dark"
                          ? "activity_track_package_dark"
                          : "activity_track_package"
                      }
                      // onClick={() => alert()}
                    >
                      {mode === "dark" ? <AppleIconDark /> : <AppleIcon />}
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        px={"16px"}
                        flexGrow={1}
                      >
                        <Typography className="header">
                          {t("Sundial for macOS")}
                        </Typography>
                      </Box>

                      {/* <CustomIconButton
                        title={t("RAL0226")}
                        size={isXlScreen ? "large" : "md"}
                        variant="square"
                        type="contained"
                        lightMode={
                          <DownloadIcon height={isXlScreen ? "18px" : "14px"} />
                        }
                        darkMode={
                          <DownloadIcon height={isXlScreen ? "18px" : "14px"} />
                        }
                      /> */}
                    </Box>
                  )}
                  {packages?.find(
                    (pkg) =>
                      pkg?.type?.toLowerCase() === "exe" && pkg?.downloadLink
                  ) && (
                    <Box
                      className={
                        mode === "dark"
                          ? "activity_track_package_dark"
                          : "activity_track_package"
                      }
                      onClick={() => downloadSundial("exe")}
                    >
                      <WindowsIcon />
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        px={"16px"}
                        flexGrow={1}
                      >
                        <Typography className="header">
                          {t("Sundial for Windows")}
                        </Typography>
                      </Box>

                      {/* <CustomIconButton
                        title={t("RAL0226")}
                        size={isXlScreen ? "large" : "md"}
                        variant="square"
                        type="contained"
                        lightMode={
                          <DownloadIcon height={isXlScreen ? "18px" : "14px"} />
                        }
                        darkMode={
                          <DownloadIcon height={isXlScreen ? "18px" : "14px"} />
                        }
                      /> */}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </div>
      ) : (
        <DndProvider backend={HTML5Backend}>
          <>
            <Selecto
              ref={selectoRef}
              dragContainer={window}
              selectableTargets={[
                "#calendar .cube",
                "#calendar .element",
                "#calendar li",
              ]}
              onDragStart={(e) => {
                // if (e.inputEvent.target.nodeName === "BUTTON") {
                //   return false;
                // }
                // return true;
                // Get the class name
                const className = e.inputEvent.target.className;
                if (!className || className instanceof Array) {
                  return false;
                }
                // Check for specific classes
                const validClasses = [
                  "rs__cell",
                  "rs__header",
                  "rs__time",
                  "MuiButtonBase",
                  "css-15awfqw",
                  "css-123scgp",
                  "MuiTypography-caption",
                ];
                try {
                  const containsValidClass = validClasses.some((cls) =>
                    className.includes(cls)
                  );

                  if (containsValidClass) {
                    return true; // Return true if any valid class is found
                  } else {
                    return false; // Return false if none of the valid classes are present
                  }
                } catch (e) {
                  return true;
                }
              }}
              hitRate={100}
              selectByClick={false}
              selectFromInside={true}
              continueSelect={true}
              ratio={0}
              toggleContinueSelect={["shift"]}
              onSelectStart={(e) => {
                isSelectingRef.current = true;
                setIsSelecting(true);
              }}
              onSelectEnd={(e) => {
                let selectedEvents = [];
                e.added.forEach((el) => {
                  let event = JSON.parse(el.id);
                  let unLoggedEvents = event?.events.filter((e) =>
                    CommonUtil.isEmptyString(e.projectId)
                  );
                  if (
                    unLoggedEvents?.length > 0 ||
                    CommonUtil.isEmptyString(event?.projectId)
                  ) {
                    let event = JSON.parse(el.id);
                    event.start = new Date(event?.start);
                    event.end = new Date(event?.end);
                    selectedEvents.push(event);
                    el.classList.remove("selected");
                  }
                });
                e.removed.forEach((el) => {
                  el.classList.remove("selected");
                });
                if (selectedEvents.length > 0) {
                  handleSelectedEvents(selectedEvents);
                }

                isSelectingRef.current = false;
                setIsSelecting(false);
              }}
              onScroll={({ direction }) => {
                scrollerRef.current?.scrollBy(
                  direction[0] * 10,
                  direction[1] * 10
                );
              }}
              onInnerScroll={({ container, direction }) => {
                container.scrollBy(direction[0] * 10, direction[1] * 10);
              }}
              scrollOptions={{
                container: scrollerRef,
                getScrollPosition: () => {
                  return [
                    scrollerRef.current?.scrollLeft,
                    scrollerRef.current?.scrollTop,
                  ];
                },
                throttleTime: 30,
                threshold: 0,
              }}
              innerScrollOptions={true}
            ></Selecto>
            <div
              id="calendar"
              className="viewport calendarView elements scroll selecto-area"
              ref={scrollerRef}
              onScroll={() => {
                selectoRef.current.checkScroll();
              }}
            >
              <Scheduler
                timeZone={CommonUtil.getTimeZoneId()}
                hourFormat={
                  getSystemSettings("timeformat")
                    ? getSystemSettings("timeformat") + ""
                    : "24"
                }
                loading={isLoading}
                navigation={false}
                disableViewNavigator
                ref={calendarRef}
                events={formatedEventsList}
                customEvents={undefined}
                resources={[]}
                editable={false}
                deletable={false}
                draggable={false}
                eventWidth={30}
                view={"day"}
                selectedDate={selectedDayByTz}
                day={{
                  startHour: startTime,
                  endHour: 24,
                  step: step,
                  startMinute: startMinute,
                  scrollToCurrentTime: true,
                }}
                eventRenderer={({ event, ...props }) => {
                  return (
                    <DraggableActivity
                      event={event}
                      stepRef={stepRef}
                      isSelecting={isSelectingRef.current}
                    />
                  );
                }}
              />
            </div>
          </>
        </DndProvider>
      )}
    </>
  );
}
