import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import Tooltip from "@mui/material/Tooltip";

const AppChart = ({ data, grandTotal }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null); 

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.dispose(); 
    }
    const chartInstance = echarts.init(chartRef.current);
    chartInstanceRef.current = chartInstance;

    const xAxisData = data.map((item) => item.categoryName);
    const seriesData = data.map((item) => ({
      value: item.totalDuration.toFixed(2),
      totalHours: item.totalHours,
      totalMinutes: item.totalMinutes,
      itemStyle: { color: item.color },
    }));

    const getOption = () => {
      const labelRotation = chartRef.current.offsetWidth < 400 ? 45 : 0;

      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: (params) => {
            const { totalHours, totalMinutes } = params[0].data;
            return `${params[0].name}: ${totalHours}h ${totalMinutes}m`;
          },
        },
        xAxis: {
          type: "category",
          data: xAxisData,
          axisLabel: {
            interval: 0,
            rotate: labelRotation,
            textStyle: {
              fontSize: 10,
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: { formatter: "{value} hrs" },
          show: false,
        },
        series: [
          {
            type: "bar",
            barWidth: "70%",
            data: seriesData,
          },
        ],
        grid: {
          bottom: labelRotation ? 80 : 40,
        },
      };
    };

    chartInstance.setOption(getOption());
    const handleResize = () => {
      chartInstance.setOption(getOption());
      chartInstance.resize();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      chartInstance.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [data, grandTotal]);

  return (
    <Tooltip>
      <div ref={chartRef} style={{ width: "100%", height: "250px" }} />
    </Tooltip>
  );
};

export default AppChart;
