import { Box, Typography, Tooltip } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { ReactComponent as CheckMarkIcon } from "../../assets/images/ralvie/selectTick.svg";
const CompanyBox = ({ c, payload, handleSelectClick, onKeyDown }) => {
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [c.name]);

  return (
    <Box
      className="companyBoxes"
      border={`1px solid ${
        payload?.companyId === c?.id ? "#FA9C2B" : "var(--input-background)"
      }`}
      boxShadow={`${
        payload?.companyId === c?.id
          ? "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
          : "none"
      }`}
      backgroundColor={`${
        payload?.companyId === c?.id
          ? "var(--input-background) !important"
          : "transparent"
      }`}
      position="relative"
      onClick={() => handleSelectClick(c?.id)}
      onKeyDown={onKeyDown}
      tabIndex={0} 
    >
      <Tooltip title={c.name} disableHoverListener={!isOverflowing}>
        <Typography
          ref={textRef}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {c.name}
        </Typography>
      </Tooltip>

      {payload?.companyId === c?.id && <CheckMarkIcon className="CheckMarkIcon" />}
    </Box>
  );
};

export default CompanyBox;
