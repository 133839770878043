import { css } from "@emotion/css";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as RefreshIconDark } from "../../assets/images/refresh_icon_dark.svg";
import { ReactComponent as SettingsIconDark } from "../../assets/images/settings-icon-dark.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings-icon.svg";
import CustomButton from "../../components/CustomButton";
import { HeaderCustomIconButton } from "../../components/CustomIconButton";
import ContentLayout from "../../components/layout-components/ContentLayout";
import Typewriter from "../../components/Typewriter";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import BarChart from "./BarChart";
import DashboardActivityToolBar from "./DashboardActivityToolBar";
import DashboardActivityView from "./DashboardActivityView";
import "./DashboardLayout.scss";
import DepartmentFilter from "./DepartmentFilter";
import MemberFilter from "./MemberFilter";
import MostUsedApps from "./MostUsedApps";
import PieChart from "./PieChart";
import ProjectChart from "./ProjectChart";
import TimesheetWeekPicker from "./TimesheetWeekPicker";
import UserActiveHours from "./UserActiveHours";
import { listApplicationSettings } from "../../services/ApplicationSettingsService";
import DashboardWorklog from "./DashboardWorklog";

export default function DashboardLayout() {
  const { mode } = useContext(AppContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [eventsToLog, setEventsToLog] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedMemberName, setSelectedMemberName] = useState("Ralvie AI");
  const [memberFilter, setMemberFilter] = useState("All members");
  const [departmentFilter, setDepartmentFilter] = useState(["All departments"]);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const timeRef = useRef();
  const mostUsedAppsRef = useRef();
  const navigate = useNavigate();
  const [generateSummary, setGenerateSummary] = useState(false);
  const [eventStartDate, setEventStartDate] = useState();
  const [eventEndDate, setEventEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [textSummary, setTextSummary] = useState(undefined);
  const [applicationRules, setApplicationRules] = useState([]);
  const [loggedHours, setLoggedHours] = useState(null);
  const [dashboardFilter, setDashboardFilter] = useState({
    userId: "All members",
    startDate: dayjs().startOf("week").subtract(1, "day").toDate(),
    endDate: dayjs().endOf("week").toDate(),
    departmentFilter: ["All departments"],
    formattedStartTime: "",
    formattedEndTime: "",
  });

  useEffect(() => {
    if (!CommonUtil.isOwnerOrAdmin()) {
      setMemberFilter(CommonUtil.getLoggedUser()?.id);
      setDashboardFilter({
        ...dashboardFilter,
        userId: CommonUtil.getLoggedUser()?.id,
      });
    }
  }, []);

  useEffect(() => {
    listApplicationSettings().then((res) => {
      if (
        res.data?.code === "RCI0000" &&
        res.data?.data?.applicationRules instanceof Array
      ) {
        setApplicationRules(res.data?.data?.applicationRules);
      }
    });
  }, []);

  const [filter, setFilter] = useState({
    logged: true,
    unlogged: true,
    hidden: false,
    title: "",
    selectedDate: dayjs(),
    activityDate: dayjs(),
  });

  const handleRefresh = () => {
    setMemberFilter(
      CommonUtil.isOwnerOrAdmin()
        ? "All members"
        : CommonUtil.getLoggedUser()?.id
    );
    setDepartmentFilter(["All departments"]);
    setSelectedMemberName("Ralvie AI");
    if (timeRef.current) {
      timeRef.current.resetState();
    }
    if (mostUsedAppsRef.current) {
      mostUsedAppsRef.current.resetState();
    }

    setDashboardFilter({
      ...dashboardFilter,
      departmentFilter: ["All departments"],
      userId: "All members",
      startDate: dayjs().startOf("week").subtract(1, "day").toDate(),
      endDate: dayjs().endOf("week").toDate(),
    });

    setFilter({
      logged: true,
      unlogged: true,
      hidden: false,
      title: "",
      selectedDate: dayjs(),
      activityDate: dayjs(),
    });

    setReload(!reload);
  };

  const handleWeek = (startDate, endDate) => {
    const currentTimestamp = Date.now();
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    const yesterdayTimestamp = currentTimestamp - oneDayInMillis;
    const yesterdayDate = new Date(yesterdayTimestamp);
    const formattedYesterday = `${yesterdayDate.getFullYear()}-${(
      "0" +
      (yesterdayDate.getMonth() + 1)
    ).slice(-2)}-${("0" + yesterdayDate.getDate()).slice(-2)}`;
    const formattedStartDate = CommonUtil.formatDateToUTCTime(
      startDate ? startDate : formattedYesterday
    );
    const formattedEndDate = CommonUtil.formatDateToUTCTime(
      endDate ? endDate : Date.now()
    );
    setEventStartDate(formattedStartDate);
    setEventEndDate(formattedEndDate);
  };

  // useEffect(() => {
  //   handleWeek();
  // }, []);

  const handleDepartmentFilterChange = (newDepartmentFilter) => {
    setDepartmentFilter(newDepartmentFilter);
    let userId = dashboardFilter.userId;
    if (
      newDepartmentFilter.length > 0 &&
      !newDepartmentFilter.includes("All departments")
    ) {
      userId = "All members";
      setMemberFilter("All members");
    }
    // Clear member filter when department filter changes
    setDashboardFilter({
      ...dashboardFilter,
      departmentFilter: newDepartmentFilter,
      userId: userId,
    });
  };

  const handleMemberFilterChange = (newMemberFilter) => {
    setMemberFilter(newMemberFilter);
    setDashboardFilter({
      ...dashboardFilter,
      userId: newMemberFilter,
    });
  };

  const handleSettings = () => {
    navigate("/categoriesApps");
  };

  const handleDateChange = (date) => {
    setFilter({ ...filter, selectedDate: date });
    setEventsToLog([]);
  };

  const actions = (
    <Box display="flex">
      <TimesheetWeekPicker
        ref={timeRef}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        selectedDay={dayjs()}
      />

      {CommonUtil.isOwnerOrAdmin() && (
        <>
          <Box>
            <DepartmentFilter
              departmentFilter={departmentFilter}
              setDepartmentFilter={handleDepartmentFilterChange}
            />
          </Box>
        </>
      )}
      {CommonUtil.isOwnerOrAdminOrTeamLead() && (
        <>
          <Box>
            <MemberFilter
              memberFilter={memberFilter}
              setMemberFilter={handleMemberFilterChange}
              setSelectedMemberName={setSelectedMemberName}
              departmentFilter={departmentFilter}
            />
          </Box>
        </>
      )}
      {CommonUtil.isOwnerOrAdmin() && (
        <Box
          item
          pr={2}
          pl={2}
          sx={{
            "& .settings-svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#4f4f4f",
            },
            "&:hover": {
              "& .hover-text, & .settings-svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <HeaderCustomIconButton
            title="Settings"
            size={isXlScreen ? "large" : "md"}
            variant="square"
            mode={mode}
            lightMode={
              <SettingsIcon
                height={isXlScreen ? "18px" : "14px"}
                className="settings-svg"
              />
            }
            darkMode={
              <SettingsIconDark
                height={isXlScreen ? "18px" : "14px"}
                className="settings-svg"
              />
            }
            onClick={handleSettings}
          />
        </Box>
      )}
      <Box
        item
        sx={{
          "& .refresh-svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .refresh-svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
      >
        <HeaderCustomIconButton
          id="btn-refresh-dashboard"
          title={t("RAL0169")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          lightMode={
            <RefreshIcon
              height={isXlScreen ? "18px" : "14px"}
              className="refresh-svg"
            />
          }
          darkMode={
            <RefreshIconDark
              height={isXlScreen ? "18px" : "14px"}
              className="refresh-svg"
            />
          }
          onClick={handleRefresh}
        />
      </Box>
    </Box>
  );

  return (
    <TimesheetContext.Provider
      value={{
        reload,
        setReload,
        eventsToLog,
        setEventsToLog,
        applicationRules,
        setApplicationRules,
      }}
    >
      <ContentLayout
        className="DashboardLayout"
        title={
          selectedMemberName?.length > 10 ? (
            <Tooltip title={selectedMemberName}>
              <Typography className="title-text text-truncate">
                {`${selectedMemberName.substring(0, 10)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography className="title-text text-truncate">
              {selectedMemberName}
            </Typography>
          )
        }
        actions={actions}
        bg={"transparent"}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            grid-gap: 24px;
            width: calc(100% - 24px);
          `}
        >
          <div
            className={css`
              width: 70%;
              height: 85vh;
              overflow-y: scroll;
              flex: none;
            `}
          >
            <div
              className={css`
                display: flex;
                grid-gap: 24px;
                width: 100%;
                min-height: 50%;
                margin-bottom: 24px;
              `}
            >
              {/* PieChart */}
              <div
                className={css`
                  min-height: 100%;
                  max-height: 100%;
                  width: 50%;
                  background: var(--card-background-color) 0% 0% no-repeat
                    padding-box;
                  border-radius: 5px;
                  opacity: 1;
                  padding: 20px 20px;
                  flex: 0 0 calc(50% - 12px);
                  overflow: hidden;
                `}
              >
                <PieChart
                  dashboardFilter={dashboardFilter}
                  userId={memberFilter}
                  startDate={startDate}
                  endDate={endDate}
                  departmentFilter={departmentFilter}
                />
              </div>
              {/* ProjectChart */}
              <div
                className={css`
                  width: 50%;
                  background: var(--card-background-color) 0% 0% no-repeat
                    padding-box;
                  border-radius: 5px;
                  opacity: 1;
                  flex: 0 0 calc(50% - 12px);
                  // overflow: hidden;
                `}
              >
                {memberFilter === "All members" && (
                  <div
                    className={css`
                      position: relative;
                      height: 95%;
                      width: 100%;
                      background: var(--card-background-color) 0% 0% no-repeat
                        padding-box;
                      border-radius: 5px;
                      opacity: 1;
                    `}
                  >
                    <ProjectChart
                      userId={memberFilter}
                      startDate={startDate}
                      endDate={endDate}
                      departmentFilter={departmentFilter}
                    />
                  </div>
                )}

                {memberFilter !== "All members" && (
                  <div
                    className={css`
                      position: relative;
                      height: 95%;
                      width: 100%;
                      background: var(--card-background-color) 0% 0% no-repeat
                        padding-box;
                      border-radius: 5px;
                      opacity: 1;
                    `}
                  >
                    <MostUsedApps
                      ref={mostUsedAppsRef}
                      dashboardFilter={dashboardFilter}
                      userId={memberFilter}
                      startDate={startDate}
                      departmentFilter={departmentFilter}
                      endDate={endDate}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* BarChart */}
            <div
              className={css`
                display: flex;
                grid-gap: 24px;
                width: 100%;
                min-height: 50%;
              `}
            >
              <BarChart
                dashboardFilter={dashboardFilter}
                userId={memberFilter}
                startDate={startDate}
                endDate={endDate}
                departmentFilter={departmentFilter}
              />
            </div>
            {/* UserActiveHours */}
            {CommonUtil.isOwnerOrAdminOrTeamLead() &&
              memberFilter === "All members" && (
                <div
                  className={css`
                    display: flex;
                    grid-gap: 24px;
                    width: 100%;
                    min-height: 50%;
                    margin-top: 24px;
                  `}
                >
                  <UserActiveHours
                    startDate={startDate}
                    endDate={endDate}
                    departmentFilter={departmentFilter}
                  />
                </div>
              )}
          </div>
          <div
            className={css`
              position: relative;
              height: 85vh;
              min-width: 30%;
              max-width: 30%;
              overflow-y: auto;
              background: var(--card-background-color) 0% 0% no-repeat
                padding-box;
              border-radius: 5px;
              opacity: 1;
            `}
          >
            {!textSummary && memberFilter === "All members" && (
              <MostUsedApps
                ref={mostUsedAppsRef}
                dashboardFilter={dashboardFilter}
                userId={memberFilter}
                startDate={startDate}
                departmentFilter={departmentFilter}
                endDate={endDate}
              />
            )}
            {!textSummary && memberFilter !== "All members" && (
              <>
                {/* <DashboardActivityToolBar
                  filter={filter}
                  handleDateChange={handleWeek}
                  startDate={startDate}
                  endDate={endDate}
                  // eventStartDate={eventStartDate}
                  onChange={(date) =>
                    setFilter({ ...filter, activityDate: date })
                  }
                  setGenerateSummary={(state) => setGenerateSummary(state)}
                />
                <DashboardActivityView
                  dashboardFilter={dashboardFilter}
                  isLoading={isLoading}
                  setIsLoading={(state) => setIsLoading(state)}
                  setTextSummary={(summary) => setTextSummary(summary)}
                  generateSummary={generateSummary}
                  userId={memberFilter}
                  zoomLevel={10}
                  filter={filter}
                  startDate={eventStartDate}
                  endDate={eventEndDate}
                  setGenerateSummary={(state) => setGenerateSummary(state)}
                /> */}

                <DashboardActivityToolBar
                  id="btn-day-clndr"
                  onChange={handleDateChange}
                  selectedDate={filter.selectedDate}
                  setGenerateSummary={(state) => setGenerateSummary(state)}
                  loggedHours={loggedHours}
                />

                <DashboardWorklog
                  dashboardFilter={dashboardFilter}
                  isLoading={isLoading}
                  setIsLoading={(state) => setIsLoading(state)}
                  setTextSummary={(summary) => setTextSummary(summary)}
                  generateSummary={generateSummary}
                  userId={memberFilter}
                  zoomLevel={10}
                  filter={filter}
                  startDate={filter.selectedDate}
                  endDate={filter.selectedDate}
                  setGenerateSummary={(state) => setGenerateSummary(state)}
                  setLoggedHours={setLoggedHours}
                />
              </>
            )}

            {textSummary && (
              <div
                className={css`
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  padding: 24px 24px;
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 16px;
                  `}
                >
                  <Typography
                    className={css`
                      font: normal normal bold 20px/22px GothamBold !important;
                      letter-spacing: 0px;
                      color: #242424 !important;
                      opacity: 1;
                    `}
                  >
                    {dayjs(eventStartDate).isSame(dayjs(), "day")
                      ? "Today's"
                      : dayjs(eventStartDate)?.format("DD-MM-YY")}
                    {" summary"}
                  </Typography>
                </div>
                <div
                  className={css`
                    height: 80%;
                    overflow: auto;
                  `}
                >
                  <div
                    className={css`
                      height: 100%;
                    `}
                  >
                    {/* <div dangerouslySetInnerHTML={{ __html: textSummary }} /> */}
                    <div>
                      <Typewriter text={textSummary} delay={10} />
                    </div>
                  </div>
                </div>
                <Box
                  pt={2}
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  minWidth={"100%"}
                  columnGap={2}
                >
                  <CustomButton
                    variant="outlined"
                    onClick={() => setTextSummary(undefined)}
                  >
                    Close
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    onClick={() => {
                      CommonUtil.copyToClipboard(textSummary);
                      setTextSummary(undefined);
                    }}
                  >
                    Copy
                  </CustomButton>
                </Box>
              </div>
            )}
            {isLoading && (
              <Box
                sx={{
                  display: isLoading ? "flex" : "none",
                }}
              >
                <CircularProgress
                  style={{
                    top: "50%",
                    left: "85%",
                    position: "fixed",
                    zIndex: 999,
                  }}
                />
              </Box>
            )}
          </div>
        </div>
      </ContentLayout>
    </TimesheetContext.Provider>
  );
}
