import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  useTheme,
  useMediaQuery,
  styled,
  InputBase,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as RefreshIconHoverDark } from "../../assets/images/refresh_icon_hover_dark.svg";
import { ReactComponent as RefreshIconHover } from "../../assets/images/refresh_icon_hover.svg";
import { ReactComponent as RefreshIconDark } from "../../assets/images/refresh-icon-dark.svg";
import { ReactComponent as EmptyProjectList } from "../../assets/images/ralvie/noProjects.svg";
import { ReactComponent as EmptyProjectListDark } from "../../assets/images/ralvie/noProjectsDark.svg";
import { ReactComponent as AddIcon } from "../../assets/images/add-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit_icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import CustomIconButton from "../../components/CustomIconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CloseIconImg } from "../../assets/images/close-icon.svg";
import "./department.scss";
import TagTextField from "../tags/TagTextField";
import CustomButton from "../../components/CustomButton";
import { toast } from "sonner";
import DoneIcon from "@mui/icons-material/Done";
import {
  createDepartment,
  updateDepartmentAPI,
  departmentList,
  downloadDepartmentImportTemplate,
  importDepartments,
  softDeleteDepartment,
} from "../../services/DepartmentService";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ImportDialog from "../../components/ImportDialog";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArchiveIcon } from "../../assets/images/ralvie/archive.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/ralvie/archiveDark.svg";
import CustomSearchField from "../../components/CustomSearchField";
import AppContext from "../../config/AppContext";
import ArchiveDialog from "../project/ArchiveDialog";

function Department() {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isLargeScreen = useMediaQuery("(min-width: 1500px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1500px)"
  );
  const isSmallScreen = useMediaQuery("(max-width: 1199px)");
  const { t } = useTranslation();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [add, setAdd] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const [editingDepartmentId, setEditingDepartmentId] = useState(null);
  const [editDepartmentName, setEditDepartmentName] = useState("");
  const [error, setError] = useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [selectedDeptmentId, setSelectedDeptmentId] = useState(null);
  const [dialogActionType, setDialogActionType] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);

  const { mode } = useContext(AppContext);

  const loadData = () => {
    const payload1 = {
      pageNumber: 1,
      pageSize: 1000,
    };
    departmentList(payload1).then((res) => {
      if (res.data.code === "RCI0000") {
        setDepartments(res.data.data.departments);
      }
    });
  };

  const [newDepartment, setNewDepartment] = useState({
    id: "",
    name: "",
  });

  const [filter, setFilter] = useState({
    name: "",
    toggleKeyToURL: true,
  });

  useEffect(() => {
    console.log("useEffect called");
    loadDepartments();
  }, [filter]);

  const loadDepartments = () => {
    setIsLoading(true);
    setDepartments([]);
    departmentList(filter).then((res) => {
      if (res?.data?.code === "RCI0000") {
        setDepartments(res.data.data.departments);
      }
      setIsLoading(false);
    });
  };

  const resetFilter = () => {
    setFilter({ name: "" });
    setNewDepartment({
      id: "",
      name: "",
    });
    setEditingDepartmentId(null);
    setClearSearch(true);
  };

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      setFilter({ name: value });
      setClearSearch(false);
    }
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: 4,
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: isXlScreen ? "12px 26px 12px 12px" : "9px 26px 7px 12px",
      backgroundColor: "transparent !important",
      opacity: 1,
      font: "normal normal normal 16px/20px Gotham",
      color: "#242424",
    },
  }));

  const NoDepartments = () => {
    return (
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        marginTop={"150px"}>
        <Box
          item
          md="12">
          {mode == "dark" ? <EmptyProjectListDark /> : <EmptyProjectList />}
        </Box>

        <Typography
          variant="h6"
          className="noProjectError">
          {isSearchMode ? t("RAL0384") : t("RAL0178")}
        </Typography>
      </Box>
    );
  };

  // const handleImportDepartments = (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   setIsLoading(true);
  //   importDepartments(formData).then((res) => {
  //     setIsLoading(false);
  //     if (res.data?.code === "RCI0000") toast.success(t("RAL0375"));
  //     else toast.error(res.data.message);
  //     resetFilter();
  //   });
  // };

  const handleOpen = (id) => {
    setSelectedDepartmentId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDepartmentId(null); // Clear the selected department ID on close
  };

  const handleCloseDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleArchiveOpenDept = (id) => {
    setSelectedDeptmentId(id);
    setOpenArchiveDialog(true);
    setDialogActionType("archive");
  };

  const handleArchiveDepartment = async () => {
    try {
      if (selectedDeptmentId) {
        const response = await softDeleteDepartment(selectedDeptmentId);
        if (response.data?.code === "RCI0000") {
          setDepartments((prevDepartments) =>
            prevDepartments.filter((dept) => dept.id !== selectedDeptmentId)
          );
          handleCloseDialog();
          toast.success(t("RAL0190"));
        } else {
          toast.error(response.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  const handleClearSearch = () => {
    setFilter({ name: "" });
    setClearSearch(true);
  };

  const actions = (
    <>
      <Box display="flex">
        <Box
          item
          pr={2.5}>
          <CustomSearchField
            width="350px"
            id="btn-search-tag"
            name="btn-search-tag"
            clearSearch={clearSearch}
            // onChange={() => setClearSearch(false)}
            onChange={(newValue) => handleSearch(newValue)}
            onClear={handleClearSearch}
            label={t("RAL0364")}
            value={filter.name}
            size={isXlScreen ? "large" : "md"}
            onClick={(value) => {
              handleSearch(value);
            }}
          />
        </Box>

        {/* <Box item pr={2}>
          <ImportDialog
            title={"Import departments"}
            downloadTemplate={() => downloadDepartmentImportTemplate()}
            onImport={(file) => handleImportDepartments(file)}
          />
        </Box> */}

        <Box
          item
          pr={2.5}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onMouseOver={() => {
            setIsHovered(true);
          }}>
          {isHovered ? (
            <CustomIconButton
              id="btn-refresh-department"
              title={t("RAL0169")}
              size={isXlScreen ? "large" : "md"}
              variant="square"
              lightMode={
                mode == "light" ? (
                  <RefreshIconHover height={isXlScreen ? "18px" : "14px"} />
                ) : (
                  <RefreshIconHoverDark height={isXlScreen ? "18px" : "14px"} />
                )
              }
              darkMode={
                mode == "light" ? (
                  <RefreshIconHover height={isXlScreen ? "18px" : "14px"} />
                ) : (
                  <RefreshIconHoverDark height={isXlScreen ? "18px" : "14px"} />
                )
              }
              loadDepartments={loadDepartments}
              onClick={() => resetFilter()}></CustomIconButton>
          ) : (
            <CustomIconButton
              id="btn-refresh-department"
              title={t("RAL0169")}
              size={isXlScreen ? "large" : "md"}
              variant="square"
              lightMode={
                mode == "light" ? (
                  <RefreshIcon height={isXlScreen ? "18px" : "14px"} />
                ) : (
                  <RefreshIconDark height={isXlScreen ? "18px" : "14px"} />
                )
              }
              darkMode={
                mode == "light" ? (
                  <RefreshIcon height={isXlScreen ? "18px" : "14px"} />
                ) : (
                  <RefreshIconDark height={isXlScreen ? "18px" : "14px"} />
                )
              }
              onClick={() => resetFilter()}></CustomIconButton>
          )}
        </Box>
        <CustomIconButton
          id="btn-add-department"
          title={t("RAL0170")}
          variant="square"
          size={isXlScreen ? "large" : "md"}
          type="contained"
          border={"none"}
          lightMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
          darkMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
          onClick={() => setAdd(true)}
          onChange={(e) =>
            setNewDepartmentName(e.target.value)
          }></CustomIconButton>
      </Box>
    </>
  );

  // Add Department
  const handleAddChange = (e) => {
    const value = e.target.value;
    setNewDepartment({ ...newDepartment, name: value });
    if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)) {
      setError("Invalid department name");
    } else {
      setError("");
    }
  };

  const resetOnAdd = () => {
    toast.success(t("RAL0188"));
    setAdd(false);
    setError("");
    setNewDepartment({ name: "" });
    loadData();
  };

  const handleAddDepartment = () => {
    const payload = {
      name: newDepartment.name,
    };

    createDepartment(payload).then((response) => {
      if (response.data?.code !== "RCI0024") {
        setError(response.data?.message);
      } else {
        resetOnAdd();
      }
    });
  };

  const addDepartment = () => {
    return (
      <>
        <Grid
          item
          xs={isLargeScreen ? 3 : isMediumScreen ? 4 : isSmallScreen ? 6 : 12}>
          <Grid
            item
            // md="2.9"
            // sm="3.8"
            // xs="12"
            className="department-box"
            // mr={2}
            // mb={1}
          >
            <Box
              display="flex"
              alignItems="center"
              // className="department"
              justifyContent="center"
              width="100% !important"
              height="60px"
              spacing={3}
              top="188px"
              left="360px"
              borderRadius="5px">
              <Box
                display={"flex"}
                alignItems={"center"}
                className={"new-department " + (error ? "error-box" : "")}
                justifyContent={"center"}
                flexDirection={"row-reverse"}>
                <Box
                  display={"flex"}
                  flexGrow={1}
                  className="department-name"
                  alignItems={"center"}>
                  <Box
                    item
                    flexGrow={1}>
                    <TagTextField
                      id="text-dep-name"
                      label={t("RAL0174")}
                      error={!!error}
                      helperText={error}
                      onChange={handleAddChange}
                      inputProps={{ maxLength: 25 }}
                    />
                  </Box>

                  <Box
                    item
                    pr={2}>
                    <CustomButton
                      disabled={error || !newDepartment.name}
                      id="text-dep-add"
                      size="small"
                      variant="contained"
                      onClick={handleAddDepartment}>
                      {t("RAL0170")}
                    </CustomButton>
                  </Box>

                  {/* <Box
                    sx={{
                      height: "20px",
                      width: "20px",
                      background: "#FE5050 0% 0% no-repeat padding-box",
                      borderRadius: "4px",
                      opacity: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      // marginLeft: "15px",
                    }}
                    onClick={() => {
                      setAdd(false);
                      setError("");
                      setNewDepartment({ name: "" });
                    }}
                  >
                    <Tooltip title={t("RAL0177")}>
                      <CloseIconImg />
                    </Tooltip>
                  </Box> */}

                  <Box
                    sx={{
                      height: "20px",
                      width: "20px",
                      background: "#FE5050 0% 0% no-repeat padding-box",
                      borderRadius: "4px",
                      opacity: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAdd(false);
                      setError("");
                      setNewDepartment({ name: "" });
                    }}>
                    <Tooltip title={t("RAL0177")}>
                      <CloseIconImg style={{ fill: "white" }} />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
            {error && (
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{ position: "relative", top: "15px" }}>
                <Box
                  item
                  pr={1}
                  pt={0.2}>
                  {
                    <ErrorIcon
                      height={14}
                      width={16}
                    />
                  }
                </Box>
                <Box item>
                  <Typography className="helper-text">{error}</Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  // Edit Department
  const handleEditDepartment = (department) => {
    setEditingDepartmentId(department.id);
    setEditDepartmentName(department.name);
  };

  const handleEditChange = (e) => {
    const value = e.target.value;
    setEditDepartmentName(value);
    if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)) {
      setError("Invalid department name");
    } else {
      setError("");
    }
  };

  const resetOnEdit = () => {
    toast.success(t("RAL0189"));
    setEditingDepartmentId(null);
    setError("");
    setEditDepartmentName("");
    loadData();
  };

  const handleEditSubmit = () => {
    const payload = {
      name: editDepartmentName,
    };

    updateDepartmentAPI(editingDepartmentId, payload)
      .then((response) => {
        if (response.data?.code !== "RCI0025") {
          setError(response.data?.message);
        } else {
          resetOnEdit();
        }
      })
      .catch((error) => {
        console.error("Error updating department:", error);
        setError("An error occurred while updating the department.");
      });
  };

  const department = (data) => {
    const isEditing = editingDepartmentId === data.id;

    return (
      <Grid
        item
        // md="2.9"
        // sm="3.8"
        // xs="12"
        className="department-box"
        // mr={2}
        // mb={1}
      >
        <Box
          // mt={3}
          display="flex"
          alignItems="center"
          className="department"
          justifyContent="center"
          width="100% !important"
          height="60px"
          spacing={3}
          top="188px"
          left="360px"
          borderRadius="5px"
          sx={{
            backgroundColor: isEditing
              ? "var(--background-color-19)"
              : "var(--background-color-18)",
          }}>
          <Box
            width="70%"
            p={"5px"}
            m={"5px"}
            flexGrow={1}>
            {isEditing ? (
              <TagTextField
                id="text-tags"
                label="Department name"
                value={editDepartmentName}
                onChange={handleEditChange}
                inputProps={{ maxLength: 25 }}
              />
            ) : (
              <Typography
                sx={{
                  font: "normal normal normal 16px/20px Gotham",
                  wordBreak: "break-word",
                }}
                variant="body1"
                color="var(--text-secondary)"
                textAlign="left">
                {data?.name}
              </Typography>
            )}
          </Box>

          <Box
            display={"flex"}
            flexGrow={1}
            alignItems={"center"}>
            {isEditing ? (
              <>
                <Box>
                  <CustomButton
                    disabled={error || !editDepartmentName}
                    size="small"
                    variant="contained"
                    onClick={handleEditSubmit}>
                    {t("RAL0309")}
                  </CustomButton>
                </Box>
                {/* <Box
                  onClick={handleEditSubmit}
                  sx={{
                    height: "20px",
                    width: "20px",
                    background: "#0FAA51 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                >
                  <Tooltip title={t("RAL0176")}>
                    <DoneIcon sx={{ p: "2px" }} />
                  </Tooltip>
                </Box> */}
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    background: "#FE5050 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    // marginTop: "10px",
                    marginInline: "15px",
                  }}
                  onClick={() => {
                    setEditingDepartmentId(null);
                    setError("");
                  }}>
                  <Tooltip title={t("RAL0177")}>
                    <CloseIconImg style={{ fill: "white" }} />
                  </Tooltip>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ marginLeft: "20px" }}>
                  <Tooltip title={t("RAL0172")}>
                    <IconButton
                      sx={{ borderRadius: "5px" }}
                      onClick={() => handleEditDepartment(data)}>
                      {mode === "dark" ? (
                        <EditIconDark id="btn-edit-tag" />
                      ) : (
                        <EditIcon id="btn-edit-tag" />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t("RAL0173")}>
                    <IconButton sx={{ borderRadius: "5px" }}>
                      {mode == "dark" ? (
                        <ArchiveIconDark
                          onClick={() => handleArchiveOpenDept(data.id)}
                        />
                      ) : (
                        <ArchiveIcon
                          onClick={() => handleArchiveOpenDept(data.id)}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {isEditing && error && (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ position: "relative", top: "15px" }}>
            <Box
              item
              pr={1}
              pt={0.2}>
              {
                <ErrorIcon
                  height={14}
                  width={16}
                />
              }
            </Box>
            <Box item>
              <Typography className="helper-text">{error}</Typography>
            </Box>
          </Box>
        )}
      </Grid>
    );
  };

  return (
    <>
      <ContentLayout
        title={t("RAL0167")}
        type="form"
        actions={actions}
        navBack={false}
        subtitle={t("RAL0168")}>
        {/* {add && addDepartment()} */}

        <Grid
          container
          spacing={3}>
          {isLoading ? (
            <Box
              display={"flex"}
              height={"100%"}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              marginTop={"150px"}>
              <CircularProgress />
            </Box>
          ) : departments.length > 0 || add ? (
            <>
              {add && addDepartment()}

              {departments.map((t, index) => (
                <Grid
                  item
                  xs={
                    isLargeScreen
                      ? 3
                      : isMediumScreen
                      ? 4
                      : isSmallScreen
                      ? 6
                      : 12
                  }
                  key={index}>
                  {department(t)}
                </Grid>
              ))}
            </>
          ) : (
            <NoDepartments />
          )}
        </Grid>
        <ArchiveDialog
          open={openArchiveDialog}
          onClose={handleCloseDialog}
          onConfirm={
            dialogActionType === "complete" ? "" : handleArchiveDepartment
          }
          name="department"
          type="Department"
        />
      </ContentLayout>
    </>
  );
}
export default Department;
