import { Box, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import AppIcon from "../../components/AppIcon";
import CommonUtil from "../../util/CommonUtils";
import EventDetails from "./EventDetails";

export default function Event(props) {
  function formatTime(seconds) {
    if (seconds < 60) {
      return Math.ceil(seconds) === 0 ? "1sec" : Math.ceil(seconds) + "sec";
    } else if (seconds < 3600) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      return minutes.toString() + "min," + remainingSeconds.toString() + "sec";
    } else {
      let hours = Math.floor(seconds / 3600);
      return hours.toString() + "hour";
    }
  }
  function formatTimeDifference(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const secondsDifference = diffInMilliseconds / 1000;

    if (secondsDifference < 1) {
      return `01sec`;
    } else if (secondsDifference < 60) {
      const seconds = Math.floor(secondsDifference);
      return `${String(seconds).padStart(2, "0")}sec`;
    } else if (secondsDifference < 3600) {
      const minutes = Math.floor(secondsDifference / 60);
      return `${String(minutes).padStart(2, "0")}mins`;
    } else {
      const hours = Math.floor(secondsDifference / 3600);
      const remainingMinutes = Math.floor((secondsDifference % 3600) / 60);
      return `${String(hours).padStart(2, "0")}:${String(
        remainingMinutes
      ).padStart(2, "0")}hrs`;
    }
  }

  function timeDifferenceInMins(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const minutesDifference = diffInMilliseconds / (1000 * 60); // 1 minute = 60 seconds = 60,000 milliseconds
    return minutesDifference;
  }
  let time =
    props?.event.start.toLocaleTimeString("en-US", {
      timeStyle: "short",
    }) +
    " - " +
    props?.event.end.toLocaleTimeString("en-US", {
      timeStyle: "short",
    });
  let description =
    props?.event?.data?.title?.replace("*", "") +
    " - " +
    props?.event?.data?.app?.split(".")[0] +
    " - " +
    time;
  let title = props?.event?.data?.app?.split(".")[0];
  // if (
  //   props?.event?.data?.url ||
  //   title.toLowerCase() === "code" ||
  //   title.toLowerCase() === "applicationframehost"
  // ) {
  //   title = props?.event?.data?.title?.replace("*", "");
  //   if (!CommonUtil.isEmptyString(title)) {
  //     let titles = title?.split(/\s-\s|\s\|\s/);
  //     if (titles && titles instanceof Array) {
  //       title =
  //         titles.length > 1
  //           ? titles[titles.length - 2]
  //           : titles[titles.length - 1];
  //     }
  //   }
  // }

  if (title.toLowerCase() === "afk") {
    title = "Idle time";
  }

  // let durationInMinutes = formatTimeDifference(
  //   new Date(props?.event.start),
  //   new Date(props?.event.end)
  // );
  //   new Date(props?.event.end).getMinutes() -
  //   new Date(props?.event.start).getMinutes();
  // durationInMinutes = durationInMinutes < 1 ? 1 : durationInMinutes;

  let showDetails =
    timeDifferenceInMins(
      new Date(props?.event.start),
      new Date(props?.event.end)
    ) >= 3;
  if (props.stepRef.current === 1) {
    showDetails = true;
  }

  // durationInMinutes =
  //   durationInMinutes <= 1 ? "1 min" : durationInMinutes + " mins";

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "100%",
      maxHeight: "300px",
      overflowY: "scroll",
      padding: "0px",
      margin: "0px",
      backgroundColor: "#f5f5f9",
      color: "black",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  const getApplicationIcon = (data) => {
    return <AppIcon data={data} height={14} width={14} />;
  };
  return (
    <div
      style={{
        background: props.event.light,
        borderLeft: "5px solid " + props.event.dark,
        height: "100%",
      }}
      // {...props}
    >
      <CustomTooltip
        arrow
        title={<EventDetails event={props.event} />}
        placement="right-start"
      >
        <Box display={"flex"}>
          <Box item px={1} pt={0.5} sx={{ width: "10%", flexWrap: "nowrap" }}>
            {getApplicationIcon(props.event?.data)}
          </Box>
          <Box item flexGrow={1} sx={{ width: "90%", flexWrap: "nowrap" }}>
            <Box display={"block"} alignItems={"flex-start"}>
              <Box className="text_overflow">
                <Typography
                  textTransform={"capitalize"}
                  maxLength="20"
                  variant="text_12_17_1_1_m"
                  text={description}
                >
                  {title}
                </Typography>
              </Box>
              {showDetails && (
                <Box>
                  <Typography variant="text_10_10_1_1_m">{time}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* <Box item sx={{ width: "37%", flexWrap: "nowrap" }}>
            <Box
              display={"flex"}
              sx={{ minWidth: "100%", justifyContent: "flex-end" }}
            >
              <Box item>
                <Typography variant="text_10_10_1_1_m" px={1}>
                  {durationInMinutes}
                </Typography>
              </Box>
            </Box>
          </Box> */}
        </Box>
      </CustomTooltip>
    </div>
  );
}
