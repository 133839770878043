import React, { useContext } from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppContext from "../../config/AppContext";
import "./CancelDialog.scss";

export default function CancelDialog(props) {
  const { open, onClose, onConfirm, header, message } = props;
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);

  return (
    <CustomDialogBox
      className="CancelDialog"
      Header={header}
      acceptText={t("RAL0300")}
      cancelText={t("RAL0301")}
      fullWidth={true}
      onClose={onClose} 
      onSubmit={onConfirm}
      open={open}
      disableBackdropClick={true}
    >
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
        style={{ padding: "1.875rem", paddingTop: "0" }}
      >
        <Grid>
          <Grid
            style={{
              wordWrap: "break-word",
              opacity: 1,
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              fontSize: "17px",
            }}
          >
            <Typography
              className="dialogInfo"
              sx={{
                color:
                  mode === "light"
                    ? "#242424cc!important"
                    : "#f8f8f880!important",
              }}
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CustomDialogBox>
  );
}
