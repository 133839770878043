import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import OnboardService from "../../services/OnboardService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckCircleIcon } from "../../assets/images/check_circle_icon.svg";
import { toast } from "sonner";

export default function ActivateEmail() {
  const [activationStatus, setActivationStatus] = useState(null);
  let navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const encodedCredentials = searchParams.get("code");

    if (encodedCredentials) {
      const decodedCredentials = atob(encodedCredentials);
      const [userId, companyId] = decodedCredentials.split(":");

      OnboardService.activateEmail(userId, companyId).then((res) => {
        if (res.data.code === "RCI0012") {
          setActivationStatus(true);
        } else if (res.data.code === "RCE0068") {
          setActivationStatus(false);
        } else {
          toast.error(res.data?.message);
          // enqueueSnackbar(res.data?.message, { variant: "error" });
          setActivationStatus(false);
        }
      });
    }
  }, []);

  return (
    <>
      {activationStatus === true ? (
        <Box>
          <Box className="password_success_000">
            <CheckCircleIcon />
          </Box>
          <Box className="password_success_001">
            <span>{t("RAL0086")}</span>
          </Box>
          <Box className="password_success_002">
            <span>{t("RAL0160")}</span>
          </Box>
          <Box className="password_success_003">
            <span>{t("RAL0072")}</span> &nbsp;
            <span
              className="password_success_004"
              onClick={() => navigate("/")}
            >
              {t("RAL0006")}
            </span>
          </Box>
        </Box>
      ) : activationStatus === false ? (
        <Box>
          <Box className="password_success_000">
            <CheckCircleIcon />
          </Box>
          <Box className="password_success_001">
            <span>{t("RAL0093")}</span>
          </Box>
          <Box className="password_success_003">
            <span>{t("RAL0072")}</span> &nbsp;
            <span
              className="password_success_004"
              onClick={() => navigate("/")}
            >
              {t("RAL0006")}
            </span>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
