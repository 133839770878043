import React, { useContext, useState } from 'react';
import { css } from '@emotion/css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../../components/CustomTextField';
import CommonUtil from '../../util/CommonUtils';
import passwordService, {
  updatePasswordAPI,
} from '../../services/PasswordService';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import CustomPasswordField from '../../components/CustomPasswordField';
import AppContext from '../../config/AppContext';
import { ReactComponent as CheckCircleFill } from '../../assets/images/ralvie/check-circle-fill.svg';
import './Profile.scss';
import { toast } from 'sonner';

export default function PasswordForm({ type, ...props }) {
  const { mode } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const decoded = CommonUtil.decodeToken();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const [payload, setPayload] = useState({
    id: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: '',
    });
  };

  const validate = () => {
    // Resetting all errors before validation
    setError({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });

    if (!payload.oldPassword) {
      setError((prevError) => ({
        ...prevError,
        oldPassword: t('RAL0001'),
      }));
      return false;
    }

    if (!payload.newPassword) {
      setError((prevError) => ({
        ...prevError,
        newPassword: t('RAL0001'),
      }));
      return false;
    }

    if (payload.oldPassword === payload.newPassword) {
      setError((prevError) => ({
        ...prevError,
        newPassword: t('Old and new password can not be same'),
      }));
      return false;
    }

    if (!CommonUtil.isAlphaNumericPassword(payload.newPassword)) {
      setError((prevError) => ({
        ...prevError,
        newPassword: t('RAL0010'),
      }));
      return false;
    }

    if (!payload.confirmPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: t('RAL0001'),
      }));
      return false;
    }

    if (payload.confirmPassword !== payload.newPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: t('RAL0011'),
      }));
      return false;
    }
    return true;
  };

  const reset = () => {
    setPayload({
      id: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    setError({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    setOpen(false);
  };

  const handleSubmit = () => {
    // Reset the errors before validating
    setError({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });

    if (validate()) {
      const id = decoded.userId;
      const state = {
        ...payload,
      };
      passwordService.updatePasswordAPI(id, state).then((res) => {
        if (res.data.code === 'UASE0023') {
          // Set error message from backend
          setError((prevError) => ({
            ...prevError,
            oldPassword: res.data.message,
          }));
          return false;
        }
        toast.success(res.data?.message);
        // enqueueSnackbar(res.data?.message, { variant: "secondary" });
        // enqueueSnackbar(res.data?.message, {
        //   variant: "success",
        //   iconVariant: "custom", // Custom icon variant
        //   content: (key) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         height: "60px",
        //         alignItems: "center",
        //         color: "#fff",
        //         backgroundColor: "#141414",
        //         padding: "8px 16px",
        //         borderRadius: "4px",
        //       }}
        //     >
        //       <CheckCircleFill style={{ color: "#4caf50", marginRight: 8 }} />
        //       <span style={{ font: "normal normal 16px/28px Gotham" }}>
        //         {res.data?.message}
        //       </span>
        //     </div>
        //   ),
        // });
        reset();
      });
    }
  };

  return (
    <React.Fragment>
      <Button
        className='ChangePasswordText'
        onClick={handleClickOpen}
        sx={{
          fontWeight: 'normal',
          color: mode == 'light' ? '#1d0b77' : '#a49dc8',
        }}>
        Change password
      </Button>
      <Dialog
        className='changePasswordForm'
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          sx: {
            background: mode == 'light' ? '#ffffff' : '#101010',
          },
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}>
        <Grid
          container
          display={'flex'}
          className='dialogGrid'>
          <DialogTitle>
            <Grid
              item
              container
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}>
              <Grid
                item
                className={css`
                  color: 'var(--text-secondary)';
                  font: normal normal bold 20px/23px Gotham;
                `}>
                Change password
              </Grid>

              <Grid item>
                <IconButton
                  onClick={handleClose}
                  sx={{ borderRadius: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
        </Grid>

        <Grid
          item
          display={'flex'}
          flexDirection={'column'}>
          <DialogContent>
            <Grid item>
              <CustomPasswordField
                className='changePasswordError'
                required
                type='password'
                fullWidth
                label={t('RAL0159')}
                placeholder={"Enter current password"}
                value={payload.oldPassword}
                name='oldPassword'
                onChange={handleChange}
                error={error.oldPassword}
                helperText={error.oldPassword}
                {...props}
                inputProps={{
                  maxLength: 25,
                }}></CustomPasswordField>
            </Grid>

            <Grid item>
              <CustomPasswordField
                className='changePasswordError createNewPass'
                required
                fullWidth
                type='password'
                label={t('RAL0111')}
                placeholder={"Enter new password"}
                value={payload.newPassword}
                name='newPassword'
                onChange={handleChange}
                aut
                error={error.newPassword}
                helperText={error.newPassword}
                inputProps={{
                  maxLength: 25,
                }}></CustomPasswordField>
            </Grid>

            <Grid item>
              <CustomPasswordField
                className='changePasswordError'
                required
                fullWidth
                type='password'
                label={t('RAL0112')}
                value={payload.confirmPassword}
                name='confirmPassword'
                onChange={handleChange}
                error={error.confirmPassword}
                helperText={error.confirmPassword}
                inputProps={{
                  maxLength: 25,
                }}></CustomPasswordField>
            </Grid>

            <Grid
              item
              pt={2}>
              <Button
                variant='contained'
                sx={{
                  width: '100%',
                  height: '60px',
                  color: '#FFFFFF',
                  fontFamily: 'Gotham',
                  background:
                    'linear-gradient(95deg, #1D0B77 0%, #6A5FA2 100%)',
                }}
                onClick={handleSubmit}>
                Change password
              </Button>
            </Grid>
          </DialogContent>
        </Grid>

        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}