import React, { useContext, useState } from "react";
import Picker from "emoji-picker-react";
import AppContext from "../../config/AppContext";

function Emojipicker(props) {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const { mode } = useContext(AppContext);
  const handleEmojiSelect = (emojiObject, event) => {
    setChosenEmoji(emojiObject);
    console.log("Selected Emoji:", emojiObject); // Logging the selected emoji object
    props.onEmojiSelect(emojiObject); // Passing the selected emoji object to the parent component
  };

  return (
    <div>
      {chosenEmoji ? (
        <span>
          <img
            style={{ width: "15px" }}
            src={chosenEmoji.emoji}
            alt={chosenEmoji.names.join(", ")}
          />
        </span>
      ) : (
        <span>Select Emoji</span>
      )}
      <Picker
        className={mode == "dark" ? "dark-emoji" : "light-emoji"}
        onEmojiClick={handleEmojiSelect}
      />
    </div>
  );
}

export default Emojipicker;
