import { Autocomplete, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTextField from "./CustomTextField";
import "./LanguageSelector.scss";

const useStyles = makeStyles({
  flag: {
    marginRight: 8,
    width: 20,
    height: 15,
  },
  option: {
    display: "flex",
    alignItems: "center",
    font: "normal normal normal var(--font-size-14) / 26px Gotham"  },
});

const languageOptions = [
  { code: "us", label: "American English", flag: "https://flagcdn.com/us.svg" },
  { code: "cn", label: "Chinese", flag: "https://flagcdn.com/cn.svg" },
  { code: "jp", label: "Japanese", flag: "https://flagcdn.com/jp.svg" },
];

function LanguageSelector(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);
  useEffect(() => {
    try {
      props?.onChange(selectedLanguage);
    } catch {}
  }, [selectedLanguage]);

  return (
    <div className="autocomplete-nopadding">
      <Autocomplete
        clearIcon={false}
        value={selectedLanguage}
        onChange={(event, newValue) => {
          setSelectedLanguage(newValue);
        }}
        options={languageOptions}
        autoHighlight
        disabled={props.disabled}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            pb={2}
            px={2}
            display={"flex"}
            columnGap={2}
            sx={{ cursor: "pointer" }}
          >
            <li {...props} className={classes.option}>
              <img src={option.flag} alt="" className={classes.flag} />
              {option.label}
            </li>
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            label={t("RAL0091")}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box px={2} borderRight={"2px solid #F4F5F8"}>
                  <img
                    src={selectedLanguage.flag}
                    alt=""
                    className={classes.flag}
                  />
                </Box>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

export default LanguageSelector;
