import api from "../config/http-common";

export const eventFilter = async (payload) => {
  let url = "/events";
  if (payload?.startTime) {
    url += "?startTime=" + payload.startTime;
  }
  if (payload?.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  if (payload?.isHidden) {
    url += "&hidden=" + payload.isHidden;
  }
  if (payload?.projectTaggedStatus) {
    url += "&projectTaggedStatus=" + payload.projectTaggedStatus;
  }
  if (payload?.windowTitle) {
    url += "&windowTitle=" + payload.windowTitle;
  }
  if (payload?.applicationName) {
    url += "&applicationName=" + payload.applicationName;
  }
  if (payload?.userId) {
    url += "&userId=" + payload.userId;
  }
  return api.securedAxiosApi().get(url);
};

export const mostUsedApps = async (payload) => {
  let url = "/events/most_used_apps";
  if (payload?.startTime) {
    url += "?startTime=" + payload.startTime;
  }
  if (payload?.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  if (payload?.companyId) {
    url += "&companyId=" + payload.companyId;
  }
  if (payload?.userId) {
    url += "&userId=" + payload.userId;
  }
  if (payload?.departmentIds) {
    url += "&departmentIds=" + payload.departmentIds;
  }
  return api.securedAxios().get(url);
};

export const bucketList = async () => {
  return api.unsecuredAxios().get("/buckets");
};

export const uploadImage = async (base64Data) => {
  const payload = {
    ProfileImage: base64Data,
  };
  return api.securedAxios().post("/settings", payload);
};

export const getSystemSettings = (key) => {
  let settings = {
    time_zone: "+05:30",
    timeformat: 12,
    schedule: false,
    launch: true,
    weekdays_schedule: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
      starttime: "9:30 AM",
      endtime: "6:30 PM",
    },
    last_sync_time: "2024-04-19T14:26:12.636703+05:30",
  };
  if (settings) {
    // // Parse the JSON string to an object
    // settings = JSON.parse(settings);

    // Check if settings is an object (or a map-like structure)
    if (typeof settings === "object" && settings !== null) {
      // Assuming key is defined elsewhere in your code
      return settings[key];
    }
  }

  return undefined;
};

export const updateEvent = async (eventId, payload) => {
  return api.securedAxios().put("/events/" + eventId, payload);
};

export const updateEventHideStatus = async (payload) => {
  return api.securedAxios().put("/events/hide_status", payload);
};

export const addManualEvents = async (state) => {
  return api.securedAxios().post("/event/manual", state);
};

export const getProjectReportData = async (payload) => {
  let url = "/timesheet/project";
  if (payload?.startDate) {
    url += "?startDate=" + payload.startDate;
  }
  if (payload?.endDate) {
    url += "&endDate=" + payload.endDate;
  }
  if (payload?.companyId) {
    url += "&companyId=" + payload.companyId;
  }
  if (payload?.userId) {
    url += "&userId=" + payload.userId;
  }
  return api.securedAxios().get(url);
};

export const userEventsCount = async () => {
  return api.securedAxiosApi().get("/event/count");
};
