import { css } from "@emotion/css";
import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as BiotimeCloudIcon } from "../../assets/images/ralvie/biocloud_logo.svg";
import { ReactComponent as HolidayIcon } from "../../assets/images/ralvie/holiday_icon.svg";
import { ReactComponent as LeaveIcon } from "../../assets/images/ralvie/leave_icon.svg";
import {
  getIntegrations,
  listUserBiotimeData,
  listUserTransactions,
} from "../../services/IntegrationService";
import {
  listWeeklyTimesheet,
  textSummaraize,
} from "../../services/WorklogService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import { weeklySummarization } from "../AI/WeeklyTimelineSummary";
import WeekTimesheetItem from "./WeekTimesheetItem";
import "./Week.scss";

export default function Week(props) {
  const { biotimeTransactions, setTextSummary } = useContext(TimesheetContext);
  const [week, setWeek] = useState([]);
  const [loggedEvents, setLoggedEvents] = useState([]);
  const [textQuery, setTextQuery] = useState(undefined);
  const [leaves, setLeaves] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const handleSummarization = async () => {
    // props.setIsLoading(true);
    if (textQuery) {
      try {
        const r = await textSummaraize(textQuery);
        setTextSummary(r.data?.data?.summary);
        // props.setIsLoading(false);
        props.setGenerateSummary(false);
      } catch (error) {
        console.error("Summarization failed:", error);
      }
    }
  };

  const handleSummarizationWithCGPT = async (language) => {
    props.setIsLoading(true);
    try {
      const r = await weeklySummarization(textQuery, language);
      let summary = r.text.replace(/\n/g, "<br>").replace(/- /g, "• ");
      const headers = [
        "Monday:",
        "Tuesday:",
        "Wednesday:",
        "Thursday:",
        "Friday:",
        "Saturday:",
        "Sunday:",
      ];
      headers.forEach((header) => {
        summary = summary.replace(header, `<b>${header}</b>`);
      });

      setTextSummary(summary);
      props.setGenerateSummary(false);
      props.setIsLoading(false);
    } catch (error) {
      console.error("Summarization failed:", error);
      props.setIsLoading(false);
    }
  };

  useEffect(() => {
    // alert(props.generateSummary);
    if (props.generateSummary) {
      // handleSummarizationWithCGPT();
    }
  }, [props.generateSummary]);

  function formatInput(input) {
    const projectName = input["Project Name"] || "";
    const description = input["Description"] || "";
    const tags = input["Tags"] ? input["Tags"].join(", ") : "";
    const event = input["Event"] || {};
    const eventUser = event.user || {};
    const eventStartTime = new Date(event.startTime);
    const eventEndTime = new Date(event.endTime);

    const formatTime = (date) => {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // convert 0 to 12
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return `${formattedHours}:${formattedMinutes}${ampm}`;
    };

    const durationMinutes = Math.floor(event.duration / 60);

    return `Project Name: ${projectName}
Tags: ${tags}
Event:
  Project ID: ${event.projectId || ""}
  Event Number: ${event.eventNumber || ""}
  User ID: ${eventUser.userId || ""}
  Email: ${eventUser.email || ""}
  Application Name: ${event.applicationName || ""}
  Duration: ${durationMinutes} m
  Time stamp: ${formatTime(eventStartTime)} - ${formatTime(eventEndTime)}
Description: ${description}`;
  }

  const getTransactions = () => {
    let myIntegrations = props.myIntegrations;
    if (myIntegrations && myIntegrations.length > 0) {
      const format = "YYYY-MM-DD HH:mm:ss";
      let payload = {
        startTime: dayjs(props.startDate).startOf("day").format(format),
        endTime: dayjs(props.endDate).endOf("day").format(format),
        empCode: myIntegrations[0].userData?.emp_code,
      };
      listUserTransactions(myIntegrations[0].id, payload).then((res) => {
        if (res.status === 200) {
          return res.data.data?.data;
        }
      });
    }
  };

  useEffect(() => {
    if (week) {
    }
  }, [week]);

  const loadTimesheets = async () => {
    props.setIsLoading(true);
    const daysOfWeekWithDetails = [];
    let events = [];
    let textQuery = "";
    console.log("start: " + props.startDate);
    console.log("end: " + props.endDate);

    let startDate = dayjs(props.startDate).add(1, "day").utc();
    let endDate = dayjs(props.endDate).utc();

    // Starting with Sunday (0) to Saturday (6)
    for (let i = 0; i < 7; i++) {
      const day = dayjs(startDate).add(1, "day").day(i);
      daysOfWeekWithDetails.push({
        date: day,
        dayName: day.format("dddd"), // Full name of the day
        fullDate: day.format("DD MMM YYYY"), // Full date
        dayOfMonth: day.format("DD"), // Day of the month
      });
    }

    const decoded = CommonUtil.decodeToken();
    const userID = decoded.userId;

    const res = await listWeeklyTimesheet({
      startDate: startDate.isUTC()
        ? startDate.format()
        : startDate.utc().format(),
      endDate: endDate.isUTC() ? endDate.format() : endDate.utc().format(),
      userId: props.userId === "Self" ? userID : props.userId,
    });

    if (res.data.data && Object.keys(res.data.data).length > 0) {
      if (week) {
        let timesheetData = res.data.data;

        daysOfWeekWithDetails.forEach((day, index) => {
          if (timesheetData[day?.dayName.toUpperCase()]) {
            day.timesheets = timesheetData[day?.dayName.toUpperCase()];
            let eventList = timesheetData[day?.dayName.toUpperCase()];
            if (eventList instanceof Array)
              events = [...events, ...eventList[0].events];

            let query = {
              "Project Name": eventList[0]?.project.name,
              Description: eventList[0]?.project.description,
              Tags: eventList[0]?.tags?.map((t) => t.name),

              Event: eventList[0].events[0],
            };
            textQuery = textQuery + "," + formatInput(query);
          }
        });
        setTextQuery(textQuery);
        setLoggedEvents(events);
      }
    }
    let myIntegrations = props.myIntegrations;
    if (myIntegrations && myIntegrations.length > 0) {
      const format = "YYYY-MM-DD HH:mm:ss";

      getIntegrations().then((response) => {
        if (
          response.data.code === "RCI0000" &&
          response.data.data &&
          response.data.data instanceof Array
        ) {
          let biotimeIntegration = response.data.data?.filter(
            (a) => a.name === "Biotime Cloud"
          );

          if (
            biotimeIntegration &&
            biotimeIntegration instanceof Array &&
            biotimeIntegration.length > 0
          ) {
            let biotimeIntegrationId = biotimeIntegration[0].id;

            let payload = {
              startTime: dayjs(props.startDate).startOf("day").format(format),
              endTime: dayjs(props.endDate).endOf("day").format(format),
              empCode: biotimeIntegration[0].userData?.emp_code,
              applicationId: biotimeIntegrationId,
              userId: props.userId === "Self" ? userID : props.userId,
            };

            listUserBiotimeData(biotimeIntegrationId, payload).then((res) => {
              if (res.status === 200 && res.data?.code === "RCI0000") {
                if (res.data?.data) {
                  let transactions = [];
                  let holidays = [];
                  let leaves = [];
                  if (res.data?.data?.holidays) {
                    holidays = res.data.data?.holidays;
                    setHolidays(res.data?.data?.holidays);
                  }
                  if (res.data?.data?.leaves) {
                    leaves = res.data.data?.leaves;
                    setHolidays(res.data?.data?.leaves);
                  }
                  if (res.data?.data?.transactions) {
                    transactions = res.data?.data?.transactions;
                  }
                  daysOfWeekWithDetails.forEach((day) => {
                    const dateString = day.date.format("YYYY-MM-DD");
                    const leavesForDay = leaves.filter(
                      (l) =>
                        l.audit_status === "Approved" &&
                        l.start_time.startsWith(dateString)
                    );

                    let leave = leavesForDay ? leavesForDay[0] : null;
                    if (leave) {
                      let start = leave?.start_time;
                      let end = leave?.end_time;
                      let hrs = dayjs(end)?.hour() - dayjs(start)?.hour();
                      if (hrs >= 9) {
                        day.leave = "Full day";
                      } else if (hrs >= 2 && hrs <= 5) {
                        day.leave = "Half day";
                      } else {
                        day.leave = "1/4th day";
                      }
                    }
                    day.leaveDetails = leave;
                    //day.leave = leavesForDay ? leavesForDay[0] : null;

                    const holidayForDay = holidays.filter((h) =>
                      h.start_date.startsWith(dateString)
                    );
                    day.holiday = holidayForDay?.length > 0 ? true : false;

                    day.holidayDetails = holidayForDay?.length
                      ? holidayForDay[0]
                      : undefined;
                    const punchesForDay = transactions.filter((punch) =>
                      punch.punch_time.startsWith(dateString)
                    );

                    const transactionForDay = transactions.filter(
                      (punch) =>
                        punch.punch_time.startsWith(dateString) &&
                        punch.punch_state !== "0" &&
                        punch.punch_state !== "1"
                    );
                    day.transactionForDay = transactionForDay;

                    punchesForDay.sort(
                      (a, b) => new Date(a.punch_time) - new Date(b.punch_time)
                    );

                    const punchIn = punchesForDay.find(
                      (punch) => punch.punch_state === "0"
                    );

                    const punchOut = punchesForDay
                      .reverse()
                      .find((punch) => punch.punch_state === "1");
                    day.punchIn = punchIn
                      ? punchIn.punch_time.split(" ")[1]
                      : null;
                    day.punchOut = punchOut
                      ? punchOut.punch_time.split(" ")[1]
                      : null;
                  });
                }
              }
            });
          }
        }
      });
      let biotimeIntegration = myIntegrations?.filter(
        (a) => a.name === "Biotime Cloud"
      );
    }
    setWeek(daysOfWeekWithDetails);
    props.setIsLoading(false);
  };

  useEffect(() => {
    if (props.startDate && props.endDate) {
      loadTimesheets();
    }
  }, [props.startDate, props.userId]);

  const biotimeEvent = (data) => {
    return (
      <Box
        className={css`
          background: #eae8f2 0% 0% no-repeat padding-box;
          border-radius: 2px;
          opacity: 1;
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          column-gap: 8px;
          display: flex;
          align-items: center;
          width: 100%;
          padding: 10px 10px;
          margin-bottom: 8px;
        `}
      >
        <Box
          className={css`
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 2px;
            opacity: 0.8;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <BiotimeCloudIcon className="icon" />
        </Box>
        <Box>
          <Typography
            className={css`
              font: normal normal normal 14px/16px GothamMedium !important;
              letter-spacing: 0px;
              color: #242424 !important;
              opacity: 1;
            `}
          >
            {data?.eventName}
          </Typography>
        </Box>
        <Box>
          <Typography
            className={css`
              font: normal normal normal 12px/14px Gotham !important;
              letter-spacing: 0px;
              color: #242424 !important;
            `}
          >
            {data?.eventTime}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div
      className={css`
        width: 100%;
      `}
    >
      <Box
        sx={{ justifyContent: "space-evenly", display: "flex", width: "100%" }}
      >
        {/* <Box item className="weekview-day-container">
          {dayjs().startOf("week").get("date")}
        </Box> */}
        {week?.map((day) => {
          let durationData = undefined;
          let duration = day?.timesheets?.reduce(
            (accumulator, currentValue) => {
              return accumulator + currentValue?.loggedDuration;
            },
            0
          );
          if (duration) {
            durationData = CommonUtil.getDurationWithRoundoff(duration);
          }

          return (
            <Box item className="weekview-day-container">
              <Box
                className="boxDiv"
                sx={{ position: "sticky", top: 0, zIndex: 999 }}
              >
                <Box
                  p={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"30px"}
                  width={"30px"}
                  borderRadius={"50%"}
                  className={css`
                    background: ${day?.date?.isSame(dayjs(), "day")
                      ? "transparent linear-gradient(131deg, #1D0B77 0%, #6A5FA2 100%) 0% 0% no-repeat padding-box;"
                      : "var(--background-color-16)"};
                    color: ${day?.date?.isSame(dayjs(), "day")
                      ? "var(--text-secondary-50) !important"
                      : "var(--text-secondary-50) !important"};
                  `}
                >
                  <Typography
                    className={css`
                      font: normal normal normal 14px/17px Gotham !important;
                      letter-spacing: 0px;
                      opacity: 1;
                      color: ${day?.date?.isSame(dayjs(), "day")
                        ? "var(--text-secondary-50) !important"
                        : "var(--text-secondary-50) !important"};
                    `}
                  >
                    {day?.dayOfMonth}
                  </Typography>
                </Box>
                <Box display={"flex"} mt={0.5} alignItems={"center"}>
                  <Box item flexGrow={1}>
                    <Typography
                      className={css`
                        font: normal normal normal 14px/17px Gotham !important;
                        letter-spacing: 0px;
                        color: var(--text-secondary-50) !important;
                        opacity: 1;
                      `}
                    >
                      {day?.dayName}
                    </Typography>
                  </Box>
                  {durationData ? (
                    <Box>
                      <Typography
                        variant="header3"
                        sx={{
                          color: "var(--text-secondary-80)",
                          font: "14px/16px GothamMedium !important",
                        }}
                      >
                        {durationData?.hr + "h " + durationData?.min + "m"}
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        variant="header3"
                        sx={{
                          color: "var(--text-secondary-80)",
                          font: "14px/16px GothamMedium !important",
                        }}
                      >
                        {"0h"}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                bgcolor={
                  day.dayName !== "Sunday" &&
                  day.dayName !== "Saturday" &&
                  !day.holiday
                    ? "var(--background-color-3)"
                    : "var(--background-color-17)"
                }
                mb={"2px"}
                p={1}
                position={"relative"}
                className="weekboxes">
                {day?.holiday !== true ? (
                  <>
                    {day.leave && (
                      <>
                        <Box
                          className={css`
                            background: #eae8f2 0% 0% no-repeat padding-box;
                            border-radius: 2px;
                            opacity: 1;
                            backdrop-filter: blur(20px);
                            -webkit-backdrop-filter: blur(20px);
                            margin-bottom: 8px;
                          `}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          height={day.leave === "Full day" ? "100%" : "50%"}
                          width={"100%"}
                          flexWrap={"wrap"}
                        >
                          <Grid container rowGap={3}>
                            <Grid
                              item
                              lg={12}
                              display={"flex"}
                              justifyContent={"center"}
                              pb={2}
                            >
                              <BiotimeCloudIcon height={24} />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <LeaveIcon />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <Typography
                                className={css`
                                  font: normal normal normal 14px/16px
                                    GothamMedium !important;
                                  letter-spacing: 0px;
                                  color: #242424 !important;
                                  opacity: 1;
                                `}
                              >
                                {day.leave + " leave"}
                              </Typography>
                            </Grid>
                            {day?.leaveDetails?.apply_reason && (
                              <Grid
                                item
                                lg={12}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <Typography
                                  className={css`
                                    font: normal normal normal 14px/16px
                                      GothamMedium !important;
                                    letter-spacing: 0px;
                                    color: #242424 !important;
                                    opacity: 1;
                                  `}
                                >
                                  {day.leaveDetails
                                    ? "(" +
                                      day.leaveDetails?.apply_reason +
                                      " )"
                                    : ""}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </>
                    )}
                    {day.leave !== "Full day" &&
                      day.punchIn &&
                      biotimeEvent({
                        eventName: "Punch In",
                        eventTime: day.punchIn,
                      })}
                    {day.leave !== "Full day" && day?.timesheets && (
                      <WeekTimesheetItem
                        timesheets={day?.timesheets}
                        transactions={day?.transactionForDay}
                      />
                    )}

                    {day.leave !== "Full day" &&
                      day.punchOut &&
                      biotimeEvent({
                        eventName: "Punch out",
                        eventTime: day.punchOut,
                      })}
                  </>
                ) : (
                  <>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={"100%"}
                      width={"100%"}
                      flexWrap={"wrap"}
                    >
                      <Grid container rowGap={3}>
                        <Grid
                          item
                          lg={12}
                          display={"flex"}
                          justifyContent={"center"}
                          pb={2}
                        >
                          <BiotimeCloudIcon height={24} />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <HolidayIcon />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <Typography
                            className={css`
                              font: normal normal normal 14px/16px GothamMedium !important;
                              letter-spacing: 0px;
                              color: #242424 !important;
                              opacity: 1;
                            `}
                          >
                            Holiday
                          </Typography>
                        </Grid>
                        {day?.holidayDetails?.alias && (
                          <Grid
                            item
                            lg={12}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Typography
                              className={css`
                                font: normal normal normal 14px/16px
                                  GothamMedium !important;
                                letter-spacing: 0px;
                                color: #242424 !important;
                                opacity: 1;
                              `}
                            >
                              {day?.holidayDetails?.alias}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </div>
  );
}
