import api from "../config/http-common";

// Helper function to get authorization header
const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

// Create Category
export const createCategory = async (payload) => {
  const { name, color } = payload;
  const data = { name, color };
  console.log("Payload sent to createCategory API:", data);

  return api
    .securedAxiosApi()
    .post("/category", data, { headers: getAuthHeader() });
};

// Update Category

export const updateCategory = async (id, payload) => {
  return api.securedAxiosApi().put(`/categories/${id}`, payload);
};

// Add Applications to Category
export const addApplicationsToCategory = async (id, payload) => {
  return api.securedAxiosApi().put(`/categories/${id}/application`, payload);
};

// Remove Application from Category
export const removeApplicationFromCategory = async (
  categoryId,
  applicationId
) => {
  console.log(
    `Removing applicationId ${applicationId} from categoryId ${categoryId}`
  );

  return api
    .securedAxiosApi()
    .delete(`/categories/${categoryId}?applicationId=${applicationId}`, {
      headers: getAuthHeader(),
    });
};

export const getAllCategories = async () => {
  return api.securedAxiosApi().get("/categories");
};

export const addCategory = async (payload) => {
  return api.securedAxiosApi().post("/category", payload);
};

export const removeAppsFromCategory = async (id, applicationId) => {
  return api
    .securedAxiosApi()
    .delete(`/categories/${id}?applicationId=${applicationId}`);
};
