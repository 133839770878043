import React, { useContext, useEffect, useRef, useState } from "react";
import CustomIconButton from "../../components/CustomIconButton";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/system";
import dayjs from "dayjs";
import { ReactComponent as ExportIcon } from "../../assets/images/ralvie/export-icon.svg";
import CustomButton from "../../components/CustomButton";
import { css } from "@emotion/css";
import CustomCheckBox from "../../components/CustomCheckBox";
import { ReactComponent as ProjectIcon } from "../../assets/images/ralvie/Project.svg";
import { exportProjectReport } from "../../services/ReportService";
import { useTranslation } from "react-i18next";
import "./ExportProjectReport.scss";
import TimesheetWeekPicker from "../dashboard/TimesheetWeekPicker";
import AppContext from "../../config/AppContext";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import TimesheetDatePickerWB from "../timesheet/TimesheetDatePickerWB";
import { toast } from "sonner";
import CommonUtil from "../../util/CommonUtils";

export default function ExportProjectReport(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(undefined);
  const [isIncludeMemberContribution, setIsIncludeMemberContribution] =
    useState(false);
  const timeRef = useRef();
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const isStartDateValid =
    startDate.isBefore(endDate) || startDate.isSame(endDate);
  const [projects, setProjects] = React.useState([]);
  const [selectedProjects, setSelcetdProjects] = React.useState([]);
  const projectsRef = useRef(selectedProjects);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { mode } = useContext(AppContext);

  useEffect(() => {
    if (props?.reports) {
      let projects = [];
      props?.reports?.forEach((r) => {
        projects.push(r?.project);
      });
      setProjects(projects);
      // handleSelecetAll(true);
    }
  }, [props.reports]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(undefined);
    setOpen(false);
    handleSelecetAll(false);
    setStartDate(dayjs());
    setEndDate(dayjs());
    setIsIncludeMemberContribution(false);
  };

  // const handleSubmit = () => {
  //   exportProjectReport(selectedProjects).then((res) => {
  //     setSelcetdProjects([]);
  //     setOpen(false);
  //   });
  // };
  const handleSubmit = () => {
    if (selectedProjects?.length < 1) {
      setError("Select atleast one project to export");
      return;
    }
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(startDate, CommonUtil.getTimeZoneId());
    let startTime = dateTz.startOf("day");
    dateTz = dayjs.tz(endDate, CommonUtil.getTimeZoneId());
    let endTime = dateTz.endOf("day");
    let start = startTime.isUTC()
      ? startTime.format()
      : startTime.utc().format();
    let end = endTime.isUTC() ? endTime.format() : endTime.utc().format();
    exportProjectReport(
      selectedProjects,
      props.clientId,
      props.member,
      start,
      end,
      props.tags,
      isIncludeMemberContribution
    ).then(() => {
      handleClose();
    });
  };

  const handleSelecetAll = (value) => {
    if (value) {
      setError(undefined);
      let projectIds = [];
      props?.reports?.forEach((r) => {
        projectIds.push(r?.project?.id);
      });
      setSelcetdProjects(projectIds);
    } else {
      setSelcetdProjects([]);
    }
  };

  const handleSelection = (id) => {
    setError(undefined);
    let projectIds = [...selectedProjects]; // Make a copy of the array
    if (projectIds.length > 0 && projectIds.find((pid) => pid === id)) {
      // Use a callback function in .find()
      let updatedList = projectIds.filter((pid) => pid !== id); // Filter out the selected id
      setSelcetdProjects(updatedList); // Make sure to update state correctly
      projectsRef.current = updatedList;
    } else {
      projectIds.push(id); // Add the new id
      setSelcetdProjects(projectIds); // Update state
      projectsRef.current = projectIds;
    }
  };

  const checkProjectSelection = (id) => {
    if (id !== undefined && selectedProjects && selectedProjects.length > 0) {
      // Use a callback function in .find() to check if the id exists in the array
      let pid = selectedProjects.find((pid) => pid === id);
      return !!pid; // Return true if pid is found, otherwise return false
    }
    return false;
  };

  const projectCard = (project) => {
    return (
      <Box
        className="projectCardOuter"
        sx={{
          border: `1px solid ${
            mode === "light" ? "#eaebef !important" : "#313131 !important"
          }`,
        }}
      >
        <CustomCheckBox
          onChange={() => handleSelection(project?.id)}
          checked={checkProjectSelection(project?.id)}
        />
        <Avatar
          className="projectAvatar"
          sx={{
            width: "46px",
            height: "46px",
            backgroundColor: (theme) => alpha(project?.color, 0.15),
          }}
        >
          <ProjectIcon fill={project?.color} opacity={0.5} />
        </Avatar>
        <Box display="flex" flexDirection={"column"}>
          <Typography
            className="projectName"
            sx={{
              color:
                mode === "light" ? "#242424 !important" : "#F8F8F8 !important",
            }}
          >
            {project?.name}
          </Typography>
          <Typography
            className="projectClientName"
            sx={{
              color:
                mode === "light"
                  ? "#242424cc !important"
                  : "#f8f8f8cc !important",
            }}
          >
            {project?.clientName}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <div>
      <CustomIconButton
        id="btn-custom-add-member"
        name="btn-custom-add-member"
        title={t("RAL0206")}
        size={isXlScreen ? "large" : "md"}
        variant="square"
        type="contained"
        lightMode={<ExportIcon height={isXlScreen ? "18px" : "14px"} />}
        darkMode={<ExportIcon height={isXlScreen ? "18px" : "14px"} />}
        onClick={() => {
          handleClickOpen();
        }}
      />
      <Dialog
        className="ExportProjectReport"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        fullWidth={true}
      >
        <Box sx={{ backgroundColor: mode === "light" ? "#ffffff" : "#101010" }}>
          <Box
            sx={{
              position: "sticky",
              top: 0, 
              backgroundColor: mode === "light" ? "#ffffff" : "#101010",
              zIndex: 2,
            }}
          >
          <DialogTitle
            className="ExportTitle"
            sx={{ color: mode === "light" ? "#242424" : "#f8f8f8" }}
            id="alert-dialog-title"
          >
            {"Export confirmation"}
          </DialogTitle>
          <DialogContent className="exportDialogContent" width={"100%"}>
            <Box py={2}>
              <Typography
                sx={{ color: mode === "light" ? "#242424cc" : "#f8f8f8cc" }}
                className="ExportDesc"
              >
                The selected project report will be downloaded. You can adjust
                the project selection to customize the report as needed.
              </Typography>
            </Box>
            <Box display="flex">
              <Box>
                <Typography
                  sx={{
                    color:
                      mode === "light"
                        ? "#242424 !important"
                        : "#F8F8F8 !important",
                  }}
                  className={css`
                    font: normal normal normal 16px/19px Gotham;
                    letter-spacing: 0px;
                    font-weight: 600;
                  `}
                >
                  <span
                    className={css`
                      font: normal normal 16px/19px Gotham;
                      letter-spacing: 0px;
                      margin-right: 0.6rem;
                    `}
                  >
                    {"Date Range"}
                  </span>
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} alignItems={"center"}>
                  <Box>
                    <TimesheetDatePickerWB
                      id="btn-start-clndr"
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      selectedDate={startDate}
                    />
                  </Box>
                  <Box mx={1}> - </Box> {/* Added mx for spacing */}
                  <Box>
                    <TimesheetDatePickerWB
                      id="btn-end-clndr"
                      onChange={(date) => {
                        setEndDate(date);
                      }}
                      selectedDate={endDate}
                    />
                  </Box>
                </Box>
                {!isStartDateValid && (
                  <Box display={"flex"} alignItems={"center"} pt={1} pl={2}>
                    <Box pr={1}>{<ErrorIcon />}</Box>
                    <Box>
                      <Typography className="helper-text">
                        {"End date must be greater than or equal to start date"}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              py={1}
              className={css`
                border-radius: 5px;
                opacity: 1;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
              `}
            >
              <CustomCheckBox
                onChange={() =>
                  setIsIncludeMemberContribution(!isIncludeMemberContribution)
                }
                checked={isIncludeMemberContribution}
              />

              <Box
                display="flex"
                flexDirection={"column"}
                rowGap={0.5}
                flexGrow={1}
              >
                <Typography
                  sx={{
                    color:
                      mode === "light"
                        ? "#242424 !important"
                        : "#F8F8F8 !important",
                  }}
                  className={css`
                    font: normal normal normal 16px/19px GothamMedium;
                    letter-spacing: 0px;
                  `}
                >
                  <span
                    className={css`
                      font: normal normal 16px/19px Gotham;
                      letter-spacing: 0px;
                    `}
                  >
                    {"Include members contribution"}
                  </span>
                </Typography>
              </Box>

              <CustomCheckBox
                onChange={() =>
                  handleSelecetAll(
                    selectedProjects?.length === props?.reports?.length
                      ? false
                      : true
                  )
                }
                checked={
                  selectedProjects?.length === props?.reports?.length
                    ? true
                    : false
                }
              />

              <Box display="flex" flexDirection={"column"} rowGap={0.5}>
                <Typography
                  sx={{
                    color:
                      mode === "light"
                        ? "#242424 !important"
                        : "#F8F8F8 !important",
                  }}
                  className={css`
                    font: normal normal normal 16px/19px GothamMedium;
                    letter-spacing: 0px;
                  `}
                >
                  <span
                    className={css`
                      font: normal normal 16px/19px Gotham;
                      letter-spacing: 0px;
                    `}
                  >
                    {"Select all"}
                  </span>
                </Typography>
              </Box>
            </Box>
            {error && (
              <Box display={"flex"} alignItems={"center"} pt={0.5}>
                <Box item pr={1}>
                  {<ErrorIcon />}
                </Box>
                <Box item>
                  <Typography className="helper-text">{error}</Typography>
                </Box>
              </Box>
            )}
          </DialogContent>
          </Box>

          <DialogContent className="exportDialogContent" width={"100%"}>
            <Box
              className="projectCardDiv"
              display={"flex"}
              flexDirection={"column"}
              rowGap={1}
            >
              {projects?.map((r) => {
                return projectCard(r);
              })}
            </Box>
          </DialogContent>
          <DialogActions className="DialogActionsDiv"
            sx={{
              backgroundColor:
                mode === "light" ? "#FFFFFF !important" : "#121212 !important",
            }}>
            <Box className="DialogActionsBtns">
              <CustomButton
                place="sp-cancel"
                id="btn-cancel-member"
                name="btn-cancel-member"
                variant="outlined"
                size="large"
                fullWidth="fullWidth"
                height="60px"
                minWidth="150px"
                onClick={() => handleClose()}
              >
                <span id="btn-cancel-member">{"Cancel"}</span>
              </CustomButton>
              <CustomButton
                id="btn-add-member"
                name="btn-add-member"
                variant="contained"
                size="large"
                fullWidth="fullWidth"
                height="60px"
                minWidth="150px"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <span id="btn-add-member">{"Export"}</span>
              </CustomButton>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
