import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonUtil from "../util/CommonUtils";
import { ReactComponent as ErrorIcon } from "../assets/images/danger_icon.svg";

export default function CustomInput(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(props.label);

  const CssTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
      },
    },
  });

  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    let val = e.target.value;
    if (val.trim() === "") {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === " " && val[val.length - 2] === " ") {
        return;
      }
      if (val[0] === " ") {
        return;
      }
    }
    if (props.regex === "none" && props.validation) {
      switch (props.validation) {
        case "alpha-numeric":
          handleAlphaNumeric(e);
          break;
        case "numeric":
          handleNumeric(e);
          break;
        case "email":
          handleEmail(e);
          break;
        case "url":
          handleUrl(e);
          break;
        case "password":
          handlePassword(e);
          break;
        case "code":
          handleCode(e);
          break;
        case "mobile":
          handleMobile(e);
          break;
        case "postcode":
          handlePostCode(e);
          break;
        case "alpha-numeric-underscore":
          handleCodeUnderscore(e);
          break;
        case "alpha-numeric-space":
          handleAlphaNumericSpace(e);
          break;
        case "numericWithoutDot":
          handleNumericWithoutDot(e);
          break;
        default:
          props.onChange(e);
      }
    }
  };

  const handleAlphaNumeric = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0025"));
    }
  };

  const handleCodeUnderscore = (e) => {
    if (CommonUtil.isAlphaNumericUnderscore(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("Only accepts alphabets, numerics and underscore"));
    }
  };

  const handleAlphaNumericSpace = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(
        t("Only accepts alphabets, numerics, space and underscore")
      );
    }
  };
  const handleNumericWithoutDot = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText("Only accepts numerics");
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0023"));
    }
  };

  const handlePostCode = (e) => {
    if (CommonUtil.isValidCode(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0021"));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0024"));
    }
  };

  const handleEmail = (e) => {
    props.onChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0022"));
    }
  };

  const handleUrl = (e) => {
    props.onChange(e);
    if (CommonUtil.isValidUrl(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0029"));
    }
  };

  const handleMobile = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0024"));
    }
  };

  const handlePassword = (e) => {
    props.onChange(e);
    if (CommonUtil.isAlphaNumericPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0010"));
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlaceholderText(`${t("RAL0089")}${props.label.toLowerCase()}`);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setPlaceholderText(props.label);
  };

  return (
    <TextField
      {...props}
      placeholder={isFocused  ? placeholderText : props.label}
      autoComplete="new-password"
      fullWidth
      id={props.id}
      value={props.value}
      helperText={
        <Typography variant="text_28_42_0_8">
          {error && (
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                marginLeft: "-15px",
              }}
            >
              <ErrorIcon height={16} width={16} />
              <Box sx={{ paddingLeft: "8px" }}>{helperText}</Box>
            </Box>
          )}
          {!error && <Box sx={{ paddingLeft: "25px" }}>{helperText}</Box>}
        </Typography>
      }
      error={error}
      label={isFocused || props.value ? props.label : "" }
      onChange={(e) => handleChange(e)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputLabelProps={{
        style: {
          color: "#474B4F",
          font: "normal normal normal 14px/25px Poppins",
        },
        shrink: true,
      }}
    />
  );
}

CustomInput.defaultProps = {
  validation: "none",
  regex: "none",
};
