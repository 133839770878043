import api from "../config/http-common";

export const TotalDurationWeek = async (payload) => {
  let url = "/events/totalDurationWeek";
  if (payload?.startTime) {
    url += "?startTime=" + payload.startTime;
  }
  if (payload?.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  url += "&companyId=" + payload.companyId;

  if (payload?.userId) {
    url += "&userId=" + payload.userId;
  }
  if (payload?.departmentId) {
    url += "&departmentId=" + payload.departmentId;
  }
  return api.securedAxios().get(url);
};

export const listApplication = async (payload) => {
  let url = "/events/application";
  if (payload?.startTime) {
    url += "?startTime=" + payload.startTime;
  }
  if (payload?.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  if (payload?.companyId) {
    url += "&companyId=" + payload.companyId;
  }
  if (payload?.userId) {
    url += "&userId=" + payload.userId;
  }
  if (payload?.departmentIds) {
    url += "&departmentIds=" + payload.departmentIds;
  }
  return api.securedAxios().get(url);
};

export const listDashboardProject = async (payload) => {
  let url = "/report/project";
  if (payload?.startTime) {
    url += "?startTime=" + payload.startTime;
  }
  if (payload?.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  if (payload?.userId) {
    url += "&userId=" + payload.userId;
  }
  if (payload?.departmentIds) {
    url += "&departmentIds=" + payload.departmentIds;
  }
  return api.securedAxios().get(url);
};

export const listProjectsReport = async (startTime, endTime) => {
  return api
    .securedAxios()
    .get("/report/project?startTime=" + startTime + "&endTime=" + endTime);
};

export const getMostActiveAndIdleUsers = async (payload) => {
  let url = "/dashboard/most_active_inactive_users";
  if (payload?.startTime) {
    url += "?startTime=" + payload.startTime;
  }
  if (payload?.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  if (payload?.departmentId) {
    url += "&departmentIds=" + payload.departmentId;
  }
  return api.securedAxiosApi().get(url);
};
