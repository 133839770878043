import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import OnboardService from "../../services/OnboardService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonLayout from "./CommonLayout";

export default function UserSuccess() {

  const { t } = useTranslation();

  return (
    
    <CommonLayout showLogo={true}>
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          py: 3,
          pr: 10,
        }}
      >
    
        <Box>
          <Typography
          sx={{textAlign: "center", fontWeight: 600, fontSize: "24px", color: "#474B4F",}}>
            {t("RAL0094")}
          </Typography>
        </Box>
      
        
      </Box>
    </CommonLayout>
  );
}
