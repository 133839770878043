import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Checkbox,
  Select,
  Tooltip,
  useMediaQuery,
  useTheme,
  Radio,
  TextField,
} from "@mui/material";
import { userList } from "../../services/MemberService";
import AppContext from "../../config/AppContext";
import { useTranslation } from "react-i18next";

export default function MemberFilter({
  memberFilter,
  setMemberFilter,
  setSelectedMemberName,
  departmentFilter,
  timesheetType,
}) {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [members, setMembers] = useState([]);
  const [userID, setUserID] = useState(null);
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [memberSearch, setMemberSearch] = useState("");

  const handleMemberFilterChange = (memberId) => {
    const selectedMember = members.find((member) => member.id === memberId);
    setMemberFilter(memberId);
    if (selectedMember) {
      setSelectedMemberName(`${selectedMember.name}'s activities`);
    } else {
      setSelectedMemberName("Ralvie AI");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await userList();
        if (usersResponse.data.code === "UASI0000") {
          let updatedMembers = [];
          if (departmentFilter?.length < 1) {
            updatedMembers = usersResponse.data.data.users
              .filter((user) => user.roleName !== "Client")
              .map((user) => ({
                id: user.id,
                name: user.memberName,
                photo: user.profilePhoto,
              }));
          } else {
            updatedMembers = usersResponse.data.data.users
              .filter((user) => user.roleName !== "Client")
              .filter((user) => {
                return (
                  departmentFilter.includes("All departments") ||
                  departmentFilter.includes(user.departmentId)
                );
              })
              .map((user) => ({
                id: user.id,
                name: user.memberName,
                photo: user.profilePhoto,
              }));
          }
          setMembers(updatedMembers);
        }
      } catch (error) {
        console.error("Error fetching user list:", error);
      }
    };

    if (departmentFilter) fetchData();
  }, [departmentFilter]); // Re-fetch data when departmentFilter changes

  useEffect(() => {
    if (timesheetType === "week") {
      userList().then((res) => {
        var updatedMembers = res.data.data.users
          .filter((user) => user.roleName !== "Client")
          .map((user) => ({
            id: user.id,
            name: user.memberName,
            photo: user.profilePhoto,
          }));
        setMembers(updatedMembers);
      });
    }
  }, [timesheetType]);

  return (
    <Box display="flex" alignItems="center">
      <Box sx={{ ml: 2 }}>
        <FormControl
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--border-color-1) !important",
                borderWidth: "1px !important",
              },
              "& .MuiSelect-select": {
                display: "block",
                width: "80%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            },
          }}
        >
          <Select
            fullWidth
            value={memberFilter}
            sx={{
              height: isXlScreen ? "50px" : "40px",
              width: "250px",
            }}
            renderValue={(selected) => {
              const selectedMember = members.find(
                (member) => member.id === selected
              );
              return selectedMember
                ? selectedMember.name.replace("null", "")
                : "All members";
            }}
            className="filterText"
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: "5px",
                  borderRadius: "5px",
                  boxShadow: "0px 3px 6px #00000026",
                },
                sx: {
                  maxHeight: 300,
                },
              },
              disableAutoFocusItem: true,
              autoFocus: false,
            }}
          >
            <MenuItem
              onKeyDown={(e) => e.stopPropagation()}
              sx={{
                fontFamily: "Gotham",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: "var(--fw-500)",
                backgroundColor:
                  mode === "dark" ? "#1F1F1F!important" : "#FFFFFF!important",
                color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                "&:hover": {
                  backgroundColor: "#1F1F1F",
                },
                "&.Mui-selected, &.Mui-selected:hover": {
                  backgroundColor:
                    mode === "dark" ? "#362C20!important" : "#FFF9F2!important",
                  color: "#FA9C2B",
                },
                position: "sticky",
                top: "0px",
                zIndex: "1",
              }}
            >
              <TextField
                id="select-search-memberfilter"
                placeholder={t("RAL0274")}
                variant="outlined"
                fullWidth
                size="small"
                value={memberSearch}
                autoFocus
                autoComplete="off"
                onChange={(e) => setMemberSearch(e.target.value)}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  onClick: (e) => {
                    e.stopPropagation();
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid var(--border-color-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid var(--border-color-1)",
                    },
                    "& input::placeholder": {
                      color:
                        mode === "light"
                          ? "#24242480 !important"
                          : "#f8f8f880 !important",
                      opacity: 1,
                    },
                  },
                }}
              />
            </MenuItem>
            <Box
              sx={{
                maxHeight: 260,
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "var(--background-color-38) transparent",
                "&::-webkit-scrollbar": {
                  width: "8px",
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                },
              }}
            >
              {timesheetType !== "week" && (
                <MenuItem
                  value="All members"
                  onClick={() => handleMemberFilterChange("All members")}
                  sx={{
                    fontFamily: "Gotham",
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "var(--fw-500)",
                    backgroundColor:
                      mode === "dark"
                        ? "#1F1F1F!important"
                        : "#FFFFFF!important",
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                    "&:hover": {
                      backgroundColor: "#1F1F1F",
                    },
                    "&.Mui-selected, &.Mui-selected:hover": {
                      backgroundColor:
                        mode === "dark"
                          ? "#362C20!important"
                          : "#FFF9F2!important",
                      color: "#FA9C2B",
                    },
                  }}
                >
                  <Radio
                    checked={memberFilter === "All members"}
                    sx={{
                      "&.Mui-checked": {
                        color: "orange",
                      },
                    }}
                  />
                  All members
                </MenuItem>
              )}

              {members &&
                members
                  .filter((member) =>
                    member.name
                      ?.toLowerCase()
                      .includes(memberSearch.toLowerCase())
                  )
                  .map((member) => (
                    <MenuItem
                      key={member.id}
                      value={member.id}
                      onClick={() => handleMemberFilterChange(member.id)}
                      sx={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "var(--fw-500)",
                        backgroundColor:
                          mode === "dark"
                            ? "#1F1F1F!important"
                            : "#FFFFFF!important",
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: "#1F1F1F",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                          backgroundColor:
                            mode === "dark"
                              ? "#362C20!important"
                              : "#FFF9F2!important",
                          color: "#FA9C2B",
                        },
                      }}
                    >
                      <Radio
                        checked={memberFilter === member.id}
                        sx={{
                          "&.Mui-checked": {
                            color: "orange",
                          },
                        }}
                      />
                      {member.name.length > 17 ? (
                        <Tooltip title={member.name} placement="top" arrow>
                          <span>{`${member.name
                            .replace("null", "")
                            .substring(0, 17)}...`}</span>
                        </Tooltip>
                      ) : (
                        member.name.replace("null", "")
                      )}
                    </MenuItem>
                  ))}
            </Box>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
