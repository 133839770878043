import { Box, Grid, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import CustomButton from "../../components/CustomButton";
import OnboardService from "../../services/OnboardService";

import CommonUtil from "../../util/CommonUtils";
import { UserContext } from "../../util/context";
import SignupLayout from "./SignupLayout";
import "./SelectOrganization.scss";
import CompanyBox from "./CompanyBox";

export default function SelectOrganization() {
  const { setTokenRefreshTimer } = useContext(UserContext);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const { companies, userName, password } = location.state || {};

  const [payload, setPayload] = useState({
    userName: userName,
    password: password,
    companyId: "",
  });

  const [error, setError] = useState({
    userName: "",
    password: "",
    companyId: "",
  });

  const handleSelectClick = (selectedCompany) => {
    setPayload({
      ...payload,
      companyId: selectedCompany,
    });
  };

  const handleSubmit = (e) => {
    if (validate()) {
      OnboardService.login(payload).then((res) => {
        if (res?.data?.code === "UASI0011") {
          if (res?.data?.data?.access_token !== null) {
            localStorage.setItem(
              "token",
              "Bearer " + res?.data?.data?.access_token
            );
            CommonUtil.decodeToken();

            localStorage.setItem(
              "refresh_token",
              "Bearer " + res?.data?.data?.refresh_token
            );

            const decoded = jwtDecode(res?.data?.data?.access_token);
            if (decoded.roleCode === "CLIENT") {
              toast.error("You don't have permission to login");
              return;
            }
            var currentSeconds = Math.floor(Date.now() / 1000);
            console.warn("decoded.exp", decoded.exp);
            console.warn("currentSeconds", currentSeconds);
            var secondsLeft = decoded.exp - currentSeconds;
            console.warn("secondsLeft", secondsLeft);
            var timer = Math.floor(secondsLeft / 60) - 5;
            console.warn("timer", timer);
            console.warn("Next token generation after", timer + " mins");

            timer = timer * 60 * 1000;
            setTokenRefreshTimer(timer);
            try {
              if (localStorage.getItem("sundial-auth-code")) {
                let url = process.env.REACT_APP_SERVER_URL?.replace(
                  "web",
                  "api"
                );
                axios
                  .put(
                    url +
                      `/v1/users/${localStorage.getItem(
                        "sundial-auth-code"
                      )}/sundial_auth`,
                    {},
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token"),
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("sundial-auth-code");
                    window.history.replace("/dashboard");
                  });
              }
            } catch (e) {}

            navigate("/dashboard");
          }
        } else if (res.data.code === "RCE0003" || res.data.code === "RCE0024") {
          localStorage.setItem("token", res.data?.data);
          navigate("/pages/company-industry", {
            state: { email: payload.email },
          });
        } else if (res.data.code === "RCE0002") {
          setError({ ...error, userName: t("RAL0143") });
        } else if (res.data.code === "RCE0001") {
          setError({ ...error, password: res.data?.message });
        } else {
          toast.error(res.data?.message);
        }
      });
    }
  };

  const validate = () => {
    if (!payload.userName) {
      setError({
        ...error,
        userName: t("RAL0001"),
      });
      return false;
    }
    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      return false;
    }
    return true;
  };
  const buttonWidths = ["width292", "width292"];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [payload]);

  return (
    <>
      <SignupLayout>
        <div className="SelectOrganization">
          <p className="mainHeading">{"Please Choose Your Organization to Continue"}</p>

          <Grid
            container
            spacing={1}
            style={{ justifyContent: "space-evenly" }}
          >
            <Grid item className="companyBoxesDiv">
              <Box className="boxesAlignment">
                {companies?.map((c) => (
                  <CompanyBox
                    key={c.id}
                    c={c}
                    payload={payload}
                    handleSelectClick={handleSelectClick}
                    onKeyDown={handleKeyDown}
                  />
                ))}

                <div className="actionBtns">
                  <div className="btnsOuterDiv">
                    <CustomButton
                      variant="contained"
                      size="large"
                      fullWidth="fullWidth"
                      onClick={() => handleSubmit()}
                    >
                      <span>{"Get started"}</span>
                    </CustomButton>
                  </div>
                  <div
                    className={
                      CommonUtil.getOS() === "MacOS"
                        ? "backToBtnsMac"
                        : "backToBtns"
                    }
                  >
                    <span>Back to</span>
                    <CustomButton onClick={() => navigate("/")}>
                      {t("RAL0006")}
                    </CustomButton>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </SignupLayout>
    </>
  );
}
