import {
  Box,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeSheetActiveIcon from "../../assets/images/nav-icons/icon-calendar-active.svg";
import TimeSheetInActiveIcon from "../../assets/images/nav-icons/icon-calendar.svg";
import TimeSheetInActiveIconDark from "../../assets/images/nav-icons/icon-calendar_dark.svg";
import DashboardActiveIcon from "../../assets/images/nav-icons/icon-dashboard-active.svg";
import DashboardInactiveIcon from "../../assets/images/nav-icons/icon-dashboard.svg";
import DashboardInactiveIconDark from "../../assets/images/nav-icons/icon-dashboard_dark.svg";
import IntegrationActiveIcon from "../../assets/images/nav-icons/icon-integration-active.svg";
import IntegrationIcon from "../../assets/images/nav-icons/icon-integration.svg";
import IntegrationIconDark from "../../assets/images/nav-icons/icon-integration_dark.svg";
import MemberActiveIcon from "../../assets/images/nav-icons/icon-member-active.svg";
import MemberInActiveIcon from "../../assets/images/nav-icons/icon-member.svg";
import MemberInActiveIconDark from "../../assets/images/nav-icons/icon-member_dark.svg";
import OrganizationActiveIconImg from "../../assets/images/nav-icons/icon-organization-active.svg";
import { ReactComponent as OrganizationIcon } from "../../assets/images/nav-icons/icon-organization.svg";
import { ReactComponent as OrganizationIconDark } from "../../assets/images/nav-icons/icon-organization_dark.svg";
import ProjectActiveIcon from "../../assets/images/nav-icons/icon-project-active.svg";
import ProjectInActiveIcon from "../../assets/images/nav-icons/icon-project.svg";
import ProjectInActiveIconDark from "../../assets/images/nav-icons/icon-project_dark.svg";
import ReportActiveIcon from "../../assets/images/nav-icons/icon-report-active.svg";
import ReportInActiveIcon from "../../assets/images/nav-icons/icon-report.svg";
import ReportInActiveIconDArk from "../../assets/images/nav-icons/icon-report_dark.svg";
import SettingsActiveIcon from "../../assets/images/nav-icons/icon-setting-active.svg";
import SettingsIcon from "../../assets/images/nav-icons/icon-setting.svg";
import SettingsIconDark from "../../assets/images/nav-icons/icon-setting_dark.svg";
import TagActiveIcon from "../../assets/images/nav-icons/icon-tag-active.svg";
import TagInActiveIcon from "../../assets/images/nav-icons/icon-tag.svg";
import TagInActiveIconDark from "../../assets/images/nav-icons/icon-tag_dark.svg";
// import AiIcon from "../../assets/images/ralvie/ai-robot.svg";
import LavoroAiIcon from "../../assets/images/lavoro-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/ralvie/ArrowRight.svg";
import { ReactComponent as ArrowRightDark } from "../../assets/images/ralvie/ArrowRight_dark.svg";
import { ReactComponent as DarkMode } from "../../assets/images/ralvie/dark_mode.svg";
import { ReactComponent as LightMode } from "../../assets/images/ralvie/light_mode.svg";
import { ReactComponent as NavPanelCloseIcon } from "../../assets/images/ralvie/nav-panel-close.svg";
import { ReactComponent as NavPanelOpenIcon } from "../../assets/images/ralvie/nav-panel-open.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/ralvie/profile-icon.svg";
import { ReactComponent as RalvieLogoIcon } from "../../assets/images/ralvie/ralvie-logo-icon.svg";
import { ReactComponent as RalvieLogo } from "../../assets/images/ralvie/ralvie-logo.svg";
import { ReactComponent as RalvieLogoDark } from "../../assets/images/ralvie/ralvie_logo_dark.svg";

import CustomSwitch from "../../components/CustomSwitch";
import AppContext from "../../config/AppContext";
import AiIntroduction from "../../pages/AI/AiIntroduction";
import RefreshToken from "../../pages/onboard/RefreshToken";
import Organization from "../../pages/organization/Organization";
import UserNavSubMenu from "../../pages/profile/UserNavSubMenu";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext } from "../../util/context";
import CustomIconButton, { CustomIconbtn } from "../CustomIconButton";
import OverFlowText from "../OverFlowText";
import CollapseNavSubMenu from "./CollapseNavSubMenu";
import "./LayoutComponents.scss";
import "./NavMenu.scss";
import NavMenuItem from "./NavMenuItem";

export default function NavMenu(props) {
  const { expand, setExpand, enableAi, setEnableAi, userProfile, profilePic } =
    useContext(sidebarContext);

  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const role = CommonUtil.getRoleCode();
  const [toggleOrgMenu, setToggleOrgMenu] = useState(false);
  const [toggleUserMenu, setToggleUserMenu] = useState(false);
  const [toggleReportMenu, setToggleReportMenu] = useState(false);
  const [toggleSettingsMenu, setToggleSettingsMenu] = useState(false);
  const [toggleAiBtn, setToggleAiBtn] = useState(false);
  const navigate = useNavigate();
  const memberName = userProfile?.memberName
    ? userProfile?.memberName
    : CommonUtil.getLoggedUser()?.firstName;

  const profileImageStyle = {
    width: isXlScreen ? "40px" : "30px",
    height: isXlScreen ? "40px" : "30px",
    borderRadius: "50%",
    objectFit: "cover",
  };

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const reportPopupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const settingPopupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const orgPopupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });

  const { colorMode, mode } = useContext(AppContext);
  const toolTipStyle = {
    tooltip: {
      sx: {
        borderRadius: "0px",
        backgroundColor: "#242424 !important",
        color: "#ffffff !important",
      },
    },
    arrow: {
      sx: {
        color: "#242424 !important",
      },
    },
  };
  const toolTipPopoverPosition = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -5],
        },
      },
    ],
  };
  useEffect(() => {
    setToggleOrgMenu(orgPopupState.isOpen);
  }, [orgPopupState.isOpen]);
  useEffect(() => {
    setToggleReportMenu(reportPopupState.isOpen);
  }, [reportPopupState.isOpen]);

  useEffect(() => {
    setToggleSettingsMenu(settingPopupState.isOpen);
  }, [settingPopupState.isOpen]);

  useEffect(() => {
    setToggleUserMenu(popupState.isOpen);
  }, [popupState.isOpen]);
  return (
    <div className={expand ? "sidebar-expand" : "sidebar"}>
      <RefreshToken />
      <Box className="navFirstSection">
        {!expand && (
          <Box className="header-logo-toggled">
            <Box flexGrow={1}>
              <RalvieLogoIcon className="header-logo-icon-toggled" />
            </Box>
            <Box
              item
              className="navbar-close"
              onClick={() => setExpand(!expand)}
            >
              <NavPanelOpenIcon className="navbar-close-icon" />
            </Box>
          </Box>
        )}
        {expand && (
          <Box className="header-logo">
            <Box flexGrow={1}>
              {mode === "dark" ? (
                <RalvieLogoDark className="header-logo-icon" />
              ) : (
                <RalvieLogo className="header-logo-icon" />
              )}
            </Box>
            <Box
              item
              className="navbar-close"
              onClick={() => setExpand(!expand)}
            >
              <NavPanelCloseIcon className="navbar-close-icon" />
            </Box>
          </Box>
        )}
        <div className="ForDashSubMenu">
          <NavMenuItem
            className="dashboardMenu"
            isActive={
              window.location.pathname === "/dashboard" ||
              window.location.pathname === "/categoriesApps"
            }
            activeIcon={
              <img
                alt={"dashboard"}
                src={DashboardActiveIcon}
                className="nav-menu-svg"
              />
            }
            inActiveIcon={
              <img
                alt={"dashboard"}
                src={
                  mode === "light"
                    ? DashboardInactiveIcon
                    : DashboardInactiveIconDark
                }
                className="nav-menu-svg"
              />
            }
            title={"Dashboard"}
            link={"/dashboard"}
            id={"nav-menu-dashboard"}
          />
          <NavMenuItem
            isActive={window.location.pathname?.includes("/timesheet")}
            activeIcon={
              <img
                alt={"timesheet"}
                src={TimeSheetActiveIcon}
                className="nav-menu-svg"
              />
            }
            inActiveIcon={
              <img
                alt={"timesheet"}
                src={
                  mode === "light"
                    ? TimeSheetInActiveIcon
                    : TimeSheetInActiveIconDark
                }
                className="nav-menu-svg"
              />
            }
            title={"Timesheet"}
            link={"/timesheet"}
            id={"nav-menu-timesheet"}
          />

          {role !== "EMPLOYEE" && role !== "CLIENT" && (
            <NavMenuItem
              activeIcon={
                <img
                  alt={"project"}
                  src={ProjectActiveIcon}
                  className="nav-menu-svg"
                />
              }
              inActiveIcon={
                <img
                  alt={"project"}
                  src={
                    mode === "light"
                      ? ProjectInActiveIcon
                      : ProjectInActiveIconDark
                  }
                  className="nav-menu-svg"
                />
              }
              isActive={window.location.pathname?.includes("/projects")}
              title={"Projects"}
              link={"/projects"}
              id={"nav-menu-project"}
            />
          )}
          {role !== "EMPLOYEE" && (
            <NavMenuItem
              isActive={window.location.pathname?.includes("/members")}
              activeIcon={
                <img
                  alt={"member"}
                  src={MemberActiveIcon}
                  className="nav-menu-svg"
                />
              }
              inActiveIcon={
                <img
                  alt={"member"}
                  src={
                    mode === "light"
                      ? MemberInActiveIcon
                      : MemberInActiveIconDark
                  }
                  className="nav-menu-svg"
                />
              }
              title={"Members"}
              link={"/members"}
              id={"nav-menu-member"}
            />
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <NavMenuItem
              isActive={window.location.pathname?.includes("/tags")}
              activeIcon={
                <img alt={"tag"} src={TagActiveIcon} className="nav-menu-svg" />
              }
              inActiveIcon={
                <img
                  alt={"tag"}
                  src={mode === "light" ? TagInActiveIcon : TagInActiveIconDark}
                  className="nav-menu-svg"
                />
              }
              title={"Tags"}
              link={"/tags"}
              id={"nav-menu-tag"}
            />
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <NavMenuItem
              isActive={window.location.pathname?.includes("/integration")}
              activeIcon={
                <img
                  alt={"integration"}
                  src={IntegrationActiveIcon}
                  className="nav-menu-svg"
                />
              }
              inActiveIcon={
                <img
                  alt={"integration"}
                  src={mode === "light" ? IntegrationIcon : IntegrationIconDark}
                  className="nav-menu-svg"
                />
              }
              title={"Integrations"}
              id={"nav-menu-integration"}
              link={"/integration"}
            />
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <Tooltip
              className="tooltipMenu"
              title={expand || toggleReportMenu ? null : "Reports"}
              placement="right"
              arrow
              componentsProps={toolTipStyle}
              PopperProps={toolTipPopoverPosition}
            >
              <Box
                {...bindTrigger(reportPopupState)}
                className={`cursor-pointer nav-menu-expand ${
                  window.location.pathname?.includes("/reports")
                    ? "nav-menu-active"
                    : "nav-menu-inactive"
                }`}
              >
                <Box
                  onClick={() => reportPopupState.close()}
                  className="nav-menu-icon"
                >
                  {toggleReportMenu ||
                  window.location.pathname?.includes("/reports") ? (
                    <img
                      src={ReportActiveIcon}
                      alt=""
                      className="nav-menu-svg"
                    />
                  ) : mode === "light" ? (
                    <img
                      src={ReportInActiveIcon}
                      alt=""
                      className="nav-menu-svg"
                    />
                  ) : (
                    <img
                      src={ReportInActiveIconDArk}
                      alt=""
                      className="nav-menu-svg"
                    />
                  )}
                </Box>

                {expand && (
                  <>
                    <Box flexGrow={1}>
                      <Typography
                        className={`nav-menu-text ${
                          window.location.pathname?.includes("/reports")
                            ? "nav-menu-text-active"
                            : ""
                        }`}
                        style={{
                          textTransform: "capitalize",
                          paddingTop:
                            CommonUtil.getOS() === "MacOS" ? "11px" : "0px",
                        }}
                      >
                        <OverFlowText text={"Reports"} maxLength="10">
                          {"Reports"}
                        </OverFlowText>
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      {mode === "dark" ? <ArrowRightDark /> : <ArrowRight />}
                    </Box>
                  </>
                )}
              </Box>
            </Tooltip>
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <Tooltip
              title={expand || toggleSettingsMenu ? null : "Settings"}
              placement="right"
              arrow
              componentsProps={toolTipStyle}
              PopperProps={toolTipPopoverPosition}
            >
              <Box
                {...bindTrigger(settingPopupState)}
                className={`cursor-pointer nav-menu-expand ${
                  window.location.pathname?.includes("/settings")
                    ? "nav-menu-active"
                    : "nav-menu-inactive"
                }`}
              >
                <Box
                  onClick={() => settingPopupState.close()}
                  className="nav-menu-icon"
                >
                  {toggleSettingsMenu ||
                  window.location.pathname?.includes("/settings") ? (
                    <img
                      src={SettingsActiveIcon}
                      alt=""
                      className="nav-menu-svg"
                    />
                  ) : mode === "light" ? (
                    <img src={SettingsIcon} alt="" className="nav-menu-svg" />
                  ) : (
                    <img
                      src={SettingsIconDark}
                      alt=""
                      className="nav-menu-svg"
                    />
                  )}
                </Box>
                {expand && (
                  <>
                    <Box flexGrow={1}>
                      <Typography
                        className={`nav-menu-text ${
                          window.location.pathname?.includes("/settings")
                            ? "nav-menu-text-active"
                            : ""
                        }`}
                        style={{
                          textTransform: "capitalize",
                          paddingTop:
                            CommonUtil.getOS() === "MacOS" ? "11px" : "0px",
                        }}
                      >
                        <OverFlowText text={"Settings"} maxLength="10">
                          {"Settings"}
                        </OverFlowText>
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      {mode === "dark" ? <ArrowRightDark /> : <ArrowRight />}
                    </Box>
                  </>
                )}
              </Box>
            </Tooltip>
          )}
        </div>
      </Box>

      <Box className="navSecondSection">
        <div
          className="navSdFlex"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "10px",
            justifyContent: "end",
          }}
        >
          {/* AiIcon */}
          <Box
            className="AiIconStyle"
            onClick={() => {
              if (!expand && !enableAi) {
                setToggleAiBtn(true);
              } else {
                setExpand(true);
              }
            }}
            sx={{
              background: enableAi
                ? "var(--AI-assistant-background)"
                : "var(--background-color-42)",
            }}
          >
            {/* <img src={AiIcon} alt="" height={"30px"} width={"30px"} />  */}
            <img src={LavoroAiIcon} alt="" height={"25px"} width={"25px"} />
            {expand && (
              <Box className="aiTextRadio">
                <Box>
                  <Typography>AI assistant</Typography>
                </Box>
                <CustomSwitch
                  className="aiAssistant"
                  value={enableAi}
                  checked={enableAi}
                  onChange={(e) => {
                    if (e.target.checked) setToggleAiBtn(e.target.checked);
                    else {
                      setEnableAi(false);
                      localStorage.setItem("enableAi", false);
                    }
                  }}
                />
              </Box>
            )}
          </Box>

          {/* OrganizationIcon */}
          <Tooltip
            className="iconOrganization"
            title={expand || toggleOrgMenu ? null : "Organization"}
            placement="right"
            arrow
            componentsProps={toolTipStyle}
            PopperProps={toolTipPopoverPosition}
          >
            <Box
              {...bindTrigger(orgPopupState)}
              className={`cursor-pointer ${
                toggleOrgMenu ? "nav-menu-expand" : "nav-menu"
              } nav-menu-inactive paddingLeftRight`}
            >
              <Box
                className="OrganizationIcon1"
                onClick={() => orgPopupState.close()}
              >
                {toggleOrgMenu ? (
                  <img
                    src={OrganizationActiveIconImg}
                    alt=""
                    className="nav-menu-svg OrganizationIconStyle"
                  />
                ) : // iconActive
                mode === "light" ? (
                  <OrganizationIcon className="nav-menu-svg OrganizationIconStyle " />
                ) : (
                  <OrganizationIconDark className="nav-menu-svg OrganizationIconStyle" />
                )}
              </Box>
              {expand && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box flexGrow={1} className="navEllipsis">
                    {/* nav-menu-text  */}
                    <Typography
                      className={`${
                        toggleOrgMenu ? "nav-menu-text-active" : ""
                      }`}
                    >
                      <OverFlowText
                        text={CommonUtil.getLoggedUser()?.companyName}
                        maxLength="13"
                      >
                        {CommonUtil.getLoggedUser()?.companyName}
                      </OverFlowText>
                    </Typography>
                  </Box>
                  <Box className="rightArrow">
                    {mode === "dark" ? <ArrowRightDark /> : <ArrowRight />}
                  </Box>
                </Box>
              )}
            </Box>
          </Tooltip>

          {/* ProfileGroupIcon */}
          <Tooltip
            className="iconGroupIcon"
            title={expand ? null : `User profile`}
            placement="right"
            arrow
            componentsProps={toolTipStyle}
            PopperProps={toolTipPopoverPosition}
          >
            <Box
              {...bindTrigger(popupState)}
              className={`cursor-pointer ${
                toggleUserMenu ? "nav-menu-expand" : "nav-menu"
              } ${
                window.location.pathname?.includes("/userprofile")
                  ? "nav-menu-active"
                  : "nav-menu-inactive"
              } nav-menu-inactive paddingLeftRight`}
            >
              <Box
                onClick={() => popupState.close()}
                sx={{
                  margin: expand ? "0 0.5rem 0 0.9rem" : "",
                }}
              >
                <CustomIconButton
                  title={""}
                  size={isXlScreen ? "md" : "sm"}
                  variant=""
                  lightMode={
                    profilePic ? (
                      <img
                        src={profilePic}
                        alt="Profile"
                        style={profileImageStyle}
                      />
                    ) : (
                      <ProfileIcon className="nav-menu-svg" />
                    )
                  }
                  darkMode={
                    profilePic ? (
                      <img
                        src={profilePic}
                        alt="Profile"
                        style={profileImageStyle}
                      />
                    ) : (
                      <ProfileIcon className="nav-menu-svg" />
                    )
                  }
                />
              </Box>

              {expand && (
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box flexGrow={1} className="navEllipsis">
                    <OverFlowText
                      className={`${
                        toggleUserMenu ? "nav-popover-active" : ""
                      }`}
                      text={memberName}
                      maxLength="13"
                    >
                      {memberName}
                    </OverFlowText>
                  </Box>
                  <Box className="rightArrow">
                    {mode === "dark" ? <ArrowRightDark /> : <ArrowRight />}
                  </Box>
                </Box>
              )}
            </Box>
          </Tooltip>

          {/* dark mode */}
          <Tooltip
            className="changeModeColor paddingLeftRight"
            title={
              expand
                ? null
                : `Switch to ${mode === "dark" ? "light" : "dark"} mode`
            }
            placement="right"
            arrow
            componentsProps={toolTipStyle}
            PopperProps={toolTipPopoverPosition}
          >
            <Box
              onClick={() => colorMode.toggleColorMode()}
              className={`cursor-pointer ${
                toggleSettingsMenu ? "nav-menu-expand" : "nav-menu"
              } `}
            >
              <Box
                sx={{
                  margin: expand ? "0 0.5rem 0 0.9rem" : "",
                }}
              >
                <CustomIconbtn
                  title={""}
                  size={isXlScreen ? "md" : "sm"}
                  variant=""
                  mode={mode}
                  darkMode={<DarkMode className="nav-menu-svg" />}
                  lightMode={<LightMode className="nav-menu-svg" />}
                />
              </Box>

              {expand && (
                <>
                  <Box flexGrow={1}>
                    <Typography>
                      {mode === "dark" ? "Light mode" : "Dark mode"}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Tooltip>
        </div>
      </Box>
      {/* UserNavSubMenu */}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -4 : 120,
        }}
        sx={{
          left: expand ? 23 : 80,
          top: 44,
        }}
        PaperProps={{
          style: {
            overflow: "visible",
            background: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
          },
        }}
      >
        <UserNavSubMenu popupState={popupState} />
      </Popover>
      {/* reportPopupState */}
      <Popover
        {...bindPopover(reportPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -2 : 120,
        }}
        sx={{
          left: expand ? 2 : 80,
        }}
      >
        <CollapseNavSubMenu
          popupState={reportPopupState}
          subModules={[
            {
              title: "Projects",
              link: "/reports/project",
              isActive: window.location.pathname?.includes("/reports/project"),
            },
          ]}
        />
      </Popover>
      {/* settingPopupState */}
      <Popover
        {...bindPopover(settingPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -2 : 120,
        }}
        sx={{
          left: expand ? 2 : 80,
        }}
      >
        <CollapseNavSubMenu
          popupState={settingPopupState}
          subModules={[
            {
              title: "Department",
              link: "/settings/department",
              isActive: window.location.pathname?.includes(
                "/settings/department"
              ),
            },
            {
              title: "Position",
              link: "/settings/position",
              isActive:
                window.location.pathname?.includes("/settings/position"),
            },
          ]}
        />
      </Popover>

      {/* Organization */}
      <Popover
        {...bindPopover(orgPopupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -2 : 120,
        }}
        sx={{
          left: expand ? 25 : 80,
          top: 40,
        }}
        PaperProps={{
          style: {
            overflow: "visible",
            background: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
          },
        }}
      >
        <Organization />
      </Popover>
      {/* enableAi */}
      <AiIntroduction
        open={toggleAiBtn}
        handleClose={() => setToggleAiBtn(false)}
        onSubmit={() => {
          setEnableAi(true);
          localStorage.setItem("enableAi", true);
          setToggleAiBtn(false);
        }}
      />
    </div>
  );
}
