import { Tooltip, Typography } from "@mui/material";
import React from "react";
import CommonUtil from "../util/CommonUtils";

export default function OverFlowText(props) {
  return (
    <Tooltip
      title={
        props.text && props.text.length > props.maxLength ? props.text : null
      }
    >
      <Typography variant={props.variant} className={props.className}>
        {CommonUtil.handleTextOverflow(props.children, props.maxLength)}
      </Typography>
    </Tooltip>
  );
}
