import api from "../config/http-common";

export const generateDaySummarize = async (payload) => {
  return api.securedAxiosApi().post("/ai/day_summarize", payload);
};

export const getSummaryHistory = async (startTime, endTime) => {
  return api
    .securedAxiosApi()
    .get("/ai/summaries?startTime=" + startTime + "&endTime=" + endTime);
};

export const projectPredict = async (payload) => {
  return api.securedAxiosApi().post("/ai/project_predict", payload);
};
