import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tooltip,} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ExpandMoreIcon } from "../../assets/images/sidebar_toggle_up_icon.svg";
import { sidebarContext } from "../../util/context";
import "./styles.scss";
import { SideBarItems } from "./SideBarItems";
import { ReactComponent as TTIMWithName } from "../../assets/images/ttim_name_icon.svg";
import { ReactComponent as TTIMWithoutName } from "../../assets/images/ttim_logo.svg";

export default function SideBarMenu(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const { expand, setExpand } = useContext(sidebarContext);
  const [selectedMenu, setSelectedMenu] = React.useState("");
  const [selectedSubMenu, setSelectedSubMenu] = React.useState("");

  const isMenuActive = (item) => {
    if ( item.link !== "" && location.pathname.startsWith(item.link)) {
      return true;
    } else {
      let items = item?.items;
      if (items instanceof Array && items.length > 0) {
        let obj = items.find((x) => location.pathname.startsWith(x.link));
        if (obj) {
          return true;
        }
      }
    }
  };

  useEffect(() => {
   
    const findActiveMainItem = (items, pathname) => {
      return items.find(item => pathname.startsWith(item.link)) || null;
    };
  
    const findActiveSubItem = (mainItem, pathname) => {
      return mainItem && mainItem.items
        ? mainItem.items.find(item => pathname.startsWith(item.link)) || null
        : null;
    };
  
    let activeMainItem = null;
    let activeSubItem = null;
  
    // Check main items first
    activeMainItem = findActiveMainItem(SideBarItems, location.pathname);
  
    // Check subitems if a main item is found
    if (activeMainItem) {
      activeSubItem = findActiveSubItem(activeMainItem, location.pathname);
    }
  
    setSelectedMenu(activeMainItem?.name);
    setSelectedSubMenu(activeSubItem?.name || null);
  }, [location.pathname, SideBarItems]);
  
  const handleMenu = (menu) => {
  
    if (expand || !expand) {
      if (
        selectedMenu === menu.name &&
        menu.items.find((childItem) => childItem.name === selectedSubMenu) &&
        menu.items
      ) {
        // If the same main menu and submenu are selected, reset both to close the submenu
        setSelectedMenu("");
        setSelectedSubMenu("");
      } else {
        setSelectedMenu(menu.name);
        let items = menu.items;
        if (items instanceof Array && items.length > 0) {
          // let obj = items.find((x) => x.link === location.pathname);
          let obj = items.find((x) => location.pathname.startsWith(x.link || ""));
          console.log('obj==',obj)
          if (obj) {
      
            setSelectedSubMenu(obj?.name || "");
          } else {
            // console.log('items==',menu)
            // console.log('items==++',items)
            setSelectedSubMenu( items[0].name );
             if (items[0].link) navigate(items[0].link);
          }
        }
        // Set selectedSubMenu to the first submenu item when reopening the submenu
        if (menu.link) navigate(menu.link);
      }
    } else {
      if (selectedMenu === menu.name) {
        setSelectedMenu(""); // Close the main menu if it's already open
        setSelectedSubMenu(""); // Reset selectedSubMenu when closing the main menu
      } else {
        setSelectedMenu(menu.name); // Set selectedMenu for filtered text matching main menu
        setSelectedSubMenu(""); // Reset selectedSubMenu for filtered text matching main menu
      }
      // toggleSidebar(!expand);
    }
  };

  const toggleSidebar = (toggle) => {
    // props.exapnded(toggle);
    setExpand(toggle);
  };

  const handleClose = () => {
    setSelectedMenu("");
    // toggleSidebar(!expand);
  };
  const handleSubMenu = (menu) => {
    setSelectedSubMenu(menu.name);
    if (menu.link) navigate(menu.link);
  };
  const [filterText, setFilterText] = useState("");
  const handleFilterChange = (event) => {
    event.preventDefault();
    setFilterText(event.target.value);
  };
  // alert(filterText)

  // Update filteredItems logic
  const filteredItems = SideBarItems.map((item) => {
    const lowerCaseFilterText = filterText.toLowerCase();
    const lowerCaseItemName = item.name.toLowerCase();

    // Check if the main menu item matches the filter partially
    if (lowerCaseItemName.includes(lowerCaseFilterText)) {
      return { ...item };
    }

    // Check if it is the "Setup" menu
    if (item.name.toLowerCase() === "setup" && item.items) {
      // Filter sub-menu items based on the filter text
      const matchingSubItems = item.items.filter((subItem) =>
        subItem.name.toLowerCase().includes(lowerCaseFilterText)
      );

      // Include the main menu item with filtered sub-items
      if (matchingSubItems.length > 0) {
        return {
          ...item,
          items: matchingSubItems,
        };
      }
    }

    return null;
  }).filter(Boolean); // Remove null entries from the array

  console.log("----", filteredItems);
  useEffect(() => {
    const filteredSubMenu = filteredItems.find(
      (item) =>
        item.items &&
        item.items.some(
          (subItem) =>
            subItem.name.toLowerCase() === (selectedSubMenu || "").toLowerCase()
        )
    );

    if (filteredSubMenu) {
      setSelectedMenu(filteredSubMenu.name); // Set selectedMenu to the parent menu of filteredSubMenu
    } else {
      setSelectedMenu(""); // Reset selectedMenu when no subitems are found for selectedSubMenu
    }
  }, [filteredItems, selectedSubMenu]);

  return (
    <Grid
      sx={{
        display: "flex",
        padding: "0px 0px",
        position: "relative",
        height: "100%",
        "@media (min-width: 2100px)": { height: `100%` },
      }}
    >
      <Grid
        id={"sideBarMain"}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: expand ? "90px" : "201px",
          height: expand ? "calc(100% - 100px)" : "calc(100% - 100px)",
          overflowY: "auto",
          overflowX: "hidden",
          transition: `0.5s`,
          transitionTimingFunction: "ease-in-out",
          "@media (max-width: 1400px)": {
            width: expand ? "90px" : "200px",
          },
          paddingLeft: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginRight: expand ? "35px" : "35px",
          }}
        >
    <>
        <Box
          id={"icon-header"}
          sx={{
            display: 'flex',
            backgroundColor: '#fff',
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            minHeight:"80px",
            paddingLeft:"15px",
            marginBottom:'-10px'
          }}>
          <TTIMWithoutName width={35} height={40}
            style={{
              cursor: 'pointer',
              marginRight:"-15px"
            }}
             />
          <TTIMWithName width={102} height={30}
            style={{
              width: !expand ? '135px' : '0px',
              paddingLeft: "10px",
              transition: "all 0.3s ease-in-out",
              cursor: 'pointer'
            }}
          />
        </Box>
      </>

          {expand ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "0.5em",
                transition: `0.1s`,
                transitionTimingFunction: "ease-in-out",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                }}
              >
                <IconButton
                  style={{
                    alignSelf: "center",
                    borderRadius: "50%",
                    height: "18px",
                    width: "18px",
                    padding: "0px",
                    top: "30px",
                    position: "absolute",
                    right: "-13px",
                    backgroundColor: `#A2A4A6`,
                    id: "sideBar:menuToggle",
                  }}
                  onClick={() => setExpand(!expand)}
                  disableRipple
                >
                  <ExpandMoreIcon
                    style={{
                      opacity: "1",
                      fill: "#A2A4A6",
                      transform: "rotate(180deg)",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                transition: `0.1s`,
                transitionTimingFunction: "ease-in-out",
                padding: "5px",
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "2%",
                margin: "auto",
                mb: "20px",
              }}
            >
              <IconButton
                style={{
                  alignSelf: "center",
                  backgroundColor: `#A2A4A6`,
                  borderRadius: "50%",
                  height: "18px",
                  width: "18px",
                  padding: "0px",
                  top: "30px",
                  position: "absolute",
                  right: "-10px",
                  id: "sideBar:menuToggleNew",
                }}
                onClick={() => setExpand(!expand)}
                disableRipple
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          )}
         
        </Box>
        <List
          id="Sidebar:MenuItems"
          sx={{ width: "100%", maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {filteredItems.map((item, index) => (
            <div key={index}>
              <ListItemButton
                onClick={() => handleMenu(item)}
                sx={{
                  width: !expand ? "83%" : "40px",
                  height: !expand ? "40px" : "40px",
                  margin: "auto",
                  marginTop: "8px",
                  marginLeft: !expand ? "12px" : " 12px",
                  padding: "6px",
                  paddingTop: expand ? "7px" : "7px",
                  paddingBottom: expand ? "7px" : "7px",
                  borderRadius: !expand ? "10px" : "10px",
                  transition: `0.5s`,
                  transitionTimingFunction: "ease-in-out",
                  paddingLeft: "0px"
                }}
              >
                <ListItemIcon
                  sx={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    opacity: "1",
                    backgroundColor: isMenuActive(item) ? "#1D0B77" : null,
                    "&.MuiListItemIcon-root": { 
                      minWidth: "20px",
                      paddingLeft: "10px",
                      padding:'10px',
                      borderRadius:'10px',
                    },
                  }}
              >
                  {selectedMenu === item.name ||
                  location.pathname === item.link ? (
                    <item.Icon1 />
                  ) : (
                    <item.Icon />
                  )}
                </ListItemIcon>
               
                  <ListItemText
                    primary={expand ? "" : item.name}

                    primaryTypographyProps={{
                      fontWeight: selectedMenu === item.name,
                      color:
                        selectedMenu === item.name ||
                        location.pathname === item.link
                          ? "#fff"
                          : "#A2A4A6",
                      fontSize: "16px",
                      color: isMenuActive(item) ? "#1D0B77" : "#A2A4A6",
                      fontFamily: "normal normal normal 16px/25px Poppins",
                      textAlign: "left",
                      letterSpacing: "0px",
                      paddingLeft:'17px',
                      textAlign: "left",
                      opacity: 1,
                    }}
                  />
               
                  {!expand ? item.items instanceof Array && item.items.length > 0 && (
                     <ExpandMore style={{ color: "#A2A4A6", marginLeft: "10px",}} />
                  ): ""}
                  
              </ListItemButton>
              
              <Collapse
                in={
                  selectedMenu === item.name ||
                  filterText.toLowerCase() === item.name.toLowerCase() ||
                  (item.items &&
                    item.items.some(
                      (subItem) =>
                        subItem.name.toLowerCase() === filterText.toLowerCase()
                    ))
                }
                timeout="auto"
                unmountOnExit
                sx={{ width: "98%", marginLeft: "8px" }}
              >
                {item.items instanceof Array &&
                  item.items.map((cItem, cIndex) => (
                    <List component="div" sx={{ width: "95%" }} disablePadding>
                      {expand === false && (
                        <Tooltip title={cItem.name === "" ? "" : ""}>
                          <ListItemText
                            sx={{
                              p: "6px",
                              borderRadius: "10px",
                              marginLeft: "10px",
                              marginRight: "0px",
                            }}
                            onClick={() => handleSubMenu(cItem)}
                            primary={
                              <ul
                                style={{
                                  transition: `0.5s`,
                                  transitionTimingFunction: "ease-in-out",
                                  fontSize: "14px",
                                  fontWeight: "200",
                                  marginBottom: "5px"
                                }}
                              >
                                <li
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                    listStyle: "none",
                                    color:
                                      selectedSubMenu === cItem.name
                                        ? "#1D0B77"
                                        : "",
                                    fontWeight:
                                      selectedSubMenu === cItem.name
                                        ? "400"
                                        : "",
                                  }}
                                >
                                  <span style={{ marginLeft: "5px", }}>
                                    {cItem.name}
                                  </span>
                                </li>
                              </ul>
                            }
                            primaryTypographyProps={{
                              fontWeight: selectedSubMenu === cItem.name,
                              color:
                                selectedSubMenu === cItem.name
                                  ? "#A2A4A6"
                                  : "#A2A4A6",
                              fontSize: "14px",
                              fontWeight: "200",
                              textAlign: "left",
                              letterSpacing: "0px",
                              opacity: "1"
                            }}
                          />
                        </Tooltip>
                      )}
                    </List>
                  ))}
              </Collapse>
            </div>
          ))}
        </List>
        {expand ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: "4em",
              backgroundColor: "#fff",
              transition: `0.1s`,
              transitionTimingFunction: "ease-in-out",
            }}
          >
          </Box>
        ) : (
          <Box
            sx={{
              transition: `0.1s`,
              transitionTimingFunction: "ease-in-out",
              position: "absolute",
              bottom: "60px",
              left: "0px",
              padding: "5px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "95%",
              margin: "auto",
              mb: "20px",
            }}
          >
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
