import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ErrorIcon } from "../assets/images/ralvie/error-icon.svg";
import CommonUtil from "../util/CommonUtils";
import { useTranslation } from "react-i18next";
import AppContext from "../config/AppContext";

const CssTextField = styled(TextField)(({ mode }) => ({
  "& .MuiOutlinedInput-root ": {
    backgroundColor: "var(--input-background) !important",
    borderRadius: "5px",
    opacity: 1,
    font: "normal normal normal 16px/20px Gotham",
    color: "var(--text-primary)",
    "& fieldset": {
      borderRadius: "5px",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    },
    "&.Mui-focused": {
      backgroundColor: "var(--input-focused-background-15) !important",
    },
    "&.Mui-error ": {
      backgroundColor: "var(--input-error-background) !important",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "5px",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
      backgroundColor: "var(--input-focused-background-15) !important",
    },
    // Autofill styles
    "& input:-webkit-autofill": {
      backgroundColor:
        mode == "dark" ? "#1f1f1f" : "var(--input-background) !important",
      WebkitBoxShadow:
        mode == "dark"
          ? "0 0 0px 1000px #1f1f1f inset !important"
          : "0 0 0px 1000px var(--input-background) inset !important",
      transition: "background-color 5000s ease-in-out 0s",
      // borderBottom: "1px solid #B2B2B2 !important",
      color: mode == "dark" ? "#242424" : "var(--text-primary)",
    },

    "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
      {
        backgroundColor:
          mode == "dark"
            ? "rgba(29, 11, 119, 0.15) !important"
            : "var(--input-focused-background-15) !important",
        WebkitBoxShadow:
          mode == "dark"
            ? "0 0 0px 1000px rgba(29, 11, 119, 0.15) inset !important"
            : "0 0 0px 1000px var(--input-focused-background-15) inset !important",
      },
  },
}));

export default function CustomTextField(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(props.label);
  const { mode } = useContext(AppContext);
  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    let val = e.target.value;
    if (val.trim() === "") {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === " " && val[val.length - 2] === " ") {
        return;
      }
      if (val[0] === " ") {
        return;
      }
    }
    if (props.validation) {
      switch (props.validation) {
        case "alpha-numeric":
          handleAlphaNumeric(e);
          break;
        case "numeric":
          handleNumeric(e);
          break;
        case "email":
          handleEmail(e);
          break;
        case "url":
          handleUrl(e);
          break;
        case "password":
          handlePassword(e);
          break;
        case "code":
          handleCode(e);
          break;
        case "mobile":
          handleMobile(e);
          break;
        case "postcode":
          handlePostCode(e);
          break;
        case "alpha-numeric-underscore":
          handleCodeUnderscore(e);
          break;
        case "alpha-numeric-space":
          handleAlphaNumericSpace(e);
          break;
        case "numericWithoutDot":
          handleNumericWithoutDot(e);
          break;
        case "nonWhiteSpace":
          handleNonWhiteSpace(e);
          break;
        default:
          props.onChange(e);
      }
    } else {
      props.onChange(e);
    }
  };

  const handleAlphaNumeric = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0025"));
    }
  };
  const handleNonWhiteSpace = (e) => {
    if (CommonUtil.isNonWhitespace(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0154"));
    }
  };

  const handleCodeUnderscore = (e) => {
    if (CommonUtil.isAlphaNumericUnderscore(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("Only accepts alphabets, numerics and underscore"));
    }
  };

  const handleAlphaNumericSpace = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(
        t("Only accepts alphabets, numerics, space and underscore")
      );
    }
  };
  const handleNumericWithoutDot = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText("Only accepts numerics");
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0023"));
    }
  };

  const handlePostCode = (e) => {
    if (CommonUtil.isValidCode(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0021"));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0024"));
    }
  };

  const handleEmail = (e) => {
    props.onChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0022"));
    }
  };

  const handleUrl = (e) => {
    props.onChange(e);
    if (CommonUtil.isValidUrl(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0029"));
    }
  };

  const handleMobile = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0024"));
    }
  };

  const handlePassword = (e) => {
    props.onChange(e);
    if (CommonUtil.isAlphaNumericPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0010"));
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (props.label) {
      setPlaceholderText(
        `${
          props.dropdown ? "Select " : t("RAL0089")
        }${props.label.toLowerCase()}`
      );
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    setPlaceholderText(props.label);
  };

  return (
    <Box width={"100%"}>
      {!props.hideLabel && (
        <Box item pb={1}>
          <Typography className={`textfield-label`}>
            {props.label}
            {props.required && <span className="starRedColor">*</span>}
          </Typography>
        </Box>
      )}
      <CssTextField
        mode={mode}
        {...props}
        error={error}
        id={props.id}
        name={props.name}
        placeholder={isFocused ? placeholderText : props.label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        label=""
        fullWidth
        helperText={""}
        autoComplete="new-password"
        value={props.value}
        onChange={(e) => handleChange(e)}
      />
      {error && (
        <Box display={"flex"} alignItems={"center"} pt={0.5}>
          <Box item pr={1}>
            {<ErrorIcon />}
          </Box>
          <Box item>
            <Typography className="helper-text">{helperText}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
