import React, { useContext, useEffect } from "react";
import { ReactComponent as PasswordIcon } from "../../assets/images/ralvie/password_success_icon.svg";
import { ReactComponent as PasswordIconDark } from "../../assets/images/ralvie/password_success_icon_dark.svg";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./PasswordSuccess.scss";
import AppContext from "../../config/AppContext";

export default function PasswordSuccess() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { mode } = useContext(AppContext);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className="PasswordSuccess">
        <Box className="password_success_000">
          {mode === "dark" ? <PasswordIconDark /> : <PasswordIcon />}
        </Box>

        <Box className="password_success_001">
          <span>{t("RAL0108")}</span>
        </Box>

        <Box className="password_success_002">
          <span>{t("RAL0109")}</span>
        </Box>

        <Box className="password_success_003">
          <span>{t("RAL0072")}</span> &nbsp;
          <span className="password_success_004" onClick={() => navigate("/")}>
            {t("RAL0006")}
          </span>
        </Box>
      </div>
    </>
  );
}
