import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MuiTelInput } from "mui-tel-input";
import { enqueueSnackbar } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import PasswordField from "../../components/PasswordField";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
import OnboardService from "../../services/OnboardService";
import CommonLayout from "./CommonLayout";
import PrivacyPolicy from "./PrivacyPolicy";
import { toast } from "sonner";

export default function SignupWithoutCompany() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const formBoxRef = useRef(null);
  const theme = useTheme();
  const colorMode = useContext(AppContext);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const styleDialog = {
    "& .MuiDialog-paper": {
      padding: "33Px",
      position: "absolute",
      top: "18px",
      borderRadius: "8px",
      bottom: "10px",
      minWidth: "60%",
    },
  };

  const commonMenuItemStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "1px solid #1D0B77",
      },
      "&.Mui-error fieldset": {
        border: "1px solid #FE5050",
      },
    },
  };

  const [openDilog, setopenDilog] = React.useState(false);
  const [HeaderContent, setHeaderContent] = React.useState("");
  const [termsCondition, setTermsCondition] = React.useState(false);
  const [init, setInit] = useState({ termsCondition: true });

  const handleAgree = () => {
    if (HeaderContent === "Terms and conditions") {
      setInit({ ...init, termsCondition: false });
      setTermsCondition(true);
    }
    setopenDilog(false);
  };

  const handleDisAgree = () => {
    if (HeaderContent === "Terms and conditions") {
      setInit({ ...init, termsCondition: false });
      setTermsCondition(false);
    }
    setopenDilog(false);
  };

  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    companyCode: "TTIM",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    confirmPassword: "",
  });

  const scrollToTop = (top) => {
    formBoxRef.current.scroll({
      top: top,
      behavior: "smooth",
    });
  };

  const handleSubmit = (e) => {
    if (validate()) {
      let code = "";
      let phone = "";
      let phoneNumber = payload.phoneNumber.split(" ");
      if (phoneNumber.length > 1) {
        code = phoneNumber[0];
        phone = payload.phoneNumber.replace(/ /g, "").substring(code.length);
      }
      let data = {
        ...payload,
        phoneCountryCode: code,
        phone: phone,
      };

      OnboardService.signupWithoutCompany(data).then((res) => {
        if (res.data.code === "UASI0001") {
          var req = {
            userName: payload.email,
            password: payload.password,
          };
          OnboardService.login(req).then((res) => {
            if (res.data.code === "RCI0000") {
              localStorage.setItem(
                "token",
                "Bearer " + res.data?.data?.access_token
              );
            }
          });
          toast.success(res.data?.message);
          // enqueueSnackbar(res.data?.message, { variant: "success" });
          navigate("/pages/user-success");
        } else if (res.data.code === "UASE0020") {
          setError({ ...error, email: res.data?.message });
        } else if (res.data.code === "RCE0068") {
          setError({ ...error, email: res.data?.message });
        } else if (res.data.code === "RCE0073") {
          setError({ ...error, email: res.data?.message });
        } else if (res.data.code === "RCE0096") {
          setError({ ...error, phoneNumber: res.data?.message });
        } else if (res.data.code === "RCE0106") {
          setError({ ...error, password: res.data?.message });
        } else {
          console.log(res.data?.message);
        }
      });
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "phoneNumber") {
      let phoneNumber = value.split(" ");
      if (phoneNumber.length > 0) {
        let phone = value.replace(/ /g, "");
        if (phone.length > 24) {
          value = value.substring(0, 25);
        }
      }
    }

    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const validate = () => {
    if (!payload.firstName) {
      setError({
        ...error,
        firstName: t("RAL0001"),
      });
      // scrollToTop(0);
      return false;
    }
    if (!payload.lastName) {
      setError({
        ...error,
        lastName: t("RAL0001"),
      });
      // scrollToTop(0);
      return false;
    }
    if (!payload.email) {
      setError({
        ...error,
        email: t("RAL0001"),
      });
      // scrollToTop(0);
      return false;
    }
    if (!payload.phoneNumber) {
      setError({
        ...error,
        phoneNumber: t("RAL0001"),
      });
      // scrollToTop(80);
      return false;
    }
    let phoneNumber = payload.phoneNumber.split(" ");
    if (phoneNumber.length < 2) {
      setError({
        ...error,
        phoneNumber: t("RAL0001"),
      });
      // scrollToTop(80);
      return false;
    }
    let phone = payload.phoneNumber.replace(/ /g, "");
    if (phone.length > 24 || phone.length < 9) {
      setError({
        ...error,
        phoneNumber: t("RAL0009"),
      });
      // scrollToTop(80);
      return false;
    }

    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      // scrollToTop(200);
      return false;
    }

    if (!CommonUtil.isAlphaNumericPassword(payload.password)) {
      setError({
        ...error,
        password: t("RAL0010"),
      });
      // scrollToTop(200);
      return false;
    }

    if (!payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: t("RAL0001"),
      });
      return false;
    }

    if (payload.confirmPassword !== payload.password) {
      setError({
        ...error,
        confirmPassword: t("RAL0011"),
      });
      return false;
    }
    if (!termsCondition) {
      setError({
        ...error,
        termsCondition: t("RAL0079"),
      });
      toast.error(t("RAL0079"));
      // enqueueSnackbar(t("RAL0079"), { variant: "error" });
      return;
    }
    return true;
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleClosePrivacyPolicy = () => {
    setopenDilog(false);
  };

  const handleOpenPrivacyPolicy = () => {
    setopenDilog(true);
    setHeaderContent("Terms and conditions");
  };

  useEffect(() => {
    if (openDilog && HeaderContent === "Terms and conditions") {
    }
  }, [openDilog, HeaderContent]);

  return (
    <>
      <CommonLayout>
        <Box
          sx={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignContent: "center",
            py: 3,
          }}
        >
          <Typography variant="text_28_42_0_1">Sign up</Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid
              item
              lg={6}
              sm={12}
              sx={{
                pr: { lg: "15px", sm: "0px" },
              }}
            >
              <CustomInput
                sx={commonMenuItemStyle}
                autoComplete={false}
                required
                fullWidth
                label={t("RAL0012")}
                value={payload.firstName}
                name="firstName"
                onChange={handleChange}
                error={error.firstName}
                helperText={error.firstName}
                inputProps={{
                  maxLength: 60,
                }}
                validation="alpha-numeric-space"
              />
            </Grid>
            <Grid
              item
              lg={6}
              sm={12}
              sx={{
                pl: { lg: "15px", sm: "0px" },
                pt: { lg: "0px", sm: "20px" },
              }}
            >
              <CustomInput
                sx={commonMenuItemStyle}
                required
                fullWidth
                label={t("RAL0013")}
                value={payload.lastName}
                name="lastName"
                onChange={handleChange}
                error={error.lastName}
                helperText={error.lastName}
                inputProps={{
                  maxLength: 30,
                }}
                validation="alpha-numeric-space"
              />
            </Grid>
            <Grid
              item
              lg={6}
              sm={12}
              sx={{
                pr: { lg: "15px", sm: "0px" },
                pt: { lg: "30px", sm: "20px" },
              }}
            >
              <CustomInput
                sx={commonMenuItemStyle}
                required
                fullWidth
                label={t("RAL0014")}
                value={payload.email}
                name="email"
                onChange={handleChange}
                error={error.email}
                helperText={error.email}
                inputProps={{
                  maxLength: 36,
                }}
                validation="email"
              />
            </Grid>
            <Grid
              item
              lg={6}
              sm={12}
              sx={{
                pl: { lg: "15px", sm: "0px" },
                pt: { lg: "30px", sm: "20px" },
              }}
            >
              <MuiTelInput
                sx={commonMenuItemStyle}
                placeholder={isFocused ? t("RAL0090") : t("RAL0016")}
                error={error.phoneNumber}
                helperText={error.phoneNumber}
                required
                label={isFocused ? t("RAL0016") : ""}
                defaultCountry="US"
                forceCallingCode
                focusOnSelectCountry
                fullWidth
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={payload.phoneNumber}
                onChange={(newValue) =>
                  handleChange({
                    target: { name: "phoneNumber", value: newValue },
                  })
                }
                inputProps={{
                  maxLength: 25,
                }}
              />
            </Grid>
            <Grid
              item
              lg={12}
              minWidth="100%"
              sx={{
                pt: { lg: "30px", sm: "20px" },
              }}
            >
              <PasswordField
                id="textfield-singup-view-password"
                sx={commonMenuItemStyle}
                required
                fullWidth
                label={t("RAL0017")}
                value={payload.password}
                name="password"
                onChange={handleChange}
                error={error.password}
                helperText={error.password}
                inputProps={{
                  maxLength: 25,
                }}
              />
            </Grid>
            <Grid
              item
              lg={12}
              minWidth="100%"
              sx={{
                pt: { lg: "30px", sm: "20px" },
              }}
            >
              <PasswordField
                id="textfield-singup-view-confirmpassword"
                sx={commonMenuItemStyle}
                required
                fullWidth
                label={t("RAL0018")}
                value={payload.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                error={error.confirmPassword}
                helperText={error.confirmPassword}
                inputProps={{
                  maxLength: 25,
                }}
              />
            </Grid>
            <Grid
              item
              lg={12}
              minWidth="100%"
              sx={{
                pt: { lg: "10px", sm: "10px" },
              }}
            >
              <Typography sx={{ color: "#474B4F" }}>
                <Checkbox
                  id={"checkbox-singup-view-terms"}
                  value={termsCondition}
                  checked={termsCondition}
                  onChange={(e) => setTermsCondition(e.target.checked)}
                />{" "}
                {t("RAL0087")}{" "}
                <Link
                  id={"link-singup-view-terms"}
                  style={{
                    color: "#1D0B77",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenPrivacyPolicy}
                >
                  {t("RAL0088")}{" "}
                </Link>
                &{" "}
                <Link
                  id={"link-singup-view-privacy"}
                  style={{
                    color: "#1D0B77",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenPrivacyPolicy}
                >
                  {t("RAL0095")}{" "}
                </Link>
              </Typography>
            </Grid>

            <Grid
              item
              lg={12}
              minWidth="100%"
              sx={{
                pt: { lg: "30px", sm: "20px" },
              }}
            >
              <Button
                variant="contained"
                fullWidth
                size="large"
                className="button-gradient"
                onClick={handleSubmit}
                id="btn_signup_submit"
                style={{ marginTop: "-15px" }}
              >
                <Typography variant="text_16_28_032_1_white">
                  {t("RAL0008")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={12} minWidth="100%">
            <Box
              display={"flex"}
              sx={{
                justifyContent: "center",
                alignContent: "center",
                mt: "30px",
                minWidth: "100%",
              }}
            >
              <Box item>
                <Typography variant="text_16_23_0_1">{t("RAL0069")}</Typography>
              </Box>
              <Box item>
                <Typography
                  id="link_signin_redirect"
                  variant="text_28_42_0_3"
                  sx={{ cursor: "pointer", paddingLeft: "5px" }}
                  onClick={() =>
                    window.location.replace("http://localhost:7600")
                  }
                >
                  {t("RAL0006")}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
      </CommonLayout>

      <Dialog
        sx={styleDialog}
        onClose={() => setopenDilog(!openDilog)}
        open={openDilog}
      >
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          style={{ color: "#474A4E", fontWeight: "600" }}
        >
          {HeaderContent}
        </Typography>
        {HeaderContent === "Terms and conditions" ? (
          <>
            <PrivacyPolicy
              onClose={handleClosePrivacyPolicy}
              onClick={() => handleAgree()}
            />
          </>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
}
