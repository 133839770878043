import { ReactComponent as Home_Icon } from "../../assets/images/home_icon.svg";
import { ReactComponent as Home_Icon1 } from "../../assets/images/home_icon_color.svg";
import { ReactComponent as Projects_Icon } from "../../assets/images/projects_icon.svg";
import { ReactComponent as Projects_Icon1 } from "../../assets/images/projects_icon_color.svg";
import { ReactComponent as Budget_Icon } from "../../assets/images/budget_icon.svg";
import { ReactComponent as Budget_Icon1 } from "../../assets/images/budget_icon_color.svg";
import { ReactComponent as Reports_Icon } from "../../assets/images/reports_icon.svg";
import { ReactComponent as Reports_Icon1 } from "../../assets/images/reports_icon_color.svg";

export const SideBarItems = [
  {
    id: "0",
    name: "Home",
    Icon: Home_Icon,
    Icon1: Home_Icon1,
    link: "/pages/home",
    items: [],
  },
  {
    id: "1",
    name: "Projects",
    Icon:  Projects_Icon,
    Icon1: Projects_Icon1,
    link: "/pages/projects",
    items: [],
  },
  {
    id: "2",
    name: "Budget",
    Icon: Budget_Icon,
    Icon1: Budget_Icon1,
    link: "/pages/budget",
    items: [],
  },
  {
    id: "3",
    name: "Reports",
    Icon: Reports_Icon,
    Icon1: Reports_Icon1,
    link: "",
    items: [
      {
        id: "4",
        icon: "",
        name: "Project Report",
        link: "/pages/reports-project",
      },
      {
        id: "5",
        icon: "",
        name: "Budget Report",
        link: "/pages/reports-budget",
      },
    ],
  },
  
];

