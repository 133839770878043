import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MemberDetails from "./MemberDetails";
import AppUsage from "./AppUsage";
import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import WeekSummary from "./WeekSummary";

const styleDialog = {
  "& .MuiDialog-paper": {
    padding: "33px",
    position: "absolute",
    top: "5px",
    bottom: "10px",
    minWidth: "70%",
  },
};

const TimesheetProjectReport = ({ open, onClose, startDate, endDate, userId }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} sx={styleDialog}>
      <IconButton
        style={{ position: "absolute", top: 10, right: 10, color: "#474A4E" }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle><span className="projectReportHeader">Timesheet Project Report</span></DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
          >
            <div style={{ border: "1px solid black", padding: "16px", height: "100%" }}>
              <MemberDetails startDate={startDate} endDate={endDate} userId={userId}/>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ border: "1px solid black", padding: "16px", height: "100%" }}>
              <AppUsage startDate={startDate} endDate={endDate} userId={userId}/>
              <Paper elevation={3} style={{ padding: 16, marginTop: '16px' }}>
              <span className="projectReportHeader">Attendance</span>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Day</TableCell>
                      <TableCell>In Time</TableCell>
                      <TableCell>Out Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>MON</TableCell>
                      <TableCell>09:30 AM</TableCell>
                      <TableCell>06:30 PM</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>TUE</TableCell>
                      <TableCell>09:30 AM</TableCell>
                      <TableCell>06:30 PM</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>WED</TableCell>
                      <TableCell>09:30 AM</TableCell>
                      <TableCell>06:30 PM</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>THUR</TableCell>
                      <TableCell>09:30 AM</TableCell>
                      <TableCell>06:30 PM</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div style={{ border: "1px solid black", padding: "16px", height: "100%" }}>
              <WeekSummary startDate={startDate} endDate={endDate} userId={userId} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TimesheetProjectReport;

