import React from 'react';
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import "./Components.scss";

export default function CustomDeleteButton({ onClick }) {
    return (
        <Button
            className="DeleteButton"
            variant="contained"
            sx={{
                backgroundColor: "#FE5050",
                '&:hover': {
                    backgroundColor: "#FE5050"
                }
            }}
            onClick={onClick} // Invoke the onClick handler passed from the parent component
        >
            <CloseIcon className="DeleteIcon" />
        </Button>
    );
}
