import React, { useEffect, useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { listApplication } from "../../services/DashboardService";
import CommonUtil from "../../util/CommonUtils";
import AppChart from "./AppChart";

export default function AppUsage({ userId, startDate, endDate }) {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [categoryData, setCategoryData] = useState([]);
  const [grandTotal, setGrandTotal] = useState({ hours: 0, minutes: 0 });

  const formattedStartTime = CommonUtil.formatDateToUTC(startDate);
  const formattedEndTime = CommonUtil.formatDateToUTCDate(endDate);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("USER"));

    const payload = {
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      companyId: userData.companyId,
      userId: userId === "Self" ? "" : userId,
    };

    listApplication(payload)
      .then((res) => {
        if (res.data.code === "RCI0000") {
          const data = res.data.data.categoryData.slice(0, 10);
          setCategoryData(data);
          setGrandTotal({
            hours: res.data.data.grandTotalHours,
            minutes: res.data.data.grandTotalMinutes,
          });
        } else {
          setCategoryData([]);
          setGrandTotal({ hours: 0, minutes: 0 });
        }
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
        setCategoryData([]);
        setGrandTotal({ hours: 0, minutes: 0 });
      });
  }, [userId, startDate, endDate]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            sx={{
              background: "#FFFFFF",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              height: {
                xs: "100px",
                sm: "150px",
                md: "200px",
                lg: "250px",
                xl: "300px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <span className="projectReportHeader">
                Usage by application categories
              </span>
            </Box>
            <Box sx={{ height: "calc(100% - 40px)", marginTop: "-60px" }}>
              <AppChart data={categoryData} grandTotal={grandTotal} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
