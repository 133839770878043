import React, { useContext, useState } from "react";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import CustomIconButton from "../../components/CustomIconButton";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/ralvie/add-icon.svg";
import AddCategory from "./AddCategory";
import ApplicationList from "./ApplicationList";
import CategoryList from "./CategoryList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TimesheetContext } from "../../util/context";
import AppContext from "../../config/AppContext";

export default function CategoryLayout() {
  const theme = useTheme();
  const { mode } = useContext(AppContext);
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [reload, setReload] = useState(false);
  const [apps, setApps] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddCategoryClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (categoryAdded = false) => {
    setAnchorEl(null);
    if (categoryAdded) {
      setReload((prev) => !prev);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const removeAppFromList = (appId) => {
    setApps((prevApps) => prevApps.filter((app) => app.id !== appId));
  };

  const actions = (
    <Box display="flex">
      <Box
        pr={2}
        sx={{
          "& .refresh-svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .refresh-svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
      >
        <CustomIconButton
          id="btn-refresh-category"
          title="Refresh"
          size={isXlScreen ? "large" : "md"}
          variant="square"
          lightMode={
            <RefreshIcon
              height={isXlScreen ? "18px" : "14px"}
              className="refresh-svg"
            />
          }
          darkMode={
            <RefreshIcon
              height={isXlScreen ? "18px" : "14px"}
              className="refresh-svg"
            />
          }
          onClick={() => setReload((prev) => !prev)} // Add refresh action
        />
      </Box>
      <Box>
        <CustomIconButton
          id={id}
          name="btn-add-category"
          title="Add Category"
          size={isXlScreen ? "large" : "md"}
          variant="square"
          type="contained"
          border={'none'}
          lightMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
          darkMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
          onClick={handleAddCategoryClick}
        />
      </Box>
    </Box>
  );

  return (
    <TimesheetContext.Provider value={{ reload, setReload }}>
      <ContentLayout
        title="Application category settings"
        navBack={true}
        actions={actions}
      >
        <AddCategory open={open} anchorEl={anchorEl} onClose={handleClose} />
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box sx={{ maxHeight: "85vh", overflowY: "auto" }}>
                <CategoryList reload={reload} setReload={setReload} removeAppFromList={removeAppFromList} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ maxHeight: "85vh", overflowY: "auto" }}>
                <ApplicationList reload={reload} setReload={setReload} apps={apps} setApps={setApps} removeAppFromList={removeAppFromList} />
              </Box>
            </Grid>
          </Grid>
        </DndProvider>
      </ContentLayout>
    </TimesheetContext.Provider>
  );
}
