import React, { useContext, useEffect } from "react";
import { ReactComponent as RalvieIntro } from "../../assets/images/ralvie/ralvie-intro.svg";
import { ReactComponent as RalvieDarkIntro } from "../../assets/images/ralvie/ralvie-dark-intro.svg";
import { ReactComponent as RalvieLogo } from "../../assets/images/ralvie/ralvie-logo.svg";
import { ReactComponent as RalvieDarkLogo } from "../../assets/images/ralvie/ralvieLogoDark.svg";
import { ReactComponent as I18nDark } from "../../assets/images/ralvie/i18n-dark.svg";
import { ReactComponent as I18nLight } from "../../assets/images/ralvie/i18n-light.svg";
import { ReactComponent as DarkMode } from "../../assets/images/ralvie/dark_mode.svg";
import { ReactComponent as LightMode } from "../../assets/images/ralvie/light_mode.svg";
import { ReactComponent as SignupLayoutImg } from "../../assets/images/SignupLayoutImg.svg";
import { Box, Grid } from "@mui/material";
import CustomIconButton, {
  CustomIconbtn,
} from "../../components/CustomIconButton";
import AppContext from "../../config/AppContext";
import "./SignupLayout.scss";

function SignupLayoutRight() {
  return (
    <>
      <div className="SignupLayout">
        <div className="h-100">
          <p className="headText">
            Unleash The <span>Power</span> of <br /> Every <span>Minute</span>
          </p>
          <p className="subText">
            Automated, AI-driven time tracking software of the future
          </p>
          <SignupLayoutImg className="invisible-img" />
        </div>
      </div>
    </>
  );
}

export default function SignupLayout(props) {
  const { colorMode, mode } = useContext(AppContext);

  return (
    <Grid container direction="row" height="100vh" width="100vw" p={2} sx={{ minHeight: "100vh" }}>
      <Grid container item height={"100%"}>
        <Grid container item md={6} xs={12} sm={12} direction={"row"}>
          <Grid item sm={12} height={"10%"} pl={5} pr={3}>
            <Box className="signUpLayoutTopAlign">
              <Box item flexGrow={1}>
                {mode === "dark" ? <RalvieDarkLogo /> : <RalvieLogo />}
              </Box>
              <Box item className="IconFlex">
                <CustomIconbtn className="ThemeIconAlign" id="btn-switch-lang"
                  title={
                    "Switch to " + (mode == "dark" ? "light" : "dark") + " mode"
                  }
                  borderColor=""
                  size=""
                  variant=""
                  mode={mode}
                  darkMode={<DarkMode />}
                  lightMode={<LightMode />}
                  onClick={() => colorMode.toggleColorMode()}
                />
                {/* <CustomIconButton
                  id="btn-switch-lang"
                  title={"Switch language"}
                  borderColor=""
                  size=""
                  variant=""
                  lightMode={mode == 'light' ? <I18nLight /> : <I18nDark />}
                  darkMode={mode == 'light' ? <I18nLight /> : <I18nDark />}
                  // onClick={() => colorMode.toggleColorMode()}
                  onClick={() => console.log("clicked")}
                /> */}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            height={"80%"}
            // width={"100%"}
            // mx={10}
            px={3}
          // border={"1px solid green"}
          >
            {props.children}
          </Grid>
        </Grid>
        <Grid container item md={6} height="100%" display={{ xs: "none", sm: "none", md: "flex", lg: "flex" }}>
          <Box container height={"100%"} width={"100%"} key={mode}>
            {/* {mode === "dark" ? <RalvieDarkIntro /> : <RalvieIntro />} */}
            <SignupLayoutRight />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
