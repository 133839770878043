import { OpenAI } from "@langchain/openai";
import { PromptTemplate } from "@langchain/core/prompts";
import { LLMChain } from "langchain/chains";

// require('dotenv').config();

// Ensure the API key is securely imported from environment variables
// const openAIApiKey = process.env.OPENAI_API_KEY;
// if (!openAIApiKey) {
//     console.error("OpenAI API key is not defined in environment variables.");
//     throw new Error("OpenAI API key is required");
// }

const llm = new OpenAI({
  openAIApiKey: "sk-proj-8w2SPHzQaxo1cSyGJkWqT3BlbkFJpp3OY2a9IqniGnH1ulR2",
  model: "gpt-4",
});

const prompt = new PromptTemplate({
  inputVariables: ["context", "language"],
  template: `As a team member on a project, I need summary of my activities in less than 200 words in key bullet points for the activities captured on my computer.
     The summary must include whom I've interacted with, the topics discussed, and the associated project,
     The purpose of the summary is to help the team leader, client, or boss understand what work I have done in the whole day.
     provide the summary from monday to sunday based on the date provided and skip the days if no activity recorded.
      replace summary into {language}
    Below are the activities:
    Human: {context}
    System: `,
  // template: `As a team member on a project, I need summary of my activities in less than 200 words in key bullet points for morning, afternoon and evening separately for the activities captured on my computer.
  //   The summary must include whom I've interacted with, the topics discussed, and the associated project,
  //   The purpose of the summary is to help the team leader, client, or boss understand what work I have done in the morning afternoon and evening.
  //   replace summary into {language}
  //   Below are the activities:
  //   Human: {context}
  //   System: `,
});

// Initialize the LLMChain with the llm and prompt
const chain = new LLMChain({ llm, prompt });

// Define the response function as asynchronous
export async function weeklySummarization(timelineData, language) {
  console.log(language);
  const formattedMultipleInputPrompt = await prompt.format({
    context: timelineData,
    language: language,
  });
  console.log(formattedMultipleInputPrompt);
  // console.log(timelineData)
  try {
    const result = await chain.invoke({
      context: timelineData,
      language: language,
    });
    console.log(result);
    return result;
  } catch (error) {
    console.error("Failed to generate response:", error);
    throw error; // Rethrow the error for further handling if necessary
  }
}
