export const timeZoneData = [
  {
    value: "-12:00",
    name: "(GMT-12:00) International Date Line West",
    zoneId: "Etc/GMT+12",
  },
  {
    value: "-11:00",
    name: "(UTC-11) Coordinated Universal Time-11",
    zoneId: "Pacific/Midway",
  },
  {
    value: "-10:00",
    name: "(UTC-10) Hawaii",
    zoneId: "Pacific/Honolulu",
  },
  {
    value: "-09:00",
    name: "(UTC-9) Alaska",
    zoneId: "America/Anchorage",
  },
  {
    value: "-08:00",
    name: "(UTC-8) Pacific Time (US & Canada) Baja California",
    zoneId: "America/Los_Angeles",
  },
  {
    value: "-07:00",
    name: "(UTC-7) Mountain Time (US & Canada)",
    zoneId: "America/Denver",
  },
  {
    value: "-06:0",
    name: "(UTC-6) Saskatchewan, Central America",
    zoneId: "Canada/East-Saskatchewan",
  },
  {
    value: "-06:00",
    name: "(UTC-6) Central Time",
    zoneId: "America/Chicago",
  },
  {
    value: "-05:0",
    name: "(UTC-5) Bogota, Lima, Quito, Rio Branco",
    zoneId: "America/Bogota",
  },
  {
    value: "-05:00",
    name: "(UTC-5) Eastern Time, Indiana(East)",
    zoneId: "America/New_York",
  },
  {
    value: "-04:30",
    name: "(UTC-4:30) Caracas",
    zoneId: "America/Caracas",
  },
  {
    value: "-04:0",
    name: "(UTC-4) Atlantic Time, Santiago",
    zoneId: "Canada/Atlantic",
  },
  {
    value: "-04:00",
    name: "(UTC-4) Cuiaba, Georgetown, La Paz, Manaus, San Juan",
    zoneId: "America/Manaus",
  },
  {
    value: "-03:30",
    name: "(UTC-3:30) Newfoundland",
    zoneId: "America/St_Johns",
  },
  {
    value: "-03:00",
    name: "(UTC-3) Brasilia, Buenos Aires, Greenland, Cayenne",
    zoneId: "America/Argentina/Buenos_Aires",
  },
  {
    value: "-02:00",
    name: "(UTC-2) The International Date Line West-02",
    zoneId: "Etc/GMT+2",
  },
  {
    value: "-01:00",
    name: "(UTC-1) Cape Verde Islands, Azores",
    zoneId: "Atlantic/Azores",
  },
  {
    value: "-00:00",
    name: "(UTC) Dublin, Edinburgh, Lisbon, London, The International Date Line West",
    zoneId: "Europe/London",
  },
  {
    value: "+01:00",
    name: "(UTC+1) Amsterdam, Brussels, Sarajevo",
    zoneId: "Europe/Paris",
  },
  {
    value: "+02:00",
    name: "(UTC+2) Beirut, Damascus, Cairo, Athens, Jerusalem",
    zoneId: "Asia/Jerusalem",
  },
  {
    value: "+03:00",
    name: "(UTC+3) Baghdad, Kuwait, Moscow, St Petersburg, Nairobi",
    zoneId: "Europe/Moscow",
  },
  {
    value: "+03:30",
    name: "(UTC+3:30) Tehran",
    zoneId: "Asia/Tehran",
  },
  {
    value: "+04:00",
    name: "(UTC+4) Abu Dhabi, Yerevan, Baku, Port Louis, Samarra",
    zoneId: "Asia/Dubai",
  },
  {
    value: "+04:30",
    name: "(UTC+4:30) Kabul",
    zoneId: "Asia/Kabul",
  },
  {
    value: "+05:00",
    name: "(UTC+5) Ashgabat, Islamabad, Karachi",
    zoneId: "Asia/Karachi",
  },
  {
    value: "+05:30",
    name: "(UTC+5:30) Chennai, Kolkata, Mumbai, New Delhi",
    zoneId: "Asia/Kolkata",
  },
  {
    value: "+05:45",
    name: "(UTC+5:45) Kathmandu",
    zoneId: "Asia/Kathmandu",
  },
  {
    value: "+06:00",
    name: "(UTC+6) Astana, Dhaka",
    zoneId: "Asia/Dhaka",
  },
  {
    value: "+06:30",
    name: "(UTC+6:30) Yangon",
    zoneId: "Asia/Yangon",
  },
  {
    value: "+07:00",
    name: "(UTC+7) Bangkok, Hanoi, Jakarta, Novosibirsk",
    zoneId: "Asia/Bangkok",
  },
  {
    value: "+08:00",
    name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi, Kuala Lumpur, Singapore",
    zoneId: "Asia/Shanghai",
  },
  {
    value: "+09:00",
    name: "(UTC+9) Osaka, Tokyo, Seoul, Yakutsk",
    zoneId: "Asia/Tokyo",
  },
  {
    value: "+09:3",
    name: "(UTC+9:30) Darwin",
    zoneId: "Australia/Darwin",
  },
  {
    value: "+09:30",
    name: "(UTC+9:30) Adelaide",
    zoneId: "Australia/Adelaide",
  },
  {
    value: "+10:00",
    name: "(UTC+10) Brisbane, Vladivostok, Guam",
    zoneId: "Australia/Brisbane",
  },
  {
    value: "+10:0",
    name: "(UTC+10) Canberra",
    zoneId: "Australia/Canberra",
  },
  {
    value: "+11:00",
    name: "(UTC+11) Solomon Islands, New Caledonia",
    zoneId: "Pacific/Guadalcanal",
  },
  {
    value: "+12:0",
    name: "(UTC+12) Anadyr, Fiji",
    zoneId: "Asia/Anadyr",
  },
  {
    value: "+12:00",
    name: "(UTC+12) Auckland, Wellington",
    zoneId: "Pacific/Auckland",
  },
  {
    value: "+13:00",
    name: "(UTC+13) Nuku'alofa",
    zoneId: "Pacific/Tongatapu",
  },
  {
    value: "+14:00",
    name: "(UTC+14) Christmas Island",
    zoneId: "Pacific/Kiritimati",
  },
];

export const timeZoneList = timeZoneData;
