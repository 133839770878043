import { Box, CircularProgress, alpha, createTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { timeZoneList } from "../../constants/TimeZone";
import { eventFilter, getSystemSettings } from "../../services/EventService";
import { ReactComponent as NoActivity } from "../../assets/images/NoActivity.svg";
import { ReactComponent as NoActivityDark } from "../../assets/images/NoActivity_dark.svg";
import { TimesheetContext } from "../../util/context";
import ActivityListItem from "./ActivityListItem";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";

export default function ActivityList(props) {
  const { reload, setReload } = useContext(TimesheetContext);
  const { t } = useTranslation();
  const [step, setStep] = useState(30.1);
  const [isLoading, setIsLoading] = useState(true);
  const [appColor, setAppColor] = useState(new Map());
  const [formatedEventsList, setFormatedEvents] = useState([]);
  const [day, setDay] = useState("today");
  const [selectedDayByTz, setSelectedDayByTz] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date().getHours());
  const [startMinute, setStartMinute] = useState(0);
  const appColorRef = useRef(appColor);
  const calendarRef = useRef(null);
  const stepRef = useRef(step);
  const [eventInterval, setEventInterval] = useState(null);
  const eventIntervalRef = useRef(eventInterval);
  const [eventsToLog, setEventsToLog] = useState();
  const timelineEventsRef = useRef([]);
  const { mode } = useContext(AppContext);

  const logEvent = (event) => {
    setEventsToLog(event);
  };
  useEffect(() => {
    if (eventsToLog) props.logEvent(eventsToLog);
  }, [eventsToLog]);
  useEffect(() => {
    if (props.zoomLevel !== -1) {
      setIsLoading(true);
      setStep(props.zoomLevel);
      stepRef.current = props.zoomLevel;
    }
  }, [props.zoomLevel]);

  const getTimeZoneId = () => {
    if (getSystemSettings("time_zone")) {
      let timeZone = timeZoneList.find(
        (e) => e.value === getSystemSettings("time_zone")
      );
      return timeZone ? timeZone?.zoneId : undefined;
    }
  };
  const getRandomColorVariantBasedOnAppName = (param) => {
    // Ensure that the parameter is a string and not an empty string
    const firstLetter =
      typeof param === "string" && param.length > 0
        ? param.charAt(0).toUpperCase()
        : "A";

    // Mapping letters to color indices
    const letterToColorIndex = {
      A: 0,
      B: 1,
      C: 2,
      D: 3,
      E: 4,
      F: 5,
      G: 6,
      H: 7,
      I: 8,
      J: 9,
      K: 10,
      L: 11,
      M: 12,
      N: 13,
      O: 14,
      P: 15,
      Q: 16,
      R: 17,
      S: 18,
      T: 19,
      U: 20,
      V: 21,
      W: 22,
      X: 23,
      Y: 24,
      Z: 25,
      // Add more mappings as needed
    };

    const fixedColors = [
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      "#0078D4",
      "#5059C9",
      "#9E6828",
      "#FF61F6",
      "#207245",
      "#1A73E8",
      // Add more colors as needed
    ];

    // Pick a random color from the fixedColors array based on the first letter
    const randomColorIndex = letterToColorIndex[firstLetter] || 0;
    let randomColor = "";

    if (param?.includes("teams")) {
      randomColor = "#5059C9";
    } else if (param?.toLowerCase().includes("xd")) {
      randomColor = "#FF61F6";
    } else if (param?.toLowerCase().includes("excel")) {
      randomColor = "#207245";
    } else if (param?.toLowerCase().includes("chrome")) {
      randomColor = "#1A73E8";
    } else if (param?.toLowerCase().includes("outlook")) {
      randomColor = "#5059C9";
    } else {
      randomColor = fixedColors[randomColorIndex];
    }

    // Create a Material-UI theme for the randomly picked color
    const theme = createTheme({
      palette: {
        primary: {
          main: randomColor,
          light: alpha(randomColor, 0.05),
          dark: alpha(randomColor, 0.9),
        },
      },
    });

    // Access the light and dark versions of the primary color
    const lightColor = theme.palette.primary.light;
    const darkColor = theme.palette.primary.dark;
    const contrastText = theme.palette.primary.contrastText;

    return {
      main: randomColor,
      light: lightColor,
      dark: darkColor,
      contrastText: contrastText,
    };
  };

  const roundToNearestMinutes = (minute) => {
    if (step === 1) {
      return minute;
    }
    let roundedMinutes = Math.round(minute / step) * step;
    if (minute < roundedMinutes) {
      roundedMinutes = roundedMinutes - step;
    }
    return roundedMinutes;
  };

  const handleZoom = (type) => {
    setIsLoading(true);
    switch (type) {
      case "in": {
        if (step === 30) {
          setStep(20);

          stepRef.current = 20;
        } else if (step === 20) {
          setStep(15);

          stepRef.current = 15;
        } else if (step === 15) {
          setStep(10);

          stepRef.current = 10;
        } else if (step === 10) {
          setStep(5);
          stepRef.current = 5;
        }
        break;
      }
      case "out":
        if (step === 5) {
          setStep(10);
          stepRef.current = 10;
        } else if (step === 10) {
          setStep(15);
          stepRef.current = 15;
        } else if (step === 15) {
          setStep(20);
          stepRef.current = 20;
        } else if (step === 20) {
          setStep(30);
          stepRef.current = 30;
        }
        break;
      default:
        setStep(step);
    }

    setIsLoading(false);
  };

  const getDateBySelectedDay = () => {
    // let day_ = day === "today" || day === "" ? 0 : 1;
    let date = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: CommonUtil.getTimeZoneId(),
      })
    );

    // if (day_ === 1) {
    //   date = new Date(new Date(date).setDate(new Date(date).getDate() - 1));
    // }

    return date;
  };

  const setEventsInterval = (e) => {
    if (e !== null || eventIntervalRef.current !== null) {
      return;
    }
    const eventInterval_ = setInterval(function () {
      loadActivities();
    }, 60 * 1000);
    setEventInterval(eventInterval_);
    eventIntervalRef.current = eventInterval_;
    return () => clearInterval(eventInterval_);
  };

  useEffect(() => {
    // setDateByTimezone();
    setFormatedEvents([]);
    setIsLoading(true);
    cleanupFunction();
    if (props.filter?.selectedDate) {
      loadActivities();
      if (props.filter?.selectedDate?.isSame(dayjs(), "day"))
        setEventsInterval(null);
    }
  }, [props.filter]);

  useEffect(() => {
    // setDateByTimezone();
    if (reload) {
      setFormatedEvents([]);
      setIsLoading(true);
      cleanupFunction();
      if (props.filter?.selectedDate) {
        loadActivities();
        if (props.filter?.selectedDate?.isSame(dayjs(), "day"))
          setEventsInterval(null);
      }
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    const element = document.getElementById("currentTimeBar");
    const myElement = document.getElementsByClassName("css-vnle6y");
    const topPos = element?.offsetTop;
    if (myElement && myElement.length > 0)
      myElement[0].scrollTop =
        topPos -
        (window.innerHeight > 900
          ? window.innerHeight - 250
          : window.innerHeight - 200);
  }, [formatedEventsList]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      const element = document.getElementById("currentTimeBar");
      const myElement = document.getElementsByClassName("css-vnle6y");
      const topPos = element?.offsetTop;
      if (myElement && myElement.length > 0)
        myElement[0].scrollTop =
          topPos -
          (window.innerHeight > 900
            ? window.innerHeight - 250
            : window.innerHeight - 200);
    }, 10);
  }, [step, startTime]);

  useEffect(() => {
    // Function to be called on unmount
    return () => {
      cleanupFunction();
    };
  }, []); // Empty dependency array to run effect only once on mount

  // Function to be called on unmount
  function cleanupFunction() {
    clearInterval(eventIntervalRef.current);
    eventIntervalRef.current = null;
    setEventInterval(null);
  }

  function foramtEvents(events) {
    setIsLoading(true);
    events = events?.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    events = events?.filter((event) => event.duration >= 30);
    const formatedEvents = [];

    events?.forEach((e) => {
      const eventStart = new Date(e.start);
      const eventEnd = new Date(e.end);
      let key = e.application?.name;
      let color = getRandomColorVariantBasedOnAppName(key);
      const newEvent = {
        ...e,
        start: eventStart,
        end: eventEnd,
        event_id: e.eventId,
        light: color?.light,
        dark: color?.dark,
      };
      formatedEvents.push(newEvent);
    });
    if (step === 30.1) {
      setStep(30);
      stepRef.current = 30;
    }
    setIsLoading(false);

    return formatedEvents;
  }

  const loadActivities = () => {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(
      props.filter?.selectedDate,
      CommonUtil.getTimeZoneId()
    );
    let startDate = dateTz.startOf("day");
    let endDate = dateTz.endOf("day");
    let projectTaggedStatus = null;
    if (props.filter?.logged && props.filter?.unlogged) {
      projectTaggedStatus = "ALL";
    } else if (props.filter?.logged) {
      projectTaggedStatus = "LOGGED";
    } else if (props.filter?.unlogged) {
      projectTaggedStatus = "UNLOGGED";
    }
    if (
      !props.filter?.logged &&
      !props.filter?.unlogged &&
      !props.filter?.hidden
    ) {
      projectTaggedStatus = "ALL";
    } else if (
      !props.filter?.logged &&
      !props.filter?.unlogged &&
      props.filter?.hidden
    ) {
      projectTaggedStatus = "NONE";
    }
    let payload = {
      startTime: startDate.isUTC()
        ? startDate.format()
        : startDate.utc().format(),
      endTime: endDate.isUTC() ? endDate.format() : endDate.utc().format(),
      isHidden: props.filter?.hidden,
      projectTaggedStatus: projectTaggedStatus,
      windowTitle: props.filter.title,
      applicationName: props.filter.applicationName,
    };
    eventFilter(payload).then((res) => {
      if (res.data?.data) {
        let startDateTime = new Date(
          new Date(res.data?.data?.startDateTime).toLocaleString("en-US", {
            timeZone: CommonUtil.getTimeZoneId(),
          })
        );

        setSelectedDayByTz(startDateTime);

        setStartTime(new Date(startDateTime).getHours());
        setStartMinute(
          roundToNearestMinutes(new Date(startDateTime).getMinutes())
        );
        timelineEventsRef.current = res.data?.data?.events;
        // setStartMinute(roundToNearestMinutes(Number(res.data?.data?.startMin)));
        setFormatedEvents(foramtEvents(res.data?.data?.events));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  function groupEventsByApp(events) {
    let groupedEvents = [];
    const afkEvents = events.filter((event) => event.data.status === "afk");

    events.forEach((event) => {
      const existingGroup = groupedEvents.find(
        (group) => group.app === event.app && group.app !== "afk"
      );

      if (existingGroup) {
        const start = existingGroup.start
          ? new Date(Math.min(existingGroup.start, event.start))
          : event.start;
        const end = existingGroup.end
          ? new Date(Math.max(existingGroup.end, event.end))
          : event.end;
        // Check if there is an "afk" event overlapping with the current event
        const overlappingAfkEvent = afkEvents.find(
          (afkEvent) =>
            new Date(afkEvent.start) <= new Date(end) &&
            new Date(afkEvent.end) >= new Date(start)
        );

        if (!overlappingAfkEvent) {
          if (existingGroup.duration < event.duration) {
            existingGroup.id = event.id;
            existingGroup.timestamp = event.timestamp;
            existingGroup.duration = event.duration;
            existingGroup.data = event.data;
            existingGroup.event_id = event.event_id;
            existingGroup.title = event.title;
            existingGroup.start = start;
            existingGroup.end = end;
            existingGroup.projectId = event.projectId;
          }
          existingGroup.events.push({
            ...event,
            id: event.id,
            timestamp: event.timestamp,
            duration: event.duration,
            data: event.data,
            start: event.start,
            end: event.end,
            event_id: event.event_id,
            title: event.title,
          });
        } else {
          groupedEvents.push({
            ...event,
            events: [event],
            start: event.start,
            end: event.end,
          });
        }
      } else {
        groupedEvents.push({
          ...event,
          events: [event],
          start: event.start,
          end: event.end,
        });
      }
    });

    groupedEvents = groupedEvents.filter((event) => event.duration >= 30);
    return groupedEvents;
  }

  return (
    <>
      {formatedEventsList.length === 0 ? (
        <div className="noActivity">
          {isLoading && (
            <Box
              sx={{ display: "flex" }}
              width={"100%"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && (
            <>
              {mode === "dark" ? <NoActivityDark /> : <NoActivity />}
              <p style={{ color: "var(--text-tertiary) !important" }}>
                {t("RAL0038")}
              </p>
            </>
          )}
        </div>
      ) : (
        <div id="list" className="listView">
          {formatedEventsList.map((event) => (
            <ActivityListItem
              event={event}
              stepRef={stepRef}
              logEvent={(event) => logEvent(event)}
            />
          ))}
        </div>
      )}
    </>
  );
}
