import React, { useRef, useEffect, useState } from "react";
import { Box, SwipeableDrawer, Avatar, Button, Typography, Grid,} from "@mui/material";
import profile from "../../assets/images/EmptyProfile.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import ErrorIcon from "@mui/icons-material/Error";
import { uploadImage } from "../../services/EventService";
import { useTranslation } from "react-i18next";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import "./UserProfile.scss";

export default function SwipeableTemporaryDrawer({
  open,
  onClose,
  userData,
  onAvatarChange,
}) {
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const [base64, setBase64] = useState(
    userData?.ProfileImage || profile.replace(/\\/g, "/")
  );
  const [fileError, setFileError] = useState(null);
  const [userDataUpdate, setUserDataUpdate] = useState("");

  useEffect(() => {
    const decoded = CommonUtil.decodeToken();
    OnboardService.getUserDetails(decoded.userId).then((data) => {
      setUserDataUpdate(data.data.data);
    });
  }, []);

  const handleFileError = (message) => {
    setFileError(message);
  };

  const handleFile = async (event) => {
    handleFileError(null); // Resetting file error at the beginning

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (!/\/(jpeg|jpg|png)$/i.test(file.type)) {
      handleFileError(t("RAL0059"));
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      handleFileError(t("RAL0060"));
      event.target.value = "";
      return;
    }

    try {
      const base64String = await readFileAsBase64(file);
      await uploadImage("profilePic", base64String);
      setBase64(base64String);
      onAvatarChange(base64String);
      handleFileError(null);
    } catch (error) {
      console.error("Error during file upload", error);
      handleFileError(t("RAL0061"));
    }
    event.target.value = "";
  };

  const handleDrawerClose = () => {
    handleFileError(null);
    if (onClose) {
      onClose();
    }
  };

  const removeImage = async () => {
    if (base64 !== profile.replace(/\\/g, "/")) {
      try {
        await uploadImage("profilePic", "");
        setBase64(profile.replace(/\\/g, "/"));
        onAvatarChange("");
        handleFileError(null);
      } catch (error) {
        console.error("Error during file removal", error);
        handleFileError(t("RAL0062"));
      }
    } else {
      handleFileError(t("RAL0063"));
    }
  };

  useEffect(() => {
    if (userData?.ProfileImage) {
      setBase64(userData.ProfileImage.replace(/\\/g, "/"));
    } else {
      setBase64(profile.replace(/\\/g, "/"));
    }
    // Reset file error on component mount or userData change

    handleFileError(null);
  }, [userData, userData?.ProfileImage]);

  useEffect(() => {
    // Log fileError for debugging
    console.log("File Error: ", fileError);
  }, [fileError]);

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const buttonStyle = {
    background: "#FEFEFE",
    border: "1px solid #E8E6F1",
    borderRadius: { xl: "10px", xs: "10px" },
    opacity: 1,
    cursor: "pointer",
    width: { xl: "50px", xs: "38px" },
    height: { xl: "50px", xs: "38px" },
    outline: "none",
    display: "flex", // Ensure the button content is centered
    justifyContent: "center",
    alignItems: "center",
  };

  const labelStyle = {
    fontSize: { xl: "14px", xs: "12px" },
    color: "#474B4F",
    textAlign: "left",
    font: "normal normal normal 16px/25px Poppins",
    letterSpacing: "0px",
    opacity: 0.5,
  };

  const valueStyle = {
    fontSize: { xl: "16px", xs: "12px" },
    textAlign: "left",
    font: "normal normal normal 16px/25px Poppins",
    letterSpacing: "0px",
    color: "#474B4F",
    opacity: 1,
  };

  const containerStyle = {
    paddingTop: { xl: "15px", xs: "7.5px" },
  };

  const valueStyleForImageDescription = {
    mt: { xl: 2, xs: 0.5 },
    mb: { xl: 0.5, xs: 0.5 },
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    color: "#474B4F",
    opacity: 0.5,
    letterSpacing: "0px",
    fontSize: { xl: "14px", xs: "12px" },
  };

  const valueStyleForImageText = {
    mt: { xl: 1, xs: 0.5 },
    textAlign: "left",
    font: "normal normal normal 14px/21px Poppins",
    color: "#474B4F",
    opacity: 0.5,
    letterSpacing: "0px",
    fontSize: { xl: "14px", xs: "12px" },
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <Box
        sx={{
          width: { xl: 576, xs: 350 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
        role="presentation"
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 4,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontSize: { xl: "24px", xs: "20px" },
              letterSpacing: "0px",
              color: "#474B4F",
              fontFamily: "Poppins, 'Helvetica', 'Arial', sans-serif",
              opacity: 1,
              height: { xl: "33px", xs: "15px" },
              fontWeight: 600,
            }}
          >
            {t("RAL0056")}
          </Typography>
          <Box
            sx={buttonStyle}
            onClick={handleDrawerClose}
            id="btn_user_profile_close"
          >
            <CloseIcon />
          </Box>
        </Box>

        {/* Avatar Section */}
        <Avatar
          alt="Profile Avatar"
          sx={{
            width: { xl: 160, xs: 100 },
            height: { xl: 160, xs: 100 },
            mb: { xl: 1, xs: 1.5 },
          }}
          src={base64}
        />

        {/* Image Rules */}
        <Typography sx={valueStyleForImageDescription}>
          {t("RAL0057")}
        </Typography>
        <Typography sx={valueStyleForImageText}>{t("RAL0058")}</Typography>

        {/* Upload and Remove Buttons */}
        <Box sx={{ mt: { xl: 3, xs: 2 } }}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFile}
            style={{ display: "none" }}
            accept="image/png, image/jpeg, image/jpg"
          />
          <Button
            variant="contained"
            sx={{
              width: { xl: "98px", xs: "85px" },
              marginRight: "20px",
              background: "linear-gradient(110deg, #1D0B77 0%, #6A5FA2 100%)",
              borderRadius: "5px",
              fontFamily: "normal normal normal 14px/21px Poppins",
              textAlign: "left",
              letterSpacing: "0px",
              color: "#FFFFFF",
              opacity: 1,
              fontSize: { xl: "14px", xs: "12px" },
            }}
            onClick={() => fileInputRef.current.click()}
            id="btn_upload_profile"
          >
            {t("RAL0064")}
          </Button>

          <Button
            sx={{
              width: { xl: "98px", xs: "85px" },
              borderRadius: "5px",
              fontSize: { xl: "14px", xs: "12px" },
              color:
                base64 === profile.replace(/\\/g, "/") ? "#474B4F" : "#FE5050",
              background:
                base64 === profile.replace(/\\/g, "/") ? "#FFFFFF" : "#FFEDED",
            }}
            onClick={removeImage}
            id="btn_remove_profile"
            disabled={base64 === profile.replace(/\\/g, "/")}
          >
            {t("RAL0065")}
          </Button>
        </Box>

        {/* File Error Message */}
        {fileError && (
          <Box
            sx={{ display: "flex", alignItems: "center", mt: 3, color: "red" }}
          >
            <ErrorIcon />
            <Typography
              variant="body2"
              sx={{
                ml: 1,
                color: "#FE5050",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              {fileError}
            </Typography>
          </Box>
        )}

        {/* User Information Section */}
        <Box
          component="section"
          sx={{
            marginTop: { xl: "40px", xs: "30px" },
            width: { xl: "516px", xs: "300px" },
            height: { xl: "197px", xs: "135px" },
            background: "#FEFEFE",
            border: "1px solid #E8E6F1",
            borderRadius: "10px",
            padding: { xl: "20px", xs: "10px" },
            opacity: 1,
          }}
        >
          <Grid container sx={{ margin: { xl: "30px", xs: "10px" } }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  sx={labelStyle}
                  id="label_key_user_profile_first_name"
                >
                  {t("RAL0012")}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={valueStyle}
                  id="label_value_user_profile_first_name"
                >
                  {userDataUpdate?.firstName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={containerStyle}>
              <Grid item xs={4}>
                <Typography
                  sx={labelStyle}
                  id="label_key_user_profile_last_name"
                >
                  {t("RAL0013")}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={valueStyle}
                  id="label_value_user_profile_last_name"
                >
                  {userDataUpdate?.lastName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={containerStyle}>
              <Grid item xs={4}>
                <Typography sx={labelStyle} id="label_key_user_profile_email">
                  {t("RAL0014")}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={valueStyle}
                  className="text-lowercase"
                  id="label_value_user_profile_email"
                >
                  {userDataUpdate?.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={containerStyle}>
              <Grid item xs={4}>
                <Typography sx={labelStyle} id="label_key_user_profile_mobile">
                  {t("RAL0066")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={valueStyle}
                  id="label_value_user_profile_mobile"
                >
                  {userDataUpdate?.phone}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
