import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ReactComponent as CheckedIcon } from "../assets/images/ralvie/Tick.svg";
import React from "react";

export default function CustomCheckBox(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          // Icon={<CheckBoxOutlineBlankIcon sx={{borderRadius:"50px !important"}} />}
          {...props}
          // checkedIcon={<CheckedIcon />}
          sx={{
            "&.Mui-checked": {
              color: "#FA9C2B",
            },
            "&.Mui-disabled": {
              color: "gray",
            },
          }}
        />
      }
      id={props.id}
      name={props.name}
      label={<Typography variant={`text_16_25_0_1`}>{props.label}</Typography>}
      labelPlacement="end"
    />
  );
}
