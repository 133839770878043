import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  styled,
  InputBase,
  Popper,
  IconButton,
  Fade,
  Paper,
  Tooltip,
} from "@mui/material";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { ReactComponent as EmptyMemberList } from "../../assets/images/ralvie/empty-member-list.svg";
import { ReactComponent as EmptyMemberListDark } from "../../assets/images/ralvie/empty-member-list-dark.svg";
import { ReactComponent as ResendMail } from "../../assets/images/ralvie/resend_mail.svg";
import { ReactComponent as ResendMailDark } from "../../assets/images/ralvie/resend_mail_dark.svg";
import { ReactComponent as AddIcon } from "../../assets/images/ralvie/add-icon.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/ralvie/more-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as RefreshIconDark } from "../../assets/images/refresh-icon-dark.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit_icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-icon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/images/ralvie/archive.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/ralvie/archiveDark.svg";
import CustomIconButton from "../../components/CustomIconButton";
import { useNavigate } from "react-router-dom";
import "./member.scss";
import "./memberIndex.scss";
import profileLight from "../../assets/images/EmptyProfile.svg";
import profileDark from "../../assets/images/EmptyProfile_dark.svg";
import {
  downloadUserImportTemplate,
  importMembers,
  resendActivationMail,
  userList,
  softDeleteMember,
  batchMembersUnarchive,
} from "../../services/MemberService";
import { sidebarContext } from "../../util/context";
import CustomSearchField from "../../components/CustomSearchField";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import CommonUtil from "../../util/CommonUtils";
import MemberFilter from "./MemberFilter";
import { toast } from "sonner";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import ListIcon from "@mui/icons-material/List";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ImportDialog from "../../components/ImportDialog";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";
import AppContext from "../../config/AppContext";
import { ReactComponent as TableViewIcon } from "../../assets/images/table-view.svg";
import UnarchiveDialog from "../project/UnarchiveDialog";
import ArchiveDialog from "../project/ArchiveDialog";

export default function Members() {
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const role = CommonUtil.getRoleCode();
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const { expand, setExpand } = useContext(sidebarContext);
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [clearSearch, setClearSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [departmentFilter, setDepartmentFilter] = useState([
    { id: "All departments", name: "All departments" },
  ]);
  const [designationFilter, setDesignationFilter] = useState([
    { id: "All positions", name: "All positions" },
  ]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const open = Boolean(anchorEl);
  const [openClose, setopenClose] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const [filter, setFilter] = useState({
    status: "",
    name: "",
    email: "",
    toggleKeyToURL: true,
    departmentIds: [],
    designationIds: [],
  });

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const resetFilter = () => {
    setFilter({
      status: "",
      name: "",
      email: "",
      toggleKeyToURL: true,
      departmentIds: ["All departments"],
      designationIds: ["All positions"],
    });
    setDepartmentFilter([{ id: "All departments", name: "All departments" }]);
    setDesignationFilter([{ id: "All positions", name: "All positions" }]);
    setRefresh(true);
    setClearSearch(true);
  };

  useEffect(() => {
    loadMembers();
  }, [filter]);

  useEffect(() => {
    const departmentIds = departmentFilter.map((dep) => dep.id);
    const designationIds = designationFilter.map((des) => des.id);

    loadMembers({
      status: filter.status,
      name: filter.name,
      email: filter.email,
      toggleKeyToURL: filter.toggleKeyToURL,
      departmentIds,
      designationIds,
    });
  }, []);

  const handleApplyFilters = (filters) => {
    const { departments, designations, status, refresherStatus } = filters;
    const { status: filterStatus, name, email, toggleKeyToURL } = status;
    const departmentIds = departments;
    const designationIds = designations;

    console.log("departmentIds", departmentIds);
    console.log("designationIds", designationIds);
    console.log("refresherStatus", refresherStatus);
    setRefresh(refresherStatus);

    setFilter((prevFilter) => ({
      ...prevFilter,
      status: filterStatus,
      name,
      email,
      toggleKeyToURL,
      departmentIds,
      designationIds,
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setopenClose(false);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: 4,
      position: "relative",
      // border: "1px solid #ced4da",
      fontSize: 16,
      padding: isXlScreen ? "12px 26px 12px 12px" : "9px 26px 7px 12px",
      backgroundColor: "transparent !important",
      opacity: 1,
      font: "normal normal normal 16px/20px Gotham",
      color: "#242424",
    },
  }));

  const EmptyMember = () => (
    <Box
      display={isLoading ? "none" : "flex"}
      height={"100%"}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box item md="12">
        {mode == "light" ? <EmptyMemberList /> : <EmptyMemberListDark />}
      </Box>
      <Box item md="12">
        <Typography className="empty-list-title">
          {filter.name ? t("RAL0385") : "The members list is empty."}
        </Typography>
      </Box>
    </Box>
  );

  // const handleImport = (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   setIsLoading(true);
  //   importMembers(formData).then((res) => {
  //     setIsLoading(false);
  //     if (res.data?.code === "RCI0000") toast.success(t("RAL0374"));
  //     else toast.error(res.data.message);
  //     resetFilter();
  //   });
  // };

  const actions = (
    <Box display={"flex"} className="memberSearchBox">
      <Box item pr={2.5}>
        <CustomSearchField
          className="searchIconHover"
          id="btn-member-search"
          name="btn-member-search"
          clearSearch={clearSearch}
          // onChange={() => setClearSearch(false)}
          onChange={(value) => setFilter({ ...filter, name: value })}
          label={t("RAL0223")}
          value={filter.name}
          size={isXlScreen ? "large" : "md"}
          onClick={(value) => {
            setClearSearch(false);
            setFilter({ ...filter, name: value });
          }}
          onClear={(value) => {
            setClearSearch(true);
            setFilter({ ...filter, name: value });
          }}
        />
      </Box>
      <Box item pr={2}>
        <Box
          sx={{
            "& .filter_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .filter_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="btn-custom-member"
            name="btn-custom-member"
            title={t("RAL0224")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              <FilterIcon
                className="filter_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <FilterIcon
                className="filter_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={handleFilterClick}
          />
        </Box>
        <MemberFilter
          title="Filter Title"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          filter={filter}
          MemberReset={resetFilter}
          // handleChange={ handleChange }
          BootstrapInput={BootstrapInput}
          onApplyFilters={handleApplyFilters}
          refresh={refresh}
        />
      </Box>
      {/* <Box item pr={2}>
        <ImportDialog
          title={t("RAL0360")}
          downloadTemplate={() => downloadUserImportTemplate()}
          onImport={(file) => handleImport(file)}
        />
      </Box> */}

      <Box item pr={2}>
        <Box
          sx={{
            "& .refresh_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .refresh_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="btn-custom-member-refersh"
            name="btn-custom-member-refresh"
            title={t("RAL0169")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              <RefreshIcon
                className="refresh_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <RefreshIcon
                className="refresh_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={() => resetFilter()}
          />
        </Box>
      </Box>

      <Box item pr={2}>
        <Box
          sx={{
            "& .table_view_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .table_view_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            variant="square"
            title={t("RAL0225")}
            size={isXlScreen ? "large" : "md"}
            lightMode={
              <TableViewIcon
                className="table_view_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <TableViewIcon
                className="table_view_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={() => navigate("/members-list")}
          />
        </Box>
      </Box>

      {(role === "OWNER" || role === "ADMIN") && (
        <CustomIconButton
          id="btn-custom-add-member"
          name="btn-custom-add-member"
          title={t("RAL0226")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          type="contained"
          border={"none"}
          lightMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
          darkMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
          onClick={() => navigate("/members/new")}
        />
      )}
    </Box>
  );

  const handleEdit = (member) => {
    if (role !== "OWNER" && member?.roleName === "Owner") {
      return;
    }
    navigate("/members/" + member?.id);
  };

  const handleResendActivationMail = (member) => {
    resendActivationMail(member?.id).then((res) => {
      if (res.data?.code === "RCI0032") {
        toast.success(t("RAL0264"));
      } else {
        toast.error(res.data?.message);
      }
    });
  };

  const closePopup = (popupState) => {
    popupState.close();
  };

  const handleArchiveOpen = (id) => {
    setSelectedMemberId(id);
    setOpenArchiveDialog(true);
  };

  const handleUnarchiveClick = (id) => {
    setSelectedMemberId(id);
    setOpenUnarchiveDialog(true);
  };

  const handleCloseUnarchiveDialog = () => {
    setOpenUnarchiveDialog(false);
  };

  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleArchiveProject = async () => {
    try {
      const response = await softDeleteMember(selectedMemberId);
      if (response?.data?.code === "RCI0000") {
        toast.success(t("RAL0265"));
        loadMembers();
        handleCloseArchiveDialog();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.error(
        "Error archiving user:",
        error.response?.data || error.message
      );
    }
  };

  const handleConfirmUnrchive = async () => {
    try {
      let memberIds = [selectedMemberId];
      if (memberIds.length >= 1) {
        const payload = { ids: memberIds };
        const res = await batchMembersUnarchive(payload);
        if (res.data?.code === "RCI0000") {
          loadMembers();
          toast.success("Members unarchived successfully");
          handleCloseUnarchiveDialog();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error("No members selected.");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while archiving projects.");
    }
  };

  const userCard = (member) => {
    return (
      <div className={expand ? "user-card-expand" : "user-card"}>
        <div className="user-card-header">
          <div style={{ flexGrow: 1 }}>
            {/* <span
              className={
                member?.status === "ACTIVE"
                  ? "active-member"
                  : "inactive-member"
              }
            >
              {member?.status === "ACTIVE" ? "Active" : "Inactive"}
            </span> */}

            <span
              className={
                member?.status === "ACTIVE"
                  ? "active-member"
                  : member?.status === "INACTIVE"
                  ? "inactive-member"
                  : "archived-member"
              }
            >
              {member?.status === "ACTIVE"
                ? "Active"
                : member?.status === "INACTIVE"
                ? "Inactive"
                : "Archived"}
            </span>
          </div>
          {(role === "OWNER" || role === "ADMIN") && (
            <PopupState variant="popper" popupId="demo-popup-popper">
              {(popupState) => (
                <div onBlur={() => closePopup(popupState)}>
                  <IconButton
                    {...bindToggle(popupState)}
                    sx={{
                      background: popupState.isOpen
                        ? "var(--background-color-40)"
                        : "",
                      borderRadius: "5px",
                      "& .more_svg": {
                        transition: "color 0.3s ease",
                        color:
                          mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                      },
                      "&:hover": {
                        background: "var(--background-color-40)",
                        "& .hover-text, & .more_svg": {
                          color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                        },
                      },
                    }}
                  >
                    <MoreIcon
                      height={"14px"}
                      width={"14px"}
                      className="more_svg"
                    />
                  </IconButton>
                  <Popper
                    {...bindPopper(popupState)}
                    transition
                    placement="bottom-start"
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ padding: "0.5rem 0" }}>
                          {member?.status !== "ARCHIVED" && (
                            <Box
                              display={"flex"}
                              px={2}
                              py={1}
                              alignItems={"center"}
                              onClick={() => {
                                closePopup(popupState);
                                handleEdit(member);
                              }}
                              sx={{
                                "&:hover": {
                                  backgroundColor:
                                    mode === "light" ? "#f5f5f5" : "#3f3f3f",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <Box item pr={2}>
                                {mode === "dark" ? (
                                  <EditIconDark className="icon" />
                                ) : (
                                  <EditIcon className="icon" />
                                )}
                              </Box>
                              <Box
                                item
                                className="menu-item"
                                sx={{
                                  color:
                                    mode === "dark"
                                      ? "var(--color-33-80)"
                                      : "var(--text-secondary-80-80)",
                                }}
                              >
                                {t("RAL0172")}
                              </Box>
                            </Box>
                          )}

                          {member?.status !== "ARCHIVED" &&
                            member.roleName !== "Owner" &&
                            // member?.status !== "ACTIVE" &&
                            member?.status === "INACTIVE" && (
                              <Box
                                display={"flex"}
                                px={2}
                                py={1}
                                alignItems={"center"}
                                onClick={() => {
                                  closePopup(popupState);
                                  handleResendActivationMail(member);
                                }}
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      mode === "light" ? "#f5f5f5" : "#3f3f3f",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                <Box item pr={2}>
                                  {mode == "light" ? (
                                    <ResendMail className="icon" />
                                  ) : (
                                    <ResendMailDark className="icon" />
                                  )}
                                </Box>
                                <Box
                                  item
                                  className="menu-item"
                                  sx={{
                                    color:
                                      mode === "dark"
                                        ? "var(--color-33-80)"
                                        : "var(--text-secondary-80-80)",
                                  }}
                                >
                                  {t("RAL0229")}
                                </Box>
                              </Box>
                            )}

                          {member?.status !== "ARCHIVED" &&
                            member.roleName !== "Owner" && (
                              <Box
                                display={"flex"}
                                px={2}
                                py={1}
                                alignItems={"center"}
                                onClick={() => handleArchiveOpen(member?.id)}
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      mode === "light" ? "#f5f5f5" : "#3f3f3f",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                <Box item pr={2}>
                                  {mode == "dark" ? (
                                    <ArchiveIconDark className="icon" />
                                  ) : (
                                    <ArchiveIcon className="icon" />
                                  )}
                                </Box>
                                <Box
                                  item
                                  className="menu-item"
                                  sx={{
                                    color:
                                      mode === "dark"
                                        ? "var(--color-33-80)"
                                        : "var(--text-secondary-80-80)",
                                  }}
                                >
                                  {t("RAL0173")}
                                </Box>
                              </Box>
                            )}

                          {member?.status == "ARCHIVED" &&
                            member.roleName !== "Owner" && (
                              <Box
                                display={"flex"}
                                px={2}
                                py={1}
                                alignItems={"center"}
                                onClick={() => handleUnarchiveClick(member?.id)}
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      mode === "light" ? "#f5f5f5" : "#3f3f3f",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                <Box item pr={2}>
                                  {mode == "dark" ? (
                                    <ArchiveIconDark className="icon" />
                                  ) : (
                                    <ArchiveIcon className="icon" />
                                  )}
                                </Box>
                                <Box
                                  item
                                  className="menu-item"
                                  sx={{
                                    color:
                                      mode === "dark"
                                        ? "var(--color-33-80)"
                                        : "var(--text-secondary-80-80)",
                                  }}
                                >
                                  {t("Unarchive")}
                                </Box>
                              </Box>
                            )}
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </div>
              )}
            </PopupState>
          )}
        </div>
        <Box className="user-card-body">
          <span className="member-card-role">
            {member?.weeklyWorkCapacity} hr/wk
          </span>
          <img
            alt={member?.memberName}
            class="image-rounded"
            src={
              member?.profilePhoto
                ? member.profilePhoto
                : (mode === "dark" ? profileDark : profileLight).replace(
                    /\\/g,
                    "/"
                  )
            }
            height="200"
            width="200"
          />
          <span className="member-card-cost">{member?.roleName}</span>
        </Box>
        <div className="user-card-details">
          <Tooltip
            title={member?.memberName?.length < 25 ? "" : member?.memberName}
          >
            <Typography className="member-card-name">
              {member?.memberName}
            </Typography>
          </Tooltip>
          <Typography className="member-card-email text-lowercase">
            <Tooltip title={member?.email?.length < 25 ? "" : member?.email}>
              {member?.email}
            </Tooltip>
          </Typography>
        </div>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          m={1.5}
        >
          <Box
            display={"flex"}
            className="member-card-billing"
            flexDirection={"column"}
            p={1}
            width="48%"
          >
            <Typography className="member-card-billing-label">
              {t("RAL0245")}
            </Typography>
            <Typography className="member-card-billing-text-value">
              <Tooltip
                title={
                  member?.departmentName?.length < 13
                    ? ""
                    : CommonUtil.capitalizeFirst(member?.departmentName)
                }
              >
                {member?.departmentName ? member?.departmentName : "-"}
              </Tooltip>
            </Typography>
          </Box>
          <Box
            display={"flex"}
            className="member-card-billing"
            flexDirection={"column"}
            p={1}
            width="48%"
          >
            <Typography className="member-card-billing-label">
              {t("RAL0244")}
            </Typography>
            <Typography className="member-card-billing-text-value">
              <Tooltip
                title={
                  member?.designationName?.length < 13
                    ? ""
                    : member?.designationName
                }
              >
                {member?.designationName ? member?.designationName : "-"}
              </Tooltip>
            </Typography>
          </Box>
        </Box>
      </div>
    );
  };

  const memberCard = (member) => {
    return (
      <Grid
        item
        md="2.9"
        sm="3.8"
        xs="12"
        className="member-card"
        mr={1}
        mb={1}
      >
        <Box
          display={"flex"}
          height={"100%"}
          width={"100%"}
          flexDirection={"column"}
        >
          <Box display={"flex"} p={2} pb={0}>
            <Box item flexGrow={1}>
              <span
                px={2.5}
                py={1}
                className={
                  member?.status === "ACTIVE"
                    ? "active-member"
                    : "inactive-member"
                }
              >
                {member?.status === "ACTIVE" ? "Active" : "Inactive"}
              </span>
            </Box>
            <Box item pt={0.5}>
              <CustomIconButton
                borderColor="white"
                variant="square"
                size="large"
                lightMode={<MoreIcon />}
                darkMode={<MoreIcon />}
                onClick={() => navigate("/members/new")}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyItems={"center"}
          >
            <Box
              display={"flex"}
              width={"40%"}
              className="member-card-cost"
              justifyContent={"flex-start"}
            >
              <span>{member?.costPerHour} $/hr</span>
            </Box>
            <Box item width={"30%"} flexGrow={"1"}>
              <img
                alt={member?.memberName}
                class="image-rounded"
                src={
                  member?.profilePhoto
                    ? member.profilePhoto
                    : (mode === "dark" ? profileDark : profileLight).replace(
                        /\\/g,
                        "/"
                      )
                }
                height="200"
              />{" "}
              <br />
            </Box>

            <Box
              display={"flex"}
              width={"35%"}
              className="member-card-role"
              justifyContent={"flex-end"}
            >
              <span>{member?.weeklyWorkCapacity} hr/wk</span>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            py={1}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              width={"auto"}
            >
              <Box item pb={0.5}>
                <Typography className="member-card-name">
                  {member?.memberName}
                </Typography>
              </Box>
              <Box item>
                <Typography className="member-card-email text-lowercase">
                  {member?.email}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display={"flex"}
            p={2}
            justifyItems={"flex-start"}
            alignItems={"center"}
            py={2}
          >
            <Box
              display={"flex"}
              className="member-card-billing"
              flexDirection={"column"}
              p={1}
              width={"45%"}
              mr={2}
            >
              <Typography className="member-card-billing-label">
                Role
              </Typography>
              <Typography className="member-card-billing-text-value">
                {member?.roleName}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              className="member-card-billing"
              flexDirection={"column"}
              p={1}
              width={"45%"}
            >
              <Typography className="member-card-billing-label">
                Billing cost
              </Typography>
              <Typography className="member-card-billing-text-value">
                {member?.costPerHour} $/hr
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  const loadMembers = () => {
    setIsLoading(true);
    const departmentIds = filter?.departmentIds || [];
    const designationIds = filter?.designationIds || [];
    const status = filter?.status || "";
    const name = filter?.name || "";
    const email = filter?.email || "";
    const toggleKeyToURL = filter?.toggleKeyToURL || "";

    const validDepartmentIds = departmentIds.includes("All departments")
      ? []
      : departmentIds.filter((id) => id !== "All departments");
    const validDesignationIds = designationIds.includes("All positions")
      ? []
      : designationIds.filter((id) => id !== "All positions");

    const requestData = {
      status,
      name,
      email,
      toggleKeyToURL,
      department: validDepartmentIds,
      designation: validDesignationIds,
    };

    userList(requestData)
      .then((res) => {
        if (res?.status === 200 && res?.data?.code === "UASI0000") {
          let users = res.data.data.users;

          const { department, designation, status } = requestData;
          users = users.filter((user) => {
            const departmentMatch =
              !department.length || department.includes(user.departmentId);
            const designationMatch =
              !designation.length || designation.includes(user.designationId);
            const statusMatch = status === "" || user.status === status;

            return departmentMatch && designationMatch && statusMatch;
          });

          setMembers(users);
          setFilteredMembers(users);
        } else {
          setMembers([]);
          setFilteredMembers([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching members:", error);
        setMembers([]);
        setFilteredMembers([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return role !== "EMPLOYEE" ? (
    <ContentLayout
      title={t("RAL0222")}
      actions={actions}
      isLoading={isLoading}
      bg={"transparent"}
    >
      {filteredMembers.length > 0 ? (
        <Grid container spacing={3} alignContent={"flex-start"}>
          {filteredMembers.map((m, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
              {userCard(m)}
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyMember />
      )}

      <ArchiveDialog
        open={openArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onConfirm={handleArchiveProject}
        name="member"
        type="Member"
      />

      <UnarchiveDialog
        open={openUnarchiveDialog}
        onClose={handleCloseUnarchiveDialog}
        onConfirm={handleConfirmUnrchive}
        name="member"
        type="Member"
      />
    </ContentLayout>
  ) : (
    <></>
  );
}
