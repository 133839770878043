import { css } from "@emotion/css";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/images/ralvie/arrowdown-white.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/ralvie/arrowup-white.svg";
import { ReactComponent as SundialIcon } from "../../assets/images/ralvie/sundial-logo.svg";
import { ReactComponent as EditIcon } from "../../assets/images/NoActivity.svg";
import { ReactComponent as ProjectIcon } from "../../assets/images/ralvie/project-icon-active.svg";
import { ReactComponent as TagIcon } from "../../assets/images/ralvie/tag-outlined-icon.svg";
import AppIcon from "../../components/AppIcon";
import CommonUtil from "../../util/CommonUtils";
import DescriptionIcon from "@mui/icons-material/Description";
import { ReactComponent as BiotimeCloudIcon } from "../../assets/images/ralvie/biocloud_logo.svg";

export default function WeekTimesheetItem(props) {
  const [timesheets, setTimesheets] = useState([]);
  useEffect(() => {
    console.log("props.timesheets", props.timesheets);
    setTimesheets(props.timesheets);
  }, [props.timesheets]);
  const [toggleTimesheet, setToggleTimesheet] = useState(null);
  const handleSubEventsToogle = (id) => {
    if (toggleTimesheet === id) {
      setToggleTimesheet(null);
    } else {
      setToggleTimesheet(id);
    }
  };
  const biotimeEvent = (data) => {
    return (
      <Box
        className={css`
          // background: #eae8f2 0% 0% no-repeat padding-box;
          border-radius: 2px;
          opacity: 1;
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          column-gap: 8px;
          display: flex;
          align-items: center;
          width: 100%;
          padding: 10px 10px;
          margin-bottom: 8px;
        `}
      >
        <Box
          className={css`
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 2px;
            opacity: 0.8;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <BiotimeCloudIcon className="icon" />
        </Box>
        <Box>
          <Typography
            className={css`
              font: normal normal normal 14px/16px GothamMedium !important;
              letter-spacing: 0px;
              color: #242424 !important;
              opacity: 1;
            `}
          >
            {data?.eventName}
          </Typography>
        </Box>
        <Box>
          <Typography
            className={css`
              font: normal normal normal 12px/14px Gotham !important;
              letter-spacing: 0px;
              color: #242424 !important;
            `}
          >
            {data?.eventTime}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <div
      className={css`
        max-height: 100%;
        overflow: hidden;
        margin-bottom: 10px;
        :hover {
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: #a2a4a680 transparent;
          &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
          }
        }
      `}
    >
      {timesheets?.length < 1 &&
        props?.transactions?.map((t) => {
          let data = {
            eventName: t.punch_state === "2" ? "Break out" : "Break in",
            eventTime: t.punch_time.split(" ")[1],
          };
          return biotimeEvent(data);
        })}
      {timesheets?.map((t, index) => {
        let duration = CommonUtil.getDurationWithRoundoff(t?.loggedDuration);
        return (
          <>
            <div
              style={{
                marginBottom: "8px",
              }}
            >
              <Box
                className={css`
                  width: 100%;
                  background: ${t?.project?.color};
                  border-radius: 2px;
                  opacity: 1;
                  padding: 8px;
                `}
              >
                <Box item display={"flex"}>
                  <Typography
                    variant="worklog_project_title"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {t?.title ? t?.title : t?.events[0]?.windowTitle}
                  </Typography>
                </Box>
                {t?.project?.clientName && (
                  <Box
                    item
                    className={css`
                      white-space: nowrap;
                      word-break: break-all;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    `}
                    py={2}
                  >
                    {t?.project?.clientName && (
                      <Typography variant="worklog_project_details">
                        {t?.project?.clientName}
                      </Typography>
                    )}
                  </Box>
                )}

                <Box
                  item
                  display={"flex"}
                  py={1}
                  // pt={Number(t?.loggedDuration) / 30 + "px"}
                >
                  <ProjectIcon style={{ paddingRight: "4px" }} />
                  <Typography
                    className={css`
                      font: normal normal normal 14px/17px Gotham !important;
                      letter-spacing: 0px;
                      color: white !important;
                      opacity: 1;
                      word-break: break-all;
                    `}
                  >
                    {t?.project?.name}
                  </Typography>
                </Box>
                {(t?.totalBilledCost || t?.totalBilledCost == 0) && (
                  <Box item display={"flex"} py={1}>
                    {/* <DescriptionIcon
                    style={{ color: "white" }}
                    fontSize="small"
                  /> */}
                    <Typography
                      className={css`
                        font: normal normal normal 12px/15px GothamMedium !important;
                        letter-spacing: 0px;
                        color: white !important;
                        opacity: 1;
                        white-space: wrap;
                      `}
                    >
                      {" $"}
                      {Number(t?.totalBilledCost).toFixed(2)}
                    </Typography>
                  </Box>
                )}
                <Box
                  item
                  display={"flex"}
                  alignItems={"center"}
                  justifyItems={"center"}
                >
                  <Box item flexGrow={1}>
                    {t.events?.some((event) => event.source === "SUNDIAL") && (
                      <SundialIcon
                        height="14px"
                        style={{ paddingRight: "4px" }}
                      />
                    )}
                    <Typography variant="worklog_project_details">
                      {duration?.hr + "h " + duration?.min + "m"}
                    </Typography>
                  </Box>
                  {t.tags?.length > 0 && (
                    <Box item>
                      <Tooltip
                        title={t.tags?.map(
                          (tag, index) =>
                            tag.name +
                            (index === Number(t.tags.length - 1) ? "" : ", ")
                        )}
                      >
                        <TagIcon className="icon" />
                      </Tooltip>
                    </Box>
                  )}
                  <Box item pl={1}>
                    {toggleTimesheet === t?.id ? (
                      <ArrowUp
                        className="icon"
                        onClick={() => handleSubEventsToogle(t?.id)}
                      />
                    ) : (
                      <ArrowDown
                        className="icon"
                        onClick={() => handleSubEventsToogle(t?.id)}
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              {toggleTimesheet === t?.id && t?.events && (
                <Box
                  item
                  className={css`
                    background: var(--background-color-14) 0% 0% no-repeat
                      padding-box;
                    border-radius: 2px;
                    opacity: 1;
                    maxheight: 200px;
                    overflow: hidden;
                    :hover {
                      overflow-y: auto;
                    }
                  `}
                >
                  {t?.events?.map((e) => {
                    let eventTime = CommonUtil.getDuration(e?.duration);
                    return (
                      <Box
                        m={0.5}
                        className={css`
                          background: var(--background-color-3) 0% 0% no-repeat
                            padding-box;
                          border-radius: 2px;
                          opacity: 1;
                          backdrop-filter: blur(5px);
                          -webkit-backdrop-filter: blur(5px);
                        `}
                      >
                        <Box display={"flex"} p={1}>
                          <Box item pr={1} pt={0.5}>
                            <AppIcon
                              data={e?.data}
                              height={18}
                              width={18}
                              type="event"
                              id={e?.eventNumber}
                            />
                          </Box>
                          <Box
                            item
                            flexDirection={"column"}
                            className={css`
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            `}
                          >
                            <Tooltip title={e.windowTitle}>
                              <span
                                className={css`
                                  font: normal normal normal 16px/19px
                                    GothamMedium;
                                  letter-spacing: 0px;
                                  color: var(--text-color-1) !important;
                                  opacity: 1;
                                  word-break: break-all;
                                `}
                              >
                                {" "}
                                {e.windowTitle}
                              </span>
                            </Tooltip>
                            <Box item py={0.5}>
                              <Typography
                                className={css`
                                  font: normal normal normal 12px/14px Gotham;
                                  letter-spacing: 0px;
                                  color: var(--text-color-7) !important;
                                  opacity: 1;
                                `}
                              >
                                {" "}
                                {eventTime.hr +
                                  "h " +
                                  eventTime.min +
                                  "m " +
                                  eventTime.sec +
                                  "s"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </div>
            {index === 0 &&
              props?.transactions?.map((t) => {
                let data = {
                  eventName: t.punch_state === "2" ? "Break out" : "Break in",
                  eventTime: t.punch_time.split(" ")[1],
                };
                return biotimeEvent(data);
              })}
          </>
        );
      })}
    </div>
  );
}
