import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Activity from "./Activity";
import MostUsedApps from "./MostUsedApps";
import { t } from "i18next";
import SideNavigationBar from "./Sidebar";

export default function Home() {
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      <Grid container spacing={2} px={3} mt={1}>
        <Grid item lg={9} sm={12} style={{ marginBottom: "10px" }}>
          <Activity
            setApps={(data) => setApps(data)}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Grid>
        {/* <Grid item lg={3} sm={12}>
          <Box pb={5} pt={1}>
            <Typography variant="text_20_30_0_1">
              {t("RAL0049")}
            </Typography>
          </Box>
          <MostUsedApps apps={apps} isLoading={isLoading} />
        </Grid> */}
      </Grid>
    </div>
  );
}
