import { css } from '@emotion/css';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as CalenderViewLight } from '../../assets/images/calendar_view_light.svg';
import { ReactComponent as DisabledCalenderViewLight } from '../../assets/images/disabled_calendar_icon_light.svg';
import { ReactComponent as ListViewLight } from '../../assets/images/table_view_icon_light.svg';
import { ReactComponent as DisabledListViewLight } from '../../assets/images/disabled_list_view_light.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/ralvie/filter-icon-light.svg';
import { ReactComponent as FilterIconHover } from '../../assets/images/ralvie/filter-icon-hover-light.svg';
import { ReactComponent as FilterIconDark } from '../../assets/images/ralvie/filter-icon.svg';
import { ReactComponent as FilterIconSelectDark } from '../../assets/images/ralvie/filter-icon-select-dark.svg';
import { ReactComponent as ZoomInIconDark } from '../../assets/images/ralvie/zoom-in-icon-dark.svg';
import { ReactComponent as ZoomOutIconDark } from '../../assets/images/ralvie/zoom-out-icon-dark.svg';

import { useTranslation } from 'react-i18next';
import { bindPopover } from 'material-ui-popup-state';
import {
  bindHover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import AppContext from '../../config/AppContext';
import TimesheetDatePicker from './TimesheetDatePicker';
import CustomSearchField, {
  CustomSearchFieldWithStartIcon,
} from '../../components/CustomSearchField';
import TimesheetDatePickerWOB from './TimesheetDatePickerWOB';

export default function TimesheetToolBar(props) {
  const { t } = useTranslation();
  const [clearSearch, setClearSearch] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredView, setHoveredView] = useState(null);
  const { mode } = useContext(AppContext);
  useEffect(() => {
    if (props.filter?.title) {
      setClearSearch(true);
    } else {
      setClearSearch(false);
    }
  }, [props.filter?.title]);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });
  return (
    <>
      <Box
        className={css`
          background: var(--background-color-11) 0% 0% no-repeat padding-box;
          border-radius: 0px;
          opacity: 1;
          width: 100%;
          height: ${props.isXlScreen ? '70px' : '60px'};
          display: flex;
          align-items: center;
          margin-bottom: 1px;
          padding: 20px;
          border-bottom: 1px solid var(--background-color-9);
        `}>
        <Box
          item
          sx={{ width: '6rem' }}>
          <Typography
            variant='header4'
            sx={{
              color: 'var(--text-secondary-80)',
              font: '16px/19px GothamMedium !important',
            }}>
            {props.filter?.selectedDate?.format('dddd')}
          </Typography>
        </Box>
        {/* datepicker */}
        <Box className='timesheet-datepicker-border-none'>
          <TimesheetDatePickerWOB
            id='btn-day-clndr'
            onChange={(date) => {
              props.handleDateChange(date);
            }}
            selectedDate={props.filter.selectedDate}
          />
        </Box>
        <Box flexGrow={1}></Box>
        {/* search text */}
        <Box
          item
          className='SearchFieldInput'>
          <CustomSearchFieldWithStartIcon
            id='btn-search-tag'
            name='btn-search-tag'
            clearSearch={clearSearch}
            // onChange={() => setClearSearch(false)}
            label={t('RAL0180')}
            value={props.filter?.applicationName}
            onChange={(value) => {
              props.handleFilter({ target: { name: 'applicationName', value: value } });
            }}
            size={props.isXlScreen ? 'large' : 'md'}
            onClick={(value) => {
              props.handleFilter({ target: { name: 'applicationName', value: value } });
            }}
            maxLength={50}
          />
        </Box>
        {/* <Box
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          `}
        >
          {clearSearch && (
            <TagTextField
              id="btn-srch-tag"
              label={t("RAL0333")}
              name="title"
              value={props.filter?.title}
              onChange={props.handleFilter}
              inputProps={{
                maxLength: 50,
              }}
            />
          )}
          {clearSearch && (
            <Box
              onClick={() => {
                props.filter?.title
                  ? props.handleFilter({ target: { name: "title", value: "" } })
                  : setClearSearch(false);
              }}
              id="btn-cls-srch-tag"
              className={css`
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                :hover {
                  background: var(--background-color-10) 0% 0% no-repeat
                    padding-box;
                  border-radius: 5px;
                  opacity: 1;
                }
              `}
            >
              <CloseIcon className="icon" id="btn-cls-srch-tag" />
            </Box>
          )}
          {!clearSearch && (
            <Box
              id="btn-srch-tag"
              onClick={() => setClearSearch(true)}
              className={css`
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                :hover {
                  background: var(--background-color-10) 0% 0% no-repeat
                    padding-box;
                  border-radius: 5px;
                  opacity: 1;
                }
              `}
            >
              <SearchIcon className="icon" id="btn-srch-tag" />
            </Box>
          )}
        </Box> */}
        {/* filter */}
        <Box
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          id='btn-fltr-tmsht'
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.125rem;
            height: ${props.isXlScreen ? '3.125rem' : '2.5rem'};
            width: auto;
            cursor: pointer;
            padding: 16px 18px 16px 20px;
            background-color: var(--background-color-6);
            margin-right: 10px;
            :hover {
              background: var(--background-color-10) 0% 0% no-repeat padding-box;
              border-radius: 5px;
              opacity: 1;
            }
          `}
          {...bindTrigger(popupState)}>
          {mode == 'dark' ? (
            isHovered ? (
              <FilterIconSelectDark
                className='icon'
                id='btn-fltr-tmsht'
              />
            ) : (
              <FilterIconDark
                className='icon'
                id='btn-fltr-tmsht'
              />
            )
          ) : isHovered ? (
            <FilterIconHover
              className='icon'
              id='btn-fltr-tmsht'
            />
          ) : (
            <FilterIcon
              className='icon'
              id='btn-fltr-tmsht'
            />
          )}{' '}
          {props.firstInnerWidth > 70 ? (
            <Typography pl={1}>Filter</Typography>
          ) : null}
        </Box>
        {/* time tags */}
        <Box
          className={css`
            border: 1px solid var(--calender--border-color);
            background-color: var(--background-color-6);
            border-radius: 2px;
            opacity: 1;
            display: flex;
            align-items: center;
            margin-right: 10px;
            padding: 5px;
            width: ${props.isXlScreen ? '90px' : '70px'};
          `}>
          <Box
            id='btn-clndrviw-timesheet'
            className={css`
              min-height: ${props.isXlScreen ? '40px' : '30px'};
              min-width: ${props.isXlScreen ? '40px' : '30px'};
              background-color: ${props.timesheetView === 'calendar'
                ? 'var(--background-color-26)'
                : 'var(--background-color-27)'};
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: '1px';
            `}
            sx={{
              '& .calender-active-svg': {
                transition: 'color 0.3s ease',
                color: mode === 'dark' ? '#a49dc8' : '#1d0b77',
              },
              '& .calender-disabled-svg': {
                transition: 'color 0.3s ease',
                color: mode === 'dark' ? 'var(--color-33-50)' : '#242424cc',
              },
              '&:hover': {
                backgroundColor: 'var(--background-color-40) !important',
                borderRadius: '1px',
                '& .calender-disabled-svg': {
                  color: mode === 'dark' ? '#f8f8f8' : '#242424',
                },
              },
            }}
            onMouseEnter={() => setHoveredView('calendar')}
            onMouseLeave={() => setHoveredView(null)}
            onClick={() => {props.setTimesheetView('calendar');setHoveredView(null);}}>
            <Tooltip
              title={t('RAL0045')}
              open={hoveredView === 'calendar'}>
              {props.timesheetView === 'calendar' ? (
                <CalenderViewLight
                  className='icon calender-active-svg'
                  id='btn-clndrviw-timesheet'
                />
              ) : (
                <DisabledCalenderViewLight
                  className='icon calender-disabled-svg'
                  id='btn-clndr-disblviw-timesheet'
                />
              )}
            </Tooltip>
          </Box>
          <Box
            id='btn-clndr-viw-tmst'
            className={css`
              min-height: ${props.isXlScreen ? '40px' : '30px'};
              min-width: ${props.isXlScreen ? '40px' : '30px'};
              background-color: ${props.timesheetView !== 'calendar'
                ? 'var(--background-color-26)'
                : 'var(--background-color-27)'};
              display: flex;
              align-items: center;
              justify-content: center;
              borderradius: '1px';
            `}
            sx={{
              '& .list-active-svg': {
                transition: 'color 0.3s ease',
                color: mode === 'dark' ? '#a49dc8' : '#1d0b77',
              },
              '& .list-disabled-svg': {
                transition: 'color 0.3s ease',
                color: mode === 'dark' ? ' var(--color-33-50)' : '#242424cc',
              },
              '&:hover': {
                backgroundColor: 'var(--background-color-40) !important',
                borderRadius: '1px',
                '& .list-disabled-svg': {
                  color: mode === 'dark' ? '#f8f8f8' : '#242424',
                },
              },
            }}
            onMouseEnter={() => setHoveredView('list')}
            onMouseLeave={() => setHoveredView(null)}
            onClick={() => {props.setTimesheetView('list');setHoveredView(null); }}>
            <Tooltip
              title={t('RAL0046')}
              open={hoveredView === 'list'}>
              {props.timesheetView !== 'calendar' ? (
                <ListViewLight
                  className='icon list-active-svg'
                  id='btn-clndr-lstviw-tmst'
                />
              ) : (
                <DisabledListViewLight
                  className='icon list-disabled-svg'
                  id='btn-clndr-dislstviw-tmst'
                />
              )}
            </Tooltip>
          </Box>
        </Box>
        {props.timesheetView !== 'list' ? (
          <Box
            className={css`
              border: 1px solid var(--calender--border-color);
              background-color: var(--background-color-6);
              opacity: 1;
              display: flex;
              align-items: center;
              width: ${props.isXlScreen ? '90px' : '70px'};
              padding: 5px;
            `}>
            {/* zoom-out */}
            <Box
              id='btn-zm-tmsht'
              className={css`
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: ${props.isXlScreen ? '40px' : '30px'};
                width: 40px;
                cursor: ${props.zoomLevel === 30 || props.zoomLevel === -1
                  ? 'not-allowed'
                  : 'pointer'};
                :hover {
                  background: ${props.zoomLevel === 30 || props.zoomLevel === -1
                    ? 'none'
                    : 'var(--background-color-10)'};
                  border-radius: 5px;
                  opacity: 1;
                }
              `}
              sx={{
                '& .zoom-out-svg': {
                  transition: 'color 0.3s ease',
                  color: mode === 'dark' ? 'var(--color-33-50)' : '#242424cc',
                },
                '&:hover': {
                  '& .zoom-out-svg': {
                    color: mode === 'dark' ? '#f8f8f8cc' : '#242424',
                  },
                },
              }}>
              <ZoomOutIconDark
                className='icon zoom-out-svg'
                id='btn-zmout-tmsht'
                onClick={() => props.handleZoom('out')}
                style={{
                  cursor:
                    props.zoomLevel === 30 || props.zoomLevel === -1
                      ? 'not-allowed'
                      : 'pointer',
                }}
              />
            </Box>
            {/* zoom-in */}
            <Box
              id='btn-zmin-tmsht'
              className={css`
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: ${props.isXlScreen ? '40px' : '30px'};
                width: 40px;
                cursor: ${props.zoomLevel === 5 ? 'not-allowed' : 'pointer'};
                :hover {
                  background: ${props.zoomLevel === 5
                    ? 'none'
                    : 'var(--background-color-10)'};
                  border-radius: 5px;
                  opacity: 1;
                }
              `}
              sx={{
                '& .zoom-in-svg': {
                  transition: 'color 0.3s ease',
                  color: mode === 'dark' ? 'var(--color-33-50)' : '#242424cc',
                },
                '&:hover': {
                  '& .zoom-in-svg': {
                    color: mode === 'dark' ? '#f8f8f8cc' : '#242424',
                  },
                },
              }}>
              <ZoomInIconDark
                id='btn-zmin-tmsht'
                className='icon zoom-in-svg'
                onClick={() => props.handleZoom('in')}
                style={{
                  cursor: props.zoomLevel === 5 ? 'not-allowed' : 'pointer',
                }}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Popover
        sx={{
          borderRadius: '10px',
        }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Box
          py={1}
          className={css`
            background: ${mode == 'dark'
                ? 'var(--color-10)'
                : 'var(--popover-background)'}
              0% 0% no-repeat;
            box-shadow: 0px 3px 6px #00000026;
            opacity: 1;
          `}>
          <FormGroup>
            <FormControlLabel
              sx={{
                height: '3.125rem',
                width: '12.5rem',
                margin: '0px',
                padding: '0px 20px',
                background:
                  mode == 'dark'
                    ? props.filter?.unlogged
                      ? '#362C20'
                      : 'transparent'
                    : props.filter?.unlogged
                    ? '#F3F2F8'
                    : 'transparent',
                '&:hover': {
                  backgroundColor: mode == 'dark' ? '#362C20' : '#F3F2F8',
                },
              }}
              name='type'
              control={
                <Checkbox
                  id='btn-chkbx-ungd-tmsht'
                  name='unlogged'
                  onChange={props.handleFilter}
                  checked={props.filter?.unlogged}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      border:
                        mode === 'dark'
                          ? '1px solid #4D4D4D'
                          : '1px solid #B2B2B2',
                      borderRadius: '4px',
                      width: '20px',
                      height: '20px',
                      boxSizing: 'border-box',
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                      border: 'none',
                      width: '22px',
                      height: '22px',
                    },
                    '&.Mui-checked': {
                      color: '#FA9C2B',
                    },
                    '& .MuiSvgIcon-root path': {
                      fill: 'transparent',
                    },
                    '&.Mui-checked .MuiSvgIcon-root path': {
                      fill: '#FA9C2B',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#FA9C2B !important',
                      color: '#FA9C2B',
                    },
                    '& .MuiSvgIcon-root::before': {
                      display: 'none',
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant='activity_view'
                  id='btn-chkbx-ungd-tmsht'
                  sx={{
                    color:
                      mode == 'dark'
                        ? 'var(--color-33-80)'
                        : 'var(--text-secondary-80-80)',
                  }}>
                  {t('RAL0334')}
                </Typography>
              }
            />
            <FormControlLabel
              sx={{
                height: '3.125rem',
                width: '12.5rem',
                margin: '0px',
                padding: '0px 20px',
                background:
                  mode == 'dark'
                    ? props.filter?.logged
                      ? '#362C20'
                      : 'transparent'
                    : props.filter?.logged
                    ? '#F3F2F8'
                    : 'transparent',
                '&:hover': {
                  backgroundColor: mode == 'dark' ? '#362C20' : '#F3F2F8',
                },
              }}
              control={
                <Checkbox
                  id='btn-chkbx-lgd-tmsht'
                  name='logged'
                  onChange={props.handleFilter}
                  checked={props.filter?.logged}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      border:
                        mode === 'dark'
                          ? '1px solid #4D4D4D'
                          : '1px solid #B2B2B2',
                      borderRadius: '4px',
                      width: '20px',
                      height: '20px',
                      boxSizing: 'border-box',
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                      border: 'none',
                      width: '22px',
                      height: '22px',
                    },
                    '&.Mui-checked': {
                      color: '#FA9C2B',
                    },
                    '& .MuiSvgIcon-root path': {
                      fill: 'transparent',
                    },
                    '&.Mui-checked .MuiSvgIcon-root path': {
                      fill: '#FA9C2B',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#FA9C2B !important',
                      color: '#FA9C2B',
                    },
                    '& .MuiSvgIcon-root::before': {
                      display: 'none',
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant='activity_view'
                  id='btn-chkbx-lgd-tmsht'
                  sx={{
                    color:
                      mode == 'dark'
                        ? 'var(--color-33-80)'
                        : 'var(--text-secondary-80-80)',
                  }}>
                  {t('RAL0335')}
                </Typography>
              }
            />
            <FormControlLabel
              sx={{
                height: '3.125rem',
                // width: "12.5rem",
                margin: '0px',
                padding: '0px 20px',
                background:
                  mode == 'dark'
                    ? props.filter?.hidden
                      ? '#362C20'
                      : 'transparent'
                    : props.filter?.hidden
                    ? '#F3F2F8'
                    : 'transparent',
                '&:hover': {
                  backgroundColor: mode == 'dark' ? '#362C20' : '#F3F2F8',
                },
              }}
              control={
                <Checkbox
                  id='btn-chkbx-hdn-tmsht'
                  name='hidden'
                  onChange={props.handleFilter}
                  checked={props.filter?.hidden}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      border:
                        mode === 'dark'
                          ? '1px solid #4D4D4D'
                          : '1px solid #B2B2B2',
                      borderRadius: '4px',
                      width: '20px',
                      height: '20px',
                      boxSizing: 'border-box',
                    },
                    '&.Mui-checked .MuiSvgIcon-root': {
                      border: 'none',
                      width: '22px',
                      height: '22px',
                    },
                    '&.Mui-checked': {
                      color: '#FA9C2B',
                    },
                    '& .MuiSvgIcon-root path': {
                      fill: 'transparent',
                    },
                    '&.Mui-checked .MuiSvgIcon-root path': {
                      fill: '#FA9C2B',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#FA9C2B !important',
                      color: '#FA9C2B',
                    },
                    '& .MuiSvgIcon-root::before': {
                      display: 'none',
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant='activity_view'
                  id='btn-chkbx-hdn-tmsht'
                  sx={{
                    color:
                      mode == 'dark'
                        ? 'var(--color-33-80)'
                        : 'var(--text-secondary-80-80)',
                  }}>
                  {t('RAL0336')}
                </Typography>
              }
            />
          </FormGroup>
        </Box>
      </Popover>
    </>
  );
}
