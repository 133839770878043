import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import CustomButton from "../../components/CustomButton";
import CustomSelectButtons from "../../components/CustomSelectButtons";
import OnboardTextField from "../../components/OnboardTextField";
import OnboardService from "../../services/OnboardService";
import "./Signup.scss";
import SignupLayout from "./SignupLayout";
import "./CustomerCompany.scss";

export default function CustomerCompany() {
  const { t, ready } = useTranslation(); 
  let navigate = useNavigate();
  const location = useLocation();
  const industryFromState = location.state ? location.state.industry : null;
  const emailFromState = location.state ? location.state.email : null;

  const companyNameRef = useRef(null);

  useEffect(() => {
    if (ready && companyNameRef.current) {
      companyNameRef.current.focus();
    }
  }, [ready]);

  const [payload, setPayload] = useState({
    name: "",
    companySize: "",
    industry: industryFromState || "",
  });

  const [error, setError] = useState({
    name: "",
    companySize: "",
  });

  const handleSubmit = (e) => {
    if (validate()) {
      OnboardService.createCustomerCompany(payload).then((res) => {
        if (res.data.code === "UASI0011") {
          localStorage.setItem(
            "token",
            "Bearer " + res.data?.data?.access_token
          );
          toast.success(t("RAL0142"));
          navigate("/pages/invite-members");
        } else {
          toast.error(res.data?.message);
        }
      });
    }
  };

  const validate = () => {
    if (!payload.name) {
      setError({
        ...error,
        name: t("RAL0001"),
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const selected = [
    { id: 1, companySize: "2-50", text: "2-50" },
    { id: 2, companySize: "51-100", text: "51-100" },
    { id: 3, companySize: "101-500", text: "101-500" },
    { id: 4, companySize: "501+", text: "501+" },
  ];

  const handleSelectClick = (selectedCompanySize) => {
    setPayload({
      ...payload,
      companySize: selectedCompanySize,
    });
  };

  const buttonWidths = ["width101", "width114", "width126", "width96"];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <SignupLayout>
      <div className="CustomerCompany" onKeyDown={handleKeyDown}>
        <div className="w-100Email">
          <div>
            <span className="customer_company_002">{t("RAL0121")}</span>
          </div>

          <Grid item sm={12} pt={5}>
            <OnboardTextField
              autoComplete={false}
              required
              fullWidth
              label={t("RAL0122")}
              value={payload.name}
              name="name"
              onChange={handleChange}
              error={error.name}
              helperText={error.name}
              inputRef={companyNameRef}
              inputProps={{
                maxLength: 60,
              }}
              validation="alpha-numeric-space"
            />
          </Grid>

          <div className="customer_company_003" style={{ paddingTop: "30px" }}>
            <span>{t("RAL0123")}</span>
          </div>

          <Grid className="customerCompanyBoxes" item sm={12} pt={2.5}>
            <CustomSelectButtons
              selected={selected}
              onClick={handleSelectClick}
              buttonWidths={buttonWidths}
            />
          </Grid>

          <Grid
            item
            sm={12}
            className="customer_company_submitbutton_006 full-width"
          >
            <CustomButton
              id="btn_create_user_company"
              variant="contained"
              size="large"
              fullWidth
              onClick={handleSubmit}
            >
              <span className="customer_company_submitbutton_007">
                {t("RAL0124")}
              </span>
            </CustomButton>
          </Grid>

          <div className="customer_company_005">
            <Link
              id={"link-company-view-back"}
              className="customer_company_004"
              to="/pages/company-industry"
            >
              {t("RAL0125")}
            </Link>
            <a className="customer_company_004" href="https://www.youtube.com/@ralvieAI" target="_blank">
              {t("RAL0343")}
            </a>
          </div>
        </div>
      </div>
    </SignupLayout>
  );
}
