import React, { useEffect } from "react";
import { jiraUserIntegration } from "../../services/IntegrationService";
import { toast } from "sonner";

export default function JiraIntegration() {
  useEffect(() => {
    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get("code");
    jiraUserIntegration(code).then((res) => {
      toast.success("Jira integrated successfully");
      setTimeout(() => {
        window.location.href = "/integration";
      }, 1000);
    });
  }, []);
  return <div></div>;
}
