import { Avatar, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import profile from "../../assets/images/EmptyProfile.svg";
import { getUser } from "../../services/MemberService";
import CommonUtil from "../../util/CommonUtils";
import { getProjectReportData } from "../../services/EventService";
import "./timesheet.scss";

export default function MemberDetails({ startDate, endDate, userId }) {
  const decoded = CommonUtil.decodeToken();
  const [profilePhoto, setProfilePhoto] = useState(profile.replace(/\\/g, "/"));
  const [member, setMember] = useState([]);
  const [projects, setProjects] = useState([]);

  const formattedStartTime = CommonUtil.formatDateToUTC(startDate);
  const formattedEndTime = CommonUtil.formatDateToUTCDate(endDate);

  const defaultState = {
    firstName: "",
    lastName: "",
    email: "",
    profilePhoto: "",
  };

  const [payload, setPayload] = useState(defaultState);
  const userID = decoded.userId;

  useEffect(() => {
    const currentUserId = userId === "All members" ? userID : userId;
    loadUser(currentUserId);
  }, [userId, userID]);

  useEffect(() => {
    setPayload({
      firstName: member?.memberName || "",
      lastName: member?.lastName || "",
      email: member?.email || "",
      profilePhoto: member?.profilePhoto || "",
      timeZone: member?.timeZone || "",
    });
    setProfilePhoto(member?.profilePhoto || profile.replace(/\\/g, "/"));
  }, [member]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("USER"));
    const payload = {
      startDate: formattedStartTime,
      endDate: formattedEndTime,
      companyId: userData.companyId,
      userId: userId === "All members" ? "" : userId,
    };

    getProjectReportData(payload)
      .then((res) => {
        if (res.data.code === "RCI0000") {
          const projectData = res.data.data;
          const formattedProjects = Object.keys(projectData).map(
            (projectName) => ({
              projectName: projectName,
              totalHours: projectData[projectName].hours,
              totalMinutes: projectData[projectName].minutes,
            })
          );
          setProjects(formattedProjects);
        } else {
          console.error("Failed to fetch project data.");
        }
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, [userId, startDate, endDate]);

  const loadUser = (id) => {
    getUser(id).then((res) => {
      if (res?.status === 200 && res?.data?.code === "UASI0033") {
        setMember(res?.data?.data);
      }
    });
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <Avatar
          alt="Profile Avatar"
          sx={{
            width: { xl: 160, xs: 100 },
            height: { xl: 160, xs: 100 },
            mb: { xl: 1, xs: 1.5 },
          }}
          src={profilePhoto}
        />
        <span className="projectReportText">
          {payload.lastName !== null
            ? payload.firstName + " " + payload.lastName
            : payload.firstName}
        </span>
      </Grid>
      <Grid item>
        <span className="projectReportTextData text-lowercase" sx={{ pt: 2 }}>
          {payload.email}
        </span>
      </Grid>
      <Grid sx={{ paddingTop: "50px" }}>
        <span className="projectReportHeader">Project & Contribution</span>
      </Grid>
      <Grid item container direction="column" alignItems="center" spacing={1}>
        {projects.length > 0 ? (
          projects.map((project, index) => (
            <Grid item key={index} className="projectContainer">
              <span className="projectName">{project.projectName}</span>
              <span className="projectTime">
                {project.totalHours > 0 && `${project.totalHours} Hrs`}{" "}
                {project.totalMinutes > 0 && `${project.totalMinutes} Mins`}
              </span>
            </Grid>
          ))
        ) : (
          <span className="projectReportText">No project details</span>
        )}
      </Grid>
    </Grid>
  );
}
