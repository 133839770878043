import api from "../config/http-common";

// Helper function to get authorization header
const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

// Get All Categories
export const getAllapplications = async () => {
  try {
    const response = await api
      .securedAxiosApi()
      .get("/applications/uncategory", { headers: getAuthHeader() });
    console.log("app data", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error; // Propagate the error to the caller
  }
};
