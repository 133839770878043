import { css } from "@emotion/css";
import DoneIcon from "@mui/icons-material/Done";
import { Autocomplete, Box, Chip, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/ralvie/Close.svg";
import { ReactComponent as TagInactiveIcon } from "../../assets/images/ralvie/tag-icon-inactive.svg";
import CustomButton from "../../components/CustomButton";
import CustomSwitch from "../../components/CustomSwitch";
import CustomTextField from "../../components/CustomTextField";
import { addManualEvents } from "../../services/EventService";
import { findProjectsByAssignedMember } from "../../services/ProjectService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import { TimePicker } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTimesheet } from "../../services/WorklogService";
import { getTagByName } from "../../services/TagService";
import "./AddEvents.scss";
import dayjs from "dayjs";
import CancelDialog from "../project/CancelDialog";

export default function AddEvents({ onClose, selectedDate }) {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [totalEventsDuration, setTotalEventsDuration] = useState(null);
  const [totalEventsDurationInSec, setTotalEventsDurationInSec] = useState(0);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const { setEventsToLog, setReload } = useContext(TimesheetContext);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tagError, setTagError] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [showEndTimeError, setShowEndTimeError] = useState(false);
  const [showStartTimeError, setShowStartTimeError] = useState(false);
  const [showTimeError, setShowTimeError] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const [state, setState] = useState({
    source: "MANUAL",
    userId: "",
    windowTitle: "",
    projectId: "",
    tags: [],
    description: "",
    startTime: "",
    endTime: "",
    loggedDuration: 0,
    eventDuration: 0,
    events: [],
    hrs: 0,
    mins: 0,
    billable: true,
  });

  const [error, setError] = useState({
    windowTitle: "",
    projectId: "",
    tags: "",
    description: "",
    duration: "",
    loggedDuration: "",
    eventDuration: "",
    events: "",
    startTime: "",
    endTime: "",
  });

  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = () => {
    setTagData([]);
    const query = {
      pageNumber: 1,
      pageSize: 1000,
      toggleKeyToURL: true,
    };
    getTagByName(query).then((res) => {
      if (res?.data?.code === "RCI0000") {
        setTagData(res.data.data.tags);
      }
    });
  };

  const isValidForm = () => {
    if (!state.windowTitle) {
      setIsSubmit(false);
      return false;
    }

    if (!state.projectId) {
      setIsSubmit(false);
      return false;
    }

    if (!state.startTime) {
      setIsSubmit(false);
      return false;
    }
    if (!state.endTime) {
      setIsSubmit(false);
      return false;
    }
    setIsSubmit(true);
  };

  useEffect(() => {
    isValidForm();
  }, [state]);

  const durationInMilliseconds = state.endTime - state.startTime;
  const durationInSeconds = durationInMilliseconds / 1000;

  const userId = CommonUtil.getLoggedUser()?.id;
  const companyId = CommonUtil.getLoggedUser()?.companyId;

  useEffect(() => {
    console.log(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    findProjectsByAssignedMember(CommonUtil.getLoggedUser()?.id)
      .then((res) => {
        if (res.data && Array.isArray(res.data.data)) {
          const filteredProjects = res.data.data.filter(project => project && project.status !== "ARCHIVED");
          setProjects(filteredProjects);
        } else {
          setProjects([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
}, []);

  useEffect(() => {
    if (totalEventsDurationInSec > 0) {
      setTotalEventsDuration(CommonUtil.getDuration(totalEventsDurationInSec));
    } else {
      setTotalEventsDuration(null);
    }
  }, [totalEventsDurationInSec]);

  useEffect(() => {
    if (state.projectId) {
      let project = projects?.filter((p) => p?.id === state.projectId);
      if (project.length > 0) {
        setSelectedProject(project[0]);
      }
    }
  }, [state.projectId, projects]);

  useEffect(() => {
    if (state.tags) {
      setSelectedTags(state.tags);
    }
  }, [state.tags]);

  useEffect(() => {
    if (totalEventsDuration) {
      setState((prevState) => ({
        ...prevState,
        hrs: totalEventsDuration?.hr,
        mins: totalEventsDuration?.min,
      }));
    }
  }, [totalEventsDuration]);

  const tagSelected = (event, value) => {
    setSelectedTags(value);
    setError((prevError) => ({ ...prevError, tags: "" }));
  };

  const validate = () => {

    const startTime = new Date(state.startTime);
    const endTime = new Date(state.endTime);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const isToday = selectedDate.isSame(today, 'day');
    const isStartTimeInThePast = startTime > now;
    const isEndTimeInvalid = endTime <= startTime;

    let hasError = false; 

    setError({
      windowTitle: "",
      projectId: "",
      tags: "",
      description: "",
      duration: "",
      loggedDuration: "",
      eventDuration: "",
      events: "",
      startTime: "",
      endTime: "",
    });

    if (!state.windowTitle) {
      setError((prevError) => ({
        ...prevError,
        windowTitle: t("RAL0001"),
      }));
      hasError = true;
    }
    
    if (!state.projectId) {
      setError((prevError) => ({
        ...prevError,
        projectId: t("RAL0001"),
      }));
      hasError = true;
    }

    if (selectedTags.length > 20) {
      setError((prevError) => ({
        ...prevError,
        tags: t("Tag limit reached"),
      }));
      hasError = true;
    }

    if (!state.startTime) {
      setError((prevError) => ({
        ...prevError,
        startTime: t("RAL0001"),
      }));
      hasError = true;
    }

    if (!state.endTime) {
      setError((prevError) => ({
        ...prevError,
        endTime: t("RAL0001"),
      }));
      hasError = true;
    }

    if (isToday) {
      if (isStartTimeInThePast) {
        setError((prevError) => ({
          ...prevError,
          startTime: t("Start time cannot be in the future."),
        }));
        setShowStartTimeError(true);
        hasError = true;
      } else {
        setShowStartTimeError(false);
      }
  
      if (endTime >= now) {
        setError((prevError) => ({
          ...prevError,
          endTime: t("End time cannot be in the future."),
        }));
        setShowTimeError(true);
        hasError = true;
      } else {
        setShowTimeError(false);
      }
    }
  
    if (isEndTimeInvalid) {
      setError((prevError) => ({
        ...prevError,
        endTime: t("End time cannot be earlier than the start time."),
      }));
      setShowEndTimeError(true);
      hasError = true;
    } else {
      setShowEndTimeError(false);
    }
  
    return !hasError;
  };

  useEffect(() => {
    if (showStartTimeError || showEndTimeError || showTimeError) {
      const timer = setTimeout(() => {
        setShowStartTimeError(false);
        setShowEndTimeError(false);
        setShowTimeError(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showStartTimeError, showEndTimeError, showTimeError]);

  let eventTotalDuration = 0;

  const handleSubmit = async () => {
    if (validate()) {
      let payloadData = {
        ...state,
        tags: selectedTags.length > 0 ? selectedTags : [],
        userId: userId,
        companyId: companyId,
        duration: durationInSeconds,
      };

      try {
        const manualEventsResponse = await addManualEvents(payloadData);

        if (manualEventsResponse.status === 200) {
          setSelectedEvents(manualEventsResponse.data?.data);
        } else {
          toast.error(manualEventsResponse.data?.message);
          return;
        }
      } catch (error) {
        toast.error("An error occurred while processing your request.");
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedEvents.length > 0) {
      const eventIds = selectedEvents.map((event) => event.id);
      let payload = {
        ...state,
        title: state.windowTitle,
        tags: selectedTags,
        loggedDuration: durationInSeconds,
        eventDuration: eventTotalDuration,
        events: eventIds,
        timesheetDate: selectedDate?.utc().format(),
      };

      // Call createTimesheet API
      createTimesheet(payload)
        .then((timesheetResponse) => {
          if (timesheetResponse.status === 200) {
            toast.success(timesheetResponse.data?.message);
            setEventsToLog([]);
            setReload(true);
            onClose();
          } else {
            toast.error(timesheetResponse.data?.message);
          }
        })
        .catch((error) => {
          toast.error("An error occurred while processing your request.");
          console.error("Error:", error);
        });
    }
  }, [selectedEvents]);

  const handleFromTimeChange = (time) => {
    setState((prevState) => ({
      ...prevState,
      startTime: time,
    }));
    setIsDataChanged(true);
  };

  const handleToTimeChange = (time) => {
    setState((prevState) => ({
      ...prevState,
      endTime: time,
    }));
    setIsDataChanged(true);
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (textAreaRef.current) {
      const { scrollHeight } = textAreaRef.current;
      const height = Math.max(40, Math.min(scrollHeight, 200)); // Set a min and max height
      textAreaRef.current.style.height = `${height}px`; // Set the height dynamically
    }
  }, [state.description]);

  const textAreaRef = useRef(null);

  const handleDescriptionChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
    setError((prevError) => ({
      ...prevError,
      description: "",
    }));
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      const { scrollHeight } = textAreaRef.current;
      const height = Math.max(40, Math.min(scrollHeight, 200));
      textAreaRef.current.style.height = `${height}px`;
    }
  }, [state.description]);

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    onClose();
  };

  return (
    <div
      className={css`
        min-height: calc(100% - 20px);
        position: relative;
        background: var(--background-color-14);
        // padding: 0px 20px 20px 20px;
        padding: 0px 14px 0px 14px;
        margin-top: 18px;
        height: 78vh;
        @media (min-width:1600px){
          // height: 70vh;
        }
      `}
    >
      <Box
        item
        className={`${css`
          background: var(--background-color-14);
          padding-bottom: 20px;
          padding-top: 20px;
          align-items: center;
          display: flex;
          position: sticky;
          top: 0;
          z-index: 9;
        `} `}
      >
        <Box flexGrow={1}>
          <Typography
            variant="header3"
            sx={{
              color: "var( --text-secondary-80)",
              font: "normal normal bold 16px/19px Gotham",
              letterSpacing: "0px",
            }}
          >
            {t("RAL0337")}
          </Typography>
        </Box>
      </Box>

      <Box
        className={css`
          height: calc(89% - 20px);
          overflow: hidden;
          padding-right: 0px;
          :hover {
            overflow-y: auto;
            padding-right: 0px;
          }
        `}
      >
        <Box display={"flex"} flexDirection={"column"} mb={2} rowGap={2}>
          <Box item width={"100%"}>
            <CustomTextField
              id="text-title-addevnt"
              hideLabel={true}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  windowTitle: e.target.value,
                }));
                setError((prevError) => ({
                  ...prevError,
                  windowTitle: "",
                }));
              }}
              placeholder={"Title"}
              required
              helperText={error?.windowTitle}
              error={Boolean(error?.windowTitle)}
              label={t("RAL0338")}
              value={state.windowTitle}
              sx={{
                ".MuiInputBase-input.MuiOutlinedInput-input": {
                  padding: "13px !important",
                },
                "& .MuiOutlinedInput-root ": {
                  backgroundColor: "var(--background-color-3) !important",
                  // color: "var(--text-primary)",
                  // "&.Mui-focused": {
                  //   backgroundColor: "var(--input-focused-background-15) !important",
                  // },
                  // "&.Mui-error ": {
                  //   backgroundColor: "var(--input-error-background) !important",
                  // },
                  // "&.Mui-focused fieldset": {
                  //   backgroundColor: "var(--input-focused-background-15) !important",
                  // },
                },
              }}
              inputProps={{
                maxLength: 50,
              }}
            />
          </Box>

          <Box item width={"100%"} mt={1}>
            <CustomTextField
              id="text-description-addevnt"
              hideLabel={true}
              multiline
              rows={1}
              inputRef={textAreaRef}
              onChange={handleDescriptionChange}
              placeholder={t("RAL0292")}
              required
              helperText={error?.description}
              error={Boolean(error?.description)}
              label={"Description"}
              value={state.description}
              sx={{
                ".MuiInputBase-input.MuiOutlinedInput-input": {
                  padding: "0px !important",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "var(--background-color-3) !important",
                  overflow: "hidden",
                },
              }}
              inputProps={{
                maxLength: 200,
              }}
            />
            <Box display={"flex"} flexDirection={"row-reverse"}>
              <Typography
                variant="activity_log_count"
                sx={{
                  color: "var(--text-secondary-80-80)",
                }}
              >
                {state?.description?.length}/200
              </Typography>
            </Box>
          </Box>

          <Box item width={"100%"} mt={-1}>
            <Autocomplete
              error={Boolean(error.projectId)}
              helperText={error.projectId}
              id="dropdown-member-create-team lead"
              fullWidth
              size={"small"}
              getOptionLabel={(option) => option?.name || ""}
              clearText="Clear"
              value={selectedProject}
              options={projects ? projects : []}
              renderInput={(params) => (
                <CustomTextField
                  hideLabel={true}
                  dropdown={true}
                  placeholder={"Project"}
                  required
                  helperText={error?.projectId}
                  error={Boolean(error?.projectId)}
                  {...params}
                  label={"Project *"}
                  sx={{
                    "& .MuiOutlinedInput-root ": {
                      backgroundColor: "var(--background-color-3) !important",
                      // color: "var(--text-primary)",
                      // "&.Mui-focused": {
                      //   backgroundColor: "var(--input-focused-background-15) !important",
                      // },
                      // "&.Mui-error ": {
                      //   backgroundColor: "var(--input-error-background) !important",
                      // },
                      // "&.Mui-focused fieldset": {
                      //   backgroundColor: "var(--input-focused-background-15) !important",
                      // },
                    },
                  }}
                />
              )}
              onChange={(event, newValue) => {
                setSelectedProject(newValue);
                setState((prevState) => ({
                  ...prevState,
                  projectId: newValue ? newValue.id : "",
                  tags: [],
                }));
                setSelectedTags([]);
                setError((prevError) => ({
                  ...prevError,
                  projectId: "",
                }));
              }}
              sx={{
                ".MuiInputBase-input.MuiOutlinedInput-input": {
                  paddingX: "10px !important",
                },
              }}
            />
          </Box>

          <Box item width={"100%"} mt={1.5}>
            <Autocomplete
              disabled={!selectedProject}
              multiple
              disableCloseOnSelect={true}
              id="btn-text-tag-addevent"
              options={selectedProject ? selectedProject?.tags : []}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              ChipProps={{
                sx: {
                  backgroundColor: "var(--popover-background) !important",
                  color: "var(--text-secondary-80)",
                  borderRadius: "2px",

                  "& .MuiChip-deleteIcon": {
                    color: "var(--text-secondary-50)",
                  },
                },
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={option.name}
                    icon={
                      option.iconUrl ? (
                        <img
                          src={option.iconUrl}
                          alt={option.name}
                          height={18}
                          width={18}
                        />
                      ) : (
                        <TagInactiveIcon height={17.99} width={17.99} />
                      )
                    } // Dynamically set the icon
                    {...getTagProps({ index })}
                    deleteIcon={<CancelIcon />}
                  />
                ))
              }
              value={selectedTags}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={tagSelected}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > span": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option.id}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline",
                    }}
                  >
                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                      {option?.iconUrl ? (
                        <img
                          src={option?.iconUrl}
                          alt={option.name}
                          height={17.99}
                          width={17.99}
                        />
                      ) : (
                        <TagInactiveIcon height={17.99} width={17.99} />
                      )}

                      <Box>{option.name}</Box>
                    </Box>
                  </Box>
                </Box>
              )}
              sx={{
                "& .MuiOutlinedInput-root": {
                  background: tagError
                    ? "#FFF1F1 0% 0% no-repeat padding-box"
                    : "#F4F5F8 0% 0% no-repeat padding-box",
                  "& fieldset": {
                    borderRadius: "none",
                    border: "none",
                  },
                  ".MuiInputBase-input.MuiOutlinedInput-input": {
                    paddingY: "4px !important",
                    paddingX: "5px !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "none",
                  },
                  "& .MuiInputLabel-root ": {
                    display: "none",
                    border: "none",
                  },
                },
                "& .MuiAutocomplete-clearIndicator": {
                  borderRadius: 0,
                  position: "relative",
                  right: "20px",
                },
                "& .MuiChip-root": {
                  // background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: "2px",
                },
              }}
              renderInput={(params) => (
                <CustomTextField
                  dropdown={true}
                  hideLabel={true}
                  helperText={error?.tags}
                  error={Boolean(error?.tags)}
                  {...params}
                  label={t("RAL0191")}
                  sx={{
                    "& .MuiOutlinedInput-root ": {
                      backgroundColor: "var(--background-color-3) !important",
                      // color: "var(--text-primary)",
                      // "&.Mui-focused": {
                      //   backgroundColor: "var(--input-focused-background-15) !important",
                      // },
                      // "&.Mui-error ": {
                      //   backgroundColor: "var(--input-error-background) !important",
                      // },
                      // "&.Mui-focused fieldset": {
                      //   backgroundColor: "var(--input-focused-background-15) !important",
                      // },
                    },
                  }}
                />
              )}
              
            />
          </Box>

          <Box className="durationTime">
            <Box display="flex" mt={1} columnGap={1}>
              <Box item width={"50%"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="custom-time-picker">
                    <TimePicker
                      id="btn-strttime-picker-add-evnt"
                      allowClear={false}
                      label={t("RAL0339")}
                      placeholder={t("RAL0339")}
                      name="startTime"
                      value={state.startTime}
                      format="HH:mm"
                      onChange={handleFromTimeChange}
                      error={Boolean(error?.startTime)}
                      helperText={error.startTime}
                      // status={!!error.startTime ? "error" : ""}
                      style={{
                        width: "100%",
                        height: "50px",
                        backgroundColor: "var(--background-color-3)",
                        color: "var(--text-secondary-50)",
                        border: "none",
                        boxShadow: "none",
                      }}
                      needConfirm={false}
                      slotProps={{ textField: { size: "medium" } }}
                      renderInput={(props) => <CustomTextField {...props} />}
                    />
                  </div>
                </LocalizationProvider>
              </Box>

              <Box item width={"50%"} className="custom-time-picker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    id="btn-endtime-picker-add-evnt"
                    allowClear={false}
                    label={t("RAL0340")}
                    placeholder={t("RAL0340")}
                    name="endTime"
                    format="HH:mm"
                    value={state.endTime}
                    onChange={handleToTimeChange}
                    error={Boolean(error?.endTime)}
                    helperText={error.endTime}
                     // showNow={false}
                    // status={!!error.endTime ? "error" : ""}
                    style={{
                      width: "100%",
                      height: "50px",
                      backgroundColor: "var(--background-color-3)",
                      color: "var(--text-secondary-50)",
                      border: "none",
                      boxShadow: "none",
                    }}
                    needConfirm={false}
                    slotProps={{ textField: { size: "medium" } }}
                    minTime={state.startTime}
                    renderInput={(props) => <CustomTextField {...props} />}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

          {((showEndTimeError && error.endTime) || (showTimeError && error.endTime)) && (
              <Box display={"flex"} alignItems={"center"} pt={0.5}>
                <Box item pr={1}>
                  {<ErrorIcon />}
                </Box>
                <Box item>
                  <Typography className="helper-text">
                    {error.endTime}
                  </Typography>
                </Box>
              </Box>
          )}

           {showStartTimeError && error.startTime && (
              <Box display={"flex"} alignItems={"center"} pt={0.5}>
                <Box item pr={1}>
                  {<ErrorIcon />}
                </Box>
                <Box item>
                  <Typography className="helper-text">
                    {error.startTime}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          <Box
            className={css`
              background: var(--background-color-3) 0% 0% no-repeat padding-box;
              border-radius: 5px;
              opacity: 1;
              display: flex;
              padding: 12px;
              align-items: center;
            `}
          >
            <Box item flexGrow={1}>
              <Typography
                className="billabeTextAddEvent">
                {t("RAL0341")}
              </Typography>
            </Box>
            <Box item>
              <CustomSwitch
                checked={state.billable}
                onChange={(e) =>
                  setState({ ...state, billable: e.target.checked })
                }
              />
            </Box>
          </Box>
        </Box>
      
        <Box className="btnFixedScrollAI" display={"flex"} flexDirection={"row-reverse"} pt={2}>
          <Box item pl={1} width={"50%"}>
            <CustomButton
              id="btn-log-evnt"
              variant="contained"
              size="large"
              fullWidth="fullWidth"
              onClick={handleSubmit}
              disabled={!isSubmit}
            >
              <span>{t("RAL0342")}</span>
            </CustomButton>
          </Box>

          <Box item width={"50%"} pr={1}>
            <CustomButton
              id="btn-cancel-evnt"
              variant="outlined"
              size="large"
              fullWidth="fullWidth"
              onClick={handleCancelButton}
            >
              <span>{t("RAL0177")}</span>
            </CustomButton>
          </Box>
        </Box>

        <CancelDialog
          open={isCancelDialogOpen}
          onClose={handleCloseCancelDialog}
          onConfirm={handleConfirmCancel}
          header={t("RAL0393")}
          message={t("RAL0394")}
        />
      </Box>

    </div>
  );
}
