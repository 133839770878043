import "react-international-phone/style.css";
import { InputAdornment, MenuItem, Select, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { defaultCountries, FlagImage, parseCountry, usePhoneInput,} from "react-international-phone";
import OnboardTextField from "./OnboardTextField";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDown } from "../../src/assets/images/ralvie/arrow-down.svg";
import AppContext from "../config/AppContext";
import "./CustomPhone.scss";
export const CustomPhone = ({ value, onChange, ...restProps }) => {
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries: defaultCountries,
      onChange: (data) => {
        const phoneNumber = data.phone;
        const phoneCountryCode = data.country.dialCode;
        onChange(phoneNumber, phoneCountryCode);
      },
    });

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <OnboardTextField className="CustomPhone"
      variant="outlined"
      label={isFocused ? t("RAL0090") : t("RAL0016")}
      color="primary"
      value={inputValue}
      onChange={handlePhoneValueChange}
      onFocus={handleFocus}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-8px" }}
          >
            <Select
              IconComponent={(_props) => {
                const rotate = _props.className.toString().includes("iconOpen");
                return (
                  <div
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      pointerEvents: "none",
                      left: 50,
                      transition: `0.3s`,
                      transitionTimingFunction: "ease-in-out",
                      transform: rotate ? "rotate(180deg)" : "none",
                    }}
                  >
                    <ArrowDown />
                  </div>
                );
              }}
              MenuProps={{
                style: {
                  height: "300px",
                  width: "340px",
                  top: "10px",
                  left: "-40px",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                background: "red",
                width: "max-content",
                // Remove default outline (display only on focus)
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                // Update default spacing
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: "flex" }} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem
                    key={country.iso2}
                    value={country.iso2}
                    color={
                      mode == "dark"
                        ? "#f8f8f8cc"
                        : "var(--text-secondary-80-80)"
                    }
                  >
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: "8px" }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography>+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
