import { Box, Typography } from "@mui/material";

import AppIcon from "../../components/AppIcon";
import OverFlowTextView from "../../components/OverFlowTextView";

export default function ListEvent(props) {
  const getTimeFromDate = (date) => {
    var newDate = new Date(date);
    var hour = newDate.getHours();
    var mins = newDate.getMinutes();
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (mins < 10) {
      mins = "0" + mins;
    }
    return hour + ":" + mins;
  };
  return (
    <Box
      key={props?.event.id}
      borderRadius={"10px"}
      borderLeft={`7px solid ${props?.event.dark}`}
      minHeight={"30px"}
      m={2}
      p={1}
      alignItems={"center"}
      bgcolor={props?.event.light}
    >
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row"}
        alignItems={"flex-start"}
      >
        <Box item pr={1}>
          <AppIcon data={props?.event.data} height={20} width={20} />
        </Box>
        <Box item mr={2}>
          <Typography variant="subtitle3" textTransform={"capitalize"}>
            {props?.event.data.app?.replace(".exe", "")}
          </Typography>
        </Box>
        <Box item>
          <Typography variant="subtitle4">
            {getTimeFromDate(props?.event.start)} -{" "}
            {getTimeFromDate(props?.event.end)}
          </Typography>
        </Box>
      </Box>
      <Box flexWrap={"wrap"}>
        <OverFlowTextView
          variant="paragraph"
          text={props?.event.data.title}
        >
          {props?.event.data.title}
        </OverFlowTextView>
      </Box>
    </Box>
  );
}
