import React, { useState, useEffect, useRef } from "react";

const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null); // Reference to the container

  useEffect(() => {
    if (currentIndex < text?.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  useEffect(() => {
    // Scroll the container to the bottom when the text is updated
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [currentText]);

  return (
    <div
      ref={containerRef} // Attach the ref to the container
      style={{ maxHeight: "100%", overflowY: "auto", font:`normal normal normal var(--font-size-14) / 26px Gotham` }} // Enable scrolling with a max height
      dangerouslySetInnerHTML={{ __html: currentText }}
    />
  );
};

export default Typewriter;
