import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import { ReactComponent as ArrowDown } from "../../assets/images/ArrowDown.svg";
import { ReactComponent as CSV } from "../../assets/images/CSV.svg";
import { ReactComponent as PDF } from "../../assets/images/PDF.svg";
import { ReactComponent as XLS } from "../../assets/images/XLS.svg";
import { ReactComponent as CalenderView } from "../../assets/images/calendar_view.svg";
import { ReactComponent as DisabledCalenderView } from "../../assets/images/disabled_calendar_icon.svg";
import { ReactComponent as Export } from "../../assets/images/export_icon.svg";
import { ReactComponent as DisabledListView } from "../../assets/images/list_view.svg";
import { ReactComponent as ListView } from "../../assets/images/table_view_icon.svg";
import { useTranslation } from "react-i18next";

import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useState } from "react";
import CommonUtil from "../../util/CommonUtils";
import CustomDatePicker from "../../components/CustomDatePicker";

const CustomSelectInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 10,
    color: "#474B4F",
    position: "relative",
    backgroundColor: "#F9F9F9",
    border: "1px solid #F9F9F9",
    font: "normal normal normal 16px/25px Poppins",
    padding: "10px 30px 10px 15px",
  },
}));
export default function TitleActionBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // Export
  const handleExport = (format) => {
    let payload = {
      format: format,
      date: props.day,
    };
    axios
      .get(
        process.env.REACT_APP_SERVER_URL +
          `/export?format=${payload.format}&date=${payload.date}`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "blob",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        let filename = "download";
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?/);
          if (match) filename = match[1];
        }

        // Create a blob URL for the response data
        const url = URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        URL.revokeObjectURL(url);
        enqueueSnackbar(
          <div style={{ width: "400px", paddingBottom: "18px" }}>
            <Box>
              <Box
                style={{
                  width: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#DFDFDF",
                    font: "normal normal normal 18px/27px Poppins",
                  }}
                >
                  Download successfully
                </Typography>
                <IconButton
                  color="inherit"
                  onClick={() => closeSnackbar()}
                  id="icon_activity_close_alert"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <br />
              <span
                style={{
                  color: "#A2A4A6",
                  font: "normal normal normal 16px/28px Poppins",
                }}
              >
                <span style={{ textTransform: "uppercase" }}>
                  {payload.format}{" "}
                </span>
                file for {payload.date}'s activities downloaded successfully.
              </span>
            </Box>
          </div>,
          { variant: "default" }
        ); // Free up memory
      });
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 10,
      marginTop: theme.spacing(1.5),
      minWidth: 150,
      minHeight: 150,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : "theme.palette.grey[300]",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        minHeight: 50,
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(2),
        },

        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <div>
      <Box display={"flex"} pb={4} minHeight={47}>
        <Box item flexGrow={1}>
          <Typography variant="text_24_35_0_2">{t("RAL0048")}</Typography>
        </Box>
        <Box item mr={2}>
          <CustomDatePicker
            handleDateChange={(value) => props.handleDateChange(value)}
          />
          {/* <FormControl sx={{ minWidth: 140 }} size="small">
            <Select
              input={<CustomSelectInput />}
              inputProps={{ "aria-label": "Without label" }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={props.day === "" ? "today" : props.day}
              label="day"
              onChange={props.handleChange}
              IconComponent={(_props) => {
                const rotate = _props.className.toString().includes("iconOpen");
                return (
                  <div
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      pointerEvents: "none",
                      right: 15,
                      transform: rotate ? "rotate(180deg)" : "none",
                    }}
                  >
                    <ArrowDown />
                  </div>
                );
              }}
            >
              <MenuItem value={"today"} selected>
                {t("RAL0050")}
              </MenuItem>
              <MenuItem value={"yesterday"}>{t("RAL0051")}</MenuItem>
            </Select>
          </FormControl> */}
        </Box>
        <div className="switch-activity">
          <ButtonGroup
            disableElevation
            aria-label="Disabled elevation buttons"
            sx={{ border: "none !important", height: "100%" }}
          >
            <Button
              id="btn_activity_switch_calendar_view"
              onClick={() =>
                props.setEventViewMode(
                  props.eventViewMode === "calendar" ? "table" : "calendar"
                )
              }
              sx={{
                background:
                  props.eventViewMode === "calendar" ? "#E8E6F1" : "white",
                border: "none !important",
                borderRadius: "10px",
              }}
            >
              {props.eventViewMode === "calendar" ? (
                <Tooltip title={t("RAL0045")}>
                  <CalenderView />
                </Tooltip>
              ) : (
                <Tooltip title={t("RAL0045")}>
                  <DisabledCalenderView />
                </Tooltip>
              )}
            </Button>
            <Button
              id="btn_activity_list_view"
              onClick={() =>
                props.setEventViewMode(
                  props.eventViewMode === "calendar" ? "table" : "calendar"
                )
              }
              sx={{
                background:
                  props.eventViewMode === "calendar" ? "white" : "#E8E6F1",
                border: "none !important",
                borderRadius: "10px",
              }}
            >
              {props.eventViewMode === "calendar" ? (
                <Tooltip title={t("RAL0046")}>
                  <DisabledListView />
                </Tooltip>
              ) : (
                <Tooltip title={t("RAL0046")}>
                  <ListView />
                </Tooltip>
              )}
            </Button>
          </ButtonGroup>
        </div>
      </Box>
    </div>
  );
}
