import { css } from "@emotion/css";
import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import GenerateSummary from "../timesheet/GenerateSummary";
import { sidebarContext } from "../../util/context";
import WeekSelect from "./WeekSelect";
import AppContext from "../../config/AppContext";
import DashboardDatePicker from "./DashboardDatePicker";

export default function DashboardActivityToolBar(props) {
  const { enableAi } = useContext(sidebarContext);
  const { mode } = useContext(AppContext);
  useEffect(() => {
    console.log(props.startDate);
  }, [props.startDate]);

  return (
    <>
      <Box
        className={css`
          // background: ${mode == "dark" ? "#101010" : "var(--background-color-15)"} 0% 0% no-repeat padding-box;
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          padding: 16px;
          border-radius: 5px 5px 0px 0px;
          position: sticky;
          top: 0;
          z-index: 1000000 !important;
          background: var(--background-color-3);
        `}
      >
        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            className={css`
              width: fit-content;
            `}
          >
            <DashboardDatePicker
              startDate={props.startDate}
              endDate={props.endDate}
              selectedDate={props.selectedDate}
              onChange={props.onChange}
            />
          </Box>
          <Box>
          <span className="dateLabelLoggedHours">
           {props.loggedHours ? (
              `${props.loggedHours.hr}h ${props.loggedHours.min}m`
           ) : ("0h 0m")}
        </span> 
          </Box>
        </Box>

        {/* {enableAi && (
          <GenerateSummary
            height={"30px"}
            onClick={() => {
              props.setGenerateSummary(true);
            }}
          />
        )} */}
      </Box>
    </>
  );
}
