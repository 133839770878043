import { css } from "@emotion/css";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { getSummaryHistory } from "../../services/AiService";
import CommonUtil from "../../util/CommonUtils";
import TimesheetDatePicker from "../timesheet/TimesheetDatePicker";

import { ReactComponent as WorklogCaptionDark } from "../../assets/images/ralvie/worklog-caption-dark.svg";
import { ReactComponent as WorklogCaption } from "../../assets/images/ralvie/worklog-caption.svg";
import AppContext from "../../config/AppContext";
import { useNavigate } from "react-router-dom";

export default function AiSummaryHistory() {
  const navigate = useNavigate();
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [summaryType, setSummaryType] = useState("day");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [generatedSummary, setGenerateSummary] = useState(undefined);
  const [monthlySummary, setMonthlySummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (summaryType === "month") {
      loadMonthlySummary();
    } else if (summaryType === "day") {
      loadSummary(selectedDate);
    }
  }, [summaryType]);

  const handleDateChange = (date) => {
    console.log(date);
    loadSummary(date);
    setSelectedDate(date);
  };

  const loadSummary = (date) => {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(date, CommonUtil.getTimeZoneId());
    let startDate = dateTz.startOf("day");
    let endDate = dateTz.endOf("day");

    getSummaryHistory(
      startDate.isUTC() ? startDate.format() : startDate.utc().format(),
      endDate.isUTC() ? endDate.format() : endDate.utc().format()
    )
      .then((res) => {
        if (
          res.data?.code === "RCI0000" &&
          res.data.data instanceof Array &&
          res.data.data.length > 0
        ) {
          let text = CommonUtil.processTextSummary(res.data.data[0]?.summary);
          setGenerateSummary(text?.replaceAll("**", ""));
        } else {
          setGenerateSummary(undefined);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const loadMonthlySummary = () => {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(new Date(), CommonUtil.getTimeZoneId());
    let startDate = dateTz.startOf("month").startOf("day");
    let endDate = dateTz.endOf("month").endOf("day");

    getSummaryHistory(
      startDate.isUTC() ? startDate.format() : startDate.utc().format(),
      endDate.isUTC() ? endDate.format() : endDate.utc().format()
    )
      .then((res) => {
        if (
          res.data?.code === "RCI0000" &&
          res.data.data instanceof Array &&
          res.data.data.length > 0
        ) {
          setMonthlySummary(res.data.data);
        } else {
          setMonthlySummary([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const actions = (
    <Box
      className={css`
        display: flex;
        column-gap: 16px;
      `}
    >
      <TimesheetDatePicker
        id="btn-day-clndr"
        onChange={(date) => {
          handleDateChange(date);
        }}
        selectedDate={selectedDate}
      />
      {/* <Box
        ml={0.5}
        mr={2.5}
        className={css`
          border: 1px solid var(--right-left-arrow-border-color);
          border-radius: 5px;
          padding: 7px;
          opacity: 1;
          align-items: center;
          height: ${isXlScreen ? "50px" : "40px"};
          display: flex;
        `}
      >
        <Box
          className={css`
            background: ${summaryType === "day"
              ? "var(--background-color-8)"
              : "var(--background-color-9)"};
            opacity: 1;
            min-height: 100%;
            display: flex;
            align-items: center;
            padding: 0px 24px;
            cursor: pointer;
            border-radius: 4px;
            :hover {
              background: var(--background-color-7);
            }
          `}
          onClick={() => setSummaryType("day")}
          id="btn-day-timesheet"
        >
          <Typography
            variant="activity_view"
            className={css`
              color: ${summaryType === "day"
                ? "var(--text-color-4) !important"
                : "var(--text-secondary-50) !important"};
            `}
          >
            {t("RAL0330")}
          </Typography>
        </Box>
        <Box
          className={css`
            background: ${summaryType === "week"
              ? "var(--background-color-8)"
              : "var(--background-color-9)"};
            opacity: 1;
            min-height: 100%;
            display: flex;
            align-items: center;
            padding: 0px 24px;
            border-radius: 4px;
            cursor: pointer;
            :hover {
              background: var(--background-color-7);
            }
          `}
          onClick={() => {
            setSummaryType("week");
          }}
          id="btn-week-timesheet"
        >
          <Typography
            variant="activity_view"
            className={css`
              color: ${summaryType === "week"
                ? "var(--text-color-4) !important"
                : "var(--text-secondary-50) !important"};
            `}
          >
            Week
          </Typography>
        </Box>
        <Box
          className={css`
            background: ${summaryType === "month" ? "#E8E6F1" : ""};
            opacity: 1;
            min-height: 100%;
            display: flex;
            align-items: center;
            padding: 0px 24px;
            cursor: pointer;
            :hover {
              background: #e8e6f1;
            }
          `}
          onClick={() => {
            setSummaryType("month");
          }}
          id="btn-month-timesheet"
        >
          <Typography variant="activity_view">Month</Typography>
        </Box>
      </Box>

      <CustomButton
        type="contained"
        id="btn-custom-member"
        name="btn-custom-member"
        title={t("RAL0224")}
        variant="contained"
        onClick={() => navigate("/ai/summary")}
      >
        {t("RAL0353")}
      </CustomButton> */}
    </Box>
  );

  const monthCard = (data) => {
    let date = dayjs(data?.startDate);
    let summary = CommonUtil.processTextSummary(data.summary);
    summary = summary?.replaceAll("**", "");
    return (
      <div
        className={css`
          background: #f4f5f8 0% 0% no-repeat padding-box;
          border-radius: 5px;
          opacity: 1;
          width: 30%;
          height: 200px;
          overflow-y: hidden;
          padding: 16px;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
        `}
      >
        <Typography
          variant="header3"
          sx={{
            color: "var( --text-secondary-80)",
            font: "normal normal bold 18px/20px Gotham",
            letterSpacing: "0px",
          }}
        >
          {date?.format("ddd MMMM YYYY")}
        </Typography>
        <div
          dangerouslySetInnerHTML={{ __html: summary }}
          style={{ height: "100%", overflow: "hidden" }}
        />
      </div>
    );
  };

  return (
    <ContentLayout
      title={t("RAL0345")}
      navBack={true}
      actions={actions}
      isLoading={isLoading}
    >
      <div
        className={css`
          background: var(--background-color-3) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          opacity: 1;
          width: 100%;
          height: 85vh !important;
          overflow-y: auto;
          padding: 24px;
          display: flex;
          flex-direction: column;
          row-gap: 24px;
        `}
      >
        <Typography
          variant="header3"
          sx={{
            color: "var( --text-secondary-80)",
            font: "normal normal bold 18px/20px Gotham",
            letterSpacing: "0px",
          }}
        >
          {t("RAL0356") +
            ": " +
            selectedDate.format(
              summaryType === "day" ? "ddd MMMM YYYY" : "MMMM YYYY"
            )}
        </Typography>
        {summaryType === "day" && generatedSummary && (
          <div
            className={css`
              height: 92% !important;
              overflow-y: auto;
            `}
          >
            {/* <Typewriter text={generatedSummary} delay={1} /> */}
            <div dangerouslySetInnerHTML={{ __html: generatedSummary }} />
          </div>
        )}
        {summaryType === "month" && monthlySummary && (
          <div
            className={css`
              height: 92% !important;
              overflow-y: auto;
              display: flex;
              row-gap: 24px;
              column-gap: 24px;
            `}
          >
            {/* <Typewriter text={generatedSummary} delay={1} /> */}
            {monthlySummary.map((summary) => {
              return monthCard(summary);
            })}
          </div>
        )}
        {!generatedSummary && (
          <div
            className={css`
              display: flex;
              width: 100%;
              height: 100%;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            `}
          >
            {mode === "light" ? <WorklogCaption /> : <WorklogCaptionDark />}
            <p
              style={{
                color: "var(--text-color-6) !important",
                font: "normal normal normal 12px/22px Gotham",
              }}
            >
              {t("RAL0358")}
            </p>
          </div>
        )}
      </div>
    </ContentLayout>
  );
}
