import {
  Box,
  Button,
  Grid,
  Popover,
  Typography,
  Autocomplete,
  IconButton,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import CustomTextField from "../../components/CustomTextField";
import CommonUtil from "../../util/CommonUtils";
import {
  teamLeadsList,
  updateUsersWithTeamLeads,
  userList,
} from "../../services/MemberService";
import CloseIcon from "@mui/icons-material/Close";

export default function AddTeamLeadsToUsers(props) {
  const { open, anchorEl, onClose, selectedRows } = props;
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedTeamLeads, setSelectedTeamLeads] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [teamLead, setTeamLead] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);

  const [payload, setPayload] = useState({
    ids: [],
    teamLeads: [],
  });

  const [error, setError] = useState({
    ids: [],
    teamLeads: "",
  });

  useEffect(() => {
    loadTeamLeads();
    loadMembers();
  }, []);

  const loadMembers = () => {
    userList({ status: "ACTIVE" }).then((res) => {
      if (res?.status === 200 && res?.data?.code === "UASI0000") {
        const filteredUsers = res?.data?.data?.users?.filter(
          (user) => user.roleName === "Employee"
        );
        setUsers(filteredUsers);
      }
    });
  };

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      const selectedUserIds = selectedRows.map((id) => id);
      const selectedUserObjects = users.filter((user) =>
        selectedUserIds.includes(user.id)
      );
      setPayload((prev) => ({
        ...prev,
        ids: selectedUserIds,
      }));
      setSelectedUsers(selectedUserObjects);
    }
  }, [selectedRows, users]);

  const loadTeamLeads = () => {
    const payload1 = {
      pageNumber: 1,
      pageSize: 1000,
    };
    teamLeadsList(payload1).then((res) => {
      if (res.data.code === "RCI0000") {
        setTeamLeaders(res.data.data.teamLeads);
      }
    });
  };

  useEffect(() => {
    if (payload?.ids && users) {
      let leadUsers = users?.filter((r) => r.id === payload.ids);
      setUser(leadUsers.length > 0 ? leadUsers[0] : null);
    }
  }, [payload.ids]);

  useEffect(() => {
    if (payload?.teamLeads && teamLeaders) {
      let telead = teamLeaders?.filter((r) => r.id === payload.teamLeads);
      setTeamLead(telead.length > 0 ? telead[0] : null);
    }
  }, [payload.teamLeads]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setSelectedTeamLeads([]);
    setSelectedUsers([]);
    setPayload({
      ids: [],
      teamLeads: [],
    });
    setError({
      id: "",
      teamLeads: "",
    });
    setIsSubmitted(false);
  };

  const validate = () => {
    let valid = true;

    if (valid && (!payload.ids || payload.ids.length === 0)) {
      setError((prev) => ({
        ...prev,
        ids: t("Users required."),
      }));
      valid = false;
    }

    if (valid && (!payload.teamLeads || payload.teamLeads.length === 0)) {
      setError((prev) => ({
        ...prev,
        teamLeads: t("Team Leads required"),
      }));
      valid = false;
    }
    return valid;
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    setError({
      ids: "",
      teamLeads: "",
    });

    if (validate()) {
      updateUsersWithTeamLeads(payload)
        .then((res) => {
          if (res.data?.code === "RCI0000") {
            toast.success("Team leads added to users successfully.");
            loadMembers();
            handleClose();
          } else {
            setError({ ...error, name: res.data?.message });
          }
        })
        .catch((error) => {
          toast.error("An error occurred while processing your request.");
        });
    }
  };

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          marginLeft: "-85px",
        },
      }}
    >
      <IconButton
        id={props.id}
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 15,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box p={3} minWidth={200}>
        <Grid item md={12} sm={12} xs={12} mt={1}>
          <Autocomplete
            multiple
            fullWidth
            disableCloseOnSelect
            size="small"
            getOptionLabel={(option) => option?.memberName || ""}
            clearText="Clear"
            value={selectedUsers}
            options={users || []}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "#FFA23A",
                    },
                  }}
                  checked={selected}
                />
                {option.memberName}
              </li>
            )}
            renderInput={(params) => (
              <CustomTextField
                id="dropdown-member-update-users"
                name="dropdown-member-update-users"
                dropdown={true}
                required={true}
                placeholder={t("Search all members")}
                error={!CommonUtil.isEmptyString(error.ids) && isSubmitted}
                helperText={isSubmitted ? error.ids : ""}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
                label={t("RAL0273")}
              />
            )}
            renderTags={(value, getTagProps) => {
              const displayedValues = value.slice(0, 2);
              const remainingCount = value.length - displayedValues.length;

              return (
                <>
                  {displayedValues.map((option, index) => (
                    <span {...getTagProps({ index })} key={option.id}>
                      {option.memberName}
                      {index < displayedValues.length - 1 ? ", " : ""}
                    </span>
                  ))}
                  {remainingCount > 0 && <span>+ {remainingCount}</span>}
                </>
              );
            }}
            onChange={(event, newValue) => {
              setSelectedUsers(newValue);
              setPayload({
                ...payload,
                ids: newValue.map((lead) => lead.id),
              });
              setError({
                ...error,
                ids: "",
              });
            }}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12} mt={1}>
          <Autocomplete
            multiple
            fullWidth
            disableCloseOnSelect
            size="small"
            getOptionLabel={(option) => option?.name || ""}
            clearText="Clear"
            value={selectedTeamLeads}
            options={teamLeaders || []}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "#FFA23A",
                    },
                  }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <CustomTextField
                id="dropdown-member-update-teamLeads"
                name="dropdown-member-update-teamLeads"
                dropdown={true}
                required={true}
                placeholder={t("Team Lead")}
                error={
                  !CommonUtil.isEmptyString(error.teamLeads) && isSubmitted
                }
                helperText={isSubmitted ? error.teamLeads : ""}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
                label={t("Team Lead")}
              />
            )}
            renderTags={(value, getTagProps) => {
              const displayedValues = value.slice(0, 2);
              const remainingCount = value.length - displayedValues.length;

              return (
                <>
                  {displayedValues.map((option, index) => (
                    <span {...getTagProps({ index })} key={option.id}>
                      {option.name}
                      {index < displayedValues.length - 1 ? ", " : ""}
                    </span>
                  ))}
                  {remainingCount > 0 && <span>+ {remainingCount}</span>}
                </>
              );
            }}
            onChange={(event, newValue) => {
              setSelectedTeamLeads(newValue);
              setPayload({
                ...payload,
                teamLeads: newValue.map((lead) => lead.userId),
              });
              setError({
                ...error,
                teamLeads: "",
              });
            }}
          />
        </Grid>

        <Grid container>
          <Button
            variant="contained"
            id="btn-add-teamleads"
            onClick={handleSubmit}
            sx={{
              marginTop: "20px",
              width: "308px",
              height: "54px",
              background:
                "transparent linear-gradient(99deg, #1D0B77 0%, #6A5FA2 100%) 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                font: "normal normal normal 16px/28px Gotham",
                color: "#FFFFFF",
              }}
            >
              {t("RAL0170")}
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Popover>
  );
}
