import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import CommonUtil from '../util/CommonUtils';

export default function OverFlowTextView(props) {
  return (
    <Tooltip title={props?.text?.length > 100 ? props?.text : ''}>
      <Typography
        variant={props?.variant}
        sx={{
          font: 'normal normal normal 14px/17px Gotham',
          color: 'var(--text-color-1) !important',
          opacity: 1,
        }}>
        {CommonUtil.handleTextOverflowEvents(
          props?.children,
          props?.text?.length,
        )}
      </Typography>
    </Tooltip>
  );
}
