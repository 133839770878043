import React, { useContext } from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./CancelDialog.scss";
import AppContext from "../../config/AppContext";
export default function UnarchiveDialog(props) {
  const { open, onClose, onConfirm, name, type } = props;
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);
  const getConfirmationMessage = () => {
    return type === "Project"
      ? `${t("Are you sure you want to unarchive")} ${name}?`
      : `${t("Are you sure you want to unarchive")} ${name}?`;
  };

  return (
    <CustomDialogBox
      className="CancelDialog"
      Header={type === "Project" ? "Unarchive project" : "Unarchive member"}
      acceptText={t("RAL0300")}
      cancelText={t("RAL0370")}
      fullWidth={true}
      onClose={onClose}
      onSubmit={onConfirm}
      open={open}
    >
      <Grid
        container
        justifyContent="center"
        direction="column"
        style={{ padding: "1.875rem", paddingTop: "0" }}
      >
        <Grid>
          <Grid
            style={{
              wordWrap: "break-word",
              opacity: 1,
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              fontSize: "17px",
            }}
          >
            <Typography
              className="dialogInfo"
              sx={{
                color:
                  mode === "light"
                    ? "#242424cc!important"
                    : "#f8f8f880!important",
              }}
            >
              {getConfirmationMessage()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CustomDialogBox>
  );
}
