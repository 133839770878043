import * as React from "react";
import { css } from "@emotion/css";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CustomIconButton from "../../components/CustomIconButton";
import { ReactComponent as LeftArrow } from "../../assets/images/icon_left_arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/icon_right_arrow.svg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useEffect, useState, useRef } from "react";
import { StaticDatePicker } from "@mui/x-date-pickers";
import AppContext from "../../config/AppContext";

// Extend dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// Custom toolbar component to hide label
const CustomToolbar = () => null;

function ButtonDatePicker(props) {
  const { mode } = React.useContext(AppContext);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [open, setOpen] = React.useState(false);
  const datePickerRef = useRef(null); // Ref for the StaticDatePicker component

  // Ensure startDate and endDate are exactly one week apart
  const initialStartDate = dayjs(props.startDate).add(1, "day").startOf("day");
  const initialEndDate = dayjs(props.startDate).add(6, "days").endOf("day");
  const [disabledNextCalenderBtn, setDisabledNxtCalenderBtn] = useState(null);

  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [value, setValue] = useState(undefined);

  // Update startDate and endDate if props.startDate changes
  useEffect(() => {
    const newStartDate = dayjs(props.startDate).add(1, "day").startOf("day");
    const newEndDate = dayjs(props.startDate).add(6, "days").endOf("day");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setValue(newStartDate.add(1, "day")); // Reset value to new start date
  }, [props.startDate]);

  // Handle date change and pass back to parent component
  useEffect(() => {
    if (value) {
      props.onDateChange(value?.utc(), value?.add(1, "day"));
    }
  }, [value]);

  // Close the date picker when clicking outside of it
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getInitialDisabledValueOfNextCalenderBtn()
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getInitialDisabledValueOfNextCalenderBtn = () => {
    const today = dayjs();
    setDisabledNxtCalenderBtn(dayjs(props.startDate).isSame(today, "day"))
  }

  const handlePrevDay = () => {
    const today = dayjs();
    if (value && value?.isValid() && value?.isAfter(startDate)) {
      setValue(value?.subtract(1, "day"));
      setDisabledNxtCalenderBtn(value?.subtract(1, "day").isSame(today, "day"))
    }
  };

  const handleNextDay = () => {
    const today = dayjs();
    if (
      value &&
      value?.isValid() &&
      value?.isBefore(endDate) &&
      !dayjs().isBefore(value?.add(1, "day"), "day")
    ) {
      setValue(value?.add(1, "day"));
      setDisabledNxtCalenderBtn(value?.add(1, "day").isSame(today, "day"))
    }
  };

  const handleDateChange = (newValue) => {
    setValue(newValue);
    setOpen(false); // Close the date picker after selecting a date
  };

  const isToday = (date) => {
    return date?.isSame(dayjs(), "day");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <span>
        <CustomIconButton
          disable={value?.isSame(startDate, "day")}
          title={"Previous day"}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          height={"30px"}
          width={"30px"}
          lightMode={<LeftArrow height={isXlScreen ? "14px" : "10px"} />}
          darkMode={<LeftArrow height={isXlScreen ? "14px" : "10px"} />}
          onClick={handlePrevDay}
        />
      </span>
      <span
        className={css`
          padding: 0px 16px;
          position: relative;
        `}
      >
        <Typography
          variant="datepicker"
          onClick={() => setOpen(true)} // Open the calendar on click
        >
          <span className="dateLabel">
            {isToday(value) ? "Today" : value?.format("DD MMMM YYYY")}
          </span>
        </Typography>

        {/* Render only the calendar part */}
        {open && (
          <div
            ref={datePickerRef}
            className={css`
              position: absolute;
              right: -140px;
              top: 36px;
              border-radius: 8px;
              overflow: hidden;
              background-color: ${mode == "dark"
                ? "#101010"
                : "var(--background-color-15)"};
              box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
              z-index: 999;
            `}
          >
            <StaticDatePicker
              minDate={startDate}
              maxDate={
                dayjs().isAfter(endDate.add(1, "day"), "day")
                  ? endDate.add(1, "day")
                  : dayjs()
              }
              displayStaticWrapperAs="desktop"
              ToolbarComponent={CustomToolbar}
              ToolbarDisplay={() => null}
              value={value}
              onChange={handleDateChange}
              renderInput={() => null}
              onClose={() => setOpen(false)}
              slotProps={{
                ...props.slotProps,
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      color: "#fff",
                      borderRadius: "5px",
                      background:
                        "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                      borderColor: "#FA9C2B",
                    },
                    "&.MuiPickersDay-root.MuiPickersDay-today": {
                      borderRadius: "5px",
                      border: "1px solid #fa9c2b !important",
                      color: mode == "dark" ? "#FFFFFF" : "#242424",
                      background: mode == "dark" ? "#362C20" : "#FFF7ED",
                      "&:hover": {
                        color: "#fff",
                        borderRadius: "5px",
                        background:
                          "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                        borderColor: "#FA9C2B",
                      },
                    },
                    ":hover": {
                      color: "#fff",
                      borderRadius: "5px",
                      background:
                        "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                      borderColor: "#FA9C2B",
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </span>
      <span>
        <CustomIconButton
          disable={value?.isSame(endDate, "day")}
          title={"Next day"}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          height={"30px"}
          width={"30px"}
          disabled={disabledNextCalenderBtn}
          lightMode={<RightArrow height={isXlScreen ? "12px" : "8px"} />}
          darkMode={<RightArrow height={isXlScreen ? "12px" : "8px"} />}
          onClick={handleNextDay}
        />
      </span>
    </div>
  );
}

export default function WeekSelect(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker {...props} />
    </LocalizationProvider>
  );
}
