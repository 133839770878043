import axios from "axios";
import api from "../config/http-common";

export const tagList = async (payload) => {
  return api
    .securedAxiosApi()
    .get(
      "/tags?" + "pageNumber=" + payload.pageNumber ||
        "" + "&pageSize=" + payload.pageSize ||
        ""
    );
};

export const getAllTags = async () => {
  return api.securedAxiosApi().get("/tags");
};

export const getTagByName = async (payload) => {
  let url = "/tags?pageNumber=1&pageSize=1000";

  if (payload?.name) {
    url = url + `&name=${payload.name}`;
  }
  return api.securedAxiosApi().get(url);
};

// export const createTag = async (payload) => {
//   return api.securedAxios().post("/tag", payload);
// };

export const createTag = async (payload) => {
  const { name, color, iconUrl } = payload;
  const data = {
    name,
    color,
    iconUrl,
  };
  console.log("Payload sent to createTag API:", data);
  return api.securedAxiosApi().post("/tag", data);
};

export const uploadTagIconById = async (id, payload) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
    },
  };
  return api.securedAxiosApi().put(`/tags/${id}/icon`, payload, config);
};

export const updateTag = async (id, payload) => {
  const { name, color, iconUrl } = payload;
  const data = {
    name,
    color,
    iconUrl,
  };
  console.log("Payload sent to updateTag API:", data);
  return api.securedAxiosApi().put(`/tags/${id}`, data);
};

export const softDeleteTag = async (id) => {
  return api.securedAxiosApi().delete(`/tags/${id}`);
};

export const exportTags = async () => {
  try {
    let api = "/tags/export?";

    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") + api,
      {
        responseType: "blob", // Important to specify the response type as blob
        headers: {
          Authorization: localStorage.getItem("token"),
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "tags.xlsx"); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};
