import { Box, IconButton } from "@mui/material";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { ReactComponent as LeftArrow } from "../assets/images/icon_left_arrow.svg";
import { ReactComponent as RightArrow } from "../assets/images/icon_right_arrow.svg";
dayjs.extend(customParseFormat);

export default function CustomDatePicker(props) {
  const dateFormat = "DD MMMM YYYY";
  const [selectedDay, setSelectedDay] = useState(dayjs(new Date()));
  const [date, setDate] = useState(dayjs(new Date()));
  const customFormat = function (value) {
    const date = dayjs(value); // Replace this with your date

    return date.format(dateFormat);
  };
  const handleDateChange = (operator) => {
    if (operator === "+") {
      setSelectedDay(
        new Date(new Date().setDate(new Date(selectedDay).getDate() + 1))
      );
      props.handleDateChange(
        new Date(new Date().setDate(new Date(selectedDay).getDate() + 1))
      );
    } else {
      setSelectedDay(
        new Date(new Date().setDate(new Date(selectedDay).getDate() - 1))
      );
      props.handleDateChange(
        new Date(new Date().setDate(new Date(selectedDay).getDate() - 1))
      );
    }
  };

  const disabledDate = function (current) {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    setDate(dayjs(selectedDay));
  }, [selectedDay]);
  //   useEffect(() => {
  //     props.handleDateChange(date);
  //   }, [date]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyItems={"center"}
        height={"100%"}
      >
        <Box item pr={2}>
          <IconButton
            onClick={() => handleDateChange("-")}
            sx={{
              background: "#F9F9F9",
              borderRadius: "10px",
              padding: "15px",
            }}
          >
            <LeftArrow />
          </IconButton>
        </Box>
        <Box item>
          <DatePicker
            disabledDate={disabledDate}
            onChange={(value, dateString) => {
              setSelectedDay(new Date(dateString));
              props.handleDateChange(new Date(dateString));
            }}
            placeholder="Borderless"
            variant="borderless"
            inputReadOnly={true}
            value={date}
            format={customFormat}
            style={{
              font: "normal normal normal 16px/25px Poppins",
              letterSpacing: "0px",
              color: "#474B4F",
            }}
            suffixIcon={false}
            allowClear={false}
          />
        </Box>
        <Box item>
          <IconButton
            disabled={new Date(selectedDay).getDate() === new Date().getDate()}
            onClick={() => handleDateChange("+")}
            sx={{
              background: "#F9F9F9",
              borderRadius: "10px",
              padding: "15px",
            }}
          >
            <RightArrow />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
