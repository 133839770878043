import { css } from "@emotion/css";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as RefreshIconDark } from "../../assets/images/refresh_icon_dark.svg";
import { HeaderCustomIconButton } from "../../components/CustomIconButton";
import ContentLayout from "../../components/layout-components/ContentLayout";
import AppContext from "../../config/AppContext";
import { listUserIntegrations } from "../../services/IntegrationService";
import { exportTimesheet } from "../../services/WorklogService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext, sidebarContext } from "../../util/context";
import MemberFilter from "../dashboard/MemberFilter";
import TimesheetWeekPicker from "../dashboard/TimesheetWeekPicker";
import AITimesheetPredictions from "../worklog/AITimesheetPredictions";
import LogWork from "../worklog/LogWork";
import Worklog from "../worklog/Worklog";
import ActivityList from "./ActivityList";
import Day from "./Day";
import TimesheetProjectReport from "./TimesheetProjectReport";
import TimesheetToolBar from "./TimesheetToolBar";
import Week from "./Week";
import EditWorkLog from "../worklog/EditWorkLog";

export default function Timesheet() {
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const { enableAi } = useContext(sidebarContext);
  const [reload, setReload] = useState(false);
  const [biotimeTransactions, setBiotimeTransactions] = useState([]);
  const [textSummary, setTextSummary] = useState(false);
  const [generateSummary, setGenerateSummary] = useState(false);
  const [eventsToLog, setEventsToLog] = useState([]);
  const [eventByDrag, setEventByDrag] = useState(undefined);
  const [zoomLevel, setZoomLevel] = useState(-1);
  const zoomLevelRef = useRef(zoomLevel);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [timesheetType, setTimesheetType] = useState("day");
  const [timesheetView, setTimesheetView] = useState("calendar");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [resetWeekCalendar, setResetWeekCalendar] = useState(false);
  const timeRef = useRef();
  const [selectedMemberName, setSelectedMemberName] = useState("Ralvie AI");
  const [memberFilter, setMemberFilter] = useState(
    CommonUtil.getLoggedUser()?.id
  );
  const [projectReportOpen, setProjectReportOpen] = useState(false);
  const [myIntegrations, setMyIntegrations] = useState([]);
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    logged: true,
    unlogged: true,
    hidden: false,
    title: "",
    applicationName: "",
    selectedDate: dayjs(),
    userId: undefined,
  });
  const [firstInnerWidth, setFirstInnerWidth] = useState(75);
  const [isResizing, setIsResizing] = useState(false);
  const containerRef = useRef(null);
  const resizeRequestRef = useRef(null);
  const currentX = useRef(0);
  const startX = useRef(0);
  const [timesheetToEdit, setTimesheetToEdit] = useState(undefined);
  const [timesheetEvents, setTimesheetEvents] = useState([]);

  const updateWidth = useCallback(() => {
    const scaleFactor = 0.5;
    const deltaX = (currentX.current - startX.current) * scaleFactor;
    const newWidth =
      (deltaX / containerRef.current.offsetWidth) * 100 + firstInnerWidth;
    setFirstInnerWidth(Math.min(Math.max(newWidth, 50), 80));
    resizeRequestRef.current = requestAnimationFrame(updateWidth);
  }, [firstInnerWidth]);

  const handleMouseDown = useCallback(
    (e) => {
      setIsResizing(true);
      startX.current = e.clientX;
      const handleMouseMove = (moveEvent) => {
        currentX.current = moveEvent.clientX;
      };
      const handleMouseUp = () => {
        setIsResizing(false);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
        if (resizeRequestRef.current) {
          cancelAnimationFrame(resizeRequestRef.current);
          resizeRequestRef.current = null;
        }
      };
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      resizeRequestRef.current = requestAnimationFrame(updateWidth);
    },
    [updateWidth]
  );

  useEffect(() => {
    return () => {
      if (resizeRequestRef.current) {
        cancelAnimationFrame(resizeRequestRef.current);
      }
    };
  }, []);

  useEffect(() => {
    listUserIntegrations().then((res) => {
      if (res.status === 200 && res.data.data) {
        setMyIntegrations(res.data.data.integrations);
      }
    });
  }, []);

  const handleFilter = (e) => {
    let name = e.target.name;
    if (name === "applicationName") {
      setFilter({
        ...filter,
        [name]: e.target.value,
      });
      return;
    }
    setFilter({
      ...filter,
      [name]: e.target.checked,
    });
  };
  const handleDateChange = (date) => {
    setFilter({ ...filter, selectedDate: date });
    setEventsToLog([]);
  };
  const handleZoom = (type) => {
    switch (type) {
      case "in": {
        if (zoomLevel === 30 || zoomLevel === -1) {
          setZoomLevel(20);

          zoomLevelRef.current = 20;
        } else if (zoomLevel === 20) {
          setZoomLevel(15);

          zoomLevelRef.current = 15;
        } else if (zoomLevel === 15) {
          setZoomLevel(10);

          zoomLevelRef.current = 10;
        } else if (zoomLevel === 10) {
          setZoomLevel(5);
          zoomLevelRef.current = 5;
        }
        break;
      }
      case "out":
        if (zoomLevel === 5) {
          setZoomLevel(10);
          zoomLevelRef.current = 10;
        } else if (zoomLevel === 10) {
          setZoomLevel(15);
          zoomLevelRef.current = 15;
        } else if (zoomLevel === 15) {
          setZoomLevel(20);
          zoomLevelRef.current = 20;
        } else if (zoomLevel === 20) {
          setZoomLevel(30 || zoomLevel === -1);
          zoomLevelRef.current = 30;
        }
        break;
      default:
        setZoomLevel(zoomLevel);
    }
  };
  const handleMemberFilterChange = (newMemberFilter) => {
    setMemberFilter(newMemberFilter);
  };

  const handleExport = (start, end, userId) => {
    start = start + "T00:00:00Z";
    end = end + "T23:59:00Z";
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(start, CommonUtil.getTimeZoneId());
    let startDate = dateTz.startOf("day");
    dateTz = dayjs.tz(end, CommonUtil.getTimeZoneId());
    let endDate = dateTz.endOf("day");
    setIsLoading(true);
    exportTimesheet(
      startDate.utc().format(),
      endDate.utc().format(),
      userId
    ).then((r) => {
      setIsLoading(false);
    });
  };

  const actions = (
    <Box display="flex">
      {/* {timesheetType === "day" && enableAi && (
        <GenerateSummary
          height={isXlScreen ? "50px" : "40px"}
          onClick={() => {
            setGenerateSummary(!generateSummary);
            setEventsToLog([]);
          }}
        />
      )} */}

      {/* <ExportDialog
        title="Export timesheet"
        handleExport={handleExport}
      ></ExportDialog> */}

      {/* {timesheetType === "day" && (
        <TimesheetDatePicker
          id="btn-day-clndr"
          onChange={(date) => {
            handleDateChange(date);
          }}
          selectedDate={filter.selectedDate}
        />
      )} */}
      {timesheetType === "week" && (
        <>
          <TimesheetWeekPicker
            id="btn-week-clndr"
            ref={timeRef}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            reset={resetWeekCalendar}
            setResetWeekCalendar={setResetWeekCalendar}
            selectedDate={dayjs()}
          />

          {CommonUtil.isOwnerOrAdminOrTeamLead() && (
            <Box pr={2}>
              <MemberFilter
                id="btn-memberfilter-timesheet"
                memberFilter={memberFilter}
                setMemberFilter={handleMemberFilterChange}
                setSelectedMemberName={setSelectedMemberName}
                departmentFilter={undefined}
                timesheetType={timesheetType}
              />
            </Box>
          )}
        </>
      )}
      <Box
        ml={0.5}
        mr={2.5}
        className={css`
          border: 1px solid var(--right-left-arrow-border-color);
          border-radius: 5px;
          padding: 7px;
          opacity: 1;
          align-items: center;
          height: ${isXlScreen ? "50px" : "40px"};
          display: flex;
        `}
      >
        <Box
          // className={css`
          //   background: ${timesheetType === "day"
          //     ? "var(--background-color-8)"
          //     : "var(--background-color-9)"};
          //   opacity: 1;
          //   min-height: 100%;
          //   display: flex;
          //   align-items: center;
          //   padding: 0px 24px;
          //   cursor: pointer;
          //   border-radius: 4px;
          //   :hover {
          //     background: var(--background-color-7);
          //     &.text-label {
          //       color: ${timesheetType === "day"
          //         ? "var(--text-color-4) !important"
          //         : "var(--text-color-8) !important"};
          //     }
          //   }
          // `}
          sx={{
            background:
              timesheetType === "day"
                ? "var(--background-color-8)"
                : "var(--background-color-9)",
            opacity: 1,
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 24px",
            cursor: "pointer",
            borderRadius: "4px",
            "& .text-label": {
              color:
                timesheetType === "day"
                  ? "var(--text-color-4) !important"
                  : "var(--text-secondary-50) !important",
            },
            "&:hover": {
              background: "var(--background-color-7)",
              "& .text-label": {
                color:
                  timesheetType === "day"
                    ? "var(--text-color-4) !important"
                    : "var(--text-color-8) !important",
              },
            },
          }}
          onClick={() => setTimesheetType("day")}
          id="btn-day-timesheet"
        >
          <Typography variant="activity_view" className={`text-label`}>
            {t("RAL0330")}
          </Typography>
        </Box>
        <Box
          sx={{
            background:
              timesheetType === "week"
                ? "var(--background-color-8)"
                : "var(--background-color-9)",
            opacity: 1,
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 24px",
            borderRadius: "4px",
            cursor: "pointer",
            "& .text-label": {
              color:
                timesheetType === "week"
                  ? "var(--text-color-4) !important"
                  : "var(--text-secondary-50) !important",
            },
            "&:hover": {
              background: "var(--background-color-7)",
              "& .text-label": {
                color:
                  timesheetType === "week"
                    ? "var(--text-color-4) !important"
                    : "var(--text-color-8) !important",
              },
            },
          }}
          onClick={() => {
            setTimesheetType("week");
            setGenerateSummary(false);
            setTextSummary(undefined);
          }}
          id="btn-week-timesheet"
        >
          <Typography variant="activity_view" className={`text-label`}>
            Week
          </Typography>
        </Box>
        {/* <Box
          className={css`
            background: ${timesheetType === "month" ? "#E8E6F1" : ""};
            opacity: 1;
            min-height: 100%;
            display: flex;
            align-items: center;
            padding: 0px 24px;
            cursor: pointer;
            :hover {
              background: #e8e6f1;
            }
          `}
          onClick={() => {
            setTimesheetType("day");
            setGenerateSummary(false);
            setTextSummary(undefined);
          }}
          id="btn-month-timesheet"
        >
          <Typography variant="activity_view">Month</Typography>
        </Box> */}
      </Box>
      <Box
        item
        sx={{
          "& .refresh-svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .refresh-svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
      >
        <HeaderCustomIconButton
          id="btn-refresh-timesheet"
          title={t("RAL0169")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          // mode={mode}
          lightMode={
            <RefreshIcon
              height={isXlScreen ? "18px" : "14px"}
              className="refresh-svg"
            />
          }
          darkMode={
            <RefreshIconDark
              height={isXlScreen ? "18px" : "14px"}
              className="refresh-svg"
            />
          }
          onClick={() => {
            setFilter({
              logged: true,
              unlogged: true,
              hidden: false,
              title: "",
              applicationName: "",
              selectedDate: dayjs(),
            });
            if (timesheetType === "day") {
              setMemberFilter("Self");
            }
            setStartDate(dayjs().startOf("week").toDate());
            setEndDate(dayjs().endOf("week").toDate());
            setResetWeekCalendar(true);
            setEventsToLog([]);
          }}
        />
      </Box>
    </Box>
  );
  return (
    <>
      <TimesheetContext.Provider
        value={{
          eventByDrag,
          setEventByDrag,
          reload,
          setReload,
          eventsToLog,
          setEventsToLog,
          textSummary,
          setTextSummary,
          biotimeTransactions,
          setBiotimeTransactions,
          timesheetToEdit,
          setTimesheetToEdit,
          timesheetEvents,
          setTimesheetEvents,
        }}
      >
        <ContentLayout
          title={t("RAL0328")}
          actions={actions}
          isLoading={isLoading}
        >
          {timesheetType === "week" && (
            <Week
              myIntegrations={myIntegrations}
              isLoading={isLoading}
              setIsLoading={(state) => setIsLoading(state)}
              startDate={startDate}
              endDate={endDate}
              generateSummary={generateSummary}
              setGenerateSummary={(state) => setGenerateSummary(state)}
              userId={memberFilter}
            />
          )}
          {timesheetType === "day" && (
            <div
              ref={containerRef}
              className={css`
                background-color: var(--main-body-background-color);
                // border-radius: 5px;
                height: 100%;
                width: 100%;
                display: flex;
                cursor: ${isResizing ? "ew-resize" : "default"};
              `}
            >
              <div
                className={css`
                  height: 100%;
                  width: ${firstInnerWidth}%;
                  overflow: scroll;
                  background: var(--background-color-3);
                `}
              >
                <div className="timeSheetBar">
                  <TimesheetToolBar
                    firstInnerWidth={firstInnerWidth}
                    filter={filter}
                    handleFilter={handleFilter}
                    handleDateChange={handleDateChange}
                    zoomLevel={zoomLevelRef.current}
                    isXlScreen={isXlScreen}
                    timesheetView={timesheetView}
                    timesheetType={timesheetType}
                    setTimesheetView={(view) => setTimesheetView(view)}
                    handleZoom={(type) => handleZoom(type)}
                  />
                  {timesheetView === "calendar" ? (
                    <Day
                      setIsLoading={(state) => setIsLoading(state)}
                      // zoomLevel={zoomLevel}
                      filter={filter}
                      handleFilter={handleFilter}
                      zoomLevel={zoomLevelRef.current}
                      isXlScreen={isXlScreen}
                      timesheetView={timesheetView}
                      timesheetType={timesheetType}
                      setTimesheetView={(view) => setTimesheetView(view)}
                      handleZoom={(type) => handleZoom(type)}
                    />
                  ) : (
                    <ActivityList
                      zoomLevel={zoomLevel}
                      filter={filter}
                      generateSummary={generateSummary}
                    />
                  )}
                </div>
              </div>
              <div
                className={css`
                  height: 100%;
                  width: ${100 - firstInnerWidth}%;
                  padding: 0 14px 0px 14px;
                  position: relative;
                  background-color: var(--background-color-11);
                  margin-left: 5px;
                  overflow: scroll;
                `}
              >
                <div
                  className={css`
                    cursor: ew-resize;
                    height: 100%;
                    min-width: 20px;
                    position: absolute;
                    left: 0;
                  `}
                  onMouseDown={handleMouseDown}
                ></div>
                {eventsToLog.length < 1 && timesheetEvents.length < 1 && (
                  <Worklog
                    id="btn-add-nw-evnt"
                    filter={filter}
                    generateSummary={generateSummary}
                    setGenerateSummary={(state) => setGenerateSummary(state)}
                  />
                )}
                {eventsToLog.length > 0 && !enableAi && (
                  <LogWork filter={filter} eventsToLog={eventsToLog} />
                )}
                {timesheetEvents?.length > 0 && (
                  <EditWorkLog
                    eventsToLog={timesheetEvents}
                    timesheetToEdit={timesheetToEdit}
                  />
                )}
                {eventsToLog.length > 0 && enableAi && (
                  <AITimesheetPredictions
                    filter={filter}
                    eventsToLog={eventsToLog}
                  />
                )}
              </div>
            </div>
          )}
        </ContentLayout>

        <TimesheetProjectReport
          id="btn-timesheeet"
          open={projectReportOpen}
          onClose={() => {
            setProjectReportOpen(false);
            setTextSummary(undefined);
            setIsLoading(false);
          }}
          startDate={startDate}
          endDate={endDate}
          userId={memberFilter}
        />
      </TimesheetContext.Provider>
    </>
  );
}
