import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { departmentList } from "../../services/DepartmentService";
import "./DepartmentFilterRes.scss";
import AppContext from "../../config/AppContext";
import { useTranslation } from "react-i18next";

const DepartmentFilter = ({ departmentFilter, setDepartmentFilter }) => {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [departments, setDepartments] = useState([]);
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [departmentSearch, setDepartmentSearch] = useState("");
  const handleDepartmentFilterChange = (departmentId) => {
    let newDepartmentFilter = [...departmentFilter];

    if (departmentId === "All departments") {
      setDepartmentFilter(["All departments"]);
      return;
    }

    if (newDepartmentFilter.includes(departmentId)) {
      newDepartmentFilter = newDepartmentFilter.filter(
        (id) => id !== departmentId
      );
    } else {
      newDepartmentFilter.push(departmentId);
    }

    if (newDepartmentFilter.includes("All departments")) {
      newDepartmentFilter = newDepartmentFilter.filter(
        (id) => id !== "All departments"
      );
    }

    if (newDepartmentFilter.length === 0) {
      setDepartmentFilter(["All departments"]);
    } else {
      setDepartmentFilter(newDepartmentFilter);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await departmentList({ pageNumber: 0, pageSize: 1000 });
        if (res?.data?.code === "RCI0000") {
          const updatedDepts = res.data.data.departments;
          setDepartments(updatedDepts);
        }
      } catch (error) {
        console.error("Error fetching department list:", error);
      }
    };

    fetchData();
  }, []);

  const getDepartmentNames = (selectedIds) => {
    if (selectedIds.includes("All departments")) {
      return "All departments";
    }
    return selectedIds
      .map((id) => departments.find((dept) => dept.id === id)?.name)
      .filter(Boolean)
      .join(", ");
  };

  return (
    <Box
      display="flex"
      alignItems="center">
      <Box sx={{ ml: 2 }}>
        <FormControl
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--border-color-1) !important",
                borderWidth: "1px !important",
              },
            },
          }}>
          <Select
            fullWidth
            value={departmentFilter}
            sx={{
              height: isXlScreen ? "50px" : "40px",
              width: "250px",
              color: "var(--text-primary)",
              "& .MuiSelect-select": {
                display: "block",
                width: "80%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              "& .MuiOutlinedInput-root": {
                borderColor: "var(--border-color-1)",
              },
            }}
            multiple
            renderValue={(selected) => getDepartmentNames(selected)}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 325,
                  marginTop: 1,
                },
              },
              disableAutoFocusItem: true,
              autoFocus: false,
            }}>
            <MenuItem
              onKeyDown={(e) => e.stopPropagation()}
              sx={{
                fontFamily: "Gotham",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: "var(--fw-500)",
                backgroundColor:
                  mode === "dark" ? "#1F1F1F!important" : "#FFFFFF!important",
                color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                "&:hover": {
                  backgroundColor: "#1F1F1F",
                },
                "&.Mui-selected, &.Mui-selected:hover": {
                  backgroundColor:
                    mode === "dark" ? "#362C20!important" : "#FFF9F2!important",
                  color: "#FA9C2B",
                },
                position: "sticky",
                top: "0px",
                zIndex: "1",
                paddingTop: "8px",
              }}>
              <TextField
                id="select-search-memberfilter"
                placeholder={t("Search departments")}
                variant="outlined"
                fullWidth
                size="small"
                value={departmentSearch}
                autoFocus
                autoComplete="off"
                onChange={(e) => setDepartmentSearch(e.target.value)}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  onClick: (e) => {
                    e.stopPropagation();
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid var(--border-color-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid var(--border-color-1)",
                    },
                    "& input::placeholder": {
                      color:
                        mode === "light"
                          ? "#24242480 !important"
                          : "#f8f8f880 !important",
                      opacity: 1,
                    },
                  },
                }}
              />
            </MenuItem>
            <Box
              sx={{
                maxHeight: 260,
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "var(--background-color-38) transparent",
                "&::-webkit-scrollbar": {
                  width: "8px",
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                },
              }}>
              <MenuItem
                value="All departments"
                onClick={() => handleDepartmentFilterChange("All departments")}
                sx={{
                  fontFamily: "Gotham",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: "var(--fw-500)",
                  backgroundColor:
                    mode === "dark" ? "#1F1F1F!important" : "#FFFFFF!important",
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                  "&:hover": {
                    backgroundColor: "#1F1F1F",
                  },
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor:
                      mode === "dark"
                        ? "#362C20!important"
                        : "#FFF9F2!important",
                    color: "#FA9C2B",
                  },
                }}>
                <Checkbox
                  checked={departmentFilter.includes("All departments")}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      border:
                        mode === "dark"
                          ? "1px solid #4D4D4D"
                          : "1px solid #B2B2B2",
                      borderRadius: "4px",
                      width: "20px",
                      height: "20px",
                    },
                    "&.Mui-checked .MuiSvgIcon-root": {
                      border: "none",
                    },
                    "&.Mui-checked": {
                      color: "orange",
                    },
                    "& .MuiSvgIcon-root path": {
                      fill: "transparent",
                    },
                    "&.Mui-checked .MuiSvgIcon-root path": {
                      fill: "orange",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#362C20!important",
                      color: "#FA9C2B",
                      width: "20px",
                      height: "20px",
                    },
                  }}
                />
                All departments
              </MenuItem>
              {departments &&
                departments
                  .filter((department) =>
                    department.name
                      .toLowerCase()
                      .includes(departmentSearch.toLowerCase())
                  )
                  .map((department) => (
                    <MenuItem
                      key={department.id}
                      sx={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "var(--fw-500)",
                        backgroundColor:
                          mode === "dark"
                            ? "#1F1F1F!important"
                            : "#FFFFFF!important",
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: "#1F1F1F",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                          backgroundColor:
                            mode === "dark"
                              ? "#362C20!important"
                              : "#FFF9F2!important",
                          color: "#FA9C2B",
                        },
                      }}
                      value={department.id}
                      onClick={() =>
                        handleDepartmentFilterChange(department.id)
                      }>
                      <Checkbox
                        display={"flex"}
                        alignItems={"center"}
                        checked={departmentFilter.includes(department.id)}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                            width: "20px",
                            height: "20px",
                            boxSizing: "border-box",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "#FA9C2B",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "#FA9C2B",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#FA9C2B!important",
                            color: "#FA9C2B",
                            width: "20px!important",
                            height: "20px!important",
                          },
                          "& .MuiSvgIcon-root::before": {
                            display: "none",
                          },
                        }}
                      />
                      {department.name.length > 20 ? (
                        <Tooltip
                          title={department.name}
                          placement="top"
                          arrow>
                          <span>{`${department.name
                            .replace("null", "")
                            .substring(0, 20)}...`}</span>
                        </Tooltip>
                      ) : (
                        department.name.replace("null", "")
                      )}
                    </MenuItem>
                  ))}
            </Box>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default DepartmentFilter;
