import React, { useContext } from "react";
import { css } from "@emotion/css";
import { Avatar, Box, Typography, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../config/AppContext";
import "./CollapseNavSubMenu.scss";

function CollapseNavSubMenu({ popupState, subModules }) {
  const { mode } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="flexDirectionStyle">
        {subModules?.map((sm, index) => {
          return (
            <Box
              key={index}
              id={sm?.link}
              onClick={() => {
                navigate(sm?.link);
                popupState.close();
              }}
              py={1}
              px={2}
              sx={{
                cursor: "pointer",
                backgroundColor: sm.isActive
                  ? mode === "dark"
                    ? "var(--color-1-20)"
                    : "#F3F2F8"
                  : mode === "dark"
                  ? "#1f1f1f80"
                  : "#FFFFFF",
                display: "flex",
                "& .hover-text": {
                  color: sm.isActive
                    ? mode === "dark"
                      ? "#f8f8f8cc"
                      : "#1D0B77"
                    : mode === "dark"
                    ? "#f8f8f8cc"
                    : "#242424cc",
                },
                "&:hover": {
                  backgroundColor:
                    mode === "dark" ? "var(--color-1-20)" : "#F3F2F8",
                  "& .hover-text": {
                    color: mode === "dark" ? "#A49DC8" : "#242424",
                  },
                },
              }}
              columnGap={2}
              alignItems={"center"}
            >
              <Typography
                id="btn-prfile-settings"
                className={`hover-text ${css`
                  font: normal normal normal 16px/20px Gotham;
                  letter-spacing: 0px;
                `}`}
              >
                {sm?.title}
              </Typography>
            </Box>
          );
        })}
      </div>
    </>
  );
}

export default CollapseNavSubMenu;
