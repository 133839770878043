import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { ReactComponent as ErrorIcon } from "../assets/images/ralvie/error-icon.svg";
import { ReactComponent as SuccessIcon } from "../assets/images/ralvie/check-icon.svg";
import CommonUtil from "../util/CommonUtils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CssTextField = styled(TextField)(({ success, errors }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: success
      ? "var(--input-success-background)"
      : errors
      ? "var(--input-error-background) !important"
      : "var(--input-background) !important",
    borderRadius: 0,
    opacity: 1,
    borderBottom: success
      ? "1px solid var(--input-success-borderBottom) !important"
      : errors
      ? "1px solid #FE5050 !important"
      : "1px solid #B2B2B2 !important",
    font: "normal normal normal 16px/20px Gotham",
    color: success
      ? "var(--text-primary)"
      : errors
      ? "var(--text-primary)"
      : "var(--text-primary) !important",
    "& fieldset": {
      color: success
        ? "var(--text-primary)"
        : errors
        ? "var(--text-primary)"
        : "var(--text-primary)",
      borderRadius: 0,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    },
    "&.Mui-focused ": {
      color: success
        ? "var(--text-primary)"
        : errors
        ? "var(--text-primary)"
        : "var(--text-primary) !important",
      backgroundColor: success
        ? "var(--input-success-background) !important"
        : errors
          ? "var(--input-error-background) !important"
          : "var(--input-focused-background-1) !important",
      borderBottom: success
        ? "var(--text-primary)"
        : errors
          ? "#FE5050"
          : "",
    },
    "&.Mui-error": {
      color: success
        ? "var(--text-primary)"
        : errors
        ? "var(--text-primary)"
        : "var(--text-primary)",
      borderBottom: "1px solid red !important",
      backgroundColor: "var(--input-error-background) !important",
      // backgroundColor: success
      //   ? "var(--input-success-background)"
      //   : errors
      //     ? "var(--input-error-background) !important"
      //     : "var(--input-focused-background-15) !important",
      "& input:-webkit-autofill": {
        backgroundColor: "transparent !important",
        WebkitBoxShadow:
          "0 0 0px 1000px transparent inset !important",
        borderBottom: "none !important",
      },

    },
    "&.Mui-focused fieldset": {
      color: success
        ? "var(--text-primary)"
        : errors
        ? "var(--text-primary)"
        : "var(--text-primary) !important",
      backgroundColor: success
        ? ""
        : errors
        ? ""
        : "var(--input-focused-background-15) !important",
      borderBottom: success
        ? "var(--input-success-borderBottom)"
        : errors
        ? "#FE5050"
        : "1px solid var(--input-focused-borderBottom) !important",
      borderRadius: 0,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    },
    "& input:-webkit-autofill": {
      color: success
        ? "var(--text-primary) !important"
        : errors
        ? "var(--text-primary) !important"
        : "var(--text-primary) !important",
      backgroundColor: success
        ? "#E7FEF1 !important"
        : errors
        ? "var(--input-error-background) !important"
        : "var(--input-background) !important",
      borderBottom: success
        ? "1px solid var(--text-primary) !important"
        : errors
        ? "1px solid #FE5050 !important"
        : "1px solid #B2B2B2 !important",
      WebkitBoxShadow:
        "0 0 0px 1000px var(--input-background) inset !important",
      transition: "background-color 5000s ease-in-out 0s",
    },
    "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
      {
        backgroundColor: success
          ? "#E7FEF1 !important"
          : errors
          ? "var(--input-error-background) !important"
          : "var(--input-focused-background-15) !important",
        WebkitBoxShadow:
          "0 0 0px 1000px var(--input-focused-background-15) inset !important",
      },
  },
}));

export default function OnboardTextField(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(props.label);
  const navigate = useNavigate();

  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    let val = e.target.value;
    if (val.trim() === "") {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === " " && val[val.length - 2] === " ") {
        return;
      }
      if (val[0] === " ") {
        return;
      }
    }
    if (props.validation) {
      switch (props.validation) {
        case "alpha-numeric":
          handleAlphaNumeric(e);
          break;
        case "numeric":
          handleNumeric(e);
          break;
        case "email":
          handleEmail(e);
          break;
        case "url":
          handleUrl(e);
          break;
        case "password":
          handlePassword(e);
          break;
        case "code":
          handleCode(e);
          break;
        case "mobile":
          handleMobile(e);
          break;
        case "postcode":
          handlePostCode(e);
          break;
        case "alpha-numeric-underscore":
          handleCodeUnderscore(e);
          break;
        case "alpha-numeric-space":
          handleAlphaNumericSpace(e);
          break;
        case "numericWithoutDot":
          handleNumericWithoutDot(e);
          break;
        default:
          props.onChange(e);
      }
    } else {
      props.onChange(e);
    }
  };

  const handleAlphaNumeric = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0025"));
    }
  };

  const handleCodeUnderscore = (e) => {
    if (CommonUtil.isAlphaNumericUnderscore(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("Only accepts alphabets, numerics and underscore"));
    }
  };

  const handleAlphaNumericSpace = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(
        t("Only accepts alphabets, numerics, space and underscore")
      );
    }
  };
  const handleNumericWithoutDot = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText("Only accepts numerics");
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0023"));
    }
  };

  const handlePostCode = (e) => {
    if (CommonUtil.isValidCode(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0021"));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0024"));
    }
  };

  const handleEmail = (e) => {
    props.onChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0022"));
    }
  };

  const handleUrl = (e) => {
    props.onChange(e);
    if (CommonUtil.isValidUrl(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0029"));
    }
  };

  const handleMobile = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.onChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0024"));
    }
  };

  const handlePassword = (e) => {
    props.onChange(e);
    if (CommonUtil.isAlphaNumericPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t("RAL0010"));
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (props.label) {
      setPlaceholderText(`${t("RAL0089")}${props.label.toLowerCase()}`);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    setPlaceholderText(props.label);
  };

  return (
    <Box>
      <Box item pb={0.5}>
        <Typography className={`textfield-label`}>
          {props.label}
          {props.required && <span className="starRedColor">*</span>}
        </Typography>
      </Box>
      <CssTextField
        {...props}
        error={error}
        id={props.id}
        required={props.required}
        placeholder={isFocused ? placeholderText : props.label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        label=""
        fullWidth
        helperText={""}
        autoComplete={props.autoComplete ? "off" : "new-password"}
        value={props.value}
        onChange={(e) => handleChange(e)}
      />

      {error && (
        <Box display={"flex"} alignItems={"center"} pt={0.5}>
          <Box item pr={1}>
            {<ErrorIcon />}
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Typography className="helper-text">{helperText}</Typography>
            {props.showSignInLink && (
              <span onClick={() => navigate("/")} className="signup_email_verify_clickSignIn_UserExists">
                {t("RAL0006")}
              </span>
            )}
          </Box>
          {props.showLinkAfterError && (
            <Box item ml={0.5} mt={0.25}>
              {props.showLinkAfterError}
            </Box>
          )}
        </Box>
      )}

      {props.success && props.firstField && (
        <Box display={"flex"} alignItems={"center"} pt={1.5} mr={-12}>
          <Box item pr={1} pt={1}>
            <SuccessIcon />
          </Box>
          <Box item>
            <span className="success-text">{props.suceessMessage}</span>
          </Box>
        </Box>
      )}

      {props.errors && props.firstField && props.errorMessage && (
        <Box display={"flex"} alignItems={"center"} pt={1.5} mr={-134}>
          <Box item pr={1} pt={0.5}>
            {<ErrorIcon />}
          </Box>
          <Box item>
            <span className="error-text">{props.errorMessage}</span>
          </Box>
        </Box>
      )}
    </Box>
  );
}
