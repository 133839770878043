import { css } from "@emotion/css";
import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/EditIcon.svg";
import CustomButton from "../../components/CustomButton";
import OverFlowTextView from "../../components/OverFlowTextView";
import TagTextField from "../tags/TagTextField";
import { toast } from "sonner";
import { updateEvent } from "../../services/EventService";
import AppContext from "../../config/AppContext";

export default function EventItem(props) {
  const { mode } = useContext(AppContext);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState(props.event?.title);

  const handleUpdateEvent = () => {
    updateEvent(props.event?.id, { windowTitle: title }).then((res) => {
      if (res.status === 200) {
        toast.success("Title updated successfully");
      }
    });
  };

  const editView = () => {
    return (
      <Box display={"flex"} flexDirection={"column"} rowGap={1} width={"100%"}>
        <Box p={1}>
          <TagTextField
            value={title}
            onChange={(e) => {
              props.setEventToEdit({ ...props.event, title: title });
              setTitle(e.target.value);
            }}
          ></TagTextField>
        </Box>
        <Box display={"flex"} flexDirection={"row-reverse"}>
          <Box item pl={1}>
            <CustomButton
              variant="contained"
              size="small"
              fullWidth="fullWidth"
              onClick={() => {
                props.setEventToEdit({ ...props.event, title: title });
                handleUpdateEvent();
                setEdit(false);
              }}
            >
              <span>{"Update"}</span>
            </CustomButton>
          </Box>
          <Box item pr={1}>
            <CustomButton
              variant="outlined"
              size="small"
              fullWidth="fullWidth"
              onClick={() => setEdit(false)}
            >
              <span>{"Cancel"}</span>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    );
  };

  const readView = () => {
    return (
      <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
        <Box display={"flex"} flexDirection={"column"} rowGap={1}>
          <OverFlowTextView variant="paragraph" text={title}>
            {title}
          </OverFlowTextView>

          <Typography
            className={css`
              font: normal normal normal 12px / 14px Gotham !important;
              letter-spacing: 0px;
              color: var(--text-color-7) !important;
              opacity: 1;
            `}
          >
            {props?.duration?.hr +
              "h " +
              props?.duration?.min +
              "m " +
              props?.duration?.sec +
              "s"}
          </Typography>
        </Box>
        {props.editable && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              "& .up_down_svg": {
                transition: "color 0.3s ease",
                color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
              },
              "&:hover": {
                "& .hover-text, & .up_down_svg": {
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                },
              },
            }}
          >
            <EditIcon
              onClick={() => {
                props.setEventToEdit(props.event);
                setEdit(true);
              }}
              className="icon up_down_svg"
            />
          </Box>
        )}
      </Box>
    );
  };

  return edit ? editView() : readView();
}
