import React, { useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import AppContext from "../config/AppContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Custom plugin to draw rounded rectangle boxes around bars and display data labels
let insideBarWidth;
const roundedBoxPlugin = {
  id: "roundedBox",
  beforeDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
      scales: { x, y },
      data,
    } = chart;
    ctx.save();
    const segment = width / data.labels.length;
    const barWidth =
      segment *
      data.datasets[0].barPercentage *
      data.datasets[0].categoryPercentage *
      2;
    const radius = 2;
    insideBarWidth = barWidth;

    for (let i = 0; i < data.labels.length; i++) {
      const xPos = x.getPixelForValue(i) - barWidth / 2;
      const barHeight = height - top + 20;

      ctx.fillStyle = pluginOptions.barColor;
      // Drawing rounded rectangles
      ctx.beginPath();
      ctx.moveTo(xPos + radius, top);
      ctx.lineTo(xPos + barWidth - radius, top);
      ctx.quadraticCurveTo(xPos + barWidth, top, xPos + barWidth, top + radius);
      ctx.lineTo(xPos + barWidth, top + barHeight - radius);
      ctx.quadraticCurveTo(
        xPos + barWidth,
        top + barHeight,
        xPos + barWidth - radius,
        top + barHeight
      );
      ctx.lineTo(xPos + radius, top + barHeight);
      ctx.quadraticCurveTo(
        xPos,
        top + barHeight,
        xPos,
        top + barHeight - radius
      );
      ctx.lineTo(xPos, top + radius);
      ctx.quadraticCurveTo(xPos, top, xPos + radius, top);
      ctx.closePath();
      ctx.fill();

      // Add labels on top of the bars
      const totalSeconds = data.datasets[0].data[i];
      const hours = Math.floor(totalSeconds / 3600);
      const remainingSeconds = totalSeconds % 3600;
      const rawMinutes = remainingSeconds / 60;
      const minutes =
        rawMinutes % 1 < 0.5 ? Math.floor(rawMinutes) : Math.ceil(rawMinutes);
      const label = `${hours ? hours : "0"}h ${minutes ? minutes : "0"}m`;

      ctx.fillStyle = pluginOptions.labelColor;
      ctx.font = pluginOptions.labelFont || "12px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
      ctx.fillText(label, xPos + barWidth / 2, top - 5);
    }
    ctx.restore();
  },
};

ChartJS.register(roundedBoxPlugin);

export function BarGraph({
  barBackgroundColor,
  barColor,
  textColor,
  weekData,
  gradientStartColor,
  gradientStartColorDark,
  gradientEndColor,
  gradientEndColorDark,
}) {
  const { mode } = useContext(AppContext);
  const labelColor = mode === "dark" ? "#f8f8f880" : "#242424";
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
      customCanvasBackgroundColor: {
        color: "white",
      },
      roundedBox: {
        barColor: barBackgroundColor,
        // textColor: textColor,
        textColor: labelColor,
        labelColor: labelColor,
      },
      tooltip: {
        enabled: false,
      },
    },
    layout: {
      padding: {
        top: 20, // Add padding to ensure labels are visible above the canvas
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
          display: false,
        },
        border: {
          display: false,
        },
        barPercentage: 0.2,
      },
      y: {
        grid: {
          display: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
        title: {
          display: true,
          text: "hrs",
        },
        ticks: {
          display: false,
          callback: function (value, index, values) {
            return "86400"; // Fixed display value
          },
        },
        border: {
          display: false,
        },
      },
    },
  };

  const dataActive = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Active Time",
        data: weekData,
        backgroundColor: function (context) {
          const chart = context.chart;
          const {
            ctx,
            chartArea,
            scales: { x },
          } = chart;

          // Check if chartArea and x.getPixelForValue are available and valid
          if (!chartArea || typeof x.getPixelForValue !== "function") {
            return mode === "dark"
              ? gradientStartColorDark
              : gradientStartColor; // Default fallback color
          }

          const { top, bottom } = chartArea;
          const index = context.dataIndex;
          const xPos = x.getPixelForValue(index);

          // Ensure all gradient-related values are finite
          if (!isFinite(xPos) || !isFinite(top) || !isFinite(bottom)) {
            return mode === "dark"
              ? gradientStartColorDark
              : gradientStartColor; // Default fallback color if any value is non-finite
          }

          // Caching gradient to avoid recreating it unnecessarily
          if (!chart.gradient) {
            chart.gradient = {};
          }

          // Create a gradient for each bar based on its index
          if (!chart.gradient[index]) {
            const gradient = ctx.createLinearGradient(xPos, top, xPos, bottom);
            gradient.addColorStop(
              0,
              mode === "dark" ? gradientStartColorDark : gradientStartColor
            ); // Start color at 0%
            gradient.addColorStop(
              1,
              mode === "dark" ? gradientEndColorDark : gradientEndColor
            ); // End color at 100%
            chart.gradient[index] = gradient;
          }

          return chart.gradient[index];
        },
        borderRadius: 2, // 50% rounded borders
        borderSkipped: false,
        barPercentage: 0.62,
        categoryPercentage: 0.62,
        barThickness: insideBarWidth,
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%", maxWidth: "500px", height: "100%" }}>
        <Bar
          options={options}
          data={dataActive}
          style={{ marginTop: "10px" }}
        />
      </div>
    </div>
  );
}
