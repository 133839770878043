import api from "../config/http-common";

export const createApplication = async (payload) => {
  return api.securedAxiosApi().post(`/application_rule`, payload);
};

export const updateApplication = async (id, payload) => {
  return api.securedAxiosApi().put(`/application_rule/${id}`, payload);
};

export const getApplicationById = async (id) => {
  return api.securedAxiosApi().get(`/application_rule/${id}`);
};

export const deleteApplication = async (id) => {
  return api.securedAxiosApi().delete(`/application_rule/${id}`);
};

export const listApplicationSettings = async (payload) => {
  let url = "/application_rules?pageNumber=0&pageSize=1000";
  if (payload?.name) {
    url += "&name=" + payload.name;
  }
  return api.securedAxiosApi().get(url);
};

export const uploadIconById = async (id, payload) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };
  return api
    .securedAxiosApi()
    .put(`/application_rule/${id}/icon`, payload, config);
};
