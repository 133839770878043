import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";

export default function CustomChecks(props) {
    const handleChange = (event) => {
        event.stopPropagation(); // Ensure stopPropagation is called correctly
        props.onChange(props.member);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={props.id}
                    name={props.name}
                    checked={props.checked}
                    onChange={handleChange}
                    sx={{
                        "&.Mui-checked": {
                            color: "#FA9C2B",
                        },
                    }}
                />
            }
            label={<Typography variant="body1">{props.label}</Typography>}
            labelPlacement="end"
        />
    );
}
