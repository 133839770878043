import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import TagIcon from "../../assets/images/ralvie/tag-icon-inactive.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/ralvie/Close.svg";
import { ReactComponent as CancelIconWhite } from "../../assets/images/ralvie/close-icon-white.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import AddMembersComponent from "../../components/AddMembersComponent";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import CustomButton from "../../components/CustomButton";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";
import CustomTextField from "../../components/CustomTextField";
import TitleBar from "../../components/layout-components/TitleBar";
import { clientList, rolesList } from "../../services/MemberService";
import { getProjectMember } from "../../services/ProjectMemberService";
import { editProject, getProject } from "../../services/ProjectService";
import { getAllTags } from "../../services/TagService";
import CancelDialog from "./CancelDialog";
import "./CreateProject.scss";
import "./EditProjects.scss";
import { useTranslation } from "react-i18next";
import ColorPickerWithPalettes from "../../components/ColorPickerWithPalettes";
import moment from "moment";

export default function EditProjects() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, isLoading] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [tagOptions, setTagOptions] = useState([]);
  const [tagError, setTagError] = useState("");
  const [membersList, setMembersList] = useState([]);
  const [savedMemberList, setSavedMemberList] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState("");
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [billableType, setBillableType] = useState("Member default");
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const fromView = location.state ? location.state?.fromView : null;

  const [formData, setFormData] = useState({
    name: "",
    color: "",
    description: "",
    clientName: "",
    startDate: null,
    endDate: null,
    tags: [],
    members: [],
    billableType: "",
    budgetType: "",
    timeBudget: "",
    costBudget: "",
  });

  const [timelineStartDate, setTimelineStartDate] = useState(
    formData.startDate
  );
  const [formErrors, setFormErrors] = useState({
    name: "",
    color: "",
    description: "",
    clientName: "",
    projectClientEmail: "",
    startDate: "",
    endDate: "",
    tags: [],
    members: [],
    billableType: "",
    budgetType: "",
    timeBudget: "",
    costBudget: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 632,
    height: "276px",
    backgroundColor: "#FFFFFF",
    boxShadow: 24,
    p: 4,
    padding: "30px",
  };

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    if (fromView === "list") {
      navigate("/projects-list");
    } else {
      navigate("/projects");
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDescriptionChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      description: "",
    }));
  };

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      const { scrollHeight } = textAreaRef.current;
      const height = Math.max(40, Math.min(scrollHeight, 300));
      textAreaRef.current.style.height = `${height}px`;
    }
  }, [formData.description]);

  const maxCharactersPerRow = 100;
  const baseWidth = 800;
  const maxWidth = 1100;
  const calculateWidth = () => {
    if (formData.description.length <= maxCharactersPerRow) {
      return baseWidth + formData.description.length * 2;
    }
    return maxWidth;
  };

  useEffect(() => {
    getProjectMember(id)
      .then((response) => {
        if (response.data && response.data.data) {
          console.log(typeof response.data.data);
          let members = JSON.parse(response.data.data);
          setMembersList(members.members);
          setSavedMemberList(members.members);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch project members:", error);
      });
  }, [id]);

  useEffect(() => {
    isLoading(true); // Activate loading indicator
    getProject(id)
      .then((response) => {
        if (response.data && response.data.data) {
          console.log("Fetched Project Data:", response.data.data); // Detailed logging
          setProjectData(response.data.data);
          setClientId(response.data.data.client.id);
        } else {
          console.log("No project data received:", response); // Log unexpected response structure
        }
        isLoading(false); // Deactivate loading indicator after data is fetched
      })
      .catch((error) => {
        console.error("Failed to fetch project details:", error);
        isLoading(false); // Deactivate loading indicator on error
      });
  }, [id]);

  const handleTagChange = (event, newValue) => {
    if (newValue.length < 50) {
      setFormData((prev) => ({
        ...prev,
        tags: newValue.map((item) => item.value),
      }));
      setTagError("");
      setFormErrors((prev) => ({ ...prev, tags: "" }));
    } else {
      setFormData((prev) => ({
        ...prev,
        tags: newValue.map((item) => item.value),
      }));
      setTagError("You can select up to 50 tags only.");
      setFormErrors((prev) => ({
        ...prev,
        tags: "You can select up to 50 tags only.",
      }));
      setTimeout(() => {
        setTagError("");
        setFormErrors((prev) => ({ ...prev, tags: "" }));
      }, 3000);
    }
  };

  const handleBudgetChange = (event) => {
    let value = event.target.value;

    if (formData.budgetType === "time") {
      // Remove non-numeric characters
      value = value.replace(/[^0-9]/g, "");

      // Ensure the value is at most 100
      if (value.length > 7) {
        value = value.substring(0, 7);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        timeBudget: value,
      }));
    } else {
      // Remove non-numeric characters except decimal point
      value = value.replace(/[^0-9.]/g, "");

      // Ensure only two decimal places
      const decimalIndex = value.indexOf(".");
      if (decimalIndex >= 0) {
        const decimalPart = value.substring(
          decimalIndex + 1,
          decimalIndex + 3 + 1
        );
        value = value.substring(0, decimalIndex + 1) + decimalPart;
      }

      // Ensure length of value is at most 15 characters
      if (value.length > 15) {
        value = value.substring(0, 15);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        costBudget: value,
      }));
    }

    event.target.value = value;
  };

  const handleBillableTypeChange = (value) => {
    setBillableType(value);
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: projectData.name || "",
      color: projectData.color || "",
      description: projectData.description || "",
      clientName: clientId || "",
      clientEmail: projectData.clientEmail || "",
      startDate: projectData.startDate ? new Date(projectData.startDate) : null,
      endDate: projectData.endDate ? new Date(projectData.endDate) : null,
      tags: handleTags(projectData.tags),
      members: membersList,
      billableType: projectData.billableType
        ? projectData.billableType
        : billableType,
      budgetType: projectData.budgetType || "",
      timeBudget: projectData.timeBudget || "",
      costBudget: projectData.costBudget || "",
    }));
  }, [projectData, membersList, clientId]);

  useEffect(() => {
    setBillableType(
      projectData.billableType ? projectData.billableType : billableType
    );
  }, [projectData.billableType]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    getAllTags()
      .then((response) => {
        if (response.data && response.data.data && response.data.data.tags) {
          setTagOptions(
            response.data.data.tags.map((tag) => ({
              value: tag.id,
              label: tag.name,
              url: tag.iconUrl,
            }))
          );
        } else {
          setTagOptions([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching tag list:", error);
        setTagOptions([]);
      });
  }, []);

  useEffect(() => {
    rolesList().then((res) => {
      if (res.data.code === "UASI0000") {
        const clientRole = res.data.data.roles.find(
          (role) => role.name === "Client"
        );
        if (clientRole) {
          clientList({ roleId: clientRole.id }).then((r) => {
            if (r.data.code === "RCI0000") {
              const updatedClients = r.data.data
                ?.filter((user) => user.status !== "ARCHIVED")
                .map((user) => ({
                  id: user.id,
                  name: user.name,
                }));
              setClients(updatedClients);
            }
          });
        } else {
          console.log("Client role not found");
        }
      }
    });
  }, []);

  const handleTags = (tags) => {
    return tags
      ? tags.filter((t) => t.status !== "ARCHIVED").map((tag) => tag.id)
      : [];
  };

  const handleMembersChange = (memberIds) => {
    setMembersList(memberIds);
  };

  const handleEditProject = () => {
    const {
      name,
      color,
      startDate,
      endDate,
      description,
      tags,
      budgetType,
      timeBudget,
      costBudget,
    } = formData;
    const newErrors = {};

    if (!name || name.trim() === "") {
      newErrors.name = "Project name is required";
    }
    if (!color || color.trim() === "") {
      newErrors.color = "Project color is required";
    }
    if (!startDate) {
      newErrors.startDate = "Please select a start date for the timeline.";
    }
    if (!endDate) {
      newErrors.endDate = "Please select a start date for the timeline.";
    }
    if (description.length > 200) {
      newErrors.projectDescription =
        "Description cannot exceed 200 characters.";
    }
    if (startDate && endDate && new Date(startDate) >= new Date(endDate)) {
      newErrors.startDate = "Start date must be before end date";
      newErrors.endDate = "End date must be after start date";
    }

    if (Object.keys(newErrors).length > 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
      return;
    }
    const updatedProjectData = {
      ...formData,
      billableType: billableType,
      budgetType: formData.budgetType,
      timeBudget: formData.budgetType === "time" ? formData.timeBudget : "",
      costBudget: formData.budgetType === "cost" ? formData.costBudget : "",
    };

    editProject(id, updatedProjectData)
      .then((response) => {
        if (response.data.code === "RCI0034") {
          toast.success(t("RAL0322"));
          if (fromView === "list") {
            navigate("/projects-list");
          } else {
            navigate("/projects");
          }
        } else {
          setFormErrors((prevErrors) => {
            const newErrors = { ...prevErrors };

            if (["RCE0135", "RCE0136"].includes(response.data.code)) {
              newErrors.description = response.data.message;
            } else {
              newErrors.description = "";
            }

            if (response.data.code === "RCE0137") {
              newErrors.color = response.data.message;
            } else {
              newErrors.color = "";
            }

            if (
              ["RCE0130", "RCE0132", "RCE0133", "RCE0146"].includes(
                response.data.code
              )
            ) {
              newErrors.name = response.data.message;
            } else {
              newErrors.name = "";
            }

            return newErrors;
          });
        }
      })
      .catch((error) => {
        console.error("Error editing project:", error);
        toast.error(t("RAL0323"));
      });
  };

  const handleStartDateChange = (date) => {
    const newStartDate = date && date.isValid() ? date.toDate() : null;
    setFormData((prevData) => ({
      ...prevData,
      startDate: newStartDate,
    }));

    if (newStartDate) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        startDate: "",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        startDate: "Please select a start date for the timeline.",
      }));
    }
  };

  const handleEndDateChange = (date) => {
    const newEndDate = date && date.isValid() ? date.toDate() : null;
    setFormData((prevData) => ({
      ...prevData,
      endDate: newEndDate,
    }));

    if (newEndDate) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        endDate: "",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        endDate: "Please select a end date for the timeline.",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name" && formErrors.name) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleColorChange = (color) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      color: color,
    }));
  };

  const styles = {
    textField: {
      "& .MuiOutlinedInput-root": {
        background: "#F4F5F8 0% 0% no-repeat padding-box",
        "& fieldset": {
          borderRadius: "none",
          border: "none",
        },
        "&.Mui-focused fieldset": {
          borderColor: "none",
        },
        "&:hover fieldset": {
          borderColor: "none",
        },
        "&.MuiInputLabel-root ": {
          display: "none",
          border: "none",
        },
        "&.Mui-focused": {
          backgroundColor: "#F4F2FE",
        },
      },
    },
    outlinedInput: {
      border: "none",
      "& fieldset": {
        border: "none",
      },
      background: "var(--input-background) 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      height: "60px",
    },
    memberListBox: {
      width: "100%",
      height: "65%",
      overflow: "auto",
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      height: "60px",
    },
  };

  return (
    <>
      <TitleBar title={t("RAL0287")} navBack={true}></TitleBar>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="85%"
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          <Box
            height="calc(100vh - 180px)"
            overflow="auto"
            className="accordionProjectDiv"
          >
            <Accordion
              className="labelMarginB accordion"
              defaultExpanded
              square={false}
              elevation={0}
              sx={{
                borderRadius: "10px",
                padding: "10px",
                marginBottom: "10px",
                "&.MuiAccordion-root": {
                  "::before": {
                    content: "none",
                  },
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                className="accordinHeader header-sticky"
              >
                {t("RAL0288")}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container md={8} sm={8} xs={8} lg={8}>
                  <Grid container item spacing={3}>
                    <Grid item md={6} sm={6} xs={6} lg={6}>
                      <CustomTextField
                        name="name"
                        required={true}
                        label={t("RAL0289")}
                        placeholder={t("RAL0290")}
                        error={formErrors.name}
                        helperText={formErrors.name}
                        value={formData.name}
                        onChange={handleChange}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                      <label className="colorLabel">{t("RAL0291")}</label>
                      <Box className="colorPicker">
                        <Box
                          sx={{
                            width: "60px",
                            height: "50px",
                            background: "var(--input-background) !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            position: "absolute",
                            cursor: "pointer",
                          }}
                          variant="contained"
                          onClick={handleClick}
                        >
                          <Box
                            sx={{
                              backgroundColor: formData.color,
                              width: "24px",
                              height: "24px",
                              borderRadius: "2px",
                            }}
                          ></Box>
                        </Box>
                        <Popover
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          disableRestoreFocus
                          sx={{ zIndex: 1000 }}
                        >
                          <ColorPickerWithPalettes
                            place="Edit_Project"
                            selectedColor={formData.color}
                            setSelectedColor={handleColorChange}
                          />
                        </Popover>

                        {/* <ColorPicker
                        value={formData.color || "#FFFFFF"}
                        style={{
                          width: "80px",
                          height: "50px",
                          fontSize: "60px",
                          background: "var(--input-background) !important",
                        }}
                        onChange={handleColorChange}
                      /> */}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} pt={2.5}>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                      <CustomTextField
                        className="textBoxDescription"
                        name="description"
                        label={t("RAL0292")}
                        rows={1}
                        placeholder={t("RAL0293")}
                        error={formErrors.description}
                        helperText={formErrors.description}
                        value={formData.description}
                        inputRef={textAreaRef}
                        multiline
                        onChange={handleDescriptionChange}
                        inputProps={{
                          maxLength: 200,
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            fontSize: 16,
                            height: 4,
                            padding: 1,
                          },
                          ".MuiInputBase-input.MuiOutlinedInput-input": {
                            padding: "0px !important",
                          },
                          "& .MuiOutlinedInput-root": {
                            overflow: "hidden",
                            width: `${calculateWidth()}px`,
                            maxWidth: `${maxWidth}px`,
                            whiteSpace: "pre-wrap",
                          },
                        }}
                      />
                      <Box
                        display={"flex"}
                        flexDirection={"row-reverse"}
                        mt={1}
                      >
                        <Typography
                          variant="activity_log_count"
                          sx={{
                            color: "var(--text-secondary-80-80)",
                          }}
                        >
                          {formData.description?.length}/200
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={3} pt={3}>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={6}
                      lg={6}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CustomizedDatePicker
                        required={true}
                        value={
                          formData.startDate ? moment(formData.startDate) : null
                        }
                        label={t("RAL0294")}
                        error={!!formErrors.startDate?.length}
                        helperText={formErrors.startDate || ""}
                        onChange={handleStartDateChange}
                        // minDate={new Date()}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={6}
                      lg={6}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <CustomizedDatePicker
                        required={true}
                        value={
                          formData.endDate ? moment(formData.endDate) : null
                        }
                        label={t("RAL0295")}
                        error={!!formErrors.endDate?.length}
                        helperText={formErrors.endDate || ""}
                        onChange={handleEndDateChange}
                        minDate={timelineStartDate || new Date()}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} pt={3}>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>{t("RAL0191")}</label>
                      <Autocomplete
                        className="tagSelect"
                        disableCloseOnSelect={true}
                        name="tags"
                        multiple
                        id="tags-outlined"
                        options={tagOptions || []}
                        getOptionLabel={(option) => option?.label || ""}
                        filterSelectedOptions
                        ChipProps={{
                          deleteIcon: <CancelIcon />,
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        value={formData.tags.map(
                          (tag) => tagOptions.find((t) => t.value === tag) || {}
                        )}
                        onChange={handleTagChange}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > span": { mr: 2, flexShrink: 0 } }}
                            {...props}
                            key={option.value}
                            aria-disabled={
                              formData.tags && formData?.tags.length >= 50
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: 8,
                                width: "auto",
                                height: 24,
                              }}
                            >
                              <img
                                src={option.url ? option.url : TagIcon} // Display custom icon if available, otherwise default
                                alt={option.label}
                                style={{
                                  width: 20,
                                  height: 20,
                                  marginRight: 8,
                                }} // Adjust icon size and margin as needed
                              />
                              <span>{option.label}</span>
                            </Box>
                          </Box>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              sx={{
                                padding: "0.9rem",
                                width: "8.125rem",
                                backgroundColor:
                                  "var(--background-color) !important",
                                color: "var(--text-secondary-80)",
                                borderRadius: "2px",
                                "& .MuiChip-label": {
                                  padding: "0 !important",
                                },
                                "& .MuiChip-avatar": {
                                  marginRight: "8px",
                                  width: "1.2rem",
                                  height: "1.2rem",
                                },
                                "& .MuiChip-deleteIcon": {
                                  marginLeft: "8px",
                                },
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              avatar={
                                <img
                                  src={option.url ? option.url : TagIcon}
                                  alt={option.label}
                                />
                              } // Directly set the avatar here
                              label={
                                <Tooltip title={option.label} placement="top">
                                  {option.label.length > 6
                                    ? `${option.label.substring(0, 6)}...`
                                    : option.label}
                                </Tooltip>
                              }
                              {...getTagProps({ index })}
                              deleteIcon={<CancelIcon />}
                            />
                          ))
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            background: tagError
                              ? "var(--input-focused-background-15) 0% 0% no-repeat padding-box"
                              : "var(--input-background) 0% 0% no-repeat padding-box",
                            "& fieldset": {
                              borderRadius: "none",
                              border: "none",
                            },
                            "&:hover fieldset": {
                              borderColor: "none",
                            },
                            "& .MuiInputLabel-root ": {
                              display: "none",
                              border: "none",
                            },
                          },
                          "&.Mui-focused": {
                            backgroundColor:
                              "var(--input-focused-background-15) !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderRadius: "5px",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                            backgroundColor:
                              "var(--input-focused-background-15) !important",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Tags" />
                        )}
                      />
                      {tagError && (
                        <Box display="flex" alignItems="center" pt={0.5}>
                          <Box pr={1}>
                            <ErrorIcon />
                          </Box>
                          <Typography variant="body2" color="error">
                            {tagError}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3} pt={1.5}>
                    <Grid
                      className="SelectClient"
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label>{t("RAL0296")}</label>
                      </Box>
                      <CustomAutoComplete
                        options={clients || []}
                        placeholder="Select client"
                        value={clientId}
                        onChange={setClientId}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordion"
              square={false}
              elevation={0}
              sx={{
                borderRadius: "10px",
                padding: "10px",
                marginBottom: "10px",
                "&.MuiAccordion-root": {
                  "::before": {
                    content: "none",
                  },
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                className="accordinHeader header-sticky"
              >
                {t("RAL0082")}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "20px 16px",
                }}
              >
                <Grid container xs={8} sm={8} md={8} lg={8}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      spacing={2}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        marginLeft={2}
                      >
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          className="bugetRadio"
                        >
                          <FormControl>
                            <RadioGroup
                              row
                              value={formData.budgetType}
                              onChange={(e) => {
                                const value = e.target.value;
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  budgetType: value,
                                }));
                              }}
                            >
                              <FormControlLabel
                                sx={{ marginRight: "50px" }}
                                value="time"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": { color: "#FA9C2B" },
                                    }}
                                  />
                                }
                                label={t("RAL0280")}
                              />
                              <FormControlLabel
                                value="cost"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": { color: "#FA9C2B" },
                                    }}
                                  />
                                }
                                label={t("RAL0297")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            disabled={!formData.budgetType}
                            placeholder={t("RAL0298")}
                            value={
                              formData.budgetType === "time"
                                ? formData.timeBudget
                                : formData.costBudget
                            }
                            onChange={handleBudgetChange}
                            endAdornment={
                              <InputAdornment position="end">
                                {formData.budgetType === "time" ? "hr" : "$"}
                              </InputAdornment>
                            }
                            sx={{ ...styles.outlinedInput }}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <AddMembersComponent
              className="accordion"
              selectedEmployee={handleMembersChange}
              EditData={savedMemberList}
              billableCostData={formData.billableType}
              billableType={handleBillableTypeChange}
              billableTypeData={formData.billableType}
            />
          </Box>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={3}
            alignItems={"end"}
            justifyContent={"flex-end"}
            mb={2}
          >
            <Grid item md={3} sm={3} xs={3} lg={3}>
              <CustomButton
                sx={{ paddingTop: "0px" }}
                place="sp-cancel"
                variant="outlined"
                class="memberButton"
                fullWidth
                onClick={handleCancelButton}
              >
                {t("RAL0177")}
              </CustomButton>
            </Grid>
            <Grid item md={3} sm={3} xs={3} lg={3}>
              <CustomButton
                variant={"contained"}
                class="memberButton"
                fullWidth
                onClick={handleEditProject}
              >
                {t("RAL0309")}
              </CustomButton>
            </Grid>
            <CancelDialog
              open={isCancelDialogOpen}
              onClose={handleCloseCancelDialog}
              onConfirm={handleConfirmCancel}
              header={t("RAL0299")}
              message={t("RAL0302")}
            />
          </Grid>
        </>
      )}
    </>
  );
}
