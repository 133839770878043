import api from "../config/http-common";

export const getIntegrations = async () => {
  return api.securedAxiosApi().get("/integrations");
};

export const saveIntegration = async (payload) => {
  return api.securedAxiosApi().post("/integration/save", payload);
};

export const loginWithBiotime = async (payload) => {
  return api.securedAxiosApi().post("/integrations/biotime/login", payload);
};

export const appIntegrations = async (authorize_url) => {
  return api.securedAxiosApi().get(authorize_url);
};
export const listUserIntegrations = async () => {
  return api.securedAxiosApi().get("/integrations/me");
};
export const listUserTransactions = async (id, payload) => {
  let url = `/integrations/biotime/${id}/transactions?`;
  if (payload.startTime) {
    url += "&startTime=" + payload.startTime;
  }
  if (payload.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  if (payload.empCode) {
    url += "&empCode=" + payload.empCode;
  }
  url = url.replace("?&", "?");
  return api.securedAxiosApi().get(url);
};
export const listUserBiotimeData = async (id, payload) => {
  let url = `/integrations/biotime?`;
  if (payload.userId) {
    url += "&userId=" + payload.userId;
  }
  if (payload.applicationId) {
    url += "&applicationId=" + payload.applicationId;
  }
  if (payload.startTime) {
    url += "&startTime=" + payload.startTime;
  }
  if (payload.endTime) {
    url += "&endTime=" + payload.endTime;
  }
  if (payload.empCode) {
    url += "&empCode=" + payload.empCode;
  }
  url = url.replace("?&", "?");
  return api.securedAxiosApi().get(url);
};

export const deleteIntegration = async (id) => {
  return api.securedAxiosApi().delete(`/integration_response/${id}`);
};

export const initDataSync = async (id) => {
  return api
    .securedAxiosApi()
    .put(`/integration_response/${id}/init_data_sync`);
};
export const jiraUserIntegration = async (code) => {
  return api.securedAxiosApi().post(`/integrations/jira/${code}`);
};
export const googleUserIntegration = async (code, application) => {
  return api
    .securedAxiosApi()
    .post(`/integrations/google?code=${code}&application=${application}`);
};

export const gitlabUserIntegration = async (code) => {
  return api.securedAxiosApi().post(`/integrations/gitlab/${code}`);
};
