import { Container, CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../../services/MemberService";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext } from "../../util/context";
import "./LayoutComponents.scss";
import NavMenu from "./NavMenu";

export default function HomeLayout(props) {
  const [expand, setExpand] = useState(false);
  const [enableAi, setEnableAi] = useState(
    localStorage.getItem("enableAi") === "true" ? true : false
  );
  const [profilePic, setProfilePic] = useState(undefined);

  const [userProfile, setUserProfile] = useState(undefined);
  const [isUserAuthenticated, setUserAuthenticated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (CommonUtil.isEmptyString(localStorage.getItem("token"))) {
      navigate("/");
    } else {
      setUserAuthenticated(true);
    }
    const decoded = CommonUtil.decodeToken();
    const userData = localStorage.getItem("USER");
    console.log("User Data in Local Storage: ", decoded); // Check if user data is stored correctly

    if (userData) {
      const user = JSON.parse(userData);
      const userId = user.userId;

      if (userId) {
        getUserById(userId)
          .then((response) => {
            if (response.data && response.data.data) {
              const pic = response.data.data.profilePhoto;
              setUserProfile(response.data.data);
              if (pic) {
                setProfilePic(pic);
              } 
            }
          })
          .catch((error) => {
            console.log("Error fetching user details: " + error.message);
          });
      } else {
        console.log("No user ID found in user data.");
      }
    } else {
      console.log("No user data found in local storage.");
    }
  }, []);
  

  return !isUserAuthenticated ? (
    <></>
  ) : (
    <sidebarContext.Provider
      value={{
        expand,
        setExpand,
        enableAi,
        setEnableAi,
        userProfile,
        setUserProfile,
        profilePic,
        setProfilePic,
      }}
    >
      <CssBaseline />
      <Container
        maxWidth={false}
        disableGutters
        style={{
          height: "100vh",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", height: "100%" }}>
              <NavMenu />
              <div className="main-body">{props.children}</div>
            </div>
          </div>
        </div>
      </Container>
    </sidebarContext.Provider>
  );
}
