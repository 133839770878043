import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery, useTheme } from "@mui/material";

export default function ProjectGraph({ projectData, totalProjects, mode }) {
  const chartRef = useRef(null);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isLg_mScreen = useMediaQuery(theme.breakpoints.up("lg_m"));
  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const sortedProjectData = projectData
      .map((project) => ({
        ...project,
        totalHours: project.hours + project.minutes / 60,
      }))
      .sort((a, b) => b.totalHours - a.totalHours)
      .reverse()
      .slice(0, 6);

    const createGradient = (color) => {
      const hexColor = color.startsWith("#")
        ? color.substring(0, 7)
        : "#000000";
      return {
        type: "linear",
        x: 0,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [
          { offset: 0.5, color: `${hexColor}B3` },
          { offset: 0.2, color: `${hexColor}80` },
          { offset: 1, color: hexColor },
        ],
        global: false,
      };
    };

    const option = {
      dataset: {
        source: sortedProjectData.map((project) => [
          project.totalHours,
          project.name,
          project.hours,
          project.minutes,
          createGradient(project.color),
        ]),
      },
      grid: {
        containLabel: false,
        top: "5%",
        bottom: "1%",
        left: "0",
      },
      xAxis: { name: "Hours", show: false },
      yAxis: { type: "category", show: false },
      tooltip: {
        trigger: "item",
        position: function (point, size) {
          const [x, y] = point; 
          const { contentSize } = size; 
          return [x - contentSize[0] / 2, y + 10];
        },
        formatter: function (params) {
          const [totalHours, name, hours, minutes] = params.data;
          return `<strong>${name}</strong>: ${hours}h ${minutes}m`;
        },
      },
      series: [
        {
          type: "bar",
          encode: {
            x: 0,
            y: 1,
          },
          itemStyle: {
            color: function (params) {
              return params.data[4];
            },
          },
          label: {
            show: true,
            position: "insideLeft",
            offset: [15, 0],
            formatter: function (params) {
              const projectName = params.value[1];
              return projectName.length > 14
                ? `${projectName.substring(0, 14)}...`
                : projectName;
            },
            fontWeight: "bold",
            color: mode === "light" ? "#000000" : "#FFFFFF",
          },
          barGap: "15px",
          barWidth: sortedProjectData.length === 1 ? "50%" : "80%",
        },
      ],
    };

    chartInstance.setOption(option);
    window.addEventListener("resize", () => {
      chartInstance.resize();
    });

    return () => {
      chartInstance.dispose();
      window.removeEventListener("resize", () => {
        chartInstance.resize();
      });
    };
  }, [projectData, mode]);

  const chartWidth = projectData.length === 1 ? "80%" : "100%";

  return (
    <Tooltip>
      <div
        ref={chartRef}
        style={{
          width: chartWidth,
          height: isXlScreen ? "25rem" : isLg_mScreen ? "20rem" : "16rem",
          // top: "-10px",
          // left: "-35px",
        }}
      />
    </Tooltip>
  );
}
