import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import TagIcon from "../../assets/images/ralvie/tag-icon-inactive.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/ralvie/Close.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import AddMembersComponent from "../../components/AddMembersComponent";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import CustomButton from "../../components/CustomButton";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";
import CustomTextField from "../../components/CustomTextField";
import TitleBar from "../../components/layout-components/TitleBar";
import { clientList, rolesList } from "../../services/MemberService";
import { createProject } from "../../services/ProjectService";
import { getAllTags } from "../../services/TagService";
import CancelDialog from "./CancelDialog";
import "./CreateProject.scss";
import { useTranslation } from "react-i18next";
import ColorPickerWithPalettes from "../../components/ColorPickerWithPalettes";
import AppContext from "../../config/AppContext";

export default function CreateProject() {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState("");
  const [projectColor, setProjectColor] = useState("#3F9F4A");
  const [projectDescription, setProjectDescription] = useState("");
  const [clientId, setClientId] = useState("");
  const [timelineStartDate, setTimelineStartDate] = useState(null);
  const [timelineEndDate, setTimelineEndDate] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [isBillable, setIsBillable] = useState(false);
  const [billableType, setBillableType] = useState("Member default");
  const [budgetType, setBudgetType] = useState();
  const [cost, setCost] = useState("0");
  const [time, setTime] = useState("0");
  const [tagError, setTagError] = useState("");
  const [membersList, setMembersList] = useState({});
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const fromView = location.state ? location.state?.fromView : null;

  const { mode } = useContext(AppContext);

  const [state, setState] = useState({
    projectName: "",
    projectColor: "",
    projectDescription: "",
    clientName: "",
    clientEmail: "",
    timelineStartDate: "",
    timelineEndDate: "",
    tags: "",
  });

  const [errors, setErrors] = useState({
    projectName: "",
    projectColor: "",
    projectDescription: "",
    clientName: "",
    clientEmail: "",
    timelineStartDate: "",
    timelineEndDate: "",
    tags: "",
  });

  const handleMembers = (data) => {
    setMembersList(data);
  };

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    if (fromView === "list") {
      navigate("/projects-list");
    } else {
      navigate("/projects");
    }
  };

  const handleSelectedEmployee = (memberId) => {
    setMembersList(membersList.filter((id) => id !== memberId));
  };

  const handleBudgetType = (e) => {
    setBudgetType(e.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDescriptionChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      projectDescription: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      projectDescription: "",
    }));
  };

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      const { scrollHeight } = textAreaRef.current;
      const height = Math.max(40, Math.min(scrollHeight, 300));
      textAreaRef.current.style.height = `${height}px`;
    }
  }, [state.projectDescription]);

  const maxCharactersPerRow = 100;
  const baseWidth = 800;
  const maxWidth = 1100;

  const calculateWidth = () => {
    if (state.projectDescription.length <= maxCharactersPerRow) {
      return baseWidth + state.projectDescription.length * 2;
    }
    return maxWidth;
  };

  useEffect(() => {
    rolesList().then((res) => {
      if (res.data.code === "UASI0000") {
        const clientRole = res.data.data.roles.find(
          (role) => role.name === "Client"
        );
        if (clientRole) {
          clientList({ roleId: clientRole.id }).then((r) => {
            if (r.data.code === "RCI0000") {
              const updatedClients = r.data.data
                ?.filter((user) => user.status !== "ARCHIVED")
                .map((user, index) => {
                  return {
                    id: user.userId,
                    name: user.name,
                  };
                });
              setClients((prevState) => [...prevState, ...updatedClients]);
            }
          });
        } else {
          console.log("Client role not found");
        }
      }
    });
  }, []);

  // Fetching tags on mount
  useEffect(() => {
    getAllTags()
      .then((res) => {
        if (res.data && res.data.data && res.data.data.tags) {
          setTagOptions(
            res.data.data.tags.map((tag) => ({
              value: tag.id,
              label: tag.name,
              url: tag.iconUrl,
            }))
          );
        } else {
          setTagOptions([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching tag list:", error);
        setTagOptions([]);
      });
  }, []);

  const validateFields = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Reset all error messages
    Object.keys(newErrors).forEach((key) => {
      newErrors[key] = "";
    });

    // Validation checks for each field
    if (!projectName.trim()) {
      newErrors.projectName = "Please enter a project name.";
      valid = false;
    } else if (projectName.length < 1) {
      newErrors.projectName = "Project name must be at least 1 characters.";
      valid = false;
    } else if (projectName.length > 50) {
      newErrors.projectName = "Project name cannot exceed 50 characters.";
      valid = false;
    }

    if (!projectColor.trim()) {
      newErrors.projectColor = "Please specify a project color.";
      valid = false;
    }

    if (projectDescription && projectDescription.length > 200) {
      newErrors.projectDescription =
        "Description cannot exceed 200 characters.";
      valid = false;
    }

    if (!timelineStartDate) {
      newErrors.timelineStartDate =
        "Please select a start date for the timeline.";
      valid = false;
    }

    if (!timelineEndDate) {
      newErrors.timelineEndDate = "Please select a end date for the timeline.";
      valid = false;
    }

    if (
      timelineStartDate &&
      timelineEndDate &&
      timelineStartDate >= timelineEndDate
    ) {
      newErrors.timelineStartDate = "Start date must be before the end date.";
      newErrors.timelineEndDate = "End date must be after the start date.";
      valid = false;
    }

    // Clear errors for budget section fields
    newErrors.clientEmail = "";
    newErrors.timelineStartDateError = "";

    setErrors(newErrors);
    return valid;
  };

  const handleCreateProject = () => {
    // Validate fields before proceeding
    if (validateFields()) {
      const payload = {
        name: projectName.trim(),
        description: state.projectDescription.trim(),
        color: projectColor,
        startDate: timelineStartDate,
        endDate: timelineEndDate,
        tags: selectedTags,
        clientId: clientId,
        isBillable: isBillable,
        members: membersList,
        billableType: billableType,
        budgetType: budgetType,
        timeBudget: time,
        costBudget: cost,
      };

      // Print the payload for debugging
      console.log("Payload:", payload);

      console.log("descrtopre:", payload.description);

      // Attempt to create the project using the API
      createProject(payload)
        .then((res) => {
          // Handle the response from the API
          if (res.data.code === "RCI0033") {
            // Success: Display message and redirect
            toast.success(t("RAL0325"));
            if (fromView === "list") {
              navigate("/projects-list");
            } else {
              navigate("/projects");
            }
            // Clear all errors on success
            setErrors({});
          } else {
            // Update errors based on specific error codes
            setErrors((prevErrors) => {
              const newErrors = { ...prevErrors };

              // Handle project description errors
              if (["RCE0135", "RCE0136"].includes(res.data.code)) {
                newErrors.projectDescription = res.data.message;
              } else {
                newErrors.projectDescription = ""; // Clear if error resolved
              }

              // Handle project color error
              if (res.data.code === "RCE0137") {
                newErrors.projectColor = res.data.message;
              } else {
                newErrors.projectColor = ""; // Clear if error resolved
              }

              // Handle project name errors
              if (
                ["RCE0130", "RCE0132", "RCE0133", "RCE0146"].includes(
                  res.data.code
                )
              ) {
                newErrors.projectName = res.data.message;
              } else {
                newErrors.projectName = ""; // Clear if error resolved
              }

              return newErrors;
            });
          }
        })
        .catch((error) => {
          console.error("Error creating project:", error);
          // Handle error response from API
          // Update state to show an error message to the user
          toast.error(t("RAL0326"));
        });
    }
  };

  const handleColorChange = (color) => {
    setProjectColor("#".concat(color.toHex())); // Ensure the color starts with a '#'
  };

  const tagSelected = (event, value) => {
    const selectedValues = value.map((option) => option.value);
    console.log(selectedValues);
    if (selectedValues.length >= 50) {
      setSelectedTags(selectedValues);
      setTagError(t("RAL0327"));
      setTimeout(() => {
        setTagError("");
      }, 3000);
    } else {
      setSelectedTags(selectedValues);
      setTagError("");
    }
  };

  const handleBillableTypeChange = (value) => {
    setBillableType(value ? value : "Common cost");
    // Clear error message for billable cost
    setErrors((prevErrors) => ({
      ...prevErrors,
      billableType: "",
    }));
  };

  const handleTimelineStartDate = (date) => {
    if (date === null || date === "" || (date && isNaN(date.valueOf()))) {
      setTimelineStartDate(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        timelineStartDate: "Please select a start date for the timeline.",
      }));
    } else {
      setTimelineStartDate(date);
      setErrors((prevErrors) => ({
        ...prevErrors,
        timelineStartDate: "",
      }));
    }
  };

  const handleTimelineEndDate = (date) => {
    if (date === null || date === "" || (date && isNaN(date.valueOf()))) {
      setTimelineEndDate(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        timelineEndDate: "Please select a end date for the timeline.",
      }));
    } else {
      setTimelineEndDate(date);
      setErrors((prevErrors) => ({
        ...prevErrors,
        timelineEndDate: "",
      }));
    }
  };

  const handleBudgetChange = (event) => {
    let value = event.target.value;

    if (budgetType === "time") {
      // Remove non-numeric characters
      value = value.replace(/[^0-9]/g, "");

      // Ensure the value is at most 100
      if (value.length > 7) {
        value = value.substring(0, 7);
      }

      setTime(value);
    } else {
      // Remove non-numeric characters except decimal point
      value = value.replace(/[^0-9.]/g, "");

      // Ensure only two decimal places
      const decimalIndex = value.indexOf(".");
      if (decimalIndex >= 0) {
        const decimalPart = value.substring(
          decimalIndex + 1,
          decimalIndex + 3 + 1
        );
        value = value.substring(0, decimalIndex + 1) + decimalPart;
      }

      // Ensure length of value is at most 15 characters
      if (value.length > 15) {
        value = value.substring(0, 15);
      }

      setCost(value);
    }

    event.target.value = value;
  };

  const styles = {
    textField: {
      "& .MuiOutlinedInput-root": {
        background: "#F4F5F8 0% 0% no-repeat padding-box",
        "& fieldset": {
          borderRadius: "none",
          border: "none",
        },
        "&.Mui-focused fieldset": {
          borderColor: "none",
        },
        "&:hover fieldset": {
          borderColor: "none",
        },
        "&.MuiInputLabel-root ": {
          display: "none",
          border: "none",
        },
        "&.Mui-focused": {
          backgroundColor: "#F4F2FE",
        },
      },
    },
    outlinedInput: {
      border: "none",
      "& fieldset": {
        border: "none",
      },
      background: "var(--input-background) 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      height: "60px",
    },
    memberListBox: {
      width: "100%",
      height: "65%",
      overflow: "auto",
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      height: "60px",
    },
  };

  return (
    <>
      <TitleBar title={t("RAL0324")} navBack={true}></TitleBar>
      <Box
        height="calc(100vh - 190px)"
        overflow="auto"
        className="accordionProjectDiv"
      >
        <Accordion
          className="accordion"
          defaultExpanded
          square="false"
          elevation={0}
          sx={{
            "&.MuiAccordion-root": {
              "::before": {
                content: "none", // Correct way to remove ::before pseudo-element
              },
            },
          }}
        >
          <AccordionSummary
            id="btn-toggle-exp-project"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            className="accordinHeader header-sticky"
          >
            {t("RAL0288")}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container md={8} sm={8} xs={8} lg={8}>
              <Grid container item spacing={3}>
                <Grid item md={6} sm={6} xs={6} lg={6}>
                  <CustomTextField
                    required={true}
                    id="text-projectname"
                    name="ProjectName"
                    label={t("RAL0289")}
                    placeholder={t("RAL0290")}
                    error={errors.projectName}
                    helperText={errors.projectName}
                    value={projectName}
                    // onChange={(e) => setProjectName(e.target.value)}
                    onChange={(e) => {
                      setProjectName(e.target.value);
                      if (e.target.value.trim() !== "") {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          projectName: "",
                        }));
                      }
                    }}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                  <label className="colorLabel">{t("RAL0291")}</label>
                  <Box className="colorPicker">
                    <Box
                      sx={{
                        width: "60px",
                        height: "50px",
                        background: "var(--input-background) !important",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                      variant="contained"
                      onClick={handleClick}
                    >
                      <Box
                        sx={{
                          backgroundColor: projectColor,
                          width: "24px",
                          height: "24px",
                          borderRadius: "2px",
                        }}
                      ></Box>
                    </Box>
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      disableRestoreFocus
                      sx={{ zIndex: 1000 }}
                    >
                      <ColorPickerWithPalettes
                        selectedColor={projectColor}
                        setSelectedColor={setProjectColor}
                      />
                    </Popover>

                    {/* <ColorPicker
                      defaultValue="#00FF00"
                      id="btn-color-picker-project"
                      name="btn-color-picker-project"
                      style={{
                        // marginTop:"10px",
                        width: "80px",
                        height: "50px",
                        fontSize: "60px",
                        background: "var(--input-background) !important",
                      }}
                      onChange={handleColorChange}
                    /> */}
                  </Box>
                </Grid>
              </Grid>
              <Grid container item spacing={3} pt={2.5}>
                <Grid item md={12} sm={12} xs={12} lg={12}>
                  <CustomTextField
                    className="textBoxDescription"
                    id="text-description"
                    name="Description"
                    label={t("RAL0292")}
                    rows={1}
                    placeholder={t("RAL0293")}
                    error={errors.projectDescription}
                    helperText={errors.projectDescription}
                    value={state.projectDescription}
                    inputRef={textAreaRef}
                    multiline
                    onChange={handleDescriptionChange}
                    inputProps={{
                      maxLength: 200,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: 16,
                        height: 4,
                        padding: 1,
                      },
                      ".MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "0px !important",
                      },
                      "& .MuiOutlinedInput-root": {
                        overflow: "hidden",
                        width: `${calculateWidth()}px`,
                        maxWidth: `${maxWidth}px`,
                        whiteSpace: "pre-wrap",
                      },
                    }}
                  />
                  <Box display={"flex"} flexDirection={"row-reverse"} mt={1}>
                    <Typography
                      variant="activity_log_count"
                      sx={{
                        color: "var(--text-secondary-80-80)",
                      }}
                    >
                      {state.projectDescription?.length}/200
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item spacing={3} pt={3}>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  lg={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <CustomizedDatePicker
                    required={true}
                    id="btn-date-project"
                    name="btn-date-project"
                    label={t("RAL0294")}
                    value={timelineStartDate}
                    error={Boolean(errors.timelineStartDate)} // Ensure this shows an error
                    helperText={errors.timelineStartDate || ""}
                    onChange={handleTimelineStartDate}
                    // minDate={timelineEndDate || ""}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  lg={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <CustomizedDatePicker
                    required={true}
                    id="btn-date-project"
                    name="btn-date-project"
                    label={t("RAL0295")}
                    value={timelineEndDate}
                    onChange={handleTimelineEndDate}
                    error={Boolean(errors.timelineEndDate)}
                    helperText={errors.timelineEndDate || ""}
                    minDate={timelineStartDate || ""}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={3} pt={3}>
                <Grid
                  item
                  pt={3}
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <label>{t("RAL0191")}</label>
                  <Autocomplete
                    className="tagSelect"
                    disableCloseOnSelect={true}
                    multiple
                    id="tags-outlined"
                    name="text-tags-project"
                    options={tagOptions || []}
                    getOptionLabel={(option) => option?.label || ""}
                    filterSelectedOptions
                    ChipProps={{
                      deleteIcon: <CancelIcon />,
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={tagSelected}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > span": { mr: 2, flexShrink: 0 } }}
                        {...props}
                        key={option.value}
                        aria-disabled={selectedTags.length >= 50}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: 8,
                            width: "auto",
                            height: 18,
                          }}
                        >
                          <img
                            src={option.url ? option.url : TagIcon}
                            alt={option.label}
                            style={{ width: 20, height: 18, marginRight: 8 }}
                          />
                          <span>{option.label}</span>
                        </Box>
                      </Box>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          sx={{
                            padding: "0.9rem",
                            width: "8.125rem",
                            backgroundColor:
                              "var(--background-color) !important",
                            color: "var(--text-secondary-80)",
                            borderRadius: "2px",
                            "& .MuiChip-label": {
                              padding: "0 !important",
                            },
                            "& .MuiChip-avatar": {
                              marginRight: "8px",
                              width: "1.2rem",
                              height: "1.2rem",
                            },
                            "& .MuiChip-deleteIcon": {
                              marginLeft: "8px",
                            },
                            whiteSpace: "nowrap", // Prevent label from wrapping
                            overflow: "hidden", // Hide overflow text
                            textOverflow: "ellipsis", // Display ellipsis for overflow text
                          }}
                          avatar={
                            <img
                              src={option.url ? option.url : TagIcon}
                              alt={option.label}
                            />
                          } // Directly set the avatar here
                          label={
                            <Tooltip title={option.label} placement="top">
                              {option.label.length > 6
                                ? `${option.label.substring(0, 6)}...`
                                : option.label}
                            </Tooltip>
                          }
                          {...getTagProps({ index })}
                          deleteIcon={<CancelIcon />}
                        />
                      ))
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        background: tagError
                          ? "var(--input-focused-background-15) 0% 0% no-repeat padding-box"
                          : "var(--input-background) 0% 0% no-repeat padding-box",
                        "& fieldset": {
                          borderRadius: "none",
                          border: "none",
                        },
                        "&:hover fieldset": {
                          borderColor: "none",
                        },
                        "& .MuiInputLabel-root ": {
                          display: "none",
                          border: "none",
                        },
                      },

                      "&.Mui-focused": {
                        backgroundColor:
                          "var(--input-focused-background-15) !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderRadius: "5px",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        backgroundColor:
                          "var(--input-focused-background-15) !important",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Tags" />
                    )}
                  />
                  {tagError && (
                    <Box display="flex" alignItems="center" pt={0.5}>
                      <Box pr={1}>
                        <ErrorIcon />
                      </Box>
                      <Typography variant="body2" color="error">
                        {tagError} {/* You can customize the error text */}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container item spacing={3}>
                <Grid
                  className="SelectClient"
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label>{t("RAL0296")}</label>
                  </Box>
                  <CustomAutoComplete
                    options={clients || []}
                    placeholder="Select client"
                    value={clientId}
                    onChange={setClientId}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion"
          square="false"
          elevation={0}
          sx={{
            "&.MuiAccordion-root": {
              "::before": {
                content: "none", // Correct way to remove ::before pseudo-element
              },
            },
          }}
        >
          <AccordionSummary
            id="btn-toggle-expand-budget"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            className="accordinHeader header-sticky"
          >
            {t("RAL0082")}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container xs={8} sm={8} md={8} lg={8}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  spacing={2}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    marginLeft={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className="bugetRadio"
                    >
                      <FormControl sx={{ marginTop: "30px" }}>
                        <RadioGroup
                          row
                          defaultValue={budgetType}
                          onChange={handleBudgetType}
                        >
                          <FormControlLabel
                            id="btn-radio-time-project"
                            name="btn-radio-time-project"
                            sx={{ marginRight: "50px" }}
                            value="time"
                            control={
                              <Radio
                                sx={{ "&.Mui-checked": { color: "#FA9C2B" } }}
                              />
                            }
                            label={t("RAL0280")}
                          />
                          <FormControlLabel
                            id="btn-radio-cost-project"
                            name="btn-radio-cost-project"
                            value="cost"
                            control={
                              <Radio
                                sx={{ "&.Mui-checked": { color: "#FA9C2B" } }}
                              />
                            }
                            label={t("RAL0297")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className="bugetHour"
                    >
                      <Box>
                        <label>{t("RAL0082")}</label>
                        <OutlinedInput
                          fullWidth
                          id="text-budget-project"
                          name="text-budget-project"
                          type="text"
                          disabled={!budgetType}
                          placeholder={t("RAL0298")}
                          value={budgetType === "time" ? time : cost}
                          onChange={handleBudgetChange}
                          endAdornment={
                            <InputAdornment position="end">
                              {budgetType === "time" ? "hr" : "$"}
                            </InputAdornment>
                          }
                          sx={{ ...styles.outlinedInput }}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <AddMembersComponent
          className="accordion"
          id="btn-toggle-exp-member"
          selectedEmployee={handleMembers}
          billableType={handleBillableTypeChange}
          billableCostData={billableType}
        />
      </Box>
      <Grid
        container
        item
        xs={12}
        lg={12}
        spacing={3}
        alignItems={"end"}
        justifyContent={"flex-end"}
        mt={1}
        mb={2}
      >
        <Grid
          sx={{
            position: "sticky",
            bottom: "1.5rem",
          }}
        >
          <CustomButton
            place="sp-cancel"
            variant="outlined"
            class="memberButton"
            id="btn-cancel-project"
            name="btn-cancel-project"
            onClick={handleCancelButton}
          >
            {t("RAL0177")}
          </CustomButton>
          <CustomButton
            variant={"contained"}
            class="memberButton"
            id="btn-add-project"
            name="btn-add-project"
            onClick={handleCreateProject}
          >
            {t("RAL0170")}
          </CustomButton>
        </Grid>
        <CancelDialog
          open={isCancelDialogOpen}
          onClose={handleCloseCancelDialog}
          onConfirm={handleConfirmCancel}
          header={t("RAL0299")}
          message={t("RAL0302")}
        />
      </Grid>
    </>
  );
}
