import { css } from "@emotion/css";
import { Box, Typography, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { getAllProjectReports } from "../../services/ReportService";
import ExportProjectReport from "./ExportProjectReport";
import { useTranslation } from "react-i18next";
import "./ProjectsReport.scss";
import ProjectReportCard from "./ProjectReportCard";
import AppContext from "../../config/AppContext";
import { ReactComponent as EmptyProjectList } from "../../assets/images/ralvie/noProjects.svg";
import { ReactComponent as EmptyProjectListDark } from "../../assets/images/ralvie/noProjectsDark.svg";

export default function ProjectsReport() {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [error, setError] = useState(false);
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getAllProjectReports(
      startDate.isUTC() ? startDate.format() : startDate.utc().format(),
      endDate.isUTC() ? endDate.format() : endDate.utc().format()
    )
      .then((res) => {
        if (res.data?.data?.projectReports) {
          setReports(res.data.data.projectReports);
          setError(false);
        } else {
          setReports([]);
          setError(true);
        }
      })
      .catch(() => {
        setReports([]);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  const actions = (
    <Box display={"flex"} className="exportSVG">
      <ExportProjectReport reports={reports} />
    </Box>
  );

  return (
    <ContentLayout title={t("RAL0205")} actions={actions}>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        {isLoading ? (
          <Box
            display={"flex"}
            height={"100%"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        ) : error || reports.length === 0 ? (
          <Box
            display={"flex"}
            height={"100%"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box item md="12">
              {mode === "dark" ? <EmptyProjectListDark /> : <EmptyProjectList />}
            </Box>
            <Typography variant="h6" className="noProjectError">
              {t("RAL0400")}
            </Typography>
          </Box>
        ) : (
          reports.map((r) => (
            <ProjectReportCard
              project={r}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          ))
        )}
      </div>
    </ContentLayout>
  );
}
