import React, { useContext } from "react";
import { TimesheetContext } from "../../util/context";

export default function WeekSummary() {
  const { textSummary } = useContext(TimesheetContext);
  const text =
    "Monday:\n- Interacted with Project Name: Increase ZKTeco' sales by 10%\n- Discussed coding\n- Project ID: R302\n- Duration: 12 minutes\n- Application Name: tesz\n- Time stamp: 10:14AM - 10:26AM\n\nWednesday:\n- Interacted with Project Name: Increase ZKTeco' sales by 10%\n- Discussed project\n- Project ID: R302\n- Duration: 1 minute\n- Application Name: ralvie-client\n- Time stamp: 9:45AM - 9:47AM\n\nThursday:\n- Interacted with Project Name: ACMS\n- Discussed project\n- Project ID: R310\n- Duration: 5 minutes\n- Application Name: Microsoft Teams\n- Time stamp: 9:35AM - 9:40AM";
  let summary = text.replace(/\n/g, "<br>").replace(/- /g, "• ");
  const headers = [
    "Monday:",
    "Tuesday:",
    "Wednesday:",
    "Thursday:",
    "Friday:",
    "Saturday:",
    "Sunday:",
  ];
  headers.forEach((header) => {
    summary = summary.replace(header, `<b>${header}</b>`);
  });
  return (
    <div>
      <span className="projectReportHeader">This Week Summary</span>

      <div dangerouslySetInnerHTML={{ __html: summary }} />
    </div>
  );
}
