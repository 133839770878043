import React, { useContext } from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppContext from "../../config/AppContext";

export default function ArchiveDialog(props) {
  const { open, onClose, onConfirm, name, type, actionType } = props;
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);
  const getConfirmationMessage = () => {
    if (actionType === "complete") {
      return `${t("Are you sure you want to mark")} ${name} ${t(
        "as completed"
      )}?`;
    }
    return `${t("Are you sure you want to ")} ${
      name === "Timesheet" ? "delete" : "archive"
    } ${name}?`;
  };

  const getHeader = () => {
    if (actionType === "complete") {
      return "Complete project";
    }

    switch (type) {
      case "Project":
        return "Archive project";
      case "Member":
        return "Archive member";
      case "Tag":
        return "Archive tag";
      case "Department":
        return "Archive department";
      case "Designation":
        return "Archive position";
      case "Timesheet":
        return "Delete timesheet";
      default:
        return "Archive item";
    }
  };

  return (
    <CustomDialogBox
      className="CancelDialog"
      Header={getHeader()}
      acceptText={t("RAL0300")}
      cancelText={
        actionType === "complete"
          ? t("RAL0372")
          : actionType === "delete"
          ? t("RAL0391")
          : t("RAL0368")
      }
      fullWidth={true}
      onClose={onClose}
      onSubmit={onConfirm}
      open={open}
    >
      <Grid
        container
        justifyContent="center"
        direction="column"
        style={{ padding: "1.875rem", paddingTop: "0" }}
      >
        <Grid>
          <Grid
            style={{
              wordWrap: "break-word",
              opacity: 1,
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              fontSize: "17px",
            }}
          >
            <Typography
              className="dialogInfo"
              sx={{
                color:
                  mode === "light"
                    ? "#242424cc!important"
                    : "#f8f8f880!important",
              }}
            >
              {getConfirmationMessage()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CustomDialogBox>
  );
}
