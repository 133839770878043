import React, { useCallback, useContext, useEffect, useState } from "react";
import ContentLayout from "../../components/layout-components/ContentLayout";
import CustomIconButton from "../../components/CustomIconButton";
import {
  Box,
  InputBase,
  Tooltip,
  useMediaQuery,
  styled,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CustomDataTable from "../../components/CustomDataTable";
import CommonUtil from "../../util/CommonUtils";
import {
  batchMembersArchive,
  batchMembersUnarchive,
  downloadUserImportTemplate,
  importMembers,
  softDeleteMember,
  userList,
} from "../../services/MemberService";
import AddTeamLeadsToUsers from "./AddTeamLeadsToUsers";
import { ReactComponent as TableViewListIcon } from "../../assets/images/table-list-view.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/images/archive-icon.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/archive-icon-dark.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/EditIcon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import { ReactComponent as AddIcon } from "../../assets/images/ralvie/add-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-icon.svg";
import { useTranslation } from "react-i18next";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ArchiveDialog from "../project/ArchiveDialog";
import { toast } from "sonner";
import CustomSearchField from "../../components/CustomSearchField";
import ImportDialog from "../../components/ImportDialog";
import MemberFilter from "./MemberFilter";
import UnarchiveDialog from "../project/UnarchiveDialog";
import AppContext from "../../config/AppContext";

function MembersListView() {
  const { mode } = useContext(AppContext);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const role = CommonUtil.getRoleCode();

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openClose, setopenClose] = useState(false);

  const getRowClassName = () => {
    return selectedRows.length > 0 ? "selectedCheckboxVisible" : "";
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: 4,
      position: "relative",
      fontSize: 16,
      padding: isXlScreen ? "12px 26px 12px 12px" : "9px 26px 7px 12px",
      backgroundColor: "transparent !important",
      opacity: 1,
      font: "normal normal normal 16px/20px Gotham",
      color: "#242424",
    },
  }));

  const [filter, setFilter] = useState({
    status: "",
    name: "",
    email: "",
    toggleKeyToURL: true,
    departmentIds: [],
    designationIds: [],
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setopenClose(false);
  };

  useEffect(() => {
    loadMembersData(page, rowsPerPage);
  }, [page, rowsPerPage, filter]);

  const loadMembersData = (pageNumber, pageSize) => {
    setIsLoading(true);
    const departmentIds = filter?.departmentIds || [];
    const designationIds = filter?.designationIds || [];
    const status = filter?.status || "";
    const name = filter?.name || "";
    const email = filter?.email || "";
    const toggleKeyToURL = filter?.toggleKeyToURL || "";

    const validDepartmentIds = departmentIds.includes("All departments")
      ? []
      : departmentIds.filter((id) => id !== "All departments");
    const validDesignationIds = designationIds.includes("All positions")
      ? []
      : designationIds.filter((id) => id !== "All positions");

    const requestData = {
      status,
      name,
      email,
      toggleKeyToURL,
      department: validDepartmentIds,
      designation: validDesignationIds,
    };

    userList(requestData, pageNumber, pageSize)
      .then((res) => {
        if (res?.status === 200 && res?.data?.code === "UASI0000") {
          let users = res.data.data.users;
          setRows(res?.data?.data?.users);
          setTotalRecords(res?.data?.data?.totalCount);
          setPage(res?.data?.data?.currentPage);
        } else {
          setRows([]);
          setTotalRecords(0);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
        setRows([]);
        setTotalRecords(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSwitch = () => {
    navigate("/members");
  };

  const handleApplyFilters = (filters) => {
    const { departments, designations, status, refresherStatus } = filters;
    const { status: filterStatus, name, email, toggleKeyToURL } = status;
    const departmentIds = departments;
    const designationIds = designations;
    setRefresh(refresherStatus);

    setFilter((prevFilter) => ({
      ...prevFilter,
      status: filterStatus,
      name,
      email,
      toggleKeyToURL,
      departmentIds,
      designationIds,
    }));
  };

  const resetFilter = () => {
    setFilter({
      status: "",
      name: "",
      email: "",
      toggleKeyToURL: true,
      departmentIds: ["All departments"],
      designationIds: ["All positions"],
    });
    setRefresh(true);
    setClearSearch(true);
  };

  const handleAddTeamLeadsToUsers = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleArchiveMember = (id) => {
    setSelectedRows([id]);
    setSelectedMemberId(id);
    setOpenArchiveDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleUnarchiveMembers = (id) => {
    setSelectedRows([id]);
    setSelectedMemberId(id);
    setOpenUnarchiveDialog(true);
  };

  const handleCloseUnarchiveDialog = () => {
    setOpenUnarchiveDialog(false);
  };

  const handleEditMembers = useCallback(
    (id, view) => {
      if (id) {
        navigate(`/members/${id}`, { state: { fromView: view } });
        handleClose();
      }
    },
    [navigate]
  );

  // const handleImport = (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   setIsLoading(true);
  //   importMembers(formData).then((res) => {
  //     setIsLoading(false);
  //     if (res.data?.code === "RCI0000") toast.success(res.data.message);
  //     else toast.error(res.data.message);
  //     resetFilter();
  //   });
  // };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const selectedUserDetails = rows.filter((user) =>
    selectedRows.includes(user.id)
  );

  const actions = (
    <Box display="flex" alignItems="center">
      {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
        <>
          {selectedUserDetails.length > 0 && (
            <>
              {selectedUserDetails[0]?.status === "ARCHIVED" ? (
                <Box pr={2}>
                  <Box
                    sx={{
                      "& .unarchive_svg": {
                        transition: "color 0.3s ease",
                        color:
                          mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                      },
                      "&:hover": {
                        "& .hover-text, & .unarchive_svg": {
                          color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                        },
                      },
                    }}
                  >
                    <CustomIconButton
                      id="btn-unarchive-project"
                      title={"Unarchive"}
                      size={isXlScreen ? "large" : "md"}
                      variant="square"
                      lightMode={
                        <ArchiveIcon
                          className="unarchive_svg"
                          height={isXlScreen ? "18px" : "14px"}
                        />
                      }
                      darkMode={
                        <ArchiveIcon
                          className="unarchive_svg"
                          height={isXlScreen ? "18px" : "14px"}
                        />
                      }
                      onClick={() => setOpenUnarchiveDialog(true)}
                    />
                  </Box>
                </Box>
              ) : (
                <>
                  {/* <Box item pr={2}>
                <CustomIconButton
                  id="btn-switch-project"
                  title={"Add TeamLeads"}
                  size={isXlScreen ? "large" : "md"}
                  variant="square"
                  lightMode={<Avatar height={isXlScreen ? "18px" : "14px"} />}
                  darkMode={<Avatar height={isXlScreen ? "18px" : "14px"} />}
                  onClick={handleAddTeamLeadsToUsers}
                />
              </Box> */}

                  <Box pr={2}>
                    <Box
                      sx={{
                        "& .archive_svg": {
                          transition: "color 0.3s ease",
                          color:
                            mode === "dark"
                              ? "var(--color-33-50)"
                              : "#242424cc",
                        },
                        "&:hover": {
                          "& .hover-text, & .archive_svg": {
                            color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                          },
                        },
                      }}
                    >
                      <CustomIconButton
                        id="btn-archive-member"
                        title={"Archive"}
                        size={isXlScreen ? "large" : "md"}
                        variant="square"
                        lightMode={
                          <ArchiveIcon
                            className="archive_svg"
                            height={isXlScreen ? "18px" : "14px"}
                          />
                        }
                        darkMode={
                          <ArchiveIcon
                            className="archive_svg"
                            height={isXlScreen ? "18px" : "14px"}
                          />
                        }
                        onClick={() => setOpenArchiveDialog(true)}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}

      {!selectedRows.length && (
        <>
          <Box item pr={2}>
            <CustomSearchField
              id="btn-member-search"
              name="btn-member-search"
              clearSearch={clearSearch}
              // onChange={() => setClearSearch(false)}
              onChange={(value) => setFilter({ ...filter, name: value })}
              label={t("RAL0223")}
              value={filter.name}
              size={isXlScreen ? "large" : "md"}
              onClick={(value) => {
                setClearSearch(false);
                setFilter({ ...filter, name: value });
              }}
              onClear={(value) => {
                setClearSearch(true);
                setFilter({ ...filter, name: value });
              }}
            />
          </Box>

          <Box item pr={2}>
            <Box
              sx={{
                "& .filter_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .filter_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <CustomIconButton
                id="btn-filter-project"
                title={"Filter"}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                lightMode={
                  <FilterIcon
                    className="filter_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                darkMode={
                  <FilterIcon
                    className="filter_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                onClick={handleFilterClick}
              />
            </Box>

            <MemberFilter
              title="Filter Title"
              open={openFilter}
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              filter={filter}
              MemberReset={resetFilter}
              BootstrapInput={BootstrapInput}
              onApplyFilters={handleApplyFilters}
              refresh={refresh}
            />
          </Box>

          {/* <Box item pr={2}>
            <ImportDialog
              title={t("RAL0171")}
              downloadTemplate={() => downloadUserImportTemplate()}
              onImport={(file) => handleImport(file)}
            />
          </Box> */}

          <Box item pr={2}>
            <Box
              sx={{
                "& .refresh_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .refresh_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <CustomIconButton
                id="btn-refresh-project"
                title={t("RAL0169")}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                lightMode={
                  <RefreshIcon
                    className="refresh_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                darkMode={
                  <RefreshIcon
                    className="refresh_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                onClick={() => resetFilter()}
              />
            </Box>
          </Box>

          <Box item pr={2}>
            <Box
              sx={{
                "& .table_view_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .table_view_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <CustomIconButton
                id="btn-switch-project"
                title={t("RAL0266")}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                lightMode={
                  <TableViewListIcon
                    className="table_view_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                darkMode={
                  <TableViewListIcon
                    className="table_view_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                onClick={handleSwitch}
              />
            </Box>
          </Box>

          {(role === "OWNER" || role === "ADMIN") && (
            <CustomIconButton
              id="btn-custom-add-member"
              name="btn-custom-add-member"
              title={t("RAL0226")}
              size={isXlScreen ? "large" : "md"}
              variant="square"
              type="contained"
              border={"none"}
              lightMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
              darkMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
              onClick={(view) =>
                navigate("/members/new", { state: { fromView: "list" } })
              }
            />
          )}
        </>
      )}
    </Box>
  );

  const columns = React.useMemo(() => [
    {
      field: "memberName",
      headerName: "Member Name",
      flex: 1,
      width: 10,

      renderCell: (params) => (
        <Tooltip
          title={params.row.memberName}
          placement="bottom-start"
          disableInteractive
        >
          <span style={{ textTransform: "capitalize" }}>
            {params.row.memberName}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={params.row.email}
          placement="bottom-start"
          disableInteractive
        >
          <span className="text-lowercase">{params.row.email}</span>
        </Tooltip>
      ),
    },
    {
      field: "roleName",
      headerName: "Role",
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={params.row.roleName}
          placement="bottom-start"
          disableInteractive
        >
          <span>{params.row.roleName}</span>
        </Tooltip>
      ),
    },
    {
      field: "designationName",
      headerName: "Designation",
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={params.row.designationName}
          placement="bottom-start"
          disableInteractive
        >
          <span>{params.row.designationName}</span>
        </Tooltip>
      ),
    },
    {
      field: "departmentName",
      headerName: "Department",
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={params.row.departmentName}
          placement="bottom-start"
          disableInteractive
        >
          <span>{params.row.departmentName}</span>
        </Tooltip>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 10,
      renderCell: (params) => {
        const statusClass =
          params.row.status === "ACTIVE"
            ? "active-member"
            : params.row.status === "INACTIVE"
            ? "inactive-member"
            : params.row.status === "ARCHIVED"
            ? "archived-member"
            : "";

        return (
          <Tooltip
            title={params.row.status}
            placement="bottom-start"
            disableInteractive
          >
          <span className={statusClass}
            style={{
              display: "inline-block",
              width: "80px",
              minWidth: "80px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              textAlign: "center",
            }}
          >
          {params.row.status === "ACTIVE" ? "Active" : params.row.status === "INACTIVE" ? "Inactive" : "Archived"}
        </span>
          </Tooltip>
        );
      },
    },

    {
      field: "weeklyWorkCapacity",
      headerName: "Work hour capacity",
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={`${params.row.weeklyWorkCapacity} hr/wk`}
          placement="bottom-start"
          disableInteractive
        >
          <span>{params.row.weeklyWorkCapacity} hr/wk</span>
        </Tooltip>
      ),
    },

    role !== "EMPLOYEE" &&
      role !== "CLIENT" &&
      role !== "TEAM LEAD" && {
        headerName: "Action",
        type: "actions",
        flex: 1,
        width: 10,
        renderCell: (params) => (
          <>
            {params.row.status === "ARCHIVED" ? (
              <Tooltip title="Unarchive" arrow>
                <GridActionsCellItem
                  icon={
                    mode === "dark" ? (
                      <ArchiveIconDark className="icon" />
                    ) : (
                      <ArchiveIcon className="icon" />
                    )
                  }
                  label="Unarchive"
                  onClick={() => handleUnarchiveMembers(params.row.id)}
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" arrow>
                  <GridActionsCellItem
                    icon={
                      mode === "dark" ? (
                        <EditIconDark className="icon" />
                      ) : (
                        <EditIcon className="icon" />
                      )
                    }
                    label="Edit"
                    style={{ marginRight: "8px" }}
                    onClick={() => handleEditMembers(params.row.id, "list")}
                  />
                </Tooltip>

                {params.row.roleName !== "Owner" && (
                  <Tooltip title="Archive" arrow>
                    <GridActionsCellItem
                      icon={
                        mode === "dark" ? (
                          <ArchiveIconDark className="icon" />
                        ) : (
                          <ArchiveIcon className="icon" />
                        )
                      }
                      label="Archive"
                      onClick={() => handleArchiveMember(params.row.id)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </>
        ),
      },
  ]);

  const handleRowSelection = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleConfirmArchive = async () => {
    try {
      let memberIds =
        selectedRows.length > 0 ? selectedRows : [selectedMemberId];
      if (memberIds.length === 1) {
        const response = await softDeleteMember(memberIds[0]);
        if (response.data?.code === "RCI0000") {
          loadMembersData(page, rowsPerPage);
          toast.success("Member archived successfully");
          setSelectedRows([]);
          handleCloseDialog();
        } else {
          toast.error(response.data?.message);
        }
      } else if (memberIds.length > 1) {
        const payload = { ids: memberIds };
        const res = await batchMembersArchive(payload);
        if (res.data?.code === "RCI0000") {
          loadMembersData(page, rowsPerPage);
          toast.success("Members archived successfully");
          setSelectedRows([]);
          handleCloseDialog();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error("No Members selected.");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while archiving members.");
    }
  };

  const handleConfirmUnrchive = async () => {
    try {
      let memberIds =
        selectedRows.length > 0 ? selectedRows : [selectedMemberId];
      if (memberIds.length >= 1) {
        const payload = { ids: memberIds };
        const res = await batchMembersUnarchive(payload);
        if (res.data?.code === "RCI0000") {
          setSelectedRows([]);
          loadMembersData(page, rowsPerPage);
          toast.success("Members unarchived successfully");
          handleCloseUnarchiveDialog();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error("No members selected.");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while archiving projects.");
    }
  };

  return (
    <>
      <div>
        <ContentLayout
          title="Members"
          rowtitle={
            selectedRows.length > 0 ? (
              <strong className="selected-projects">
                {selectedRows.length} member
                {selectedRows.length > 1 ? "s" : ""} selected
              </strong>
            ) : null
          }
          actions={actions}
          isLoading={isLoading}
        >
          <AddTeamLeadsToUsers
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />

          <CustomDataTable
            page={page - 1}
            totalRecords={totalRecords}
            rowsPerPage={rowsPerPage}
            columns={columns}
            rows={rows}
            checkboxSelection={true}
            onRowSelectionModelChange={handleRowSelection}
            getRowClassName={getRowClassName}
            onPageChange={(e) => {
              const CurrentPage = CommonUtil.getPageNumber(e);
              setPage(CurrentPage);
            }}
            onPageSizeChange={(e) => {
              setRowsPerPage(e);
              setPage(1);
            }}
          />

          <ArchiveDialog
            open={openArchiveDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmArchive}
            name="member"
            type="Member"
          />

          <UnarchiveDialog
            open={openUnarchiveDialog}
            onClose={handleCloseUnarchiveDialog}
            onConfirm={handleConfirmUnrchive}
            name="member"
            type="Member"
          />
        </ContentLayout>
      </div>
    </>
  );
}

export default MembersListView;
