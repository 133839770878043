import { createContext } from "react";

export const TimesheetContext = createContext({});

export const AiContext = createContext({});

export const sidebarContext = createContext({});

export const UserContext = createContext({});

export const quickActionContext = createContext({});
