import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import Tooltip from "@mui/material/Tooltip";
import AppContext from "../../config/AppContext";

export default function CategoryDoughnut({
  mode,
  data,
  grandTotal,
  selectedCategories,
}) {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [currentGrandTotal, setCurrentGrandTotal] = useState({
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    if (chartRef.current) {
      chartInstanceRef.current = echarts.init(chartRef.current);
    }

    const createGradient = (color) => {
      const hexColor = color.startsWith("#")
        ? color.substring(0, 7)
        : "#000000";
      return {
        type: "linear",
        x: 0,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [
          { offset: 0, color: `${hexColor}B3` },
          { offset: 0.5, color: `${hexColor}80` },
          { offset: 1, color: hexColor },
        ],
        global: false,
      };
    };

    // Filter data based on selectedCategories
    const filteredData = data.filter((item) => selectedCategories.includes(item.categoryName));

    // Calculate total hours and minutes based on filtered data
    let totalDuration = 0;
    filteredData.forEach((item) => {
      totalDuration += item.totalDuration;
    });
    const totalHours = Math.floor(totalDuration / 3600);
    const totalSeconds = totalDuration % 3600;
    const totalMinutes = Math.round(totalSeconds / 60);

    // Update current grand total state
    setCurrentGrandTotal({ hours: totalHours, minutes: totalMinutes });

    const formattedData = filteredData.map((item) => ({
      value: item.totalDuration.toFixed(2),
      name: item.categoryName,
      totalHours: item.totalHours,
      totalMinutes: item.totalMinutes,
      itemStyle: {
        color: createGradient(item.color),
      },
    }));

    // Format total time string with two digits for hours and minutes
    const formattedTotalHours = String(totalHours).padStart(2, "0");
    const formattedTotalMinutes = String(totalMinutes).padStart(2, "0");
    const formattedTime = `${formattedTotalHours}h ${formattedTotalMinutes}m`;
    const titleText = `Total hrs: ${formattedTime}`;
    const labelColor = mode === "light" ? "#242424" : "#f8f8f880";
    const legendTextColor = mode === "light" ? "#3F3F3F" : "#f8f8f880";
    const option = {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let { totalHours, totalMinutes } = params.data;
          if (Number(totalHours) === 0 && Number(totalMinutes) === 0) {
            totalMinutes = 1;
          }
          const formattedHours = String(totalHours).padStart(2, "0");
          const formattedMinutes = String(totalMinutes).padStart(2, "0");
          const formattedTime = `${formattedHours}h ${formattedMinutes}m`;
          return `${formattedTime}`;
        },
      },
      legend: {
        orient: "vertical",
        right: '10%',
        top: "middle",
        selectedMode: false,
        formatter: function (name) {
          return name;
        },
        textStyle: {
          color: legendTextColor,
        },
        data: formattedData.map((item) => item.name),
        itemWidth: 20,
        itemHeight: 20,
        itemGap: 15,
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          center: ["30%", "50%"],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: "center",
            formatter: function () {
              return `{a|Total hrs}\n{b|${formattedTime}}`;
            },
            rich: {
              a: {
                fontSize: 12,
                lineHeight: 20,
                fontWeight: "bold",
                color: labelColor,
              },
              b: {
                fontSize: 16,
                lineHeight: 20,
                fontWeight: "bold",
                color: labelColor,
              },
            },
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 16,
              fontWeight: "bold",
              formatter: function () {
                return `{a|Total hrs}\n{b|${formattedTime}}`;
              },
              rich: {
                a: {
                  fontSize: 12,
                  lineHeight: 20,
                  fontWeight: "bold",
                  color: labelColor,
                },
                b: {
                  fontSize: 16,
                  lineHeight: 20,
                  fontWeight: "bold",
                  color: labelColor,
                },
              },
            },
          },
          labelLine: {
            show: false,
          },
          data: formattedData,
        },
      ],
    };

    chartInstanceRef.current.setOption(option);

    const handleResize = () => {
      chartInstanceRef.current && chartInstanceRef.current.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (chartInstanceRef.current) {
        chartInstanceRef.current.dispose();
        chartInstanceRef.current = null;
      }
    };
  }, [data, grandTotal, selectedCategories, mode]);

  return (
    <Tooltip>
      <div ref={chartRef} style={{ width: "100%", height: "250px", marginBottom: '70px' }} />
    </Tooltip>
  );
}
