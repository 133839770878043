import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import React from "react";
// Example Base64 encoded data URL of your SVG icon
// Replace this with your actual Base64 encoded data URL
const tickIconDataURL =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI3LjM1NCIgdmlld0JveD0iMCAwIDEwIDcuMzU0Ij4KICA8cGF0aCBpZD0iU3VidHJhY3Rpb25fMjQ1IiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDI0NSIgZD0iTS0xMjk3LjgyMSwxNDIyNC44NTRhMS4wMjcsMS4wMjcsMCwwLDEtLjcyOS0uM2wtMi42NDgtMi42NDZhMS4wMTEsMS4wMTEsMCwwLDEtLjMtLjcyNSwxLjA0MiwxLjA0MiwwLDAsMSwuMy0uNzMsMS4wMjIsMS4wMjIsMCwwLDEsLjczLS4zLDEuMDIyLDEuMDIyLDAsMCwxLC43MjkuM2wxLjkxOSwxLjkyLDQuNTY0LTQuNTY2YTEuMDEzLDEuMDEzLDAsMCwxLC43MjctLjMsMS4wMjgsMS4wMjgsMCwwLDEsLjcyOS4zLDEuMDMsMS4wMywwLDAsMSwuMy43MywxLjAxLDEuMDEsMCwwLDEtLjMuNzI1bC01LjI5NCw1LjI5M0ExLjAyMywxLjAyMywwLDAsMS0xMjk3LjgyMSwxNDIyNC44NTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzAxLjUgLTE0MjE3LjUpIiBmaWxsPSIjZmE5YzJiIi8+Cjwvc3ZnPgo=";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  margin: 1,
  display: "flex",
  alignItems: 'center',
  "& .MuiSwitch-thumb": {
    // top: 1.5,
    // left: 2,
    width: 14,
    height: 14,
    borderRadius: "50%",
    boxShadow: "0 2px 4px 0 rgba(0, 35, 11, 0.2)",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
    position: "relative",
    backgroundColor: "#A2A4A6", // Ensure the thumb is white
    "&::before": {
      content: '""',
      position: "absolute",
      top: "43% !important",
      left: "50%",
      transform: "translate(-50%, -50%) scale(0)", // Start scaled down to 0
      transition: "transform 0.3s ease-in-out",
      width: "100%",
      height: "100%",
      backgroundSize: "50% 50%", // Adjust the size of the icon
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    border: "1px solid #A2A4A6",
    borderRadius: 44 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "var(--color-47)" : "#FFFFFF",
    boxSizing: "border-box",
  },
  "& .MuiSwitch-switchBase": {
    // padding: 3.7,
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    left: '5px',
    top: 'auto',
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        border: "none",
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#FA9C2B" : "#FA9C2B",
      },
      "& .MuiSwitch-thumb": {
        top: 0,
        left: -2,
        width: 18,
        height: 18,
        backgroundColor: theme.palette.mode === "dark" ? "#010101" : "#ffffff",
      },
      "& .MuiSwitch-thumb::before": {
        // Scale up the icon to its full size when checked
        transform: "translate(-30%, -65%) scale(1)",
        content: `url('${tickIconDataURL}')`, // Use the Base64 encoded SVG as the content
      },
    },
  },
}));
const CustomSwitch = (props) => <AntSwitch {...props} id="btn-custom-switch" />;
export default CustomSwitch;
