import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import HomeLayout from "../../components/layout-components/HomeLayout";
import TitleBar from "../../components/layout-components/TitleBar";

export default function ContentLayout(props) {
  return (
    <Grid container className="content-layout" alignItems={"start"}>
      <Grid item xs={12} md={12}>
        <TitleBar {...props}>{props.actions}</TitleBar>
        {
          props.description && <Typography className="description">{props.description}</Typography>
        }
      </Grid>
      <Grid
        container
        item
        className={props.type === "form" ? props.place === "tags" ? "content-body-tags" : "content-body" : "content-list"}
        sx={{
          backgroundColor: props.bg ? props.bg : "var(--background-color-3)",
        }} //transparent
      >
        {props.type === "form" && (
          <Grid container height={"fit-content"}>
            {props.children}
          </Grid>
        )}
        {props.type !== "form" && props.children}
      </Grid>
      <Box
        sx={{
          display: props.isLoading ? "flex" : "none",
        }}
      >
        <CircularProgress
          style={{ top: "50%", left: "50%", position: "fixed" }}
        />
      </Box>
    </Grid>
  );
}
