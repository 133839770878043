import React, { useEffect } from "react";
import { toast } from "sonner";
import { gitlabUserIntegration } from "../../services/IntegrationService";

export default function GitlabIntegration() {
  useEffect(() => {
    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get("code");
    gitlabUserIntegration(code).then((res) => {
      toast.success("Gitlab integrated successfully");
      setTimeout(() => {
        window.location.href = "/integration";
      }, 1000);
    });
  }, []);
  return <div></div>;
}
