import { css } from "@emotion/css";
import {
  Box,
  Dialog,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import CustomButton from "../../components/CustomButton";
import { loginWithBiotime } from "../../services/IntegrationService";
import CommonUtil from "../../util/CommonUtils";
import AppContext from "../../config/AppContext";
import CustomTextFieldPopup from "../../components/CustomTextFieldPopup";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";

import { ReactComponent as VisibilityIconDark } from "../../assets/images/icon_viewoff_dark.svg";
import { ReactComponent as VisibilityOffIconDark } from "../../assets/images/icon_viewon_dark.svg";

export default function BiotimeLogin(props) {
  const { mode } = useContext(AppContext);

  const [visibility, setVisibility] = useState(true);
  const { t } = useTranslation();
  const [error, setError] = useState({
    server: "",
    email: "",
    password: "",
    company: "",
    other: "",
  });
  const [payload, setPayload] = useState({
    server: "https://biotime.mx",
    email: "",
    password: "",
    company: "",
  });
  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      other: "",
      [e.target.name]: "",
    });
  };
  const handleSubmit = () => {
    if (!payload.server) {
      setError({
        ...error,
        server: t("RAL0001"),
      });
      toast.error("Select region");
      return;
    }
    if (!payload.email) {
      setError({
        ...error,
        email: t("RAL0001"),
      });
      return;
    }
    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      return;
    }
    if (!payload.company) {
      setError({
        ...error,
        company: t("RAL0001"),
      });
      return;
    }
    loginWithBiotime(payload).then((res) => {
      if (res.data.code === "RCI0000" && res.data.data && res.data.data.token) {
        toast.success("Biotime integrated successfully");
        handleClose();
      } else if (res.data.code === "RCE0194") {
        setError({ ...error, other: res.data.data });
      }
    });
  };
  const handleClose = () => {
    setPayload({
      email: "",
      password: "",
      company: "",
    });
    setError({
      email: "",
      password: "",
      company: "",
      other: "",
    });
    props.onClose();
  };

  return (
    <Dialog open={props.open}>
      <div
        className={css`
          background: ${mode == "dark" ? "#101010" : "#ffffff"} 0% 0% no-repeat
            padding-box;
          border-radius: 5px;
          opacity: 1;
          min-height: 30vh;
          min-width: 60vh;
          padding: 24px;
        `}
      >
        <Box
          pt={1}
          pb={3}
          sx={{
            display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            // height: "3.125rem",
          }}
        >
          <Box item flexGrow={1}>
            <Typography
              className={css`
                font: normal normal bold 20px/23px GothamBold !important;
                letter-spacing: 0px;
                color: ${mode == "dark" ? "#f8f8f8cc" : "#242424"} !important;
              `}
            >
              Sign in with Biotime Cloud
            </Typography>
          </Box>
          <IconButton
            id={props.id}
            onClick={handleClose}
            sx={{
              border:
                mode === "light" ? "1px solid #FFFFFF" : "1px solid #313131",
              borderRadius: "5px",
              color: (theme) => theme.palette.grey[500],
              // height: "3.125rem",
              // width: "3.125rem",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box pb={2}>
          <Typography className="textfield-label" pb={1}>
            Region
          </Typography>
          <div>
            <FormControl fullWidth>
              <Select
                displayEmpty
                id="dropdown-region-integration"
                name="dropdown-region-integration"
                value={payload.server}
                onChange={(e) =>
                  setPayload({ ...payload, server: e.target.value })
                }
                renderValue={payload.server ? undefined : () => "Select region"}
                input={<OutlinedInput />}
                inputProps={{
                  "aria-label": "Without label",
                  placeholder: "Select region",
                }}
                sx={{
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                  background:
                    mode === "light"
                      ? "var(--color-42) 0% 0% no-repeat padding-box"
                      : "var(--color-10-50) 0% 0% no-repeat padding-box",
                  // background:
                  //   "var(--background-color-5) 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  // height: "60px",
                  "& .MuiSelect-select": {
                    color: mode == "dark" ? "#f8f8f880" : "#24242480",
                  },
                }}
              >
                <MenuItem
                  id="select-region"
                  name="select-region"
                  key={0}
                  value={"https://biotime.mx"} // Assuming `id` is a unique identifier for each member
                  sx={{
                    font: "normal normal normal 16px/20px Gotham",
                    backgroundColor:
                      mode === "dark"
                        ? "#1F1F1F!important"
                        : "#FFFFFF!important",
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                    "&:hover": {
                      backgroundColor: "#1F1F1F",
                    },
                    "&.Mui-selected, &.Mui-selected:hover": {
                      backgroundColor:
                        mode === "dark"
                          ? "#362C20!important"
                          : "#FFF9F2!important",
                      color: "#FA9C2B",
                    },
                  }}
                >
                  {"Mexico"} {/* Display member's name */}
                </MenuItem>

                <MenuItem
                  id="select-region"
                  name="select-region"
                  key={1}
                  value={"https://api.biotimecloud.info"} // Assuming `id` is a unique identifier for each member
                  sx={{
                    font: "normal normal normal 16px/20px Gotham",
                    backgroundColor:
                      mode === "dark"
                        ? "#1F1F1F!important"
                        : "#FFFFFF!important",
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                    "&:hover": {
                      backgroundColor: "#1F1F1F",
                    },
                    "&.Mui-selected, &.Mui-selected:hover": {
                      backgroundColor:
                        mode === "dark"
                          ? "#362C20!important"
                          : "#FFF9F2!important",
                      color: "#FA9C2B",
                    },
                  }}
                >
                  {"Biotime 2.0"} {/* Display member's name */}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
        <Box pb={2}>
          <CustomTextFieldPopup
            required={true}
            id="text-email"
            name="email"
            label="Email"
            placeholder="Enter Email"
            error={!CommonUtil.isEmptyString(error.email)}
            helperText={error.email}
            value={payload.email}
            onChange={(e) => handleChange(e)}
          ></CustomTextFieldPopup>
        </Box>

        <Box pb={2}>
          <CustomTextFieldPopup
            type={visibility ? "password" : "text"}
            id="text-password"
            required={true}
            name="password"
            label="Password"
            error={!CommonUtil.isEmptyString(error.password)}
            helperText={error.password}
            value={payload.password}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <div
                  class="tooltipContainer"
                  style={{
                    position: "absolute",
                    right: 15,
                  }}
                >
                  <Box
                    sx={{
                      "& .OffIcon_svg": {
                        transition: "color 0.3s ease",
                        color:
                          mode === "dark"
                            ? "var(--color-33-50)"
                            : "var(--color-43-50)",
                      },
                      "& .OnIcon_svg": {
                        transition: "color 0.3s ease",
                        color:
                          mode === "dark"
                            ? "var(--color-33)"
                            : "var(--color-43)",
                      },
                      "&:hover": {
                        "& .OffIcon_svg": {
                          color:
                            mode === "dark"
                              ? "var(--color-33-80)"
                              : "var(--color-43-50)",
                        },
                        "& .OnIcon_svg": {
                          color:
                            mode === "dark"
                              ? "var(--color-33)"
                              : "var(--color-43)",
                        },
                      },
                    }}
                  >
                    <span class="tooltipText">
                      {visibility ? "View password" : "Hide password"}
                    </span>
                    {visibility ? (
                      <VisibilityOffIconDark
                        id="icon_hide_password"
                        className="OffIcon_svg"
                        style={{
                          cursor: "pointer",
                          height: "17px",
                          width: "22px",
                        }}
                        onClick={() => {
                          setVisibility(!visibility);
                        }}
                      />
                    ) : (
                      <VisibilityIconDark
                        id="icon_view_password"
                        className="OnIcon_svg"
                        style={{
                          cursor: "pointer",
                          height: "17px",
                          width: "22px",
                        }}
                        onClick={() => {
                          setVisibility(!visibility);
                        }}
                      />
                    )}
                  </Box>
                </div>
              ),
            }}
          ></CustomTextFieldPopup>
        </Box>

        <Box pb={2}>
          <CustomTextFieldPopup
            id="text-company"
            required={true}
            name="company"
            label="Company"
            error={!CommonUtil.isEmptyString(error.company)}
            helperText={error.company}
            value={payload.company}
            onChange={(e) => handleChange(e)}
          ></CustomTextFieldPopup>
        </Box>

        {error?.other && (
          <Box display={"flex"} alignItems={"center"} pb={2}>
            <Box item pr={1}>
              {<ErrorIcon />}
            </Box>
            <Box item>
              <Typography className="helper-text">{error?.other}</Typography>
            </Box>
          </Box>
        )}

        <Box>
          <CustomButton
            id="btn-authenticate"
            variant="contained"
            size="large"
            fullWidth="fullWidth"
            height="60px"
            onClick={() => handleSubmit()}
          >
            <span>{"Authenticate"}</span>
          </CustomButton>
        </Box>
      </div>
    </Dialog>
  );
}
