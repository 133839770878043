import { css } from "@emotion/css";
import { Box, Divider, Popover, Typography } from "@mui/material";
import { bindHover, bindPopover } from "material-ui-popup-state";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { ReactComponent as MoreIconDark } from "../../assets/images/ralvie/more_icon_dark.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/ralvie/more-icon.svg";
import { ReactComponent as SundialLogo } from "../../assets/images/ralvie/sundial-logo-small.svg";
import { ReactComponent as ClockLight } from "../../assets/images/ralvie/clock-light.svg";
import { ReactComponent as ClockDark } from "../../assets/images/ralvie/clock-dark.svg";
import AppIcon from "../../components/AppIcon";
import OverFlowText from "../../components/OverFlowText";
import {
  getSystemSettings,
  updateEventHideStatus,
} from "../../services/EventService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import "./timesheet.scss";
import AppContext from "../../config/AppContext";

export default function ActivityListItem(props) {
  const { mode } = useContext(AppContext);
  const { setReload, eventsToLog, setEventsToLog } =
    useContext(TimesheetContext);
  const [showMore, setShowMore] = useState("none");
  const [loggedEvents, setLoggedEvents] = useState(0);
  const [hiddenEvents, setHiddenEvents] = useState(0);
  const [hoveredEventId, setHoveredEventId] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setLoggedEvents(getLoggedEventCount(props.event));
    setHiddenEvents(hiddenEventsCount(props.event));
  }, [props.event]);

  useEffect(() => {
    if (eventsToLog && eventsToLog.length < 1 && isSelected) {
      setIsSelected(false);
    } else {
      let eventToSelect = eventsToLog?.find((e) => e.id === props?.event?.id);
      if (eventToSelect) {
        setIsSelected(true);
      }
    }
  }, [eventsToLog]);

  const logEvent = (event) => {
    if (CommonUtil.isEmptyString(event?.projectId)) {
      let events = eventsToLog?.filter((e) => e.event_id === event.event_id);
      if (events?.length > 0) {
        events = eventsToLog?.filter((e) => e.event_id !== event.event_id);
        if (events?.length > 0) {
          setEventsToLog(events);
        } else {
          setEventsToLog([]);
        }
      } else {
        let updatedEvent = {
          ...event,
          events: [event],
        };
        const newArray = [...eventsToLog, updatedEvent];
        setEventsToLog(newArray);
      }
    }
  };

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const popupMore = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });

  const handleHideEvents = (hiddenStatus) => {
    let eventIds = [props.event?.id];
    let payload = {
      eventIds: eventIds,
      hiddenStatus: hiddenStatus,
    };

    updateEventHideStatus(payload).then((res) => {
      if (res.data?.code === "RCI0000") {
        toast.success(
          hiddenStatus
            ? "Events hidden successfully"
            : "Events unhidden successfully"
        );
        setReload(true);
      }
    });
  };

  function timeDifferenceInMins(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const minutesDifference = diffInMilliseconds / (1000 * 60); // 1 minute = 60 seconds = 60,000 milliseconds
    return minutesDifference;
  }

  const start = new Date(props.event.start).getTime();
  const end = new Date(props.event.end).getTime();
  // Calculate total duration of all events including sub-events
  const totalDurationMillis = end - start;

  // Convert milliseconds to hours, minutes, and seconds
  const totalDurationHours = Math.floor(totalDurationMillis / (1000 * 60 * 60));
  const totalDurationMinutes = Math.floor(
    (totalDurationMillis % (1000 * 60 * 60)) / (1000 * 60)
  );

  const totalDurationSeconds = Math.floor(
    (totalDurationMillis % (1000 * 60)) / 1000
  );

  const totalDurationFormatted = `${totalDurationHours}h ${totalDurationMinutes}m ${totalDurationSeconds}s`;

  let time =
    props?.event.start.toLocaleTimeString("en-US", {
      timeStyle: "short",
      hour12: Number(getSystemSettings("timeformat")) === 12,
    }) +
    " - " +
    props?.event.end.toLocaleTimeString("en-US", {
      timeStyle: "short",
      hour12: Number(getSystemSettings("timeformat")) === 12,
    });

  // Convert eventStart and eventEnd to milliseconds
  const startMillis = props.event.start.getTime();
  const endMillis = props.event.end.getTime();

  // Find the difference in milliseconds
  const durationInMillis = Math.abs(endMillis - startMillis);

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(durationInMillis / (1000 * 60 * 60));
  const minutes = Math.floor(
    (durationInMillis % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((durationInMillis % (1000 * 60)) / 1000);

  const totalDuration = `${hours}h ${minutes}m ${seconds}s`;

  let eventTime =
    props?.event.start.toLocaleTimeString("en-US", {
      hour12: Number(getSystemSettings("timeformat")) === 12,
    }) +
    " - " +
    props?.event.end.toLocaleTimeString("en-US", {
      hour12: Number(getSystemSettings("timeformat")) === 12,
    });

  // Concatenate titles from the events array

  let description = props.event.events?.map((e, index) => {
    const startTime = new Date(e.start).toLocaleTimeString("en-US", {
      timeStyle: "medium",
      hour12: Number(getSystemSettings("timeformat")) === 12,
    });
    const endTime = new Date(e.end).toLocaleTimeString("en-US", {
      timeStyle: "medium",
      hour12: Number(getSystemSettings("timeformat")) === 12,
    });

    const duration = CommonUtil.getDuration(e.duration);

    const eventDate = new Date(e.start);
    const day = eventDate.toLocaleDateString("en-US", { day: "2-digit" });
    const month = eventDate.toLocaleDateString("en-US", { month: "short" });
    const year = eventDate.toLocaleDateString("en-US", { year: "numeric" });
    const eventDateFormatted = `${day} ${month} ${year}`;

    return (
      <div
        key={e.id}
        style={{
          backgroundColor: e.projectId ? "#919191" : "white",
        }}
      >
        <Typography className="listDetails">
          <div style={{ display: "flex" }}>
            <span style={{ color: "#242424", paddingTop: "5px" }}>&#8226;</span>
            {props.event.events?.length >= 1 && (
              <>
                <span
                  style={{
                    marginLeft: "0.5em",
                    paddingTop: "5px",
                    flexGrow: "1",
                  }}
                >
                  <OverFlowText
                    variant="listDetails"
                    text={`${e.title.replace("*", "")} - ${eventDateFormatted}`}
                    maxLength={80}
                  >
                    {`${e.title.replace("*", "")} - ${eventDateFormatted}`}
                  </OverFlowText>
                </span>
                <Typography variant="text_10_10_1_1_m">
                  {duration?.hr +
                    "h " +
                    duration?.min +
                    "m " +
                    duration?.sec +
                    "s"}
                </Typography>
              </>
            )}
          </div>
          <Typography className="listEventTime">
            {startTime} - {endTime}
          </Typography>
        </Typography>
        {index !== props.event.events?.length - 1 && (
          <Box pt={1}>
            <Divider />
          </Box>
        )}
      </div>
    );
  });

  description = <div>{description}</div>;

  let title = props?.event?.data?.app?.split(".")[0];

  if (title.toLowerCase() === "afk") {
    title = "Idle time";
  }

  let showDetails =
    timeDifferenceInMins(
      new Date(props?.event.start),
      new Date(props?.event.end)
    ) >= (props.stepRef.current > 10 ? 6 : 3);

  // Ensure showDetails is true if events length is 5 or below
  if (props.event.events?.length <= 5) {
    showDetails = true;
  }

  if (props.stepRef.current === 1) {
    showDetails = true;
  }

  const getApplicationIcon = (data) => {
    return (
      <AppIcon
        data={data?.data}
        height={20}
        width={20}
        type="event"
        id={data?.event_id}
      />
    );
  };

  const getLoggedEventCount = (data) => {
    let loggedEvents = data?.events?.filter(
      (e) => !CommonUtil.isEmptyString(e.projectId)
    );

    return loggedEvents?.length;
  };

  const hiddenEventsCount = (data) => {
    let loggedEvents = data?.events?.filter((e) => e.hidden);
    return loggedEvents?.length;
  };

  return (
    <div
      onMouseOut={() => {
        setShowMore("none"); // Set setShowMore to "none" when mouse out
      }}
      onMouseOver={() => {
        setShowMore("inline"); // Set setShowMore to "inline" on mouse hover
      }}
      id="activity-calendar"
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: props.event.projectId
          ? mode == "dark"
            ? "#010101"
            : "#D9D9D9"
          : isSelected
          ? "#919191"
          : props.event.light,
        height: "100%",
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          width: "90%",
          height: "100%",
          borderLeft: `4px solid ${
            props.event.projectId ? "#4f4f4f" : props.event.dark
          }`,
        }}
        onClick={() => logEvent(props.event)}
        {...bindHover(popupState)}
      >
        <Box
          key={props.event.event_id}
          className={css`
            position: relative;
          `}
        >
          <Box display={"flex"} p={1.5}>
            <Box item pr={1} pt={0.8}>
              {getApplicationIcon(props.event)}
            </Box>

            <Box item flexGrow={1} pl={0.5} sx={{ width: "100%" }}>
              <Box display={"block"} alignItems={"flex-start"}>
                <Box className="text_overflow">
                  <Box item>
                    <Typography
                      maxLength="20"
                      variant="activity_header"
                      text={description}
                      style={{
                        textTransform: "capitalize",
                        fontSize: "0.8rem",
                        fontWeight: 600,
                        textTransform: "capitalize",
                      }}
                      sx={{
                        whiteSpace: "normal",
                        wordBreak: "break-all",
                        color: "var(--text-secondary-80)",
                      }}
                    >
                      {props?.event?.title}
                    </Typography>

                    <Typography className="timesheet-duration">
                      {mode == "dark" ? (
                        <ClockDark height={"10px"} mt={2} />
                      ) : (
                        <ClockLight height={"10px"} mt={2} />
                      )}
                      &nbsp;&nbsp;
                      <Typography
                        variant="activity_duration"
                        style={{
                          fontSize: "0.7rem",
                          color: props.event.projectId
                            ? mode == "dark"
                              ? "#f8f8f880"
                              : "#474B4F"
                            : "",
                        }}
                      >
                        {eventTime}
                      </Typography>
                      {/* <Typography
                        sx={{ paddingLeft: "5px" }}
                        variant="activity_log_count"
                        style={{ color: loggedEvents > 0 ? "white" : "" }}
                      >
                        {loggedEvents + "/" + props.event?.events?.length}
                      </Typography> */}
                    </Typography>
                  </Box>

                  {hoveredEventId === props.event.event_id && (
                    <Box
                      mt={2}
                      p={1}
                      className={css`
                        background: #ffffff;
                        opacity: 1;
                      `}
                    >
                      <Typography className="listDetails">
                        {description}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>

      <div style={{ minWidth: "10%" }}>
        <Box display={"flex"}>
          <Box
            item
            px={1}
            pt={1}
            pl={8}
            sx={{
              width: "10%",
              flexWrap: "nowrap",
              zIndex: 1000,
              marginRight: "6px",
              marginTop: "6px",
              "& .more_svg": {
                transition: "color 0.3s ease",
                color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
              },
              "&:hover": {
                "& .hover-text, & .more_svg": {
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                },
              },
            }}
            // display={showMore}
            {...bindTrigger(popupMore)}
          >
            <MoreIcon className="icon more_svg" />
          </Box>
        </Box>
        {/* <Box display={"flex"}>
          <Box
            item
            px={1}
            pt={1}
            pl={8}
            sx={{ width: "10%", flexWrap: "nowrap", zIndex: 1000 }}
            display={showMore}
            onClick={() => {
              if (hoveredEventId !== props.event.event_id) {
                setHoveredEventId(props.event.event_id);
              } else setHoveredEventId(null);
            }}
          >
            {hoveredEventId === props.event.event_id ? (
              <ArrowUp className="icon" />
            ) : (
              <ArrowDown className="icon" />
            )}
          </Box>
        </Box> */}
      </div>

      <Popover
        {...bindPopover(popupMore)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          onClick={() => handleHideEvents(props.event?.hidden ? false : true)}
          py={1}
          px={2}
          className={css`
            background: ${mode == "dark" ? "#101010" : "#ffffff"} 0% 0%
              no-repeat padding-box;
            color: ${mode == "dark" ? "#f8f8f8cc" : "#242424"};
            box-shadow: 0px 3px 6px #00000026;
            opacity: 1;
            cursor: pointer;
          `}
        >
          {props.event?.hidden ? "Show" : "Hide"}
        </Box>
      </Popover>
    </div>
  );
}
