import React, { useEffect } from "react";
import {
  googleUserIntegration,
  jiraUserIntegration,
} from "../../services/IntegrationService";
import { toast } from "sonner";

export default function GmailIntegration() {
  useEffect(() => {
    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get("code");
    var state = url.searchParams.get("state");
    googleUserIntegration(code, state).then((res) => {
      if (state === "calendar")
        toast.success("Google calendar integrated successfully");
      else toast.success("Gmail integrated successfully");
      setTimeout(() => {
        window.location.href = "/integration";
      }, 1000);
    });
  }, []);
  return <div></div>;
}
