import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Popover,
  Snackbar,
} from "@mui/material";
import { ReactComponent as RefreshIcon } from "../../assets/images/ralvie/Refresh-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/ralvie/add-icon.svg";
import { ReactComponent as EmptyListIconDark } from "../../assets/images/ralvie/Group-icon-dark.svg";
import { ReactComponent as EmptyListIcon } from "../../assets/images/ralvie/Group-icon.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/ralvie/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/ralvie/ArrowDown.svg";
import ContentLayout from "../../components/layout-components/ContentLayout";
import "./appsettings.scss";
import { ColorPicker } from "antd";
import AppIcon from "../../components/AppIcon";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  getAllCategories,
  createCategory,
  addApplicationsToCategory,
  removeApplicationFromCategory,
} from "../../services/CategoryService";
import { getAllapplications } from "../../services/ApplicationService";
import { alpha } from "@mui/material/styles";
import { ChromePicker } from "react-color";
import {
  usePopupState,
  bindPopover,
  bindTrigger,
} from "material-ui-popup-state/hooks";
import AppContext from "../../config/AppContext";

const fixedColors = [
  "#FF61F6",
  "#207245",
  "#1A73E8",
  "#0078D4",
  "#5059C9",
  "#9E6828",
  "#FF61F6",
  "#207245",
  "#1A73E8",
  "#0078D4",
  "#5059C9",
  "#9E6828",
  "#FF61F6",
  "#207245",
  "#1A73E8",
  "#0078D4",
  "#5059C9",
  "#9E6828",
  "#FF61F6",
  "#207245",
  "#1A73E8",
  "#0078D4",
  "#5059C9",
  "#9E6828",
];

const ItemTypes = {
  APPLICATION: "application",
};

function ExpandButton({ isExpanded, onClick }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "20px",
        right: "20px",
        width: "14px",
        height: "8px",
        background: "transparent",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      {isExpanded ? <ArrowUp /> : <ArrowDown />}
    </Box>
  );
}

// const handleRefresh = () => {
//     window.location.reload();
//   };

function AppSettings() {
  // const navigate = useNavigate();
  const { mode } = useContext(AppContext);
  const [reload, setReload] = useState(false);

  const handleRefresh = () => {
    setReload(!reload);
  };

  const handleTimeApply = () => {
    handleRefresh();
  };

  const [applications, setApplications] = useState([]);
  const [categoriesNames, setcategoriesNames] = useState([]);
  const { t } = useTranslation();
  const [categoryColors, setCategoryColors] = useState({});
  const [categories, setCategories] = useState({});
  const [categoryItems, setcategoryItems] = useState([]);
  const [ApplicationItems, setApplicationItems] = useState([]);
  const [UncatApplicationItems, setUncatApplicationItems] = useState([]);
  const [uncategoryApps, setuncategoryApps] = useState([]);
  const [appColorMap, setAppColorMap] = useState({});

  useEffect(() => {
    // Fetch categories from the API
    getAllCategories()
      .then((response) => {
        // Check if the response contains data and category array
        if (response && response.data && response.data.category) {
          const categoriesItems = response.data.category;

          const categoriesNameList = categoriesItems.map(
            (category) => category.name
          );
          // Construct categoryColors object
          const formattedCategoryColors = categoriesItems.reduce(
            (acc, category) => {
              acc[category.name] = category.color;
              return acc;
            },
            {}
          );

          const filteredCategories = categoriesItems.map((category) => ({
            categoryName: category.name,
            applications: category.applications || [],
          }));

          const categoriesWithApps = {};
          filteredCategories.forEach((categoryData) => {
            const categoryName = categoryData.categoryName;
            const applications = categoryData.applications.map(
              (app) => app.name
            );
            categoriesWithApps[categoryName] = applications;
          });

          // Initialize categories object with empty arrays
          const initialCategories = categoriesItems.reduce((acc, category) => {
            if (!acc[category.name]) {
              acc[category.name] = [];
            }
            return acc;
          }, {});

          // Update state with formatted data
          setCategoryColors(formattedCategoryColors);
          // setCategories(initialCategories);
          setCategories(categoriesWithApps);
          setcategoryItems(categoriesItems);
          // console.log("categories categories",categories)

          setcategoriesNames(categoriesNameList);
        } else {
          console.error("Invalid response format");
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
    getAllapplications()
      .then((response) => {
        if (response && response.data && response.data.application) {
          const apiApplications = response.data.application;
          // Map the API applications to a dictionary for quick lookup
          const titlesMap = apiApplications.map((acc, application) => {
            acc[application.app] = application.name;
            const app_data = { app: acc.name, id: acc.id };
            return app_data;
          }, {});

          const newAppColorMap = {};
          let previousColor = null;

          titlesMap.forEach((appData, index) => {
            let currentColor = fixedColors[index % fixedColors.length];
            if (currentColor === previousColor) {
              currentColor = fixedColors[(index + 1) % fixedColors.length];
            }
            newAppColorMap[appData.app] = currentColor;
            previousColor = currentColor;
          });

          setAppColorMap(newAppColorMap);
          setApplications(titlesMap);
          setuncategoryApps(titlesMap);
          // setApplicationItems(apiApplications);
          setUncatApplicationItems(apiApplications);

          console.log("setUncatApplicationItems", UncatApplicationItems);

          console.log("titlesMap", apiApplications);
        } else {
          console.error("Invalid response format");
        }
      })
      .catch((error) => {
        console.error("Error fetching applications:", error);
      });

    getAllapplications()
      .then((response) => {
        if (response && response.data && response.data.application) {
          const allapiApplications = response.data.application;
          // Map the API applications to a dictionary for quick lookup
          const allApplicationsMap = allapiApplications.map(
            (acc, application) => {
              acc[application.app] = application.name;
              const app_data = { app: acc.name, id: acc.id };
              return app_data;
            },
            {}
          );
          setApplicationItems(allApplicationsMap);
        } else {
          console.error("Invalid response format");
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  // const refreshComponents = () => {
  //   setCategories({ ...categories });
  //   setuncategoryApps([...uncategoryApps]);
  //   // You can add more state updates here if needed
  // };

  const handleDrop = async (app, categoryName) => {
    const updatedCategories = { ...categories };

    // Check if the item is already in the category
    if (!updatedCategories[categoryName].includes(app.app)) {
      updatedCategories[categoryName].push(app.app);
      setCategories(updatedCategories);

      try {
        // Get the IDs for the application and category
        const AppId = UncatApplicationItems.find(
          (item) => item.name === app.app
        ).id;
        const CatId = categoryItems.find(
          (item) => item.name === categoryName
        ).id;

        // Add the application to the category
        await addApplicationsToCategory(CatId, { appIds: [AppId] });

        // Remove the application from the list of All Detected Applications
        const latestapp = setuncategoryApps((prev) =>
          prev.filter((item) => item.app !== app.app)
        );

        console.log("uncategoryApps", uncategoryApps);
      } catch (error) {
        console.error("Error adding application to category:", error);

        // Rollback the state update on error
        updatedCategories[categoryName] = updatedCategories[
          categoryName
        ].filter((name) => name !== app.app);
        setCategories(updatedCategories);
      }
    }
  };

  const handleRemove = async (app, categoryName) => {
    const updatedCategories = { ...categories };
    updatedCategories[categoryName] = updatedCategories[categoryName].filter(
      (a) => a !== app
    );
    setCategories(updatedCategories);

    try {
      const Newapp = ApplicationItems.find((item) => item.app === app);
      if (!Newapp) {
        throw new Error("Application not found in ApplicationItems");
      }

      const AppId = Newapp.id;
      const CatId = categoryItems.find((item) => item.name === categoryName).id;
      await removeApplicationFromCategory(CatId, AppId);

      // setUncatApplicationItems(prev => {
      //   // Check if the item is already in the list to avoid duplicates
      //   if (prev.some(item => item.id === Newapp.id)) {
      //     return prev;
      //   }
      //   return [...prev, Newapp];
      // });

      setuncategoryApps((prev) => [...prev, Newapp]);
      console.log("uncategoryApps", uncategoryApps);
      // refreshComponents()

      // console.log("setUncatApplicationItems", uncategoryApps);
    } catch (error) {
      console.error("Error removing application from category:", error);
      updatedCategories[categoryName].push(app);
      console.log("Rollback categories after error", updatedCategories);
      setCategories(updatedCategories);
    }
  };

  // Render the list of all detected applications, filtering out duplicates
  // {[...new Set(applications.map(app => app.app))].map((appName, index) => (
  //   <DraggableApp key={index} app={{ app: appName }} />
  // ))}

  const handleCreateCategory = (categoryName, categoryColor) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [categoryName]: [],
    }));
    setCategoryColors((prevCategoryColors) => ({
      ...prevCategoryColors,
      [categoryName]: categoryColor,
    }));
  };

  function DraggableApp({ app }, reload) {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.APPLICATION,
      item: { app },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
    const appColor = appColorMap[app.app];
    const lightColor = appColor ? alpha(appColor, 0.08) : "transparent";
    useEffect(() => {
      // Perform any state reset or actions needed on reload
      // Example: Reset to expanded state on reload
      console.log("refreshing uncategoryApps", uncategoryApps);
    }, [reload]);

    return (
      <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1, cursor: "move" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // background: '#F9F9F9',
            backgroundColor: lightColor,

            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <AppIcon data={app} height={36} width={36} type="app" />
          <Typography
            variant="body1"
            sx={{
              marginLeft: "10px",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "19px",
              fontFamily: "GothamMedium",
              color: "#474B4F",
              letterSpacing: "0px",
              opacity: "1",
            }}
          >
            {app.app}
          </Typography>
        </Box>
      </div>
    );
  }

  const StaticColorDisplay = ({ color }) => {
    return (
      <Box
        sx={{
          width: "36px",
          height: "36px",
          background: "#F4F5F8 0% 0% no-repeat padding-box",
          borderRadius: "5px",
          opacity: 1,
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "26px",
            height: "26px",
            background: color,
            borderRadius: "5px",
          }}
        />
      </Box>
    );
  };

  function DropZoneCard({
    title,
    color,
    onDrop,
    apps,
    onRemove,
    categories,
    setCategories,
    uncatApplicationItems,
    setUncatApplicationItems,
  }) {
    const [isExpanded, setIsExpanded] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.APPLICATION,
      drop: (item) => {
        if (apps[title].length < 10) {
          onDrop(item.app, title);
        } else {
          setShowPopup(true);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    const toggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    const handleClosePopup = () => {
      setShowPopup(false);
    };

    useEffect(() => {
      // Perform any state reset or actions needed on reload
      setIsExpanded(true); // Example: Reset to expanded state on reload
    }, [reload]);

    return (
      <div
        ref={drop}
        style={{
          position: "relative",
          background: isOver ? "#E0E0E0" : "#FFFFFF",
          border: isOver ? "2px dashed #A9A9A9" : "2px dashed transparent",
          borderRadius: "5px",
          padding: "10px",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <StaticColorDisplay color={color} />
          <Typography variant="h5" component="div" className="design-card">
            {title}
          </Typography>
        </Box>
        <ExpandButton isExpanded={isExpanded} onClick={toggleExpand} />

        {isExpanded && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {apps[title]?.length === 0 ? (
              <Box sx={{ textAlign: "center", width: "100%" }}>
                <EmptyListIcon style={{ marginBottom: "10px" }} />
                <Typography variant="body1">
                  No Applications Detected.
                </Typography>
              </Box>
            ) : (
              apps[title]?.map((appName, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    background: "#F9F9F9 0% 0% no-repeat padding-box",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "calc(33.33% - 10px)",
                    boxSizing: "border-box",
                  }}
                >
                  <AppIcon data={appName} height={36} width={36} type="app" />
                  <Typography
                    variant="body1"
                    sx={{
                      marginLeft: "10px",
                      flexGrow: 1,
                      fontFamily: "GothamMedium",
                      color: "#474B4F",
                    }}
                  >
                    {appName}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      width: "20px",
                      height: "20px",
                      background: "#FE5050",
                      borderRadius: "4px",
                      minWidth: "20px",
                      padding: 0,
                    }}
                    onClick={() => onRemove(appName, title)}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "white", lineHeight: "20px" }}
                    >
                      X
                    </Typography>
                  </Button>
                </Box>
              ))
            )}
          </Box>
        )}

        <Snackbar
          open={showPopup}
          autoHideDuration={6000}
          onClose={handleClosePopup}
          message="Categories created more than 10"
        />
      </div>
    );
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function DropdownMenu({ onCreateCategory, existingCategories }) {
    const [categoryName, setCategoryName] = useState("");
    const [categoryColor, setCategoryColor] = useState("#0FAA51");
    const [validationMessage, setValidationMessage] = useState("");
    const [colorAnchorEl, setColorAnchorEl] = useState(null);
    const [showDropdownMenu, setShowDropdownMenu] = useState(true);

    const handleCreateCategory = () => {
      if (existingCategories.includes(categoryName)) {
        setValidationMessage("Category already exists");
      } else if (existingCategories.length >= 10) {
        setValidationMessage("Cannot create more than 10 categories");
      } else if (categoryName) {
        const payload = { name: categoryName, color: categoryColor };
        // Assume createCategory is a function that creates a new category
        createCategory(payload)
          .then((response) => {
            onCreateCategory && onCreateCategory(categoryName, categoryColor);
            setCategoryName("");
            setShowDropdownMenu(false);
            handleClose();
          })
          .catch((error) => {
            console.error("Error creating category:", error);
          });
      }
    };

    const handleCategoryNameChange = (e) => {
      const value = e.target.value;
      if (value.length <= 25) {
        setCategoryName(value);
        setValidationMessage(
          value.length === 25 ? "25 chars only allowed" : ""
        );
      } else {
        setValidationMessage("25 chars only allowed");
      }
    };

    const handleColorClick = (event) => {
      setColorAnchorEl(event.currentTarget);
    };

    const handleColorClose = () => {
      setColorAnchorEl(null);
    };

    const handleColorChangeComplete = (color) => {
      setCategoryColor(color.hex);
      handleColorClose();
    };

    const colorOpen = Boolean(colorAnchorEl);
    const colorId = colorOpen ? "color-popover" : undefined;

    return (
      <>
        {showDropdownMenu && (
          <Box
            sx={{
              width: "348px",
              background: "#FFFFFF",
              boxShadow: "0px 3px 6px #00000026",
              borderRadius: "5px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                width: "100%",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  height: "54px",
                  borderRadius: "5px",
                  background: "#F4F5F8",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 15px",
                  marginRight: "10px",
                }}
              >
                <TextField
                  variant="standard"
                  placeholder="Enter Category name"
                  value={categoryName}
                  onChange={handleCategoryNameChange}
                  InputProps={{
                    disableUnderline: true,
                    style: { textAlign: "center" },
                  }}
                  sx={{
                    width: "100%",
                    font: "normal normal normal 16px/20px Gotham",
                  }}
                />
              </Box>
              <Button
                sx={{
                  width: "54px",
                  height: "54px",
                  background: "#F4F5F8",
                  borderRadius: "5px",
                  minWidth: "54px",
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
                onClick={handleColorClick}
              >
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    background: categoryColor,
                    borderRadius: "2px",
                    position: "absolute",
                  }}
                />
              </Button>
              <Popover
                id={colorId}
                open={colorOpen}
                anchorEl={colorAnchorEl}
                onClose={handleColorClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ChromePicker
                  color={categoryColor}
                  onChangeComplete={handleColorChangeComplete}
                />
              </Popover>
            </Box>
            {validationMessage && (
              <Typography
                variant="body2"
                color="error"
                align="center"
                sx={{ mb: 2 }}
              >
                {validationMessage}
              </Typography>
            )}
            <Button
              variant="contained"
              onClick={handleCreateCategory}
              sx={{
                width: "308px",
                height: "54px",
                background:
                  "transparent linear-gradient(99deg, #1D0B77 0%, #6A5FA2 100%) 0% 0% no-repeat padding-box",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "34px",
                  height: "18px",
                  textAlign: "left",
                  font: "normal normal normal 16px/28px Gotham",
                  color: "#FFFFFF",
                }}
              >
                Add
              </Typography>
            </Button>
          </Box>
        )}
      </>
    );
  }

  const [showDropdown, setShowDropdown] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const actions = (
    <Box display="flex" alignItems="center">
      <Button
        variant="outlined"
        className="refreshButton-icon"
        sx={{ marginRight: "20px" }}
        onClick={handleRefresh}
      >
        <RefreshIcon className="icon" />
      </Button>
      <Button
        variant="contained"
        className="addButton-icon"
        onClick={handleAddClick}
      >
        <AddIcon className="icon" />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DropdownMenu
          onCreateCategory={handleCreateCategory}
          existingCategories={categoriesNames}
        />
      </Popover>
    </Box>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <ContentLayout
        title="Application category settings"
        navBack={true}
        actions={actions}
      >
        <Grid container spacing={3} sx={{ mt: -3 }}>
          <Grid item xs={12} md={8}>
            <Grid
              container
              spacing={0}
              sx={{ height: "80vh", overflow: "auto", flexDirection: "row" }}
            >
              {Object.keys(categories).map((category, index) => (
                <Grid item xs={12} key={index}>
                  {/* <button onClick={handleRefresh}>Refresh</button> */}
                  <DropZoneCard
                    title={category}
                    color={categoryColors[category]}
                    onDrop={handleDrop}
                    apps={categories}
                    categoryApps={categoryItems}
                    onRemove={handleRemove}
                    reload={reload}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              sx={{
                // width: '494px',
                height: "80vh",
                background: "#FFFFFF",
                borderRadius: "5px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
              }}
            >
              <Typography variant="h6" className="sticky-title">
                All detected applications
              </Typography>
              <Grid
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                {[...new Set(uncategoryApps.map((app) => app.app))].map(
                  (appName, index) => (
                    <DraggableApp
                      key={index}
                      app={{ app: appName }}
                      reload={reload}
                    />
                  )
                )}
                {UncatApplicationItems.length === 0 && (
                  <Grid>
                    <Box
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "28vh",
                      }}
                    >
                      {mode == "dark" ? (
                        <EmptyListIconDark />
                      ) : (
                        <EmptyListIcon />
                      )}
                      <p>{t("RAL0156")}</p>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentLayout>
    </DndProvider>
  );
}

export default AppSettings;
