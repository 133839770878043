import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const CustomSelect = ({ options, label, value, onChange, placeholder }) => {
  var selectedValue = "";
  const handleChange = (event) => {
    selectedValue =
      event.target.value === "No client" ? "" : event.target.value;
    onChange(selectedValue);
  };

  return (
    <FormControl fullWidth>
      <InputLabel shrink htmlFor="select-single">
        {label}
      </InputLabel>
      <Select
        fullWidth
        id="btn-select-options"
        value={value || ""} // Ensure the component is controlled with a fallback to an empty string
        onChange={handleChange}
        label={label}
        inputProps={{
          id: "select-single",
        }}
        sx={{
          background: "var(--input-background)",
          borderRadius: "5px",
          color: "var(--text-primary)",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
            },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 180,
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "var(--background-color-38) transparent",
              "&::-webkit-scrollbar": {
                width: "8px",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "var(--background-color-38)",
              },
            },
          }, 
        }}
        displayEmpty
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
