import { Box, SwipeableDrawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AmericanFlag } from "../../assets/images/america_flag.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { ReactComponent as Selected } from "../../assets/images/selected.svg";

export default function LanguageDrawer({ open, onClose }) {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const default_languages = [
    {
      icon: AmericanFlag,
      name: "English",
      code: "en",
    },
  ];

  const handleDrawerClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const languageSelect = (data) => {
    setSelectedLanguage(data);
    localStorage.setItem("lang", data);
  };

  useEffect(() => {
    setSelectedLanguage(
      localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    );
  }, []);

  const buttonStyle = {
    background: "#FEFEFE",
    border: "1px solid #E8E6F1",
    borderRadius: { xl: "10px", xs: "10px" },
    opacity: 1,
    cursor: "pointer",
    width: { xl: "50px", xs: "38px" },
    height: { xl: "50px", xs: "38px" },
    outline: "none",
    display: "flex", // Ensure the button content is centered
    justifyContent: "center",
    alignItems: "center",
  };

  const LanguageBox = (lang) => ({
    display: "flex",
    width: "404px",
    height: "60px",
    background: selectedLanguage === lang ? "#E8E6F1" : "#F9F9F9",
    borderRadius: "10px",
    opacity: 1,
    marginBottom: "10px",
    transition: "background-color 0.3s ease", // Add transition for smooth color change
    ":hover": {
      background: "#E8E6F1", // Change background color on hover
      cursor: "pointer", // Change cursor to pointer on hover
    },
  });

  const LangHoverColor = {
    background: "#E8E6F1 0% 0% no-repeat padding-box;",
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <Box
        sx={{
          width: { xl: 432, xs: 432 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
        role="presentation"
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 4,
            width: "100%",
            marginBottom: "5px",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontSize: { xl: "24px", xs: "20px" },
              letterSpacing: "0px",
              color: "#474B4F",
              fontFamily: "Poppins",
              opacity: 1,
              height: { xl: "33px", xs: "15px" },
              fontWeight: 600,
            }}
          >
            {t("RAL0091")}
          </Typography>
          <Box
            sx={buttonStyle}
            onClick={handleDrawerClose}
            id="btn_user_profile_close"
          >
            <CloseIcon />
          </Box>
        </Box>

        {default_languages.map((lang) => (
          <Box
            key={lang.code}
            sx={LanguageBox(lang.code)}
            onClick={() => languageSelect(lang.code)}
          >
            <Box
              sx={{
                display: "flex",
                flex: "fit-content",
                alignItems: "center", // Centers the flex items vertically within this Box
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {lang.icon && (
                  <lang.icon
                    code={lang.code}
                    fontSize="small"
                    className="flag"
                  />
                )}{" "}
                <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", }}>
                  {lang.name}
                </Typography>
              </Box>
              <Box className="flag">
                {selectedLanguage === lang.code ? <Selected /> : ""}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </SwipeableDrawer>
  );
}
