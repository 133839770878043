import { Grid, Menu, MenuItem, Tooltip, Typography, Zoom, styled } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import BackgroundImage from "../../assets/images/onboard_background.svg";
import DarkBackgroundImage from "../../assets/images/onboard_background_dark.svg";
import { ReactComponent as Logo } from "../../assets/images/sundial_logo_title.svg";
import I18n from "../../assets/images/i18n.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AppContext from "../../config/AppContext";
import { ReactComponent as AmericanFlag } from "../../assets/images/america_flag.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckCircleIcon } from "../../assets/images/check_circle_icon.svg";
import { ReactComponent as TTIM_ICON } from "../../assets/images/ttim_login_icon.svg";

export default function CommonLayout(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const colorMode = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const langOpen = Boolean(langAnchorEl);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [tooltipAction, setTooltipAction] = useState(false);

  const bgStyle = {
    backgroundImage: `url(${
      theme.palette.mode === "light" ? BackgroundImage : DarkBackgroundImage
    })`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
  };

  const CustomizedAvatar = styled(Avatar)(({ theme }) => ({
    "& .MuiAvatar-img": {
      height: "40%",
      width: "39%",
    },
    "&:hover": {
      background: theme.palette.mode === "light" ? "#E8E6F1" : "#4b4b4b", 
    },
  }));

  const handleLangClick = (event) => {
    setLangAnchorEl(event.currentTarget);
    setTooltipAction(true);
  };
  const handleLanglose = () => {
    setLangAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setTooltipAction(false);
  };

  return (
    <div style={bgStyle}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          alignContent: "center",
          pr: 3,
          pt: 3,
          pb: 1,
        }}
      >
      {props.showLogo ? ( "" ) :(
        <Tooltip title={tooltipAction ? "" : t("RAL0091")} TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
        <Box
          style={{ cursor: "pointer" }}
          onMouseDown={handleLangClick}
          onMouseEnter={handleMouseEnter}
          id="btn_onboard_change_lang"
        >
          <CustomizedAvatar
            alt="Change language"
            src={I18n}
            sx={{ width: 50, height: 50, background: "#fff" }}
          />
        </Box>
        </Tooltip>
      )}
      </Box>
      
      <Grid>
        <Grid lg={6} xs={12}>
          <Grid
            container
            width={"100%"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignContent: "flex-start",
              paddingBottom: "30px",
            }}
          >
            <Grid
              item
              elevation={1}
              style={{
                borderRadius: "10px",
                minHeight: props.showLogo ? "70vh" : "40vh",
                paddingBottom: props.showLogo ? "30px" : "50px",
                width: props.showLogo ? "35%" : "60%",
                marginTop: props.showLogo ?"30px": "1px"
              }}
              className="form-container"
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "center",
                  "& > :not(style)": {
                    mt: props.showLogo ? 12 : 4,
                    mb: 1,
                  },
                }}
              >
                {props.showLogo ? (
                  <CheckCircleIcon />
                ) : ( 
                  <TTIM_ICON width={200} height={60} />
                )}
              </Box>
              <Box
                sx={{
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    pl: "15%",
                    pr: "15%",
                  },
                }}
              >
                {props.children}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Menu
        anchorEl={langAnchorEl}
        id="account-menu"
        open={langOpen}
        onClose={handleLanglose}
        onClick={handleLanglose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLanglose} id="menu_onboard_change_lang_en" sx={{width: "190px"}}>
          <AmericanFlag fontSize="small" />{" "}
          <Typography style={{ paddingLeft: "20px" }}>{t("RAL0028")}</Typography>
        </MenuItem>
      </Menu>

    </div>
  );
}
