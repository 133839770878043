import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { css } from "@emotion/css";
import CustomButton from "../../components/CustomButton";
import AppContext from "../../config/AppContext";
import CancelDialog from "../project/CancelDialog";
import { useTranslation } from "react-i18next";
export default function AiIntroduction(props) {

  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    props.handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={() => props.handleClose()}
      >
        <Box
          sx={{
            backgroundColor:
              mode === "dark" ? "#1f1f1f !important" : "#ffffff !important",
          }}
        >
          <DialogTitle
            className={css`
              font: normal normal normal 20px/23px GothamBold !important;
              letter-spacing: 0px;
              color: ${mode === "light"
                ? "#242424 !important"
                : "#f8f8f8cc !important"};
              opacity: 1;
            `}
          >
            AI activity
          </DialogTitle>
          <DialogContent
            sx={{
              height: "65vh",
            }}
            // className={css`
            //   overflow-y: auto;
            //   scrollbar-width: thin;
            //   scrollbar-color: var(--background-color-38) transparent;
            //   &::-webkit-scrollbar {
            //     width: 8px;
            //     background-color: transparent;
            //   }
            //   &::-webkit-scrollbar-thumb {
            //     border-radius: 10px;
            //   }
            // `}
          >
            <iframe
              className={css`
                margin: 16px 0px;
                border-radius: 5px;
              `}
              width="540px"
              height="220px"
              src="https://www.youtube.com/embed/0TUdTZQsHaI"
              title="Freelance Flow-Boost your Productivity with Ralvie AI"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <DialogContentText>
              <Typography
                className={css`
                  font: normal normal normal 16px/28px Gotham !important;
                  letter-spacing: 0px;
                  color: ${mode === "light"
                    ? "#242424 !important"
                    : "#f8f8f8cc !important"};
                  opacity: 1;
                `}
              >
                Enable AI to streamline project mapping, predict tags, and save
                time. Summarize daily activities into shareable daily reports
                and concise weekly notes.
                <br></br>
                <br></br>
                Administrators can generate summaries of organization members'
                activities for performance analysis and comparisons.
                <br></br>
                <br></br>
                Rest assured, the AI handles your data with care and does not
                store it. Your data is carefully analyzed and then disposed of,
                ensuring you do not need to worry about data privacy. has
                context menu
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            className={css`
              width: 100%;
              justify-content: flex-end;
              margin-bottom: 16px;
            `}
          >
            <CustomButton
              place="sp-cancel"
              variant="outlined"
              size="large"
              onClick={handleCancelButton}
            >
              <span>{"Cancel"}</span>
            </CustomButton>
            <CustomButton
              variant="contained"
              size="large"
              onClick={() => props.onSubmit(true)}
            >
              <span>{"Enable AI"}</span>
            </CustomButton>
          </DialogActions>

          <CancelDialog
            open={isCancelDialogOpen}
            onClose={handleCloseCancelDialog}
            onConfirm={handleConfirmCancel}
            header={t("RAL0395")}
            message={t("RAL0396")}
          />

        </Box>
      </Dialog>
    </React.Fragment>
  );
}
