import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./LayoutComponents.scss";
import { useContext, useEffect, useState } from "react";
import { sidebarContext } from "../../util/context";
import { ReactComponent as ArrowDown } from "../../assets/images/ralvie/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/ralvie/ArrowUp.svg";
import { bindTrigger } from "material-ui-popup-state";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
export default function NavMenuItem(props) {
  const { mode } = useContext(AppContext);
  const navigate = useNavigate();
  const { expand, setExpand } = useContext(sidebarContext);
  const [showSubMenus, setShowSubMenus] = useState(false);

  const handleClick = () => {
    if (props.subModules?.length > 0) setShowSubMenus(!showSubMenus);
    if (props.subModules?.length > 0 && props.popupState && !expand) {
      props.popupState.open();
      return;
    }
    if (props.type === "openMenu") {
      props.onClick();
    } else if (props.subModules?.length < 1) {
      navigate(props.link);
    }
  };

  useEffect(() => {
    if (props.type === "openMenu") {
      setShowSubMenus(true);
    }
  }, [expand]);

  useEffect(() => {
    setShowSubMenus(props.isActive);
  }, [props.isActive]);
  const toolTipStyle = {
    tooltip: {
      sx: {
        borderRadius: "0px",
        backgroundColor: "#242424 !important",
        color: "#ffffff !important",
      },
    },
    arrow: {
      sx: {
        color: "#242424 !important",
      },
    },
  };
  return (
    <>
      <Tooltip
        className="tooltipMenu"
        title={expand || props.isActive ? null : props.title}
        placement="right"
        arrow
        componentsProps={toolTipStyle}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -5],
              },
            },
          ],
        }}
      >
        <Box
          component={Link}
          to={props.link}
          className={`cursor-pointer ${
            expand ? "nav-menu-expand" : "nav-menu"
          } ${props.isActive ? "nav-menu-active" : "nav-menu-inactive"}`}
          onClick={() => handleClick()}
        >
          <Box className="nav-menu-icon">
            {props.isActive ? props.activeIcon : props.inActiveIcon}
          </Box>
          {expand && (
            <Box flexGrow={1}>
              <Typography
                className={`nav-menu-text ${
                  props.isActive ? "nav-menu-text-active" : ""
                }`}
                style={{
                  paddingTop: CommonUtil.getOS() === "MacOS" ? "11px" : "0px",
                }}
              >
                {props.title}
              </Typography>
            </Box>
          )}
          {expand && props.subModules?.length > 0 && (
            <Box pr={2}>
              {showSubMenus ? (
                <ArrowUp onClick={() => setShowSubMenus(false)} />
              ) : (
                <ArrowDown onClick={() => setShowSubMenus(true)} />
              )}
            </Box>
          )}
        </Box>
      </Tooltip>
      {expand && showSubMenus && (
        <Box>
          {props.subModules?.map((sm, index) => {
            return (
              <Box
                flexGrow={1}
                key={sm?.link}
                className={"nav-sub-menu " + sm.className}
              >
                <Typography
                  component={Link}
                  to={sm?.link}
                  className={`nav-menu-text ${
                    sm?.isActive ? "nav-menu-text-active" : ""
                  }`}
                >
                  {sm?.title}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </>
    // <Grid
    //   {...props}
    //   container
    //   className={`cursor-pointer nav-menu ${
    //     props.isActive ? "nav-menu-active" : ""
    //   }`}
    //   onClick={() => navigate(props.link)}
    // >
    //   <Grid
    //     container
    //     item
    //     md={props.toggleNavBar ? 12 : 3}
    //     py={2}
    //     justifyContent={"center"}
    //     sx={{ opacity: 1 }}
    //   >
    //     {props.isActive ? props.activeIcon : props.inActiveIcon}
    //   </Grid>
    //   {!props.toggleNavBar && (
    //     <Grid container item md={9} justifyContent={"flex-start"}>
    //       <Typography
    //         className={`nav-menu-text ${
    //           props.isActive ? "nav-menu-text-active" : ""
    //         }`}
    //       >
    //         {props.title}
    //       </Typography>
    //     </Grid>
    //   )}
    // </Grid>
  );
}
