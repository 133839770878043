import { Box, IconButton, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

export default function PrivacyPolicy(props) {

  const { onClose } = props;

    return (
        <>
        <div>  
          <IconButton
              style={{ position: 'absolute', top: 10, right: 10, color: '#474A4E' }}
              onClick={onClose}
                >
              <CloseIcon />
          </IconButton>


        <Typography variant='subtitle1' component='div' gutterBottom style={{ color: '#474A4E', fontWeight: '500', paddingTop:"20px" }}>
              Introduction
            </Typography>
            <Typography variant='subtitle1' gutterBottom component='div'>
            These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Website Name accessible at Website.com. These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
            </Typography>
            <Typography variant='subtitle1' gutterBottom component='div'>
            Minors or people below 18 years old are not allowed to use this Website.
            </Typography>

            <Typography variant='subtitle1' component='div' gutterBottom style={{ color: '#474A4E', fontWeight: '500' }}>
            Intellectual Property Rights
            </Typography>

            <Typography variant='subtitle1' gutterBottom component='div'>
            Other than the content you own, under these Terms, Company Name and/or its licensors own all the intellectual property rights and materials contained in this Website. You are granted limited license only for purposes of viewing the material contained on this Website.
            </Typography>

            <Typography variant='subtitle1' component='div' gutterBottom style={{ color: '#474A4E', fontWeight: '500' }}>
            Restrictions 
            </Typography>

            <Typography variant='subtitle1' gutterBottom component='div'>
            You are specifically restricted from all of the following:
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Publishing any Website material in any other media;
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Selling, sublicensing and/or otherwise commercializing any Website material;
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Publicly performing and/or showing any Website material;
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Using this Website in any way that is or may be damaging to this Website;
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Using this Website in any way that impacts user access to this Website;
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;
                <br /><FiberManualRecordIcon fontSize="0.1px" sx={{color:"#FA9C2B", opacity: "1",}}/> Using this Website to engage in any advertising or marketing.
            </Typography>
            <Typography variant='subtitle1' gutterBottom component='div'>
            Certain areas of this Website are restricted from being access by you and Company Name may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.
              </Typography>
        
            </div>
        </>
    )
}