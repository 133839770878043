import { Button } from "@mui/material";
import { useContext } from "react";
import AppContext from "../config/AppContext";

export default function CustomArchiveNoButton(props, icon) {
  const { mode } = useContext(AppContext);
  return (
    <Button
      id={props.id}
      name={props.name}
      className={props.class}
      fullWidth={props.fullWidth}
      size={props.size}
      variant={props.variant}
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        height: props.height ? props.height : "100%",
        textTransform: "none !important",
        background:
          props.variant === "contained"
            ? "transparent linear-gradient(95deg, #1D0B77 0%, #6A5FA2 100%) 0% 0% no-repeat padding-box"
            : "none",
        color:
          props.variant === "contained"
            ? "#FFFFFF !important"
            : "var(--text-light-color-blue)",

        color:
          props.variant === "outlined"
            ? mode == "dark"
              ? "#A49DC8"
              : "#1d0b77"
            : "var(--text-light-color-blue)",

        border:
          props.variant === "outlined"
            ? "1px solid #A49DC8"
            : "1px solid var(--text-light-color-blue)",
        font:
          props?.size === "small"
            ? "normal normal normal 14px/20px Gotham !important"
            : "normal normal normal 16px/28px Gotham !important",
        borderRadius: "5px !important",
        "&:disabled": {
          color:
            props.variant === "contained"
              ? "#FFFFFF !important"
              : "var(--text-light-color-blue)",
          background:
            props.variant === "contained"
              ? "linear-gradient(95deg, rgba(29, 11, 119, 0.5) 0%, rgba(106, 95, 162, 0.5) 100%)"
              : "none",
        },
        "&:hover": {
          border:
            props.variant === "outlined"
              ? "1px solid #A49DC8"
              : "1px solid var(--text-light-color-blue)",
          background:
            props.variant === "contained"
              ? "transparent linear-gradient(95deg, #1D0B77 0%, #6A5FA2 100%) 0% 0% no-repeat padding-box"
              : "none",
          color:
            props.variant === "outlined"
              ? mode == "dark"
                ? "#A49DC8"
                : "#1d0b77"
              : "var(--text-light-color-blue)",
          border:
            props.variant === "outlined"
              ? "1px solid #A49DC8"
              : "1px solid var(--text-light-color-blue)",
        },
      }}
    >
      {props.children}
    </Button>
  );
}
