import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import "./components/Components.scss";
import AppRoutes from "./AppRoutes";
import { useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import AppContext from "./config/AppContext";
import AppLanguage from "./util/AppLanguages";
import i18n from "i18next";
import { SnackbarProvider } from "notistack";
import { ToastContainer } from "react-toastify";
import { Toaster } from "sonner";
import { UserContext } from "./util/context";
import { useMode } from "./config/theme";

function App() {
  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    language = language === null ? "en" : language;
    i18n.changeLanguage(language);
  }, []);

  const [theme, colorMode, mode] = useMode();
  const contextValue = { colorMode, mode }
  const [tokenRefreshTimer, setTokenRefreshTimer] = useState(0);

  return (
    <BrowserRouter basename="/">
      <div data-theme={mode} className="theme">
        <AppContext.Provider value={contextValue}>
          <UserContext.Provider
            value={{ tokenRefreshTimer, setTokenRefreshTimer }}
          >
            <ThemeProvider theme={theme}>
              <Toaster position="bottom-right" richColors closeButton />
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                style={{
                  marginTop: "10px",
                  borderRadius: "10px",
                }}
              >
                <AppRoutes />
                <ToastContainer
                  autoClose={2000}
                  position="top-right"
                  hideProgressBar
                  className="toast-container"
                  toastClassName="dark-toast"
                />
              </SnackbarProvider>
            </ThemeProvider>
          </UserContext.Provider>
        </AppContext.Provider>
      </div>

    </BrowserRouter>
  );
}

export default App;
