import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Popover,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ColorPicker } from "antd";
import { useTranslation } from "react-i18next";
import { addCategory } from "../../services/CategoryService";
import { toast } from "sonner";
import "./category.scss";
import AppContext from "../../config/AppContext";
import ColorPickerWithPalettes from "../../components/ColorPickerWithPalettes";

export default function AddCategory({ open, anchorEl, onClose }) {
  const { mode } = useContext(AppContext);
  const [categoryColor, setCategoryColor] = useState("#00FF00");
  const { t } = useTranslation();
  const [anchorColor, setAnchorColor] = useState(null);

  const [payload, setPayload] = useState({
    name: "",
    color: categoryColor,
  });

  const [error, setError] = useState({
    name: "",
    color: "",
  });

  useEffect(() => {
    if (open) {
      setPayload({
        name: "",
        color: "#00FF00",
      });
      setCategoryColor("#00FF00");
      setError({
        name: "",
        color: "",
      });
    }
  }, [open]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const validate = () => {
    if (!payload.name) {
      setError({
        ...error,
        name: t("RAL0001"),
      });
      return false;
    }
    return true;
  };

  const handleConfirm = () => {
    if (validate()) {
      addCategory(payload)
        .then((res) => {
          if (res.data?.code === "RCI0000") {
            toast.success("Category created successfully.");
            onClose(true);
          } else if (res.data?.code === "RCE0151") {
            setError({ ...error, name: res.data?.message });
          } else if (res.data?.code === "RCE0169") {
            toast.error(res.data?.message);
          } else {
            setError({ ...error, name: res.data?.message });
          }
        })
        .catch((error) => {
          toast.error("An error occurred while processing your request.");
          console.error("Error:", error);
        });
    }
  };

  const handleColorChange = (color) => {
    setCategoryColor(color);
    setPayload({
      ...payload,
      color: color,
    });
  };

  const handleClick = (event) => {
    setAnchorColor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorColor(null);
  };

  const styles = {
    outlinedInput: {
      border: "none",
      "& fieldset": {
        border: "none",
      },
      background: `${
        mode == "dark" ? "#010101" : "var(--background-color-6)"
      } 0% 0% no-repeat padding-box`,
      "&.Mui-focused": {
        backgroundColor:
          mode == "dark"
            ? "rgba(29, 11, 119, 0.15)"
            : "var(--input-focused-background-15)",
      },
      "&.Mui-error": {
        backgroundColor:
          mode == "dark"
            ? "rgb(254, 80, 80, 0.1)"
            : "var(--input-error-background) !important",
      },
      // Autofill styles
      "& input:-webkit-autofill": {
        backgroundColor:
          mode == "dark" ? "#010101" : "var(--background-color-6)",
        WebkitBoxShadow:
          mode == "dark"
            ? "0 0 0px 1000px #010101 inset !important"
            : "0 0 0px 1000px var(--background-color-6) inset !important",
        transition: "background-color 5000s ease-in-out 0s",
        color: mode == "dark" ? "#242424" : "var(--text-primary)",
      },
      "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
        {
          backgroundColor:
            mode == "dark"
              ? "rgba(29, 11, 119, 0.15) !important"
              : "var(--input-focused-background-15) !important",
          WebkitBoxShadow:
            mode == "dark"
              ? "0 0 0px 1000px rgba(29, 11, 119, 0.15) inset !important"
              : "0 0 0px 1000px var(--input-focused-background-15) inset !important",
        },
      // "& input::placeholder": {
      //   color: mode === "dark" ? "#f8f8f880" : "#24242480",
      // },
      borderRadius: "5px",
      height: "54px",
    },
    colorPickerContainer: {
      // position: "relative",
      // zIndex: 1500,
    },
  };

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 45,
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          background: mode == "dark" ? "#1F1F1F" : "var(--popover-background)",
        },
      }}
    >
      <Box p={3} minWidth={200}>
        <Grid container spacing={3}>
          <Grid item md={9} sm={8} xs={12}>
            <FormControl fullWidth error={Boolean(error.name)}>
              <OutlinedInput
                fullWidth
                id="text-category-name"
                name="name"
                placeholder="Enter Category name"
                value={payload.name}
                onChange={handleChange}
                inputProps={{
                  maxLength: 25,
                  autoComplete: "off",
                }}
                sx={styles.outlinedInput}
              />
              <FormHelperText>{error.name}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={3} sm={4} xs={12} style={{
            display: 'flex',
            justifyContent: 'end'
          }}>
            <Box
              className="colorPicker"
              mt={1}
              sx={styles.colorPickerContainer}
            >
              <Box
                sx={{
                  width: "60px",
                  height: "50px",
                  background:
                    mode === "light"
                      ? "#f4f5f8 !important"
                      : "#010101 !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                variant="contained"
                onClick={handleClick}
              >
                <Box
                  sx={{
                    backgroundColor: categoryColor,
                    width: "24px",
                    height: "24px",
                    borderRadius: "2px",
                  }}
                ></Box>
              </Box>
              <Popover
                open={Boolean(anchorColor)}
                anchorEl={anchorColor}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus
                sx={{ zIndex: 1500 }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <ColorPickerWithPalettes
                  selectedColor={payload.color}
                  setSelectedColor={handleColorChange}
                  onMouseDown={(e) => e.stopPropagation()}
                />
              </Popover>
              {/* <ColorPicker
                id="btn-add-color"
                defaultValue="#00FF00"
                style={{
                  width: "100%",
                  height: "54px",
                  zIndex: 2000,
                  background: `${mode == "dark" ? "#010101" : "var(--background-color-6)"
                    } 0% 0% no-repeat padding-box`,
                }}
                onChange={handleColorChange}
                getPopupContainer={(trigger) => trigger.parentNode}
              /> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            id="btn-add-category"
            onClick={handleConfirm}
            sx={{
              marginTop: "20px",
              width: "308px",
              height: "54px",
              background:
                "transparent linear-gradient(99deg, #1D0B77 0%, #6A5FA2 100%) 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                font: "normal normal normal 16px/28px Gotham",
                color: "#FFFFFF",
              }}
            >
              Add
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Popover>
  );
}
