import React from "react";

import { ReactComponent as PdfIcon } from "../assets/images/PDF.svg";
import { ReactComponent as AdobeIcon } from "../assets/images/adobe_xd_icon.svg";
import FirefoxImg, {
  ReactComponent as FirefoxIcon,
} from "../assets/images/firefox_icon.svg";
import GoogleCromeImg, {
  ReactComponent as GoogleCromeIcon,
} from "../assets/images/google_chrome_icon.svg";
import DefaultAppImg, {
  ReactComponent as DefaultAppIcon,
} from "../assets/images/icon-app-default.svg";
import { ReactComponent as NotepadIcon } from "../assets/images/icon-notepad.svg";
import { ReactComponent as WpsIcon } from "../assets/images/icon-wps-office.svg";
import { ReactComponent as DBeaver } from "../assets/images/icon_beaver.svg";
import { ReactComponent as TeamsIcon } from "../assets/images/icon_teams.svg";
import { ReactComponent as MSExcelIcon } from "../assets/images/icons8-excel.svg";
import { ReactComponent as FolderIcon } from "../assets/images/icons8-folder.svg";
import { ReactComponent as GitlabIcon } from "../assets/images/icons8-gitlab.svg";
import { ReactComponent as JiraIcon } from "../assets/images/icons8-jira-144.svg";
import { ReactComponent as OutlookIcon } from "../assets/images/icons8-outlook.svg";
import { ReactComponent as MSPowerPointIcon } from "../assets/images/icons8-powerpoint.svg";
import { ReactComponent as SpringBootIcon } from "../assets/images/icons8-spring-boot.svg";
import { ReactComponent as MSWordIcon } from "../assets/images/icons8-word.svg";
import { ReactComponent as OneDrive } from "../assets/images/onedrive.svg";

export default function AppIcon(props) {
  const addDefaultImg = (ev, data) => {
    // let imgSrc = undefined;
    // if (src?.includes("https")) {
    //   imgSrc = src?.replace("https", "http");
    // } else {
    //   imgSrc = DefaultAppIcon;
    // }
    if (data?.application?.toLowerCase().includes("firefox")) {
      ev.target.src = FirefoxImg;
    } else if (data?.application?.toLowerCase().includes("chrome")) {
      ev.target.src = GoogleCromeImg;
    } else {
      ev.target.src = DefaultAppImg;
    }
  };

  const getBrowserIcon = (data) => {
    // if (data?.application?.toLowerCase().includes("teams - microsoft")) {
    //   return <TeamsIcon height={props.height} width={props.width} key = {props?.id} id = {props?.id} />;
    // } else if (data?.url?.toLowerCase().includes("xd.adobe")) {
    //   return <AdobeIcon height={props.height} width={props.width} key = {props?.id} id = {props?.id} />;
    // } else if (data?.title?.toLowerCase().includes("jira")) {
    //   return <JiraIcon height={props.height} width={props.width} key = {props?.id} id = {props?.id} />;
    // }
    if (data?.url?.toLowerCase().includes("xd.adobe")) {
      return (
        <AdobeIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      (props.type === "event" || props.type === "app") &&
      data?.application?.toLowerCase() === "onedrive"
    ) {
      return (
        <OneDrive
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    }
    if (
      data?.title?.toLowerCase().includes(".docx") ||
      data?.title?.toLowerCase().includes("word") ||
      data?.url?.toLowerCase().includes("/:w:/")
    ) {
      return (
        <MSWordIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      data?.title?.toLowerCase().includes(".xlsx") ||
      data?.title?.toLowerCase().includes(".csv") ||
      data?.url?.toLowerCase().includes("/:x:/")
    ) {
      return (
        <MSExcelIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.title?.toLowerCase().includes("gitlab")) {
      return (
        <GitlabIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      data?.title?.toLowerCase().includes("outlook") ||
      data?.title?.toLowerCase().includes("mail")
    ) {
      return (
        <OutlookIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      data?.title?.toLowerCase().includes(".ppt") ||
      data?.title?.toLowerCase().includes("power") ||
      data?.url?.toLowerCase().includes("/:p:/")
    ) {
      return (
        <MSPowerPointIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      props.type !== "event" &&
      props.type !== "app" &&
      data?.url?.toLowerCase().includes("sharepoint.com")
    ) {
      return (
        <OneDrive
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else {
      let src = undefined;
      if (data?.url !== null && data?.url !== undefined) {
        let domain = data?.url
          ?.replace("https://", "")
          .replace("http://", "")
          .split("/")[0];
        if (domain) src = domain + "/favicon.ico";
        if (
          domain &&
          (domain.includes("localhost") ||
            domain.includes("10") ||
            domain.includes("14"))
        ) {
          src = "http://" + domain + "/favicon.ico";
        } else if (domain)
          // src =
          //   "https://s2.googleusercontent.com/s2/favicons?domain_url=" + domain;
          src = "http://" + domain + "/favicon.ico";
      }
      if (src !== undefined && src != null && src !== "") {
        return (
          <img
            src={src}
            alt={""}
            height={props.height}
            width={props.width}
            onError={(e) => addDefaultImg(e, "https://" + src)}
            key={props?.id}
            id={props?.id}
          />
        );
      } else if (data?.application?.toLowerCase().includes("firefox")) {
        return (
          <FirefoxIcon
            height={props.height}
            width={props.width}
            key={props?.id}
            id={props?.id}
          />
        );
      } else if (data?.application?.toLowerCase().includes("chrome")) {
        // <img
        //   src="https://zkteco.atlassian.net/favicon.ico"
        //   alt=""
        //   height={props.height}
        //   width={props.width}
        // key = {props?.id} id = {props?.id} />;
        return (
          <GoogleCromeIcon
            height={props.height}
            width={props.width}
            key={props?.id}
            id={props?.id}
          />
        );
      } else {
        return (
          <DefaultAppIcon
            height={props.height}
            width={props.width}
            key={props?.id}
            id={props?.id}
          />
        );
      }
    }
  };
  const getApplicationIcon = (data) => {
    if (
      data?.url ||
      data?.application?.toLowerCase().includes("firefox") ||
      data?.application?.toLowerCase().includes("chrome") ||
      data?.application?.toLowerCase().includes("msedge")
    ) {
      return getBrowserIcon(data);
    } else if (data?.application?.toLowerCase().includes("teams")) {
      return (
        <TeamsIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("adobe")) {
      return (
        <AdobeIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("jira")) {
      return (
        <JiraIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      data?.application?.toLowerCase().includes("word") ||
      data?.application?.toLowerCase().includes("msword")
    ) {
      return (
        <MSWordIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      data?.application?.toLowerCase().includes("excel") ||
      data?.application?.toLowerCase().includes("msexcel")
    ) {
      return (
        <MSExcelIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("gitlab")) {
      return (
        <GitlabIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      data?.application?.toLowerCase().includes("olk") ||
      data?.application?.toLowerCase().includes("outlook") ||
      data?.title?.toLowerCase().includes("outlook")
    ) {
      return (
        <OutlookIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (
      data?.application?.toLowerCase().includes("power") ||
      data?.application?.toLowerCase().includes("mspowerpoint")
    ) {
      return (
        <MSPowerPointIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("explorer")) {
      return (
        <FolderIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("wps")) {
      return (
        <WpsIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("spring")) {
      return (
        <SpringBootIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("pdf")) {
      return (
        <PdfIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("note")) {
      return (
        <NotepadIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else if (data?.application?.toLowerCase().includes("dbeaver")) {
      return (
        <DBeaver
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    } else {
      return (
        <DefaultAppIcon
          height={props.height}
          width={props.width}
          key={props?.id}
          id={props?.id}
        />
      );
    }
  };
  return getApplicationIcon(props.data);
}
