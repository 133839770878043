import React, { useEffect, useState } from 'react'
import { activateMiotUser } from '../../services/MemberService';
import { toast } from 'sonner';
import CommonLayout from '../onboard/CommonLayout';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ActivateMiotUser() {
    const [message, setMessage] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get("code");

        activateMiotUser(code).then((res) => {
            if(res.data?.code === "UASI0017"){
                setMessage("Account activated successfully")
            } else {
                setMessage(res.data?.message)
            }
        })
        
      }, []);
  return (
    message ? <CommonLayout showLogo={message === "Account activated successfully"}>
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          py: 3,
          pr: 10,
        }}
      >
    
        <Box>
          <Typography
          sx={{textAlign: "center", fontWeight: 600, fontSize: "24px", color: "#474B4F",}}>
            {message}
          </Typography>
        </Box>
      
        
      </Box>
    </CommonLayout> : <></>
  )
}
