import { css } from "@emotion/css";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MyProfile } from "../../assets/images/ralvie/MyProfile.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/ralvie/logout-icon.svg";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext } from "../../util/context";
import "./Profile.scss";
import "./UserNavSubMenu.scss";
import { revokeToken } from "../../services/OnboardService";

function UserNavSubMenu({ popupState }) {
  const { mode } = useContext(AppContext);
  const navigate = useNavigate();
  const { userProfile, profilePic } = useContext(sidebarContext);
  const memberName = userProfile?.memberName ? userProfile?.memberName : CommonUtil.getLoggedUser()?.firstName;
  const memberEmail = CommonUtil.getLoggedUser()?.email;

  return (
    <>
      <div className={`flexDirectionStyle userSettings `}>
        <Box
          sx={{ height: 70, width: 360, flexDirection: "row" }}
          container
          display={"flex"}
          alignItems="center"
          px={3}
          pt={3}
          mb={3}
        >
          <Box item>
            
            <Avatar
              sx={{ height: 60, width: 60 }}
              src={
                profilePic ||
                "../../assets/images/ralvie/user_profile_default.svg"
              }
            />
          </Box>
          <Box item sx={{ marginLeft: "20px", width: 250 }}>
            {memberName.length > 14 ? (
              <Tooltip title={memberName} placement="top" arrow>
                <Typography
                  className="text-truncate"
                  sx={{
                    font: "normal normal bold 16px/19px Gotham",
                    textTransform: "capitalize",
                    marginBottom: "10px",
                    color: mode === "dark" ? "#f8f8f8cc" : "#4F4F4F",
                  }}
                >
                  <span>{`${memberName.substring(0, 14)}...`}</span>
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                className="text-truncate"
                sx={{
                  font: "normal normal bold 16px/19px Gotham",
                  textTransform: "capitalize",
                  marginBottom: "10px",
                  color: mode === "dark" ? "#f8f8f8cc" : "#4F4F4F",
                }}
              >
                {memberName}
              </Typography>
            )}

            {memberEmail.length > 14 ? (
              <Tooltip title={memberEmail} placement="bottom" >
                <Typography
                  mr={3}
                  className="text-truncate"
                  sx={{
                    textTransform: "lowercase",
                    font: "normal normal normal 14px/19px Gotham",
                    color: mode === "dark" ? "#f8f8f880" : "#4F4F4F",
                  }}
                >
                  <span>{`${memberEmail.substring(0, 14)}...`}</span>
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                mr={3}
                className="text-truncate"
                sx={{
                  textTransform: "lowercase",
                  font: "normal normal normal 14px/19px Gotham",
                  color: mode === "dark" ? "#f8f8f880" : "#4F4F4F",
                }}
              >
                {memberEmail}
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          id="btn-profile-settings"
          onClick={() => {
            navigate("/userprofile");
            popupState.close();
          }}
          mt={1}
          py={2}
          px={3}
          sx={{
            cursor: "pointer",
            // backgroundColor: mode === "dark" ? "#1f1f1f80" : "#FFFFFF",
            display: "flex",
            alignItems: "center",
            columnGap: 2,
            transition: "background-color 0.3s ease",
            "& .my-profile-svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            },
            "&:hover": {
              backgroundColor:
                mode === "dark"
                  ? "var(--color-1-20)"
                  : "var(--background-color-28)",
              "& .hover-text, & .my-profile-svg": {
                color: mode === "dark" ? "#6A5FA2" : "#1D0B77",
              },
            },
          }}
        >
          <MyProfile className="my-profile-svg" />

          <Typography
            className={`hover-text`}
            sx={{
              marginLeft: "5px",
              font: "normal normal normal 18px/21px Gotham !important",
              lineHeight: "20px",
              fontFamily: "Gotham, sans-serif",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            }}
          >
            {"Profile settings"}
          </Typography>
        </Box>

        <Box
          id="btn-usr-signout"
          onClick={() => {
            revokeToken().then((res) => {
              CommonUtil.resetUserSession();
              navigate("/");
            });
          }}
          py={2}
          px={3}
          mb={1}
          sx={{
            cursor: "pointer",
            display: "flex",
            "& .logout-svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            },
            "&:hover": {
              backgroundColor:
                mode === "light" ? "#FFEDED" : "var(--color-12-10)",
              "& .hover-text, & .logout-svg": {
                color:
                  mode === "dark" ? "#FE5050 !important" : "#FE5050 !important",
              },
            },
          }}
          columnGap={2}
          alignItems={"center"}
        >
          <LogoutIcon className="logout-svg" />
          <Typography
            id="btn-usr-signout"
            className={`hover-text ${css`
              font: normal normal normal 18px/21px Gotham !important;
              letter-spacing: 0px;
              color: ${mode === "dark"
                ? "var(--color-33-50)"
                : "#4F4F4F"} !important;
            `}`}
            // ml={-0.7}
          >
            {"Sign out"}
          </Typography>
        </Box>
      </div>
    </>
  );
}

export default UserNavSubMenu;
