// import React from 'react'
// import {Box, Grid, Typography} from "@mui/material"

// function ProjectReportAccordion() {
//   return (
//     <>
//        <Grid container  md={12} lg={12} xl={12} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
//             <Box
//             sx={{ width: "100%",bgcolor:"#FFFFFF",
//              height: "350px",borderRadius:"5px",
//               }}>

//                <Grid container item  md={12} lg={12} xl={12} display={"flex"} flexDirection={"row"} m={2}  >

//                     <Grid item md={6.5} lg={6.5} xl={6.5}>
//                       <Typography className='accordion-heading'>Worklog Details</Typography>
//                     </Grid>

//                </Grid>

//                 <Grid container item md={12} lg={12} xl={12} display={"flex"} m={2} >
//                   <Grid item mt={1}>

//                   </Grid>
//                 </Grid>

//             </Box>
//             </Grid>
//     </>
//   )
// }

// export default ProjectReportAccordion

import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Avatar,
} from "@mui/material";
import { getProjectMembers } from "../../services/ReportService";
import { useTranslation } from "react-i18next";
import "./ProjectsReport.scss";

function ProjectReportAccordion({ projectId }) {
  const { t } = useTranslation();
  const [projectMembers, setProjectMembers] = useState([]);

  useEffect(() => {
    fetchProjectMembers();
  }, [projectId]);

  const fetchProjectMembers = async () => {
    try {
      const response = await getProjectMembers(projectId);
      if (response?.data?.code === "RCI0000") {
        setProjectMembers(response.data.data?.projectMembers);
        //  alert(JSON.stringify(response.data.data));
      }
    } catch (error) {
      console.error("Error fetching project members:", error);
    }
  };

  const convertSecondsToHMS = (totalSeconds) => {
    if (totalSeconds < 60) {
      return `${totalSeconds}s`;
    }
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = Math.ceil(totalSeconds % 60);

    // hours = hours < 1 ? "00" : hours < 10 ? "0" + hours : hours;
    // minutes = minutes < 1 ? "00" : minutes < 10 ? "0" + minutes : minutes;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <>
      <Grid
        className="reportGridDiv"
        container
        md={12}
        lg={12}
        xl={12}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box className="projectReportTable">
          <Grid
            className="reportGridHeading"
            container
            item
            md={12}
            lg={12}
            xl={12}
            display={"flex"}
            flexDirection={"row"}
          >
            <Grid item>
              <Typography className="accordion-heading">
                {t("RAL0213")}
              </Typography>
            </Grid>
          </Grid>

          <div className="reportGridTable">
            <Grid
              container
              item
              md={12}
              lg={12}
              xl={12}
              display={"flex"}
              sx={{ width: "100%" }}
            >
              <Grid item sx={{ width: "100%" }}>
                <TableContainer
                  className="reportTableHeight"
                  sx={{ maxHeight: 280, overflow: "auto", width: "100%" }}
                >
                  <Table sx={{ width: "100%" }}>
                    <TableHead className="reportTableHeaderFix">
                      <TableRow sx={{ bgcolor: "var(--background-color-25)" }}>
                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0214")}
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0215")}
                        </TableCell>

                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0217")}
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0209")}
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0218")}
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0210")}
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0219")}
                        </TableCell>
                        <TableCell
                          sx={{ border: "none", color: "var(--text-color-5)" }}
                        >
                          {t("RAL0220")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {projectMembers.map((member) => (
                        <TableRow sx={{ border: "none" }} key={member.id}>
                          <TableCell
                            sx={{
                              border: "none",
                              color: "var(--text-color-9)",
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {/* avatar */}
                            <Avatar
                              alt={member.memberName || "No Name"}
                              src={member.profilePhoto}
                            />
                            <div
                              style={{
                                width: "8rem",
                                whiteSpace: "normal",
                                wordBreak: "break-all",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {member.name}
                            </div>
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              color: "var(--text-color-9)",
                              textTransform: "lowercase",
                            }}
                          >
                            {member.email}
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              color: "var(--text-color-9)",
                            }}
                          >
                            {convertSecondsToHMS(member.totalLoggedDuration)}
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              color: "var(--text-color-9)",
                            }}
                          >
                            {convertSecondsToHMS(member.billedDuration)}
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              color: "var(--text-color-9)",
                            }}
                          >
                            {" "}
                            {member.billedCost?.toFixed(2)}
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              color: "var(--text-color-9)",
                            }}
                          >
                            {convertSecondsToHMS(member.unbilledDuration)}
                            {/* Add UnBilled hours data here if available */}{" "}
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              color: "var(--text-color-9)",
                            }}
                          >
                            {member.unBilledCost?.toFixed(2)}
                            {/* Add Unbilled money data here if available */}
                          </TableCell>

                          <TableCell align="center" sx={{ border: "none" }}>
                            <Box
                              sx={{
                                display: "inline-block",
                                padding: "4px 8px",
                                borderRadius: "5px",
                                backgroundColor: member.active
                                  ? "var(--btn-background-1)"
                                  : "var(--btn-background-2)",
                                color: member.active ? "#0FAA51" : "#FE5050",
                                textAlign: "center",
                                textTransform: "capitalize",
                                width: "100px",
                                minWidth: "100px",
                              }}
                            >
                              {member.status}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Grid>
    </>
  );
}

export default ProjectReportAccordion;
