import React, { useContext, useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { ReactComponent as CalendarIcon } from "../assets/images/ralvie/Calendar.svg";
import { ReactComponent as CalendarIconDark } from "../assets/images/ralvie/CalendarDark.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactComponent as ErrorIcon } from "../assets/images/ralvie/error-icon.svg";
import dayjs from "dayjs"; // Make sure to import dayjs
import "./CustomizedDatePicker.scss";
import AppContext from "../config/AppContext";

const StyledTextField = styled(TextField)({});

export default function CustomizedDatePicker(props) {
  const { mode } = useContext(AppContext);

  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "&.Mui-focused": {
              display: "none",
              border: "none",
            },
            "&.MuiInputLabel-root ": {
              borderColor: "none",
            },
          },
          "&:hover fieldset": {
            display: "none",
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "light" ? "#ffffff" : "#1f1f1f",
            color: mode === "light" ? "#242424" : "#f8f8f8",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            color: mode === "light" ? "#242424" : "#f8f8f8",
          },
          label: {
            color: mode === "light" ? "#242424" : "#f8f8f8",
          },
          switchViewButton: {
            color: mode === "light" ? "#242424" : "#f8f8f8",
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "light" ? "#ffffff" : "#1f1f1f",
            color: mode === "light" ? "#242424" : "#f8f8f8",
          },
          weekDayLabel: {
            color: mode === "light" ? "#242424" : "#f8f8f8",
          },
          weekContainer: {
            color: mode === "light" ? "#242424" : "#f8f8f8",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: mode === "light" ? "#242424" : "#f8f8f8",
            "&.Mui-selected": {
              color: "#fff",
              borderRadius: "5px",
              background:
                "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
              borderColor: "#FA9C2B",
            },
            "&.MuiPickersDay-today": {
              borderRadius: "5px",
              border: "1px solid #fa9c2b !important",
              color: mode === "light" ? "#242424" : "#f8f8f8",
              background: mode === "dark" ? "#362C20" : "#FFF7ED",
              "&:hover": {
                color: "#fff",
                borderRadius: "5px",
                background:
                  "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                borderColor: "#FA9C2B",
              },
            },
            ":hover": {
              color: "#fff",
              borderRadius: "5px",
              background:
                "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
              borderColor: "#FA9C2B",
            },
            "&.Mui-disabled": {
              color:
                mode === "light"
                  ? "#24242480 !important"
                  : "#f8f8f880 !important",
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: mode === "light" ? "#242424" : "#f8f8f8", // Icon color
          },
        },
      },
    },
  });
  const [error, setError] = useState(props.error);
  // Convert the initial date string to a dayjs object
  const [selectedDate, setSelectedDate] = useState(
    props.value ? dayjs(props.value) : null
  );

  console.log("error--------------->", error);

  useEffect(() => {
    // Make sure to convert props.value to a dayjs object when it updates
    setSelectedDate(props.value ? dayjs(props.value) : null);
    setError(props.error);
  }, [props.value, props.error]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date === null) {
      setError(true);
    } else {
      setError(false);
      if (props.onChange) {
        props.onChange(date);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <label>
        {props.label}
        {props.required && <span className="starRedColor">*</span>}
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format={props.format ? props.format : "DD/MM/YYYY"}
          value={selectedDate}
          error={error}
          id={props.id}
          name={props.name}
          disabled={props.disabled}
          className="datePicker"
          renderInput={(params) => <StyledTextField {...params} />}
          onChange={handleDateChange}
          minDate={props.minDate ? dayjs(props.minDate) : undefined}
          maxDate={props.maxDate ? dayjs(props.maxDate) : undefined}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: error
                ? "var(--input-error-background) !important"
                : "var(--input-background) !important",
              color: "var(--text-primary)",
              font: "normal normal normal 16px/20px Gotham",
              opacity: 1,
              "& fieldset": {
                borderRadius: "none",
                border: "none",
              },

              "&:hover fieldset": {
                borderColor: "none",
              },
              "&.MuiInputLabel-root ": {
                display: "none",
                border: "none",
              },
              "&.Mui-focused": {
                backgroundColor:
                  "var(--input-focused-background-15) !important",
              },
              "&.Mui-focused fieldset": {
                borderRadius: "5px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "none",
                backgroundColor:
                  "var(--input-focused-background-15) !important",
              },
              "& .MuiButtonBase-root": {
                "&:hover": {
                  backgroundColor:
                    mode == "dark" && "var(--background-color-40) !important",
                },
              },
              "& .MuiInputBase-input.Mui-disabled": {
                "-webkit-text-fill-color": "var(--text-primary)",
                opacity: 0.4, 
              },
            },
          }}
          slots={{
            openPickerIcon: mode == "light" ? CalendarIcon : CalendarIconDark,
          }}
          slotProps={{
            textField: {
              placeholder: props.label,
            },
            field: {
              readOnly: true
          },
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  color: "#fff",
                  borderRadius: "5px",
                  background:
                    "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                  borderColor: "#FA9C2B",
                },
                "&.MuiPickersDay-root.MuiPickersDay-today": {
                  borderRadius: "5px",
                  border: "1px solid #fa9c2b !important",
                  color: mode == "dark" ? "#FFFFFF" : "#242424",
                  background: mode == "dark" ? "#362C20" : "#FFF7ED",
                  "&:hover": {
                    color: "#fff",
                    borderRadius: "5px",
                    background:
                      "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                    borderColor: "#FA9C2B",
                  },
                },
                ":hover": {
                  color: "#fff",
                  borderRadius: "5px",
                  background:
                    "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                  borderColor: "#FA9C2B",
                },
              },
            },
          }}
        />
      </LocalizationProvider>
      {error && (
        <Box display="flex" alignItems="center" pt={0.5}>
          <Box pr={1}>
            <ErrorIcon />
          </Box>
          <Typography className="helper-text" color="error">
            {props.helperText} {/* You can customize the error text */}
          </Typography>
        </Box>
      )}
    </ThemeProvider>
  );
}
