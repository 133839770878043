import { css } from "@emotion/css";
import React, { useState } from "react";
import { ChromePicker, SketchPicker } from "react-color";

export default function CustomColorPicker() {
  const [color, setColor] = useState(undefined);
  return (
    <div>
      <SketchPicker
        disableAlpha={false}
        disablePicker={false}
        presetColors={["red", "green"]}
        className={css`
          height: 100%;
          width: 100%;
        `}
        color={color}
        onChangeComplete={(p) => setColor(p)}
      />
    </div>
  );
}
