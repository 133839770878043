import api from "../config/http-common";

export const updatePasswordAPI = async (id, payload) => {
 
  return api.securedAxiosApi().put(`/users/password/${id}`, payload);
};

const passwordService = {
  updatePasswordAPI,
};

export default passwordService;
 