import React, { useContext } from "react";
import { DialogTitle, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { DialogActions } from "@mui/material";
import CustomButton from "./CustomButton";
import AppContext from "../config/AppContext";
import CustomArchiveNoButton from "./CustomArchiveNoButton";

export default function CustomDialogBox(props) {
  const { onSubmit, children, onClose, acceptText, cancelText, ...other } =
    props;
  const { mode } = useContext(AppContext);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog 
      {...other}
      open={props.open}
      onClose={handleClose}
      disableEscapeKeyDown={true} 
      disableBackdropClick={true}>

      <div style={{ background: mode === "light" ? "#ffffff" : "#101010" }}>
        <DialogTitle
          className="headerTitle"
          sx={{
            padding: "1.875rem",
            color:
              mode === "light" ? "#242424!important" : "#f8f8f8cc!important",
          }}
        >
          {props.Header}
          <IconButton
            id={props.id}
            onClick={handleClose}
            sx={{
              border:
                mode === "light" ? "1px solid #FFFFFF" : "1px solid #313131",
              borderRadius: "5px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {props.children}
        <DialogActions
          id={props.id}
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0 1.875rem 1.875rem 1.875rem",
          }}
        >
          <CustomArchiveNoButton
            id={props.id}
            variant="outlined"
            class="dialogButtonReject"
            fullWidth
            onClick={handleClose}
          >
            {acceptText}
          </CustomArchiveNoButton>

          <CustomButton
            id={props.id}
            variant={"contained"}
            class="dialogButtonAccept"
            fullWidth
            onClick={handleSubmit}
          >
            {cancelText}
          </CustomButton>
        </DialogActions>
      </div>
    </Dialog>
  );
}
