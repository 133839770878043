import React, { useContext } from "react";
import { useState } from "react";
import { TextField, Tooltip, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorIcon } from "../assets/images/danger_icon.svg";
import { styled } from "@mui/material/styles";
import { ReactComponent as VisibilityIconDark } from "../assets/images/icon_viewoff_dark.svg";
import { ReactComponent as VisibilityOffIconDark } from "../assets/images/icon_viewon_dark.svg";
import AppContext from "../config/AppContext";
import "./PasswordField.scss";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root ": {
    backgroundColor: "var(--input-background)",
    borderRadius: 0,
    opacity: 1,
    paddingRight: 0,
    borderBottom: "1px solid #B2B2B2 !important",
    font: "normal normal normal 16px/20px Gotham",
    color: "var(--text-primary)",
    "& fieldset": {
      borderRadius: 0,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    },
    "&.Mui-focused": {
      backgroundColor: "var(--input-focused-background-1)",
      borderBottom: "1px solid var(--input-focused-borderBottom) !important",
    },
    "&.Mui-error": {
      borderBottom: "1px solid red !important",
      backgroundColor: "var(--input-error-background) !important",

      "& input:-webkit-autofill": {
        backgroundColor: "var(--input-error-background) !important",
        WebkitBoxShadow: "0 0 0px 1000px transparent inset !important",
        borderBottom: "none !important",
      },
    },
    "&.Mui-focused fieldset": {
      borderRadius: 0,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    },
    "& input:-webkit-autofill": {
      backgroundColor: "var(--input-background) !important",
      WebkitBoxShadow:
        "0 0 0px 1000px var(--input-background) inset !important",
      transition: "background-color 5000s ease-in-out 0s",
      borderBottom: "1px solid #B2B2B2 !important",
      color: "var(--text-primary)",
    },
    "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
      {
        backgroundColor: "var(--input-focused-background-15) !important",
        WebkitBoxShadow:
          "0 0 0px 1000px var(--input-focused-background-15) inset !important",
      },
  },
});

export default function PasswordField(props) {
  const [visibility, setVisibility] = useState(true);
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const { mode } = useContext(AppContext);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Box width={"100%"}>
      <Box
        item
        pb={0.5}>
        <Typography className={`textfield-label`}>
          {props.label}
          {props.required && <span className="starRedColor">*</span>}
        </Typography>
      </Box>
      <CssTextField
        {...props}
        fullWidth
        placeholder={
          props.customPlaceholder && isFocused
            ? `${t("RAL0089")}${props.label.toLowerCase()}`
            : props.name === "password"
            ? isFocused
              ? `${t("Enter")} ${t("RAL0110").toLowerCase()}`
              : props.placeholder || props.label
            : props.placeholder || props.label
        }
        label=""
        id={props.id}
        value={props.value}
        required={props.required}
        name={props.name}
        onChange={props.onChange}
        type={visibility ? "password" : "text"}
        error={props.error}
        helperText={
          <Typography variant="text_28_42_0_8">
            {props.error && (
              <span style={{ display: "flex", alignItems: "flex-start" }}>
                <ErrorIcon
                  height={23}
                  width={46}
                  style={{ paddingTop: "4px" }}
                />
                <span style={{ paddingLeft: "8px" }}>{props.helperText}</span>
              </span>
            )}
            {!props.error && (
              <span style={{ paddingLeft: "25px" }}>{props.helperText}</span>
            )}
          </Typography>
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        InputLabelProps={{
          style: {
            color: "#474B4F",
            font: "normal normal normal 14px/25px Poppins",
          },
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <div
              class="tooltipContainer"
              style={{
                position: "absolute",
                right: 15,
              }}
            >
              <Box
                sx={{
                  "& .OffIcon_svg": {
                    transition: "color 0.3s ease",
                    color:
                      mode === "dark"
                        ? "var(--color-33-50)"
                        : "var(--color-43-50)",
                  },
                  "& .OnIcon_svg": {
                    transition: "color 0.3s ease",
                    color:
                      mode === "dark" ? "var(--color-33)" : "var(--color-43)",
                  },
                  "&:hover": {
                    "& .OffIcon_svg": {
                      color:
                        mode === "dark"
                          ? "var(--color-33-80)"
                          : "var(--color-43-50)",
                    },
                    "& .OnIcon_svg": {
                      color:
                        mode === "dark" ? "var(--color-33)" : "var(--color-43)",
                    },
                  },
                }}
              >
                <span class="tooltipText">
                  {visibility ? "View password" : "Hide password"}
                </span>
                {visibility ? (
                  <VisibilityOffIconDark
                    id="icon_hide_password"
                    className="OffIcon_svg"
                    style={{
                      cursor: "pointer",
                      height: "17px",
                      width: "22px",
                    }}
                    onClick={() => {
                      setVisibility(!visibility);
                    }}
                  />
                ) : (
                  <VisibilityIconDark
                    id="icon_view_password"
                    className="OnIcon_svg"
                    style={{
                      cursor: "pointer",
                      height: "17px",
                      width: "22px",
                    }}
                    onClick={() => {
                      setVisibility(!visibility);
                    }}
                  />
                )}
              </Box>
            </div>
          ),
        }}
      />
    </Box>
  );
}
