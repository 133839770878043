import { Avatar, Box, Grid, Paper, styled, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import I18n from "../../assets/images/i18n_icon.svg";
import SwitchTheme from "../../assets/images/switch_theme.svg";
import UserPic from "../../assets/images/EmptyProfile.svg";
import NotifyIcon from "../../assets/images/notify_icon.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import OnboardService, { logout } from "../../services/OnboardService";
import { ReactComponent as AmericanFlag } from "../../assets/images/america_flag.svg";
import { ReactComponent as User } from "../../assets/images/user.svg";
import { ReactComponent as Logout } from "../../assets/images/Logout.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/ArrowUp.svg";
import { ReactComponent as Language } from "../../assets/images/language.svg";
import SwipeableTemporaryDrawer from "./UserProfile";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../util/CommonUtils";
import { useLocation } from 'react-router-dom';
import LanguageDrawer from "./Language";
import OverFlowText from "../../components/OverFlowText";

export default function Header() {
  const CustomizedAvatar = styled(Avatar)`
    & .MuiAvatar-img {
      height: 40%;
      width: 39%;
    }
  `;
  const [user, setUser] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newAvatar, setNewAvatar] = useState(null); // State to store the new avatar image
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();
  const decoded = CommonUtil.decodeToken();
  const location = useLocation();
  const [isLanguageDrawerOpen, setIsLanguageDrawerOpen] = useState(false);
  const [userDataUpdate, setUserDataUpdate] = useState("");

  // Callback function to handle updated avatar data
  const handleAvatarChange = (newAvatarData) => {
    setNewAvatar(newAvatarData); // Set the new avatar image data
  };

  const open = Boolean(anchorEl);
  const langOpen = Boolean(langAnchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLangClick = (event) => {
    setLangAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setIsDrawerOpen(false);
  };

  const handleLanglose = () => {
    setLangAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setIsDrawerOpen(true); // Open the drawer when "My profile" is clicked
    handleMenuClose(); // Close the menu
  };

  const handleLanguageClick = () => {
    setIsLanguageDrawerOpen(true); // Open the drawer when "My profile" is clicked
    handleMenuClose(); // Close the menu
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const id = decoded.userId;
      const response = await OnboardService.getUserCredentials(id);
      const userData = response?.data?.data?.user;
      setUserData(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  useEffect(() => {
    const decoded = CommonUtil.decodeToken();
    OnboardService.getUserDetails(decoded.userId).then((data) => {
      setUserDataUpdate(data.data.data);
    });
  }, []);

  return (
    <Grid mt={2}>
      <Box 
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent="flex-start"
        alignItems="center"
        px={3}
        minHeight={"100%"}
      >
        {location.pathname === "/pages/home" && (
        <Box item flexGrow={1} sx={{pl:"10px"}}>
          <Typography variant="text_24_35_0_1">{t("RAL0080")}</Typography>
        </Box>
        )}

        {location.pathname === "/pages/projects" && (
        <Box item flexGrow={1} sx={{pl:"10px"}}>
          <Typography variant="text_24_35_0_1">{t("RAL0081")}</Typography>
        </Box>
        )}

       {location.pathname === "/pages/budget" && (
        <Box item flexGrow={1} sx={{pl:"10px"}}>
          <Typography variant="text_24_35_0_1">{t("RAL0082")}</Typography>
        </Box>
        )}

       {location.pathname === "/pages/reports-budget" && (
        <Box item flexGrow={1} sx={{pl:"10px"}}>
          <Typography variant="text_24_35_0_1">{t("RAL0083")}</Typography>
        </Box>
        )}

      {location.pathname === "/pages/reports-project" && (
        <Box item flexGrow={1} sx={{pl:"10px"}}>
          <Typography variant="text_24_35_0_1">{t("RAL0083")}</Typography>
        </Box>
        )}
                
        {/* <Box item style={{ cursor: "pointer" }} pr={2} height={"100%"}>
          <Tooltip title={t("RAL0040")}>
            <CustomizedAvatar
              alt="Change theme"
              src={SwitchTheme}
              imgProps={{ sx: { height: "45% !important" } }}
              sx={{
                width: 45,
                height: 45,
                background: "#f8f7fb",
                imageResolution: "from-image",
              }}
            />
          </Tooltip>
        </Box>

        <Box
          item
          style={{ cursor: "pointer" }}
          pr={2}
          height={"100%"}
          onClick={handleLangClick}
          id="btn_header_change_lang"
        >
          <Tooltip title={t("RAL0041")}>
            <CustomizedAvatar
              alt="Change language"
              src={I18n}
              imgProps={{ sx: { height: "45% !important" } }}
              sx={{ width: 45, height: 45, border: "1px solid #EEEEEE" }}
            />
          </Tooltip>
        </Box>
        <Box item style={{ cursor: "pointer" }} pr={2} height={"100%"}>
          <Tooltip title={t("RAL0042")}>
            <CustomizedAvatar
              alt="Notifications"
              src={NotifyIcon}
              imgProps={{ sx: { height: "45% !important" } }}
              sx={{ width: 45, height: 45, border: "1px solid #EEEEEE" }}
            />
          </Tooltip>
        </Box> */}
        
        <Box item height={"100%"}>
          <Box display={"flex"} alignItems={"center"} height={"100%"}>
            <Box item>
              <Avatar
                component={Paper}
                elevation={1}
                alt="User pic"
                src={userData?.ProfileImage || newAvatar || UserPic}
                sx={{
                  width: 45,
                  height: 45,
                  imageResolution: "from-image",
                  cursor: "pointer"
                }}
              />
            </Box>
            
            <Box
              item
              pl={1}
              onClick={handleClick}
              id="box_header_open_profile_menu"
            >
            <Typography sx={{cursor: "pointer"}} variant="text_16_25_0_1">{t("RAL0039")} {" "}
              <OverFlowText
                variant="text_16_25_0_1"
                text={userDataUpdate?.firstName} 
                maxLength={20}
                >
                  {userDataUpdate?.firstName}
              </OverFlowText>
              </Typography>

              {/* <Typography sx={{cursor: "pointer"}} variant="text_16_25_0_1">
                 {t("RAL0039")} {userDataUpdate?.firstName}
               </Typography> */}

              {open ? (
                <ArrowUp style={{ marginLeft: "10px", cursor: "pointer" }} />
              ) : (
                <ArrowDown style={{ marginLeft: "10px", cursor: "pointer" }} />
              )}
            </Box>

          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        id="menu_header"
        PaperProps={{
          elevation: 0,
          sx: {
            width: 220, // Set the width
            height: 172, // Set the height
            background: "#FFFFFF",
            boxShadow: "0px 3px 6px #00000026", // Custom shadow
            borderRadius: "10px", // Rounded corners
            opacity: 1,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2.5,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >

        <MenuItem onClick={handleProfileClick} id="menu_header_open_profile" className="headerMenuIcon">
          <Box display="flex" alignItems="center">
            <User className="icon"  style={{ marginRight: 22, height: "40px", marginLeft: 20 }} />
            {t("RAL0043")}
          </Box>
        </MenuItem>

        <MenuItem onClick={handleLanguageClick} id="menu_header_open_language" className="headerMenuIcon">
          <Box display="flex" alignItems="center">
            <Language className="icon"  style={{ marginRight: 20, height: "40px", marginLeft: 20, color: '#000' }} />
            {t("RAL0091")}
          </Box>
        </MenuItem>

        <MenuItem onClick={() => logout()} id="menu_header_logout" className="logout">
          <Box display="flex" alignItems="center">
            <Logout className="icon" style={{ marginRight: 15.5, height: "40px", marginLeft: 20 }} />
            {t("RAL0044")}
          </Box>
        </MenuItem>

      </Menu>

      <SwipeableTemporaryDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        userData={userData}
        onAvatarChange={handleAvatarChange}
      />

      <LanguageDrawer open={isLanguageDrawerOpen}
        onClose={() => setIsLanguageDrawerOpen(false)} />

      <Menu
        anchorEl={langAnchorEl}
        id="account-menu"
        open={langOpen}
        onClose={handleLanglose}
        onClick={handleLanglose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLanglose} id="menu_header_lang_en">
          <AmericanFlag fontSize="small" />{" "}
          <span style={{ paddingLeft: "10px" }}>{"American English"}</span>
        </MenuItem>
      </Menu>
      </Grid>

  );
}
