import axios from "axios";
import api from "../config/http-common";

export const createProject = async (payload) => {
  return api.securedAxiosApi().post("/project", payload);
};

export const listProject = async (status = "", name = "") => {
  const query = [];
  if (status && status !== "") {
    query.push(`status=${encodeURIComponent(status)}`);
  }
  if (name && name !== "") {
    query.push(`name=${encodeURIComponent(name)}`);
  }
  const queryString = query.length ? `?${query.join("&")}` : "";
  const url = `${process.env.REACT_APP_SERVER_URL?.replace(
    "/web",
    "/api/v1"
  )}/projects${queryString}`;
  return api.securedAxios().get(url);
};

export const getProject = async (id) => {
  return api.securedAxiosApi().get("/projects/" + id);
};
export const editProject = async (id, payload) => {
  return api.securedAxiosApi().put("/projects/" + id, payload);
};

export const findProjectsByAssignedMember = async (memberId) => {
  return api.securedAxiosApi().get(`/user/${memberId}/projects`);
};

export const downloadProjectImportTemplate = async () => {
  // return api.securedAxiosApi().get("/department/import-template");
  try {
    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        "/projects/import-template",
      {
        responseType: "blob", // Important to specify the response type as blob
        headers: {
          Authorization: localStorage.getItem("token"),
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "project import_ralvie cloud.xlsx"); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export const importProjects = async (payload) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
    },
  };
  return api.securedAxiosApi().post(`/projects/import`, payload, config);
};

export const softDeleteProject = async (id) => {
  return api.securedAxiosApi().delete(`/projects/${id}`);
};

export const viewProjectList = async (paging, status = "", name = "") => {
  const { pageNumber, pageSize } = paging;
  let query = `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (status && status !== "") {
    query += `&status=${encodeURIComponent(status)}`;
  }
  if (name && name !== "") {
    query += `&name=${encodeURIComponent(name)}`;
  }
  const url = `${process.env.REACT_APP_SERVER_URL?.replace(
    "/web",
    "/api/v1"
  )}/projects${query}`;
  return api.securedAxios().get(url);
};

export const exportProjects = async () => {
  try {
    let api = "/projects/export?";

    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") + api,
      {
        responseType: "blob", // Important to specify the response type as blob
        headers: {
          Authorization: localStorage.getItem("token"),
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "projects.xlsx"); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export const batchProjetsArchive = async (payload) => {
  return api
    .securedAxios()
    .delete(
      `${process.env.REACT_APP_SERVER_URL?.replace(
        "/web",
        "/api/v1"
      )}/projects/archive`,
      { data: payload }
    );
};

export const batchProjetsUnarchive = async (payload) => {
  return api
    .securedAxios()
    .put(
      `${process.env.REACT_APP_SERVER_URL?.replace(
        "/web",
        "/api/v1"
      )}/projects/unarchive`,
      payload
    );
};

export const batchProjetsComplete = async (payload) => {
  return api
    .securedAxios()
    .put(
      `${process.env.REACT_APP_SERVER_URL?.replace(
        "/web",
        "/api/v1"
      )}/projects/complete`,
      payload
    );
};
