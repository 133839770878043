import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, createTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Scheduler } from "zk-timesheet";
import { ReactComponent as NoActivity } from "../../assets/images/NoActivity.svg";
import { ReactComponent as MinusIcon } from "../../assets/images/minus_icon.svg";
import { ReactComponent as PlusIcon } from "../../assets/images/plus_icon.svg";

import {
  bucketList,
  eventFilter,
  mostUsedApps,
} from "../../services/EventService";
import Event from "./CalendarEvent";
import ListEvent from "./ListEvent";
import TitleActionBar from "./TitleActionBar";

export default function Activity(props) {
  const { t } = useTranslation();
  const [day, setDay] = useState("today");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userSelectedDate, setUserSelectedDate] = useState(null);
  const calendarRef = useRef(null);
  const [EVENTS, setEvents] = useState([]);
  const [newEvents, setNewEvents] = useState(false);
  const [formatedEventsList, setFormatedEvents] = useState([]);
  const [tableEvents, setTableEvents] = useState([]);
  const [startTime, setStartTime] = useState(new Date().getHours());
  const [startMinute, setStartMinute] = useState(0);
  const [eventInterval, setEventInterval] = useState(null);
  const eventIntervalRef = useRef(eventInterval);
  const [bucketId, setBucketId] = useState("");
  const bucketRef = useRef(bucketId);
  const [eventViewMode, setEventViewMode] = useState("calendar");
  const [step, setStep] = useState(10.01);
  const [isLoading, setIsLoading] = useState(true);
  const stepRef = useRef(step);
  const eventsRef = useRef(EVENTS);
  const [timeWithNoEvents, setTimeWithNoEvents] = useState(null);
  const timeWithNoEventsRef = useRef(timeWithNoEvents);
  const [timeWithLastEvents, setTimeWithLastEvents] = useState(null);
  const timeWithLastEventsRef = useRef(timeWithLastEvents);
  const [isInit, setIsInit] = useState(false);
  const initRef = useRef(isInit);
  const handleChange = (event) => {
    setDay(event.target.value);
  };

  const handleDateChange = (value) => {
    setUserSelectedDate(new Date(value));
  };
  const [appColor, setAppColor] = useState(new Map());
  const appColorRef = useRef(appColor);

  const setApplicationColor = (apps) => {
    const appColor_ = appColorRef.current;
    apps.forEach((e) => {
      let key = e.app;
      let color = "";
      if (appColor_.size < 1) {
        color = getRandomColorVariantBasedOnAppName(key);
        appColor_.set(key, color);
      } else if (appColor_.has(key)) {
        color = appColor_.get(key);
      } else {
        color = getRandomColorVariantBasedOnAppName(key);
        appColor_.set(key, color);
      }
    });
    setAppColor(appColor_);
    appColorRef.current = appColor_;
  };

  function foramtEvents() {
    setIsLoading(true);
    let events = EVENTS.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
    // Find the "afk" events
    const afkEvents = events.filter((event) => event.data.status === "afk");

    // Iterate through events and update end time if necessary
    events.forEach((event, index) => {
      if (event.data.status !== "afk") {
        // Check if there is an "afk" event overlapping with the current event
        const overlappingAfkEvent = afkEvents.find(
          (afkEvent) =>
            new Date(afkEvent.start) <= new Date(event.end) &&
            new Date(afkEvent.end) >= new Date(event.start)
        );

        // If there is an overlapping "afk" event, update the end time and duration of the current event
        if (overlappingAfkEvent) {
          const newEndTime = overlappingAfkEvent.start;
          const newDuration =
            (new Date(newEndTime) - new Date(event.start) - 1000) / 1000; // subtract an extra 1 second
          // Update end time and duration only if new end time is greater than the start time
          if (new Date(event.end) > new Date(newEndTime)) {
            event.end = newEndTime;
            event.duration = newDuration;
          }
        }
        const currentTime = new Date();
        const eventEndTime = new Date(event.end);
        const minuteDifference = (currentTime - eventEndTime) / (60 * 1000);
        if (minuteDifference <= 1) {
          // Set the end time and duration such that the end time is 2 min less than the current time
          const newEndTime = new Date(
            currentTime - 2 * 60 * 1000
          ).toISOString();
          const newDuration =
            (new Date(newEndTime) - new Date(event.start)) / 1000; // convert milliseconds to seconds

          event.end = newEndTime;
          event.duration = newDuration;
        }
      }
    });
    events = events.filter((event) => event.duration >= 30);
    const formatedEvents = [];
    // const appColor = new Map();

    events.forEach((e) => {
      const eventStart = new Date(e.timestamp);
      const eventEnd = new Date(e.timestamp);
      eventEnd.setSeconds(eventEnd.getSeconds() + e.duration);
      let key = e.app;
      let color = appColor.get(key);
      if (key === "afk" && !color) {
        color = getRandomColorVariantBasedOnAppName(key);
        appColor.set(key, color);
      }
      const newEvent = {
        ...e,
        start: eventStart,
        end: eventEnd,
        event_id: e.id,
        title: e.data.title,

        light: color?.light,
        dark: color?.dark,
      };
      formatedEvents.push(newEvent);
    });
    if (step === 10.01) {
      setStep(10);
      stepRef.current = 10;
    }
    setIsLoading(false);
    return formatedEvents;
  }

  // Fetch events
  const fetchEvents = () => {
    setIsInit(true);
    initRef.current = true;
    let day_ = day === "today" || day === "" ? 0 : 1;
    const date = day_ === 0 ? new Date() : new Date(new Date(userSelectedDate));

    let startTime = "";

    let endTime = "";
    if (eventsRef.current?.length < 1 || day_ === 1) {
      startTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0
      ).toISOString();

      endTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999
      ).toISOString();
    } else {
      date.setMinutes(date.getMinutes() - 1);
      startTime = date.toISOString();
      endTime = new Date().toISOString();
    }

    if (day_ !== 1 && timeWithNoEventsRef.current) {
      startTime = timeWithNoEventsRef.current;
    }

    let payload = {
      startTime: startTime,
      endTime: endTime,
    };
    let request = {
      startTime: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0
      ).toISOString(),

      endTime: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999
      ).toISOString(),
    };
    mostUsedApps(request).then((res) => {
      if (
        res?.status === 200 &&
        res.data?.data?.mostUsedApps instanceof Array &&
        res.data?.data?.mostUsedApps?.length > 0
      ) {
        props.setApps(res.data?.data.mostUsedApps);
        setApplicationColor(res.data?.data.mostUsedApps);
      }

      eventFilter(payload).then((res) => {
        if (
          res?.status === 200 &&
          res.data?.data?.events instanceof Array &&
          res.data?.data?.events?.length > 0
        ) {
          let events = eventsRef.current;
          if (events.length > 0) {
            res.data?.data.events.forEach((e) => {
              let index = events.findIndex((x) => x.id === e.id);
              if (index === -1) events.push(e);
              else events[index] = e;
            });
          } else {
            events = res.data?.data.events;
            let startDateTime = new Date(res.data?.data.startDateTime);
            setStartTime(startDateTime.getHours());
            setStartMinute(roundToNearestMinutes(startDateTime.getMinutes()));
          }
          setTableEvents(events);
          setEvents(events);
          setNewEvents(true);
          eventsRef.current = events;
          setTimeWithNoEvents(null);
          setTimeWithLastEvents(payload.startTime);
          timeWithNoEventsRef.current = null;
          timeWithLastEventsRef.current = payload.startTime;
        } else {
          if (!timeWithNoEventsRef?.current) {
            var parts = timeWithLastEventsRef?.current?.split(/[-T:Z]/);
            if (parts) {
              var prevDate = new Date(
                Date.UTC(
                  parts[0],
                  parts[1] - 1,
                  parts[2],
                  parts[3],
                  parts[4],
                  parts[5]
                )
              );

              // Subtract 5 minutes
              prevDate.setMinutes(prevDate.getMinutes() - 5);
              timeWithNoEventsRef.current = prevDate.toISOString();
              setTimeWithNoEvents(prevDate.toISOString());
            }
          }
          if (eventsRef.current.length > 0) return;
          setFormatedEvents([]);
          setEvents([]);
          eventsRef.current = [];
          setTableEvents([]);
          setStartTime(new Date().getHours());
          setStartMinute(roundToNearestMinutes(new Date().getMinutes()));
          setIsLoading(false);
          props.setApps([]);
        }
      });
    });
  };

  const setEventsInterval = (e) => {
    if (e !== null || eventIntervalRef.current !== null) {
      return;
    }
    const eventInterval_ = setInterval(function () {
      fetchEvents(bucketRef.current);
    }, 60 * 1000);
    setEventInterval(eventInterval_);
    eventIntervalRef.current = eventInterval_;
    return () => clearInterval(eventInterval_);
  };

  useEffect(() => {
    if (!initRef.current) fetchEvents();
  }, []);

  useEffect(() => {
    if (
      userSelectedDate &&
      new Date(userSelectedDate).getDate() < new Date().getDate()
    ) {
      setDay(new Date(userSelectedDate).toISOString());
    } else {
      setDay("today");
    }
  }, [userSelectedDate]);

  useEffect(() => {
    setFormatedEvents([]);
    props.setApps([]);
    setIsLoading(true);
    setEvents([]);
    eventsRef.current = [];
    if (day !== "") {
      if (isInit) fetchEvents();
      if (eventInterval !== null) {
        clearInterval(eventInterval);
        setEventInterval(null);
        eventIntervalRef.current = null;
      }
      if (day === "today") setEventsInterval(null);
    }
  }, [day]);

  useEffect(() => {
    props.setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (newEvents) {
      let day_ = day === "today" || day === "" ? 0 : 1;
      // let newDate = new Date(
      //   new Date(
      //     new Date(
      //       new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(
      //         0
      //       )
      //     )
      //   ).setDate(new Date().getDate() - day_)
      // );
      const date =
        day_ === 0
          ? new Date()
          : new Date(new Date().setDate(userSelectedDate.getDate()));
      setSelectedDate(date);
      setFormatedEvents(foramtEvents());
      setIsLoading(false);
      setNewEvents(false);
    }
  }, [newEvents]);

  useEffect(() => {
    const element = document.getElementById("currentTimeBar");
    var myElement = document.getElementsByClassName("css-vnle6y");
    var topPos = element?.offsetTop;
    if (myElement && myElement.length > 0)
      myElement[0].scrollTop = topPos - 150;
  }, [formatedEventsList, eventViewMode]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("currentTimeBar");
      var myElement = document.getElementsByClassName("css-vnle6y");
      var topPos = element?.offsetTop;
      if (myElement && myElement.length > 0)
        myElement[0].scrollTop = topPos - 150;
    }, 10);
  }, [step]);

  const fetchBuckets = () => {
    bucketList().then((res) => {
      if (res.status === 200) {
        if (Object.keys(res.data).length > 0) {
          for (let key of Object.keys(res.data)) {
            if (key?.includes("window")) {
              setBucketId(key);
            }
          }
        } else {
          setTimeout(fetchBuckets, 5000);
        }
      }
    });
  };

  const handleZoom = (type) => {
    setIsLoading(true);
    switch (type) {
      case "in": {
        if (step === 30) {
          setStep(20);

          stepRef.current = 20;
        } else if (step === 20) {
          setStep(15);

          stepRef.current = 15;
        } else if (step === 15) {
          setStep(10);

          stepRef.current = 10;
        } else if (step === 10) {
          setStep(5);
          stepRef.current = 5;
        }
        break;
      }
      case "out":
        if (step === 5) {
          setStep(10);
          stepRef.current = 10;
        } else if (step === 10) {
          setStep(15);
          stepRef.current = 15;
        } else if (step === 15) {
          setStep(20);
          stepRef.current = 20;
        } else if (step === 20) {
          setStep(30);
          stepRef.current = 30;
        }
        break;
      default:
        setStep(step);
    }

    setIsLoading(false);
  };

  const getRandomColorVariants = () => {
    const fixedColors = ["#1D0B77", "#FA9C2B", "#3FD981", "#3EAEFF", "#FE5050"];
    // Pick a random color from the fixedColors array
    const randomColor =
      fixedColors[Math.floor(Math.random() * fixedColors.length)];

    // Create a Material-UI theme for the randomly picked color
    const theme = createTheme({
      palette: {
        primary: {
          main: randomColor,
          light: alpha(randomColor, 0.1),
          dark: alpha(randomColor, 0.9),
        },
      },
    });

    // Access the light and dark versions of the primary color
    const lightColor = theme.palette.primary.light;
    const darkColor = theme.palette.primary.dark;
    const contrastText = theme.palette.primary.contrastText;

    return {
      main: randomColor,
      light: lightColor,
      dark: darkColor,
      contrastText: contrastText,
    };
  };

  const getRandomColorVariantBasedOnAppName = (param) => {
    // Ensure that the parameter is a string and not an empty string
    const firstLetter =
      typeof param === "string" && param.length > 0
        ? param.charAt(0).toUpperCase()
        : "A";

    // Mapping letters to color indices
    const letterToColorIndex = {
      A: 0,
      B: 1,
      C: 2,
      D: 3,
      E: 4,
      F: 5,
      G: 6,
      H: 7,
      I: 8,
      J: 9,
      K: 10,
      L: 11,
      M: 12,
      N: 13,
      O: 14,
      P: 15,
      Q: 16,
      R: 17,
      S: 18,
      T: 19,
      U: 20,
      V: 21,
      W: 22,
      X: 23,
      Y: 24,
      Z: 25,
      // Add more mappings as needed
    };

    const fixedColors = [
      "#1D0B77",
      "#FA9C2B",
      "#3FD981",
      "#3EAEFF",
      "#FE5050",
      "#8C6CD7",
      "#4CAF50",
      "#FFC107",
      "#FF5722",
      "#00BCD4",
      "#2196F3",
      "#673AB7",
      "#FA9C2B",
      "#FFEB3B",
      "#795548",
      "#607D8B",
      "#E91E63",
      "#9C27B0",
      "#FF9800",
      "#4CAF50",
      "#009688",
      "#FF4081",
      "#9E9E9E",
      "#CDDC39",
      "#FF5722",
      "#FF5252",
      "#03A9F4",
      // Add more colors as needed
    ];

    // Pick a random color from the fixedColors array based on the first letter
    const randomColorIndex = letterToColorIndex[firstLetter] || 0;
    const randomColor = fixedColors[randomColorIndex];

    // Create a Material-UI theme for the randomly picked color
    const theme = createTheme({
      palette: {
        primary: {
          main: randomColor,
          light: alpha(randomColor, 0.1),
          dark: alpha(randomColor, 0.9),
        },
      },
    });

    // Access the light and dark versions of the primary color
    const lightColor = theme.palette.primary.light;
    const darkColor = theme.palette.primary.dark;
    const contrastText = theme.palette.primary.contrastText;

    return {
      main: randomColor,
      light: lightColor,
      dark: darkColor,
      contrastText: contrastText,
    };
  };

  const roundToNearestMinutes = (minute) => {
    if (step === 1) {
      return minute;
    }
    let roundedMinutes = Math.round(minute / step) * step;
    if (minute < roundedMinutes) {
      roundedMinutes = roundedMinutes - step;
    }
    return roundedMinutes;
  };

  return (
    <div>
      <TitleActionBar
        day={day}
        handleChange={handleChange}
        setEventViewMode={setEventViewMode}
        eventViewMode={eventViewMode}
        EVENTS={EVENTS}
        handleDateChange={handleDateChange}
      />

      <Box
        display={"flex"}
        sx={{
          width: "100%",
          height: "50px",
          background: "#EFEFEF",
          borderRadius: "10px 10px 0px 0px",
          alignItems: "center",
        }}
      >
        <Box item px={2} flexGrow={1}>
          <Typography variant="text_18_27_0_1" id="label_day">
            {day === "yesterday"
              ? "Yesterday - " +
                new Date(
                  new Date().setDate(new Date().getDate() - 1)
                ).toDateString()
              : t("RAL0052") + new Date().toDateString()}
          </Typography>
        </Box>
        {eventViewMode === "calendar" && (
          <Box item px={2}>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              color="inherit"
            >
              <Tooltip title={"Zoom In (Alt + Scroll Up)"}>
                <Button
                  id="btn_activity_zoom_in"
                  disabled={step === 5}
                  onClick={() => handleZoom("in")}
                  startIcon={<PlusIcon />}
                  sx={{
                    background: "white",

                    borderColor: "#F9F9F9 !important",
                    px: "0px !important",

                    width: "30px",

                    height: "30px",
                  }}
                ></Button>
              </Tooltip>
              <Tooltip title={"Zoom Out (Alt + Scroll Down"}>
                <Button
                  id="btn_activity_zoom_out"
                  disabled={step === 30}
                  onClick={() => handleZoom("out")}
                  startIcon={<MinusIcon />}
                  sx={{
                    background: "white",
                    border: "none !important",

                    px: "0px !important",

                    width: "30px",

                    height: "30px",
                  }}
                ></Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        )}
      </Box>
      <div
        className={
          tableEvents.length !== 0 && eventViewMode === "table"
            ? "tableView"
            : EVENTS.length === 0
            ? "calendarView"
            : ""
        }
        id="calendar"
        onWheelCapture={(event) => {
          if (event.altKey) {
            event.preventDefault();
            if (event.deltaY > 0) {
              handleZoom("out");
            } else {
              handleZoom("in");
            }
          }
        }}
      >
        {tableEvents.length === 0 && eventViewMode === "table" ? (
          <div className="noActivity">
            {!isLoading && (
              <>
                <NoActivity />
                <p>{t("RAL0038")}</p>
              </>
            )}
            {isLoading && (
              <Box
                sx={{ display: "flex" }}
                width={"100%"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        ) : eventViewMode === "table" ? (
          formatedEventsList.map((event) => <ListEvent event={event} />)
        ) : formatedEventsList.length === 0 && eventViewMode !== "table" ? (
          <div className="noActivity">
            {!isLoading && (
              <>
                <NoActivity />
                <p>{t("RAL0038")}</p>
              </>
            )}
            {isLoading && (
              <Box
                sx={{ display: "flex" }}
                width={"100%"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        ) : eventViewMode !== "table" ? (
          isLoading ? (
            <Box
              sx={{ display: "flex" }}
              width={"100%"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <div>
              <Scheduler
                loading={isLoading}
                navigation={false}
                disableViewNavigator
                ref={calendarRef}
                events={formatedEventsList}
                resources={[]}
                editable={false}
                deletable={false}
                draggable={false}
                eventWidth={30}
                view={"day"}
                selectedDate={selectedDate}
                day={{
                  startHour: startTime,
                  endHour: 24,
                  step: step,
                  startMinute: startMinute,
                  scrollToCurrentTime: false,
                }}
                eventRenderer={({ event, ...props }) => {
                  return <Event event={event} stepRef={stepRef} />;
                }}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}
