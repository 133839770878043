import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomeLayout from "./components/layout-components/HomeLayout";
import MainLayout from "./components/layout-components/MainLayout";
import Home from "./pages/home";
import ActivateEmail from "./pages/onboard/ActivateEmail";
import ForgotPassword from "./pages/onboard/ForgotPassword";
import ResetPassword from "./pages/onboard/ResetPassword";
import SignupWithoutCompany from "./pages/onboard/SignupWithoutCompany";
import UserSuccess from "./pages/onboard/UserSuccess";
import PasswordSuccess from "./pages/signup/PasswordSuccess";
import SignupLayout from "./pages/signup/SignupLayout";
import SignupUser from "./pages/signup/SignupUser";
import VerifyEmail from "./pages/signup/VerifyEmail";
import Tags from "./pages/tags/Tags";
import AddMember from "./pages/members/AddMember";
import Members from "./pages/members";
import UpdateMember from "./pages/members/UpdateMember";
import CustomerCompany from "./pages/signup/CustomerCompany";
import InviteMembers from "./pages/signup/InviteMembers";
import SelectWorkspace from "./pages/signup/SelectWorkspace";
import ActivityTrack from "./pages/signup/ActivityTrack";
import CompanyIndustry from "./pages/signup/CompanyIndustry";
import CreateProject from "./pages/project/CreateProject";
import Projects from "./pages/project/Projects";
import EditProjects from "./pages/project/EditProjects";
import LoginUser from "./pages/signup/LoginUser";
import ActivateMember from "./pages/members/ActivateMember";
import ValidateOtp from "./pages/signup/ValidateOtp";
import VerifyUser from "./pages/signup/VerifyUser";
import Timesheet from "./pages/timesheet/Timesheet";
import Integration from "./pages/integration/Integration";
import ActivateMiotUser from "./pages/members/ActivateMiotUser";
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import CommonUtil from "./util/CommonUtils";
import Designation from "./pages/settings/Designation";
import Department from "./pages/settings/Department";
import AppSettings from "./pages/category/ApplicationSettings";
import ProfileSettings from "./pages/profile/ProfileSettings";
import CategoryLayout from "./pages/categories/CategoryLayout";
import CustomColorPicker from "./components/CustomColorPicker";
import SundialAuthentication from "./pages/onboard/SundialAuthentication";
import ApplicationSettings from "./pages/applicationsettings/ApplicationSettings";
import CreateApplicationSettings from "./pages/applicationsettings/CreateApplicationSettings";
import UpdateApplicationSettings from "./pages/applicationsettings/UpdateApplicationSettings";
import JiraIntegration from "./pages/integration/JiraIntegration";
import GmailIntegration from "./pages/integration/GmailIntegration";
import GitlabIntegration from "./pages/integration/GitlabIntegration";
import ProjectListView from "./pages/project/ProjectListView";
import MembersListView from "./pages/members/MemberListView";
import ProjectsReport from "./pages/reports/ProjectsReport";
import SelectOrganization from "./pages/signup/SelectOrganization";
import AiSummary from "./pages/AI/AiSummary";
import AiSummaryHistory from "./pages/AI/AiSummaryHistory";

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path={"/sunidal-authentication"}
        element={<SundialAuthentication />}
      />
      <Route
        path={"/signup"}
        element={
          <SignupLayout>
            <>signup</>
          </SignupLayout>
        }
      />
      <Route path={"/select-organization"} element={<SelectOrganization />} />
      <Route path={"/user-activation"} element={<ActivateMiotUser />} />
      <Route path={"/color"} element={<CustomColorPicker />} />

      <Route
        path="/dashboard"
        element={
          <HomeLayout>
            <DashboardLayout />
          </HomeLayout>
        }
      />
      <Route
        path="/dashboard/categorysettings"
        element={
          <HomeLayout>
            <AppSettings />
          </HomeLayout>
        }
      />

      <Route
        path={"/timesheet"}
        element={
          <HomeLayout>
            <Timesheet />
          </HomeLayout>
        }
      />

      <Route
        path={"/categoriesApps"}
        element={
          <HomeLayout>
            <CategoryLayout />
          </HomeLayout>
        }
      />

      <Route
        path={"/projects"}
        element={
          <HomeLayout>
            <Projects />
          </HomeLayout>
        }
      />

      <Route
        path={"/projects-list"}
        element={
          <HomeLayout>
            <ProjectListView />
          </HomeLayout>
        }
      />

      <Route
        path={"/projects/new"}
        element={
          <HomeLayout>
            <CreateProject />
          </HomeLayout>
        }
      />
      <Route
        path={"/projects/:id"}
        element={
          <HomeLayout>
            <EditProjects />
          </HomeLayout>
        }
      />
      <Route
        path={"/members"}
        element={
          <HomeLayout>
            <Members />
          </HomeLayout>
        }
      />
      <Route
        path={"/members/new"}
        element={
          <HomeLayout>
            <AddMember />
          </HomeLayout>
        }
      />
      <Route
        path={"/members/:id"}
        element={
          <HomeLayout>
            <UpdateMember />
          </HomeLayout>
        }
      />

      <Route
        path={"/members-list"}
        element={
          <HomeLayout>
            <MembersListView></MembersListView>
          </HomeLayout>
        }
      />

      <Route
        path={"/settings/application"}
        element={
          <HomeLayout>
            <ApplicationSettings />
          </HomeLayout>
        }
      />

      <Route
        path={"/settings/application/new"}
        element={
          <HomeLayout>
            <CreateApplicationSettings />
          </HomeLayout>
        }
      />

      <Route
        path={"/application-settings/:id"}
        element={
          <HomeLayout>
            <UpdateApplicationSettings />
          </HomeLayout>
        }
      />

      <Route
        path={"/tags"}
        element={
          <HomeLayout>
            <Tags />
          </HomeLayout>
        }
      />
      <Route path={"/pages/sign"} element={<SignupLayout />} />
      {/* <Route path={"/pages/login"} element={<Login />} /> */}
      <Route
        path={"/pages/home"}
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        }
      />
      <Route path={"/"} element={<LoginUser />} />

      <Route path={"/pages/forgot-password"} element={<ForgotPassword />} />
      <Route path={"/pages/reset-password"} element={<ResetPassword />} />
      <Route path={"/pages/user-activate"} element={<ActivateMember />} />
      <Route path={"/pages/member-activate"} element={<ActivateEmail />} />
      <Route
        path={"/"}
        element={<Navigate to="/pages/signup/companyCode:TTIM" />}
      />
      <Route
        path={"/pages/signup/companyCode:TTIM"}
        element={<SignupWithoutCompany />}
      />
      <Route path={"/pages/user-success"} element={<UserSuccess />} />
      <Route path={"/pages/signup"} element={<SignupUser />} />
      <Route path={"/pages/verify-email"} element={<VerifyEmail />} />
      <Route path={"/pages/password-success"} element={<PasswordSuccess />} />
      <Route path={"/pages/create-company"} element={<CustomerCompany />} />
      <Route path={"/pages/company-industry"} element={<CompanyIndustry />} />
      <Route path={"/pages/select-workspace"} element={<SelectWorkspace />} />
      <Route path={"/pages/activity-track"} element={<ActivityTrack />} />
      <Route path={"/pages/select-workspace"} element={<SelectWorkspace />} />
      <Route path={"/pages/invite-members"} element={<InviteMembers />} />
      <Route path={"/pages/login"} element={<LoginUser />} />
      <Route path={"/pages/validate-email"} element={<ValidateOtp />} />
      <Route path={"/pages/verify-user"} element={<VerifyUser />} />

      <Route
        path={"/integration"}
        element={
          <HomeLayout>
            <Integration />
          </HomeLayout>
        }
      />
      <Route
        path={"/integrations/jira/callback"}
        element={
          <HomeLayout>
            <JiraIntegration />
          </HomeLayout>
        }
      />
      <Route
        path={"/integrations/google/callback"}
        element={
          <HomeLayout>
            <GmailIntegration />
          </HomeLayout>
        }
      />
      <Route
        path={"/integrations/gitlab/callback"}
        element={
          <HomeLayout>
            <GitlabIntegration />
          </HomeLayout>
        }
      />
      <Route
        path={"/settings/position"}
        element={
          <HomeLayout>
            <Designation />
          </HomeLayout>
        }
      />

      <Route
        path={"/settings/department"}
        element={
          <HomeLayout>
            <Department />
          </HomeLayout>
        }
      />

      <Route
        path={"/userprofile"}
        element={
          <HomeLayout>
            <ProfileSettings />
          </HomeLayout>
        }
      />

      <Route
        path={"/reports/project"}
        element={
          <HomeLayout>
            <ProjectsReport />
          </HomeLayout>
        }
      />
      <Route
        path={"/ai/summary"}
        element={
          <HomeLayout>
            <AiSummary />
          </HomeLayout>
        }
      />
      <Route
        path={"/ai/summary/history"}
        element={
          <HomeLayout>
            <AiSummaryHistory />
          </HomeLayout>
        }
      />
    </Routes>
  );
}
