import { css } from "@emotion/css";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import AtlasianLogo from "../../assets/images/ralvie/atlasian_logo.svg";
import { ReactComponent as BiotimeLogo } from "../../assets/images/ralvie/biocloud_logo.svg";
import GitlabLogo from "../../assets/images/ralvie/gitlab_logo.svg";
import { ReactComponent as GmailLogo } from "../../assets/images/ralvie/gmail_logo.svg";
import { ReactComponent as GoogleCalendarLogo } from "../../assets/images/ralvie/google_calendar_logo.svg";
import Banner from "../../assets/images/ralvie/integration_banner.svg.svg";
import { ReactComponent as MsOutlookLogo } from "../../assets/images/ralvie/microsoft_outlook_logo.svg";
import MsTeamsLogo from "../../assets/images/ralvie/microsoft_teams-logo.svg";
import { ReactComponent as MsOffice365Logo } from "../../assets/images/ralvie/office_365_logo.svg";
import WorkdayLogo from "../../assets/images/ralvie/workday_logo_2.svg";
import ZlinkLogo from "../../assets/images/ralvie/zlink_logo.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as RefreshIconDark } from "../../assets/images/refresh_icon_dark.svg";
import CustomDialogBox from "../../components/CustomDialogBox";
import CustomSwitch from "../../components/CustomSwitch";
import ContentLayout from "../../components/layout-components/ContentLayout";
import {
  deleteIntegration,
  getIntegrations,
  initDataSync,
  listUserIntegrations,
} from "../../services/IntegrationService";
import ".//integration.scss";
import BiotimeLogin from "./BiotimeLogin";
import AppContext from "../../config/AppContext";
export default function Integration() {
  const { mode } = useContext(AppContext);
  const [openBiotimeForm, setOpenBiotimeForm] = useState(false);
  const [biotimeToggle, setBiotimeToggle] = useState(false);
  const [jiraToggle, setJiraToggle] = useState(false);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [apps, setApps] = useState([]);
  const [myIntegrations, setMyIntegrations] = useState([]);
  const [biotimeUser, setBioTimeUser] = useState(false);
  const [openConfirmPrompt, setOpenConfrimPrompt] = useState(false);
  const [appIdToDelete, setAppIdtoDelete] = useState(undefined);
  const [reload, setReload] = useState(false);
  const data = [
    {
      logo: <BiotimeLogo />,
      name: "Biotime Cloud",
      description: "Track time and attendance globally, across all locations.",
      link: "biotimecloud.com",
      active: false,
      enabled: false,
      authoraizationUrl: "",
    },
    {
      logo: <img src={AtlasianLogo} alt={"AtlasianLogo"} />,
      name: "Jira",
      description: "Jira admins manage roles, permissions, and access schemes.",
      link: "jira.atlassian.com",
      active: false,
      enabled: false,
      authoraizationUrl:
        "https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=6tIgO6mNbZ4LUeeKuVNuiiy94YlaSUax&scope=offline_access%20read%3Aissue-event%3Ajira%20read%3Auser%3Ajira%20read%3Aissue-details%3Ajira%20read%3Aaudit-log%3Ajira%20read%3Aavatar%3Ajira%20read%3Afield-configuration%3Ajira%20read%3Aissue-meta%3Ajira%20read%3Aapplication-role%3Ajira%20read%3Agroup%3Ajira%20read%3Aissue-type%3Ajira%20read%3Aproject%3Ajira%20read%3Aproject.property%3Ajira%20read%3Aissue-type-hierarchy%3Ajira%20read%3Aproject-category%3Ajira%20read%3Aproject-version%3Ajira%20read%3Aproject.component%3Ajira&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fintegrations%2Fjira%2Fcallback&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent",
    },
    {
      logo: <img src={GitlabLogo} alt={"GitlabLogo"} height={56} width={56} />,
      name: "Gitlab",
      description:
        "GitLab is a web-based Git repository that provides free open and private repositories, issue-following capabilities, and wikis",
      link: "gitlab.com",
      active: false,
      enabled: false,
      authoraizationUrl:
        "http://gitlab.zkteco.com/oauth/authorize?client_id=012cf7b341346be49ed47402518c987c781e0c5f83ce3450e7e49d14c656b0ca&redirect_uri=http://localhost:3000/integrations/gitlab/callback&response_type=code&state=STATE&scope=api",
    },
    {
      logo: <GmailLogo />,
      name: "Gmail",
      description:
        "Gmail is a free email with spam filtering, address book, features.",
      link: "gmail.com",
      active: false,
      enabled: false,
      authoraizationUrl:
        "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=http://localhost:3000/integrations/google/callback&client_id=644972714343-cbf3ol4cfv8tv47vt2r3ntd03ahtkuvi.apps.googleusercontent.com",
    },
    {
      logo: <GoogleCalendarLogo />,
      name: "Google calendar",
      description:
        "Google Calendar organizes, coordinates, shares, and simplifies scheduling.",
      link: "calendar.google.com",
      active: false,
      enabled: false,
      authoraizationUrl:
        "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&access_type=offline&include_granted_scopes=true&response_type=code&state=calendar&redirect_uri=http://localhost:3000/integrations/google/callback&client_id=644972714343-cbf3ol4cfv8tv47vt2r3ntd03ahtkuvi.apps.googleusercontent.com",
    },
    {
      logo: <MsOutlookLogo />,
      name: "Outlook mail",
      description:
        "Outlook manages email, calendar, contacts, and tasks effectively.",
      link: "outlook.office.com",
    },
    {
      logo: <img src={MsTeamsLogo} alt={"MsTeamsLogo"} />,
      name: "Microsoft teams",
      description:
        "Microsoft Teams is hub for messaging, meetings, file sharing, collaboration.",
      link: "microsoftteams.com",
    },
    {
      logo: <MsOffice365Logo />,
      name: "Office 365",
      description:
        "Microsoft 365 is cloud productivity with apps, compatibility, and flexibility.",
      link: "office365.com",
    },
    {
      logo: (
        <img src={WorkdayLogo} alt={"WorkdayLogo"} height={56} width={56} />
      ),
      name: "Workday",
      description:
        "Workday is a cloud-based software program for human resources (HR) designed to simplify workforce management",
      link: "workday.com",
    },
    {
      logo: <img src={ZlinkLogo} alt={"ZlinkLogo"} height={56} width={56} />,
      name: "Zlink",
      description:
        "ZLink is a versatile organization management application that it is a one stop solution for all the ZKTeco Cloud Solutions.",
      link: "zlink.minervaiot.com",
    },
  ];

  useEffect(() => {
    if (appIdToDelete) {
      setOpenConfrimPrompt(true);
    } else {
      setOpenConfrimPrompt(false);
    }
  }, [appIdToDelete]);

  useEffect(() => {
    getIntegrations().then((response) => {
      if (response.data.code === "RCI0000") {
        setApps(response.data.data);
        // listUserIntegrations().then((res) => {
        //   let userIntegrations = undefined;
        //   if (res.status === 200 && res.data.data) {
        //     userIntegrations = res.data.data.integrations;
        //     setMyIntegrations(res.data.data.integrations);
        //   }

        //   if (response.data.data && response.data.data instanceof Array) {
        //     let applications = data;
        //     applications.forEach((a) => {
        //       a.active = false;
        //       a.enabled = false;
        //       let match = response.data.data.filter(
        //         (ap) => ap?.name === a.name
        //       );
        //       if (match && match instanceof Array && match.length > 0) {
        //         a.active = true;
        //         if (match[0].authorizeUrl) {
        //           a.authoraizationUrl = match[0].authorizeUrl;
        //         }
        //         if (
        //           userIntegrations &&
        //           userIntegrations instanceof Array &&
        //           userIntegrations.length > 0
        //         ) {
        //           userIntegrations.forEach((i) => {
        //             if (i.applicationId === match[0].id) {
        //               a.enabled = true;
        //               a.id = i.id;
        //               if (i.applicationUserId) {
        //                 a.link = i.applicationUserId;
        //               }
        //             }
        //           });
        //         }
        //       }
        //     });
        //     setApps(applications);
        //   }
        // });
        callUserIntegrations(response.data.data);
      }
    });
  }, []);

  const callUserIntegrations = (apps) => {
    listUserIntegrations().then((res) => {
      let userIntegrations = undefined;
      if (res.status === 200 && res.data.data) {
        userIntegrations = res.data.data.integrations;
        setMyIntegrations(res.data.data.integrations);
      }

      if (apps instanceof Array) {
        let applications = data;
        applications.forEach((a) => {
          a.active = false;
          a.enabled = false;
          let match = apps.filter((ap) => ap?.name === a.name);
          if (match && match instanceof Array && match.length > 0) {
            a.active = true;
            if (match[0].authorizeUrl) {
              a.authoraizationUrl = match[0].authorizeUrl;
            }
            if (
              userIntegrations &&
              userIntegrations instanceof Array &&
              userIntegrations.length > 0
            ) {
              userIntegrations.forEach((i) => {
                if (i.applicationId === match[0].id) {
                  a.enabled = true;
                  a.id = i.id;
                  if (i.applicationUserId) {
                    a.link = i.applicationUserId;
                  }
                }
              });
            }
          }
        });
        console.log(applications);
        setApps(applications);
      }
    });
  };

  useEffect(() => {
    if (myIntegrations && myIntegrations.length > 0) {
      let biotimeIntegrationId = apps?.filter(
        (a) => a.name === "Biotime Cloud"
      );
      if (biotimeIntegrationId?.length > 0) {
        biotimeIntegrationId = biotimeIntegrationId[0].id;
      }
      if (biotimeIntegrationId) {
        let biotimeIntegrationResponse = myIntegrations.filter(
          (ui) => ui.applicationId === biotimeIntegrationId
        );
        if (biotimeIntegrationResponse.length > 0) {
          setBioTimeUser(biotimeIntegrationResponse[0]);
          setBiotimeToggle(true);
        }
      }

      let jiraIntegrationId = apps?.filter((a) => a.name === "Jira");
      if (jiraIntegrationId?.length > 0) {
        jiraIntegrationId = jiraIntegrationId[0].id;
      }
      if (jiraIntegrationId) {
        let jiraIntegrationIdResponse = myIntegrations.filter(
          (ui) => ui.applicationId === jiraIntegrationId
        );
        if (jiraIntegrationIdResponse.length > 0) {
          // setBioTimeUser(biotimeIntegrationResponse[0]);
          setJiraToggle(true);
        }
      }
    } else {
      setBioTimeUser(false);
      setBiotimeToggle(false);
    }
  }, [myIntegrations]);

  useEffect(() => {}, []);

  // useEffect(() => {
  //   if (biotimeUser && biotimeUser.id) {
  //     const format = "YYYY-MM-DD HH:mm:ss";
  //     let payload = {
  //       startTime: dayjs().startOf("day").format(format),
  //       endTime: dayjs().endOf("day").format(format),
  //       empCode: biotimeUser.userData?.emp_code,
  //     };
  //     listUserTransactions(biotimeUser.id, payload).then((res) => {
  //       if (res.status === 200) {
  //         setBioTimeUserTransactions(res.data.data?.data);
  //       }
  //     });
  //   }
  // }, [biotimeUser]);

  const handleDelete = () => {
    deleteIntegration(appIdToDelete).then((res) => {
      if (res.data.code === "RCI0000") {
        setAppIdtoDelete(undefined);

        setApps([]);
        setTimeout(() => {
          let applications = apps;
          applications.forEach((a) => {
            if (a.id === appIdToDelete) {
              a.id = "";
              a.enabled = false;
            }
          });
          setApps(applications);
        }, 500);
        toast.success("Integration removed successfully");
      } else {
        toast.success("Integration not found");
      }
    });
  };

  const handleInitDataSync = (id) => {
    initDataSync(id).then((res) => {
      if (res.data.code === "RCI0000") {
        toast.success("Intialized data sync");
      } else {
        toast.success("Something went wrong");
      }
    });
  };

  const handleIntegrationToggle = (toggle, app) => {
    if (!toggle) {
      setAppIdtoDelete(app.id);
      return;
    }
    if (app.name === "Biotime Cloud") {
      if (toggle) handleBiotimeToggle(toggle);
    } else if (app.authoraizationUrl) {
      window.location.replace(app.authoraizationUrl, "", "_self");
    }
  };

  const handleBiotimeToggle = (toggle) => {
    if (toggle && !biotimeUser) {
      setBiotimeToggle(true);
      setOpenBiotimeForm(true);
    } else {
      setBiotimeToggle(false);
      setOpenBiotimeForm(false);
    }
  };

  const handleBiotimeFormClose = () => {
    setOpenBiotimeForm(false);

    listUserIntegrations().then((res) => {
      if (res.status === 200 && res.data.data) {
        let data = res.data.data.integrations;
        if (data && data instanceof Array && data.length > 0) {
          setApps([]);
          setTimeout(() => {
            let applications = apps;
            applications.forEach((a) => {
              if (a.name === "Biotime Cloud") {
                a.id = data[0]?.id;
                a.enabled = true;
              }
            });
            setApps(applications);
          }, 100);
        } else {
          setApps([]);
          setTimeout(() => {
            let applications = apps;
            applications.forEach((a) => {
              if (a.name === "Biotime Cloud") {
                a.id = "";
                a.enabled = false;
              }
            });
            setApps(applications);
          }, 100);
        }
        setMyIntegrations(res.data.data.integrations);
      }
    });
  };

  return (
    <>
      <ContentLayout title="Integrations" bg={"transparent"}>
        <div
          className={css`
            width: 100%;
          `}
        >
          <div
            className={css`
              width: 100%;
              height: 25vh;
              border-radius: 5px;
              background-image: url(${Banner});
              background-size: cover; /* Ensures the image covers the entire div */
              background-position: center; /* Centers the image within the div */
              background-repeat: no-repeat; /* Prevents the image from repeating */
              display: flex;
              align-items: center;
            `}
          >
            <Box px={8}>
              <Box pb={2}>
                <Typography
                  className={css`
                    font: normal normal normal 36px/42px GothamBold !important;
                    letter-spacing: 0px;
                    color: #ffffff !important;
                    opacity: 1;
                    @media (min-width: 1500px) {
                      font: normal normal normal 42px/46px GothamBold !important;
                    }
                  `}
                >
                  Integration Gateway
                </Typography>
              </Box>
              <Typography
                className={css`
                  font: normal normal normal 16px/24px Gotham !important;
                  letter-spacing: 0px;
                  color: #ffffff !important;
                  opacity: 1;
                  @media (min-width: 1500px) {
                    font: normal normal normal 20px/34px Gotham !important;
                  }
                `}
              >
                Integrate apps to share data and streamline your <br></br>{" "}
                workflow for improved efficiency and productivity.
              </Typography>
            </Box>
          </div>
          <Box sx={{ padding: "30px 5px" }}>
            <Typography
              className={css`
                font: normal normal bold 20px/22px GothamBold !important;
                letter-spacing: 0px;
                color: var(--text-secondary-80) !important;
                opacity: 1;
              `}
            >
              Applications for the integration
            </Typography>
          </Box>
          <Box
            flexWrap={"wrap"}
            className={css`
              display: flex;
              column-gap: 30px;
              row-gap: 30px;
            `}
          >
            {apps?.map((i) => {
              return (
                <Box
                  className={css`
                    background: var(--background-color-3) 0% 0% no-repeat
                      padding-box;
                    border-radius: 5px;
                    opacity: 1;
                    padding: 24px;
                    // width: ${isXlScreen ? "24.28%" : "23.9%"};
                    width: calc(25% - 23px);
                  `}
                >
                  <Box item display={"flex"} width={"100%"}>
                    <Box flexGrow={1} height={"56px"}>
                      {i.logo}
                    </Box>
                    <Box>
                      {i?.name === "Biotime Cloud" ? (
                        <CustomSwitch
                          className="CustomSwitch"
                          id="btn-custom-biotime"
                          checked={i.enabled}
                          value={i.enabled}
                          onChange={(e) => {
                            handleIntegrationToggle(e.target.checked, i);
                          }}
                        />
                      ) : (
                        <CustomSwitch
                          sx={{
                            cursor: "not-allowed !important",
                            "& .MuiSwitch-thumb": {
                              backgroundColor:
                                "var(--background-color-36) !important",
                            },
                            "& .MuiSwitch-track": {
                              border:
                                "1px solid var(--border-color-6) !important",
                            },
                          }}
                          disabled
                        />
                      )}
                    </Box>
                  </Box>
                  <Box py={2}>
                    <Typography
                      className={css`
                        font: normal normal normal 16px/26px GothamMedium !important;
                        letter-spacing: 0px;
                        color: var(--text-secondary-80) !important;
                        opacity: 1;
                      `}
                    >
                      {i.name}
                    </Typography>
                  </Box>
                  <Box pb={1} minHeight={"100px"}>
                    <Typography
                      className={css`
                        font: normal normal normal 14px/24px Gotham !important;
                        letter-spacing: 0px;
                        color: var(--text-secondary-50) !important;
                        opacity: 1;
                      `}
                    >
                      {i.description}
                    </Typography>
                  </Box>
                  <Box display={"flex"}>
                    <Typography
                      className={css`
                        font: normal normal normal 14px/26px Gotham !important;
                        letter-spacing: 0px;
                        color: var(--text-light-color-blue) !important;
                        opacity: 1;
                        flex-grow: 1;
                      `}
                    >
                      {i.link}
                    </Typography>
                    {i.enabled && i.name.includes("Biotime") && (
                      <Box>
                        {mode == "light" ? (
                          <RefreshIcon
                            className="icon"
                            onClick={() => handleInitDataSync(i?.id)}
                          />
                        ) : (
                          <RefreshIconDark
                            onClick={() => handleInitDataSync(i?.id)}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </div>
        {/* <Box>
          {headcard}
          <Box py={3}>
            <Typography
              className={css`
                font: normal normal bold 20px/22px GothamBold !important;
                letter-spacing: 0px;
                color: #242424;
                opacity: 1;
              `}
            >
              Applications for the integration
            </Typography>
          </Box>
          <Box display={"flex"} columnGap={3}>
            <Box item>{IntegrationContents()}</Box>
            <Box item>{biotTimeIntegrationCard}</Box>
            <Box
              item
              className={css`
                background: #ffffff 0% 0% no-repeat padding-box;
                border-radius: 5px;
                opacity: 1;
              `}
            ></Box>
          </Box>
          <BiotimeLogin
            open={openBiotimeForm}
            onClose={() => handleBiotimeFormClose()}
          />
        </Box> */}
        <BiotimeLogin
          open={openBiotimeForm}
          onClose={() => handleBiotimeFormClose()}
        />
      </ContentLayout>
      <CustomDialogBox
        id="btn-remove-integration"
        Header="Remove integration"
        acceptText="No, don't"
        cancelText="Yes, remove"
        fullWidth={true}
        onClick={() => setAppIdtoDelete(undefined)}
        onSubmit={() => handleDelete()}
        open={openConfirmPrompt}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          style={{ padding: "20px" }}
        >
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
              }}
            >
              <Typography className="dialogInfo">
                Are you sure you want to remove the integration with Biotime
                cloud?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CustomDialogBox>
    </>
  );
}
