import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { sidebarContext } from "../../util/context";
import { useEffect } from "react";
import Header from "../../pages/home/Header";
import SideBarMenu from "./SideBarMenu";

export default function MainLayout(props) {
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    const handleStorage = () => {
    };

    // Add event listener for 'storage' event
    window.addEventListener("storage", handleStorage);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("storage", handleStorage);
    };
  }, []);

  return (
    <sidebarContext.Provider value={{ expand, setExpand }}>
      <Grid
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SideBarMenu />

        <Grid
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Header />
          <Box
            sx={{
              width: "100%",
              paddingLeft: "10px",
              paddingBottom: "5px"
            }}
          >
            {props.children}
          </Box>
        </Grid>
      </Grid>
    </sidebarContext.Provider>
  );
}
