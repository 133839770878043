import { ReactComponent as SearchIcon } from "../assets/images/ralvie/search-icon.svg";
import { ReactComponent as SearchIconDark } from "../assets/images/ralvie/search_icon_dark.svg";
import { ReactComponent as ClearIcon } from "../assets/images/ralvie/clearTextIcon.svg";
import { ReactComponent as ClearIconDark } from "../assets/images/ralvie/clearTextIconDark.svg";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../config/AppContext";
import { useContext } from "react";
export default function CustomSearchField(props) {
  const [value, setValue] = React.useState(props.value);
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);

  React.useEffect(() => {
    if (value !== props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.clearSearch) {
      setValue("");
    }
  }, [props.clearSearch]);

  const handleChange = (event) => {
    const { maxLength } = props;
    const { value } = event.target;

    if (maxLength && value.length > maxLength) {
      return;
    }

    setValue(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (props.onClick) {
        props.onClick(value);
      }
    }
  };
  const handleClear = () => {
    setValue("");
    if (props.onChange) {
      props.onChange("");
    }
    if (props.onClear) {
      props.onClear();
    }
  };
  return (
    <Paper
      component="form"
      variant="outlined"
      sx={{
        p: "0px 4px",
        display: "flex",
        alignItems: "center",
        width: props.width || "100%",
        height: props.size === "md" ? "40px" : "50px",
        borderColor: "var(--border-color-3)",
      }}
    >
      <InputBase
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        sx={{
          ml: 1,
          flex: 1,
          color: "var(--text-secondary) !important",
          "& input": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          },
          "& input::placeholder": {
            color: "var(--text-primary)",
          },
        }}
        placeholder={props.label}
        className="input-placeholder"
        inputProps={{
          "aria-label": props.label,
          maxLength: props.maxLength || "",
        }}
      />

     {value && (
        <Tooltip title="Clear">
      <IconButton
        aria-label="clear input"
        onClick={handleClear}
        sx={{
          borderRadius: "5px",
          background: "transparent",
          visibility: value ? "inherit" : "hidden",
          "& .clear_svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .clear_svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
      >
            {mode === "dark" ? (
              <ClearIconDark className="clear_svg" />
            ) : (
              <ClearIcon className="clear_svg" />
          )}
          </IconButton>
        </Tooltip>
      )}

      <IconButton
        sx={{
          borderRadius: "5px",
          background: "transparent",
          "& .search_svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .search_svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
        id={props.id}
        name={props.name}
        type="button"
        aria-label="search"
        onClick={() => props.onClick(value)}
      >
        <Tooltip title={t("RAL0180")}>
          {mode === "dark" ? (
            <SearchIconDark
              height={props.size === "md" ? "14px" : "18px"}
              width={props.size === "md" ? "14px" : "18px"}
              className="search_svg"
            />
          ) : (
            <SearchIcon
              height={props.size === "md" ? "14px" : "18px"}
              width={props.size === "md" ? "14px" : "18px"}
              className="search_svg"
            />
          )}
        </Tooltip>
      </IconButton>
    </Paper>
  );
}
export function CustomSearchFieldWithStartIcon(props) {
  const [value, setValue] = React.useState(props.value);
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);

  React.useEffect(() => {
    if (value !== props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleChange = (event) => {
    const { maxLength } = props;
    const { value } = event.target;

    if (maxLength && value.length > maxLength) {
      return;
    }

    setValue(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (props.onClick) {
        props.onClick(value);
      }
    }
  };
  const handleClear = () => {
    setValue("");
    if (props.onChange) {
      props.onChange("");
    }
  };
  return (
    <Paper
      component="form"
      variant="outlined"
      sx={{
        p: "0px 4px",
        display: "flex",
        alignItems: "center",
        width: props.width || "100%",
        height: props.size === "md" ? "40px" : "50px",
        borderColor: "var(--border-color-3)",
      }}
      z
    >
      <IconButton
        id={props.id}
        name={props.name}
        type="button"
        aria-label="search"
        onClick={() => props.onClick(value)}
        sx={{
          borderRadius: "5px",
          background: "transparent",
          "& .search_svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .search_svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
      >
        <Tooltip title={t("RAL0180")}>
          {mode === "dark" ? (
            <SearchIconDark
              height={props.size === "md" ? "14px" : "18px"}
              width={props.size === "md" ? "14px" : "18px"}
              className="search_svg"
            />
          ) : (
            <SearchIcon
              height={props.size === "md" ? "14px" : "18px"}
              width={props.size === "md" ? "14px" : "18px"}
              className="search_svg"
            />
          )}
        </Tooltip>
      </IconButton>
      <InputBase
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        sx={{
          ml: 1,
          flex: 1,
          "& input": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          },
          "& input::placeholder": {
            color: "var(--text-primary)",
          },
        }}
        placeholder={props.label}
        className="input-placeholder"
        inputProps={{
          "aria-label": props.label,
          maxLength: props.maxLength || "",
        }}
      />
      
     {value && (
        <Tooltip title="Clear">
      <IconButton
        aria-label="clear input"
        onClick={handleClear}
        sx={{
          borderRadius: "5px",
          background: "transparent",
          visibility: value ? "inherit" : "hidden",
          "& .clear_svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .clear_svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
      >
            {mode === "dark" ? (
              <ClearIconDark className="clear_svg" />
            ) : (
              <ClearIcon className="clear_svg" />
          )}
          </IconButton>
        </Tooltip>
      )}

    </Paper>
  );
}

// Set default props
CustomSearchField.defaultProps = {
  value: "",
  width: "100%",
  size: "md",
  label: "Search",
  maxLength: 50,
  clearSearch: false,
  onChange: null,
  onClick: null,
};
