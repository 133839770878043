import React, { useContext, useRef, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import CustomTextField from "../../components/CustomTextField";
import CommonUtil from "../../util/CommonUtils";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { toast } from "sonner";
import {
  createApplication,
  uploadIconById,
} from "../../services/ApplicationSettingsService";
import { useTranslation } from "react-i18next";
import profile from "../../assets/images/EmptyProfile.svg";
import profileDark from "../../assets/images/EmptyProfile_dark.svg";
import AppContext from "../../config/AppContext";
import TitleBar from "../../components/layout-components/TitleBar";
import CancelDialog from "../project/CancelDialog";

export default function CreateApplicationSettings() {
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [icon, setIcon] = useState(
    (mode === "dark" ? profileDark : profile).replace(/\\/g, "/")
  );
  const [profileFile, setProfileFile] = useState(null);
  const fileInputRef = useRef(null);

  const [payload, setPayload] = useState({
    name: "",
    icon: "",
    keywords: [],
  });

  const [error, setError] = useState({
    name: "",
    icon: "",
    keywords: "",
  });

  const validate = () => {
    if (!payload.name) {
      setError({
        ...error,
        name: t("RAL0001"),
      });
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validate()) {
      createApplication(payload)
        .then((res) => {
          if (res.data?.code === "RCI0035") {
            if (profileFile !== null) {
              let id = res.data?.data?.id;
              if (id) {
                const formData = new FormData();
                formData.append("file", profileFile);
                return uploadIconById(id, formData).then(() => {
                  toast.success("Application created successfully");
                  setIsLoading(false);
                  navigate("/settings/application");
                });
              }
            } else {
              toast.success("Application created successfully");
              setIsLoading(false);
              navigate("/settings/application");
            }
          } else if (res.data?.code === "RCE0170") {
            setError({
              ...error,
              name: res.data?.message,
            });
            setIsLoading(false);
          } else {
            toast.error(res.data?.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error(
            "An error occurred while creating the application settings."
          );
          setIsLoading(false);
          console.error(err);
        });

      return false;
    }
  };

  const handleFile = (event) => {
    handleFileError("");

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (!/\/(jpeg|jpg|png)$/i.test(file.type)) {
      handleFileError(t("RAL0059"));
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      handleFileError(t("RAL0060"));
      event.target.value = "";
      return;
    }
    try {
      setIcon(URL.createObjectURL(file));
      setProfileFile(file);
      handleFileError("");
    } catch (error) {
      console.error("Error during file upload", error);
      handleFileError(t("RAL0061"));
    }
    event.target.value = "";
  };

  const handleFileError = (message) => {
    setError({
      ...error,
      icon: message,
    });
  };

  const removeProfilePhoto = () => {
    setProfileFile(null);
    setIcon((mode === "dark" ? profileDark : profile).replace(/\\/g, "/"));
    setError({
      ...error,
      icon: "",
    });
    setPayload({
      ...payload,
      icon: "",
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "keywords") {
      setPayload((prevPayload) => ({
        ...prevPayload,
        [name]: value.split(",").map((keyword) => keyword.trim()),
      }));
    } else {
      setPayload((prevPayload) => ({
        ...prevPayload,
        [name]: value,
      }));
    }
    setError((prevError) => ({
      ...prevError,
      [name]: "",
    }));
    setIsChanged(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && payload.keywords.length > 0) {
      const input = e.target.value;
      const caretPosition = e.target.selectionStart;
      if (caretPosition === 0) {
        e.preventDefault();
        setPayload((prevPayload) => {
          const newKeywords = [...prevPayload.keywords];
          newKeywords.pop();
          return { ...prevPayload, keywords: newKeywords };
        });
      } else if (
        input[caretPosition - 1] === " " &&
        input[caretPosition - 2] === ","
      ) {
        e.preventDefault();
        setPayload((prevPayload) => {
          const newKeywords = [...prevPayload.keywords];
          newKeywords.pop();
          return { ...prevPayload, keywords: newKeywords };
        });
      }
    }
  };

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    navigate("/settings/application");
  };

  return (
    <>
      <TitleBar title={t("RAL0198")} navBack={true}></TitleBar>
      <Box height="calc(100vh - 110px)" overflow="auto" sx={{
        padding: "24px",
        backgroundColor: "var(--background-color-3)",
        borderRadius: "5px"
      }}>
        <Grid item md={12} mb={2}>
          <Typography className="form-title">{t("RAL0199")}</Typography>
        </Grid>

        <Grid container item spacing={3} mb={3}>
          <Grid item md={"auto"} sm={12} xs={12}>
            <Avatar
              alt="Profile Avatar"
              sx={{
                width: { xl: 160, xs: 160 },
                height: { xl: 160, xs: 160 },
                marginBlock: "auto"
              }}
              src={icon}
            ></Avatar>
          </Grid>

          <Grid container item md={3} xs={12} sm={12} mt={2}>
            <Box sx={{
              marginBlock: "auto"
            }}>
              <Box className="jpgMessage">
                <Typography className="form-profile-upload-text">
                  {t("RAL0203")}
                </Typography>
                <Typography className="form-profile-upload-text">
                  {t("RAL0204")}
                </Typography>
              </Box>

              <Box display={"flex"} gap={"20px"} paddingTop={"20px"}>
                <Box width={"98px"} height={"40px"}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFile}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                  />
                  <CustomButton
                    disabled={isLoading}
                    variant="contained"
                    size="small"
                    fullWidth="fullWidth"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <span> {t("RAL0064")}</span>
                  </CustomButton>
                </Box>

                <Box width={"98px"} height={"40px"}>
                  <CustomButton
                    place={"ps-remove"}
                    disabled={
                      isLoading ||
                      (profileFile === null &&
                        CommonUtil.isEmptyString(payload?.icon))
                    }
                    variant="outlined"
                    size="small"
                    fullWidth="fullWidth"
                    onClick={() => removeProfilePhoto()}
                  >
                    <span>{t("RAL0065")}</span>
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          {error.profilePhoto && (
            <Grid item md="12">
              <Typography className="error-text">{error.icon}</Typography>
            </Grid>
          )}
        </Grid>

        <Grid container item md="12" spacing={3} mb={1}>
          <Grid item md={4} sm={12} xs={12}>
            <CustomTextField
              required={true}
              name="name"
              label={t("RAL0139")}
              error={!CommonUtil.isEmptyString(error.name)}
              helperText={error.name}
              value={payload.name}
              onChange={(e) => handleChange(e)}
              inputProps={{
                maxLength: 25,
              }}
            ></CustomTextField>
          </Grid>
        </Grid>

        <Grid container item md="12" spacing={3} mb={1}>
          <Grid item md={4} sm={12} xs={12}>
            <CustomTextField
              name="keywords"
              label={t("RAL0201")}
              placeholder={t("RAL0202")}
              value={payload.keywords.join(", ")}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              inputProps={{
                maxLength: 60,
              }}
            />
          </Grid>
        </Grid>

        <Grid
          className="AppBtnSetting"
          container
          item
          md={12}
          spacing={3}
          justifyContent="flex-end"
        >
          <Grid item md={3} sm={6} xs={12} sx={{ flexBasis: "0% !important" }}>
            <CustomButton
              id="btn-add-member"
              name="btn-add-member"
              disabled={isLoading}
              variant="contained"
              size="large"
              fullWidth="fullWidth"
              onClick={handleSubmit}
            >
              <span id="btn-add-member"> {t("RAL0200")}</span>
            </CustomButton>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <CustomButton
              place={"sp-cancel"}
              id="btn-cancel-member"
              name="btn-cancel-member"
              disabled={isLoading}
              variant="outlined"
              size="large"
              fullWidth="fullWidth"
              onClick={handleCancelButton}
            >
              <span id="btn-cancel-member"> {t("RAL0177")}</span>
            </CustomButton>
          </Grid>
          <CancelDialog
            open={isCancelDialogOpen}
            onClose={handleCloseCancelDialog}
            onConfirm={handleConfirmCancel}
            header={t("RAL0379")}
            message={t("RAL0380")}
          />
        </Grid>
      </Box>
    </>
  );
}
