import React, { useContext } from "react";
// import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as EditIcon } from "../../assets/images/edit_icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import AppContext from "../../config/AppContext";
import { IconButton } from "@mui/material";
function CustomEditIcon({ onClick, title }) {
  const { mode } = useContext(AppContext);
  return (
    <Tooltip title={title}>
      <IconButton sx={{ borderRadius: "5px" }}>
        {mode == "dark" ? (
          <EditIconDark
            id="btn-edit-tag"
            name="btn-edit-tag"
            width="18px"
            // title={"Edit"}
            className="edit-icon"
            onClick={onClick}
          />
        ) : (
          <EditIcon
            id="btn-edit-tag"
            name="btn-edit-tag"
            width="18px"
            // title={"Edit"}
            className="edit-icon"
            onClick={onClick}
          />
        )}
      </IconButton>

    </Tooltip>
  );
}

export default CustomEditIcon;
