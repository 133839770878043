import { css } from "@emotion/css";
import {
  Box,
  Button,
  CircularProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import AiIcon from "../../assets/images/ralvie/ai-robot.svg";
import LavoroAiIcon from "../../assets/images/lavoro-icon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/images/ralvie/archive.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/ralvie/archiveDark.svg";
import { ReactComponent as ArrowDownDark } from "../../assets/images/ralvie/arrowdown-dark.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/ralvie/arrowdown-white.svg";
import { ReactComponent as ArrowUpDark } from "../../assets/images/ralvie/arrowup-dark.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/ralvie/arrowup-white.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/EditIcon.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/ralvie/more-icon.svg";
import { ReactComponent as SundialIcon } from "../../assets/images/ralvie/sundial-logo.svg";
import { ReactComponent as TagIcon } from "../../assets/images/ralvie/tag-outlined-icon.svg";
import { ReactComponent as WorklogCaptionDark } from "../../assets/images/ralvie/worklog-caption-dark.svg";
import { ReactComponent as WorklogCaption } from "../../assets/images/ralvie/worklog-caption.svg";
import AppIcon from "../../components/AppIcon";
import CustomButton from "../../components/CustomButton";
import CustomIconButton from "../../components/CustomIconButton";
import Typewriter from "../../components/Typewriter";
import AppContext from "../../config/AppContext";
import {
  deleteTimesheetById,
  getTimesheetById,
  listTimesheet,
  textSummaraize,
} from "../../services/WorklogService";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext, TimesheetContext } from "../../util/context";
import { summarization } from "../AI/TimelineSummary";
import ArchiveDialog from "../project/ArchiveDialog";
import AddEvents from "../timesheet/AddEvents";
import "./Worklog.scss";
function formatTimesheetData(input) {
  const projectName = input["Project Name"] || "";
  const description = input["Description"] || "";
  const tags = input["Tags"] ? input["Tags"].join(", ") : "";
  const event = input["Event"] || {};
  const eventUser = event.user || {};
  const eventStartTime = new Date(event.startTime);
  const eventEndTime = new Date(event.endTime);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // convert 0 to 12
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes}${ampm}`;
  };

  const durationMinutes = Math.floor(event.duration / 60);

  return `Project Name: ${projectName}
Tags: ${tags}
Event:
Project ID: ${event.projectId || ""}
Event Number: ${event.eventNumber || ""}
User ID: ${eventUser.userId || ""}
Email: ${eventUser.email || ""}
Application Name: ${event.applicationName || ""}
Duration: ${durationMinutes} m
Time stamp: ${formatTime(eventStartTime)} - ${formatTime(eventEndTime)}
Description: ${description}`;
}

function processTextSummary(text) {
  const summary = text?.replace(/\"/g, "").trim(); // Clean up the summary text
  const startIndex = summary.indexOf("Projects:");
  const cleanSummary = summary.substring(startIndex).trim();
  const lines = cleanSummary.split("\n"); // Split the summary into lines

  let htmlSummary = '<div class="summary">';
  lines.forEach((line) => {
    if (line.includes(":")) {
      const [label, content] = line.split(":", 2);
      htmlSummary += `<p><strong>${label.trim()}:</strong> ${content.trim()}</p>`;
    } else {
      htmlSummary += `<p>${line.trim()}</p>`;
    }
  });
  htmlSummary += "</div>";
  return htmlSummary;
}

export default function Worklog(props) {
  const { enableAi } = useContext(sidebarContext);
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const worklogsRef = useRef(null);
  const [worklogContainerHeight, setWorklogContainerHeight] = useState(0);
  const [timesheets, setTimesheets] = useState([]);
  const [toggleTimesheet, setToggleTimesheet] = useState(null);
  const [loggedHours, setLoggedHours] = useState(null);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [reset, setReset] = useState(false);
  const {
    reload,
    textSummary,
    setTextSummary,
    setReload,
    setEventByDrag,
    setTimesheetToEdit,
    setTimesheetEvents,
    setEventsToLog,
  } = useContext(TimesheetContext);
  const [textSummaryQuery, setTextSummaryQuery] = useState(undefined);
  const [dataToSummaraize, setDataToSummaraize] = useState([]);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [timesheetToDelete, setTimesheetToDelete] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(undefined);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    setWorklogContainerHeight(worklogsRef?.current?.clientHeight);
  }, [worklogsRef, timesheets, toggleTimesheet]);
  useEffect(() => {
    loadTimesheet();
  }, [props.filter?.selectedDate]);

  useEffect(() => {
    if (reset) {
      loadTimesheet();
      setReset(false);
    }
  }, [reset]);

  const loadTimesheet = () => {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(
      props.filter?.selectedDate,
      CommonUtil.getTimeZoneId()
    );
    let startDate = dateTz.startOf("day");
    let endDate = dateTz.endOf("day");
    let textQuery = "";
    let queryData = [];
    let company = CommonUtil.getLoggedUser()?.companyName;
    listTimesheet({
      startDate: startDate.utc().format(),
      endDate: endDate.utc().format(),
    }).then((res) => {
      let timesheets = res.data?.data;
      if (timesheets instanceof Array && timesheets.length > 0) {
        timesheets.forEach((e) => {
          let query = {
            company: company,
            projectName: e?.project.name,
            description: e?.project.description,
            tags: e?.tags?.map((t) => t.name),
            event: e.events?.map((t) => t.windowTitle),
            duration: `${e.loggedDuration % 60} m`,
            client: e?.project?.clientName,
            contactEmail: e?.project?.clientEmail,
            platform: e.events?.map((t) => t.applicationName),
            timeStamp: `${e.events?.[0]?.startTime} - ${
              e.events?.[e.events?.length - 1]?.startTime
            }`,
          };
          queryData.push(query);
          textQuery = textQuery + "\n" + formatTimesheetData(query);
        });
        console.log("queryData----", queryData);
        setDataToSummaraize(queryData);
        setTimesheets(res.data?.data);
      } else {
        setTimesheets([]);
      }
      if (textQuery) {
        setTextSummaryQuery(textQuery);
      }
    });
  };

  const handleSubEventsToogle = (id) => {
    if (toggleTimesheet === id) {
      setToggleTimesheet(null);
    } else {
      setToggleTimesheet(id);
    }
  };

  useEffect(() => {
    const totalDuration = timesheets?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.loggedDuration;
    }, 0);
    if (totalDuration > 0)
      setLoggedHours(CommonUtil.getDurationWithRoundoff(totalDuration));
    else setLoggedHours(null);
  }, [timesheets]);

  const handleSummarizationWithCGPT = async (language) => {
    setIsLoading(true);
    try {
      const r = await summarization(textSummaryQuery, language);
      setTextSummary(r.text.replace(/\n/g, "<br>").replace(/- /g, "• "));
      props.setGenerateSummary(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Summarization failed:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.generateSummary) {
      handleSummarization();
    }
  }, [props.generateSummary]);

  const handleSummarization = async () => {
    if (timesheets.length < 1) {
      toast.warning("No timesheet found for the day");
      props.setGenerateSummary(false);
      return;
    }
    setIsLoading(true);
    if (textSummaryQuery) {
      try {
        const r = await textSummaraize({
          language: localStorage.getItem("text-summary-lang"),
          text: dataToSummaraize,
        });
        setTextSummary(processTextSummary(r.data?.data?.summary));
        props.setGenerateSummary(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Summarization failed:", error);
        setIsLoading(false);
      }
    }
  };

  const handleEditClick = async () => {
    try {
      const response = await getTimesheetById(open);
      if (response.data?.code === "RCI0000") {
        setEventsToLog([]);
        setTimesheetToEdit(response.data.data);
        groupEventsByApp(response.data.data?.events);
        setTimesheetEvents(foramtEvents(response.data.data?.events));
      } else {
        toast.error("Failed to fetch timesheet data.");
      }
    } catch (error) {
      toast.error("An error occurred while fetching the timesheet data.");
    }
  };

  const handleDeleteClick = async () => {
    setTimesheetToDelete(open);
    setOpenArchiveDialog(true);
  };

  const handleDelete = async () => {
    if (!timesheetToDelete) {
      return;
    }
    try {
      const response = await deleteTimesheetById(timesheetToDelete);
      if (response.data?.code === "RCI0038") {
        toast.success(response.data?.message);
        loadTimesheet();
        setReload(true);
      } else {
        toast.error("Failed to delete timesheet.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the timesheet.");
    }
    setTimesheetToDelete(undefined);
    setOpenArchiveDialog(false);
    setOpen(undefined);
  };

  const handleAddEventClick = () => {
    setShowAddEvent(true);
  };

  const handleCloseAddEvent = () => {
    setShowAddEvent(false);
    setReset(true);
  };

  function foramtEvents(data) {
    const events = data.sort(
      (a, b) =>
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    );
    const formatedEvents = [];

    events.forEach((e) => {
      const eventStart = new Date(e.startTime);
      const eventEnd = new Date(e.startTime);
      eventEnd.setSeconds(eventEnd.getSeconds() + e.duration);
      const newEvent = {
        ...e,
        start: eventStart,
        end: eventEnd,
        event_id: e.id,
        title: e.data.title,
        timeStamp: eventStart,
      };
      formatedEvents.push(newEvent);
    });
    return formatedEvents;
  }

  function groupEventsByApp(events) {
    events = foramtEvents(events);
    let groupedEvents = [];

    events.forEach((event) => {
      const existingGroup = groupedEvents.find(
        (group) => group.data.app === event.data.app && group.data.app !== "afk"
      );

      if (existingGroup) {
        const start = existingGroup.start
          ? new Date(Math.min(existingGroup.start, event.start))
          : event.start;
        const end = existingGroup.end
          ? new Date(Math.max(existingGroup.end, event.end))
          : event.end;
        if (existingGroup.duration < event.duration) {
          existingGroup.id = event.id;
          existingGroup.timestamp = event.timestamp;
          existingGroup.duration = event.duration;
          existingGroup.data = event.data;
          existingGroup.event_id = event.event_id;
          existingGroup.title = event.title;
          existingGroup.start = start;
          existingGroup.end = end;
          existingGroup.projectId = event.projectId;
        }
        existingGroup.events.push({
          ...event,
          id: event.id,
          timestamp: event.timestamp,
          duration: event.duration,
          data: event.data,
          start: event.start,
          end: event.end,
          event_id: event.event_id,
          title: event.title,
        });
      } else {
        groupedEvents.push({
          ...event,
          events: [event],
          start: event.start,
          end: event.end,
        });
      }
    });
    groupedEvents = groupedEvents.filter((event) => event.duration >= 30);
    console.log(groupedEvents);
    return groupedEvents;
  }

  const ItemTypes = {
    ACTIVITY: "activity",
  };

  function ActivityDropZone({ onDrop }) {
    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.ACTIVITY,
      drop: (item) => {
        onDrop(item);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    return (
      <div
        ref={drop}
        pb={3}
        style={{
          background: isOver ? "#E0E0E0" : "#FFFFFF",
          // border: isOver ? "2px dashed #A9A9A9" : "2px dashed transparent",
        }}
      >
        <Box
          item
          flexGrow={1}
          className={css`
            position: absolute;
            min-height: calc(
              100% - 1.9rem -
                ${worklogContainerHeight ? worklogContainerHeight : 0}px
            );
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            align-items: center;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${encodeURIComponent(
              mode == "dark" ? "#f8f8f84d" : "#2424244d"
            )}' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          `}
        >
          {!showAddEvent && (
            <>
              <Box item>
                {mode == "light" ? <WorklogCaption /> : <WorklogCaptionDark />}
              </Box>
            </>
          )}
          {!showAddEvent && (
            <>
              <Box item>
                <Typography className="worklog-caption">
                  You can add more events in the timesheet
                </Typography>
              </Box>
            </>
          )}

          {!showAddEvent && (
            <>
              <Box item>
                <Typography className="worklog-caption">
                  to log with the project
                </Typography>
              </Box>
            </>
          )}

          {!showAddEvent && (
            <Box
              display={"flex"}
              height={"10%"}
              alignItems={"center"}
              justifyContent={"center"}
              my={2}
            >
              <CustomButton
                id={props.id}
                variant="contained"
                fullWidth={true}
                onClick={handleAddEventClick}
              >
                Add new event
              </CustomButton>
            </Box>
          )}
        </Box>
      </div>
    );
  }

  function ActivityDropButton({ onDrop }) {
    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.ACTIVITY,
      drop: (item) => {
        onDrop(item);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    return (
      <div
        ref={drop}
        style={{
          padding: "15px 0",
        }}
        className="btnFixedScroll-1"
      >
        <Box
          display={"flex"}
          height={"10%"}
          alignItems={"center"}
          justifyContent={"center"}
          className={css`
            position: sticky;
            bottom: 0;
          `}
        >
          <CustomButton
            variant="contained"
            fullWidth={true}
            onClick={handleAddEventClick}
          >
            Add new event
          </CustomButton>
        </Box>
      </div>
    );
  }

  const handleDropActivity = (event) => {
    setEventByDrag(event);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={css`
          min-height: calc(100% - 40px);
          position: relative;
        `}
      >
        {!showAddEvent && !selectedTimesheet && (
          <>
            <Box
              item
              className={css`
                padding-bottom: 14px;
                align-items: center;
                display: flex;
              `}
              style={{
                position: "sticky",
                top: 0,
                background: "var(--background-color-3)",
                paddingTop: "14px",
                zIndex: 1,
                height: "50px",
              }}
            >
              <Box flexGrow={1}>
                <Typography
                  variant="header3"
                  sx={{
                    color: "var( --text-secondary-80)",
                    font: "normal normal bold 18px/20px Gotham",
                    letterSpacing: "0px",
                  }}
                >
                  {dayjs(props.filter?.selectedDate).isSame(dayjs(), "day")
                    ? "Today's"
                    : props.filter?.selectedDate?.format("DD-MM-YY")}{" "}
                  {textSummary ? " summary" : " timesheet"}
                </Typography>
              </Box>
              {!enableAi && !textSummary && loggedHours && (
                <Box>
                  <Typography variant="header4">
                    {loggedHours?.hr + "h " + loggedHours?.min + "m"}
                  </Typography>
                </Box>
              )}
              {enableAi && (
                <CustomIconButton
                  id="btn-import-department"
                  title={t("RAL0345")}
                  size={"md"}
                  variant="square"
                  lightMode={
                    <img
                      // src={AiIcon}
                      src={LavoroAiIcon}
                      alt=""
                      height={isXlScreen ? "18px" : "14px"}
                      width={"20px"}
                    />
                  }
                  darkMode={
                    <img
                      // src={AiIcon}
                      src={LavoroAiIcon}
                      alt=""
                      height={isXlScreen ? "18px" : "14px"}
                      width={"20px"}
                    />
                  }
                  onClick={() => navigate("/ai/summary")}
                ></CustomIconButton>
              )}
            </Box>
          </>
        )}

        {textSummary && (
          <>
            <div
              className={css`
                min-height: calc(100% - 60px);
                position: relative;
                overflow: hidden;
                :hover {
                  overflow-y: auto;
                }
              `}
            >
              <Box>
                {" "}
                {/* <div dangerouslySetInnerHTML={{ __html: textSummary }} /> */}
                <div>
                  <Typewriter text={textSummary} delay={20} />
                </div>
              </Box>
            </div>
            <Box
              pt={2}
              display={"flex"}
              flexDirection={"row-reverse"}
              minWidth={"100%"}
              columnGap={2}
            >
              <CustomButton
                variant="outlined"
                onClick={() => setTextSummary(undefined)}
              >
                Close
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() => {
                  CommonUtil.copyToClipboard(textSummary);
                  setTextSummary(undefined);
                }}
              >
                Copy
              </CustomButton>
            </Box>
          </>
        )}

        {!textSummary && !showAddEvent && !selectedTimesheet && (
          <div
            ref={worklogsRef}
            className={css`
              max-height: 70vh;
              overflow: auto;
              margin-bottom: 0px;
              &:hover {
                overflow-y: auto;
              }
            `}
          >
            {timesheets?.map((ts) => {
              let duration = CommonUtil.getDurationWithRoundoff(
                ts?.loggedDuration
              );
              const title = ts?.title ? ts.title : ts.events[0].windowTitle;
              const projectName = ts?.project?.name;
              return (
                <div
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  <Box
                    className={css`
                      width: 100%;
                      background: ${ts?.project?.color};
                      border-radius: 5px;
                      opacity: 1;
                      padding: 16px;
                    `}
                  >
                    <Box display={"flex"}>
                      <Box
                        item
                        display={"flex"}
                        pb={1}
                        className={css`
                          white-space: wrap;
                          text-overflow: ellipsis;
                        `}
                        flexGrow={1}
                      >
                        {title.length > 50 ? (
                          <Tooltip title={title} placement="top" arrow>
                            <Typography
                              variant="worklog_project_title"
                              sx={{ wordBreak: "break-all" }}
                            >
                              <span>{`${title.substring(0, 50)}...`}</span>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            variant="worklog_project_title"
                            sx={{ wordBreak: "break-all", textTransform: "capitalize" }}
                          >
                            {title}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Button
                          className="timesheetMenu"
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                            setOpen(ts?.id);
                          }}
                          sx={{
                            "& .more_svg": {
                              transition: "color 0.3s ease",
                              color: "var(--color-33)",
                            },
                            "&:hover": {
                              backgroundColor: "#00000033 !important",
                            },
                          }}
                        >
                          <MoreIcon
                            height={"14px"}
                            width={"14px"}
                            className="more_svg"
                          />
                        </Button>
                      </Box>
                    </Box>

                    <Box item display={"flex"} pb={1}>
                      {projectName.length > 14 ? (
                        <Tooltip title={projectName} placement="top" arrow>
                          <Typography
                            variant="worklog_project_title"
                            sx={{ wordBreak: "break-all" }}
                          >
                            <span>{`${projectName.substring(0, 14)}...`}</span>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="worklog_project_title"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {projectName}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      item
                      className={css`
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      `}
                    >
                      {ts?.project?.clientName && (
                        <Typography variant="worklog_project_details">
                          {ts?.project?.clientName}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      item
                      display={"flex"}
                      alignItems={"center"}
                      justifyItems={"center"}
                    >
                      <Box item flexGrow={1}>
                        {!ts.events?.some(
                          (event) => event.source === "MANUAL"
                        ) && (
                          <SundialIcon
                            height="14px"
                            style={{ paddingRight: "4px" }}
                          />
                        )}
                        <Typography variant="worklog_project_details">
                          {duration?.hr + "h " + duration?.min + "m"}
                        </Typography>
                      </Box>
                      {ts.tags?.length > 0 && (
                        <Tooltip
                          title={ts.tags?.map(
                            (tag, index) =>
                              tag.name +
                              (index === Number(ts.tags.length - 1) ? "" : ", ")
                          )}
                        >
                          <Box item pr={0.5}>
                            <TagIcon className="icon" />
                          </Box>
                        </Tooltip>
                      )}
                      {/* <Box item pr={0.5} onClick={() => handleDeleteClick(ts.id)}>
                      <Tooltip title="Delete">
                         <DeleteIcon sx={{ color: "#FFFFFF", fontSize: 20, cursor: "pointer" }} />
                      </Tooltip>
                    </Box> */}

                      <Box
                        item
                        className={css`
                          height: 30px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          min-width: 30px !important;
                        `}
                      >
                        {toggleTimesheet === ts?.id ? (
                          <ArrowUp
                            className="icon"
                            onClick={() => handleSubEventsToogle(ts?.id)}
                          />
                        ) : (
                          <ArrowDown
                            className="icon"
                            onClick={() => handleSubEventsToogle(ts?.id)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>

                  {toggleTimesheet === ts?.id && ts?.events && (
                    <Box
                      item
                      className={css`
                        background: var(--background-color-4);
                        opacity: 1;
                        maxheight: 200px;
                        overflow: hidden;
                        :hover {
                          overflow-y: auto;
                        }
                      `}
                    >
                      {ts?.events?.map((e) => {
                        let eventTime = CommonUtil.getDuration(e?.duration);
                        return (
                          <Box
                            m={1}
                            mx={2}
                            className={css`
                              background: var(--background-color-3);
                              border-radius: 5px;
                              opacity: 1;
                            `}
                          >
                            <Box display={"flex"} p={2}>
                              <Box item pr={2}>
                                <AppIcon
                                  data={e?.data}
                                  height={18}
                                  width={18}
                                  type="event"
                                  id={e?.eventNumber}
                                />
                              </Box>
                              <Box
                                item
                                flexDirection={"column"}
                                className={css`
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                `}
                              >
                                <Tooltip title={e.windowTitle}>
                                  <span
                                    className={css`
                                      font: normal normal normal 16px/19px
                                        GothamMedium;
                                      letter-spacing: 0px;
                                      color: var(--text-primary);
                                      opacity: 1;
                                    `}
                                  >
                                    {" "}
                                    {e.windowTitle}
                                  </span>
                                </Tooltip>
                                <Box item py={1}>
                                  <Typography
                                    className={css`
                                      font: normal normal normal 12px/14px
                                        Gotham;
                                      letter-spacing: 0px;
                                      color: var(--text-primary);
                                      opacity: 1;
                                    `}
                                  >
                                    {" "}
                                    {eventTime.hr +
                                      "h " +
                                      eventTime.min +
                                      "m " +
                                      eventTime.sec +
                                      "s"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {!textSummary && !showAddEvent && (
          <>
            {timesheets?.length < 1 && (
              <ActivityDropZone
                onDrop={(item) => handleDropActivity(item?.event)}
              />
            )}
          </>
        )}

        {!textSummary &&
          timesheets?.length > 0 &&
          !showAddEvent &&
          !selectedTimesheet && (
            <ActivityDropButton
              onDrop={(item) => handleDropActivity(item?.event)}
            />
          )}
        {!textSummary && showAddEvent && (
          <AddEvents
            onClose={handleCloseAddEvent}
            selectedDate={props.filter?.selectedDate}
          />
        )}

        <Box
          sx={{
            display: isLoading ? "flex" : "none",
            zIndex: 999,
          }}
        >
          <CircularProgress
            style={{ top: "50%", left: "85%", position: "fixed" }}
          />
        </Box>
        <ArchiveDialog
          open={openArchiveDialog}
          onClose={() => {
            setOpenArchiveDialog(false);
            setOpen(undefined);
          }}
          onConfirm={() => handleDelete()}
          name="Timesheet"
          type="Timesheet"
          actionType={"delete"}
        />
        <Menu
          className="projectActionBtn"
          open={open !== undefined} // Only open the menu for the active project
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setOpen(undefined);
          }}
        >
          <MenuItem onClick={() => handleEditClick()}>
            <ListItemIcon>
              {mode === "dark" ? (
                <EditIconDark className="icon" />
              ) : (
                <EditIcon className="icon" />
              )}
            </ListItemIcon>
            <Box
              item
              sx={{
                color:
                  mode === "dark"
                    ? "var(--color-33-80)"
                    : "var(--text-secondary-80-80)",
              }}
            >
              {"Edit"}
            </Box>
          </MenuItem>
          <MenuItem onClick={() => handleDeleteClick()}>
            <ListItemIcon>
              {mode == "dark" ? (
                <ArchiveIconDark className="archiveIcon" />
              ) : (
                <ArchiveIcon className="archiveIcon" />
              )}
            </ListItemIcon>
            <Box
              item
              sx={{
                color:
                  mode === "dark"
                    ? "var(--color-33-80)"
                    : "var(--text-secondary-80-80)",
              }}
            >
              {"Delete"}
            </Box>
          </MenuItem>
        </Menu>
      </div>
    </DndProvider>
  );
}
