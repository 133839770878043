import { css } from "@emotion/css";
import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/images/ralvie/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/ralvie/ArrowUp.svg";
import { ReactComponent as CancelIconWhite } from "../../assets/images/ralvie/close-icon-white.svg";
import AppIcon from "../../components/AppIcon";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
import EventItem from "./EventItem";
export default function EventList(props) {
  const { mode } = useContext(AppContext);
  const [state, setState] = useState({});
  const [showSubEvents, setShowSubEvents] = useState(false);
  const [subEvents, setSubEvents] = useState([]);

  const [duration, setDuration] = useState(props.duration);
  useEffect(() => {
    let event = props.event;
    if (event) {
      setState({ ...state, title: event.title });
      setSubEvents(props.event?.events);
    } else {
      setSubEvents([]);
    }
  }, [props.event]);

  return (
    <div
      style={{ width: "100%" }}
      className={css`
        background: ${props?.event?.light} 0% 0% no-repeat padding-box;
        border-radius: 2px;
        border-left: 4px solid ${props?.event?.dark};
        opacity: 1;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
      `}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={1}
        pl={0.5}
        py={1.5}
      >
        <Box
          display={"flex"}
          className={css`
            width: calc(100% - 1.5rem);
          `}
        >
          <Box px={1.5} style={{ paddingTop: "0.3rem" }}>
            <AppIcon
              data={props?.event?.data}
              height={18}
              width={18}
              type="event"
              id={props.event?.event_id}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            rowGap={1}
            className={css`
              width: calc(100% - 3rem);
            `}
          >
            <Tooltip title={props?.event?.title}>
              <span
                className={css`
                  font: normal normal normal 16px / 19px GothamMedium !important;
                  letter-spacing: 0px;
                  opacity: 1;
                  color: var(--text-color-1) !important;
                  padding-top: 0.4rem;
                  white-space: wrap;
                  // display: inline-block;
                  // max-width: 90%;
                  // overflow: hidden;
                  // text-overflow: ellipsis;
                `}
              >
                {props?.event?.title}
              </span>
              {/* {props?.event?.events && (
                <Box
                  className={css`
                    display: inline-block;
                    padding-left: 10px !important;
                  `}
                ></Box>
              )} */}
            </Tooltip>
            <Typography
              className={css`
                font: normal normal normal 12px / 14px Gotham !important;
                letter-spacing: 0px;
                opacity: 1;
                color: var(--text-color-7) !important;
              `}
            >
              {duration?.hr + "h " + duration?.min + "m " + duration?.sec + "s"}
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            item
            className={css`
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background: #fe5050 0% 0% no-repeat padding-box;
              border-radius: 5px;
              opacity: 1;
              height: 20px;
              width: 20px;
            `}
          >
            <CancelIconWhite onClick={() => props.onRemove()} />
          </Box>
          <Box
            sx={{
              "& .up_down_svg": {
                transition: "color 0.3s ease",
                color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
              },
              "&:hover": {
                "& .hover-text, & .up_down_svg": {
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                },
              },
            }}
          >
            {showSubEvents && subEvents.length ? (
              <ArrowUp
                className="icon up_down_svg"
                onClick={() => setShowSubEvents(false)}
              />
            ) : (
              <ArrowDown
                className="icon up_down_svg"
                onClick={() => setShowSubEvents(true)}
              />
            )}
          </Box>
        </Box>
      </Box>
      {showSubEvents && (
        <Box display={"flex"} flexDirection={"column"}>
          {subEvents?.map((event) => {
            let subEventDuration = CommonUtil.getDurationWithoutRoundoff(
              event.duration
            );
            return (
              <Box
                display={"flex"}
                flexDirection={"row"}
                py={1}
                mx={1}
                borderTop={"1px solid var(--border-color-1)"}
              >
                <Box>
                  <Checkbox
                    color="secondary"
                    checked={props.isEventUnselected(event.id)}
                    onChange={() => props.onRemoveSubevent(event)}
                  />
                </Box>
                <EventItem
                  editable={props.editable}
                  event={event}
                  duration={subEventDuration}
                  setEventToEdit={(e) => props.setEventToEdit(e)}
                  onTitleUpdate={() => props.onTitleUpdate(event)}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </div>
  );
}
