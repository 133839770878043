import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  styled,
  InputBase,
  Hidden,
  CircularProgress,
  css,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/ArrowDown.svg";
import { ReactComponent as AddIcon } from "../../assets/images/add-icon.svg";
import { ReactComponent as CloseIconImg } from "../../assets/images/close-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import TagIcon from "../../assets/images/ralvie/tag-icon-inactive.svg";
import dropDown from "../../assets/images/ralvie/dropDown.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/images/ArrowDown.svg";
import Emojipicker from "./Emojipicker";
import CustomInput from "../../components/CustomInput";
import TagService, {
  createTag,
  tagList,
  uploadTagIconById,
  updateTag,
  getTagByName,
  softDeleteTag,
  exportTags,
} from "../../services/TagService";
import ContentLayout from "../../components/layout-components/ContentLayout";
import "./tag.scss";
import TagTextField from "./TagTextField";
import CustomButton from "../../components/CustomButton";
import CustomIconButton from "../../components/CustomIconButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { Picker } from "emoji-mart";
import CustomEditIcon from "./CustomEditIcon";
import CustomRefreshButton from "./CustomRefreshButton";
import CustomSearchField from "../../components/CustomSearchField";
import { ReactComponent as EmptyTagList } from "../../assets/images/ralvie/empty_tag_list.svg";
import { ReactComponent as EmptyTagListDark } from "../../assets/images/ralvie/empty_tag_list_dark.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/images/ralvie/archive.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/ralvie/archiveDark.svg";
import AppContext from "../../config/AppContext";
import { ReactComponent as ExportIcon } from "../../assets/images/ralvie/export-icon.svg";
import { ReactComponent as ExportIconDark } from "../../assets/images/ralvie/export-icon-dark.svg";
import ArchiveDialog from "../project/ArchiveDialog";
import {
  bindTrigger,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";

export default function Demo() {
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const [tagIconFile, setTagIconFile] = useState(null);
  const [tagIcon, setTagIcon] = useState(TagIcon.replace(/\\/g, "/"));
  const [tags, setTags] = useState([]);
  const [add, setAdd] = useState(false);
  // const [edit, setEdit] = useState(false);
  const [editTag, setEditTag] = useState("");
  const [editTagName, setEditTagName] = useState("");
  const [editingTagId, setEditingTagId] = useState(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [dialogActionType, setDialogActionType] = useState("");
  const [activeTagMode, setActiveTagMode] = useState(null);

  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isLargeScreen = useMediaQuery("(min-width: 1900px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1900px)"
  );
  const isSmallScreen = useMediaQuery("(max-width: 1199px)");
  const [isLoading, setIsLoading] = useState(false);
  //const [tags, setTags] = useState([]);
  const [newTagName, setNewTagName] = useState("");
  const [tagError, setTagError] = useState(false);
  const [open, setOpen] = useState(false);
  const [tagId, setTagId] = useState(null);
  const [filter, setFilter] = useState({
    name: "",
  });
  const EmojiPopupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });

  useEffect(() => {
    loadTags();
  }, [filter]);

  const loadTags = () => {
    setIsLoading(true);
    setTags([]);
    getTagByName(filter).then((res) => {
      if (res?.data?.code === "RCI0000") {
        setTags(res.data.data.tags);
        setIsLoading(false);
      }
    });
  };

  const handleOpen = (id) => {
    setTagId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleArchiveOpenTag = (id) => {
    setSelectedTagId(id);
    setOpenArchiveDialog(true);
    setDialogActionType("archive");
  };

  const handleArchiveTag = async (id) => {
    try {
      const response = await softDeleteTag(selectedTagId);

      if (response.data?.code === "RCI0000") {
        setFilter({ name: "" });
        setTagId(null);
        toast.success(t("RAL0195"));
        handleCloseDialog();
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emojiObject) => {
    setSelectedEmoji(emojiObject);
    EmojiPopupState.close();
    setShowEmojiPicker(false); // Close emoji picker after selecting an emoji
  };

  // const handleEditTag = (tag) => {
  //   if (!add) {
  //     setError(undefined);
  //     setEditingTagId(tag.id);
  //     setEditTag(tag);
  //     setEditTagName(tag.name);
  //     setSelectedEmoji(tag.iconUrl ? { imageUrl: tag.iconUrl } : null);
  //   }
  // };
  const handleEditTag = (tag) => {
    setAdd(false);  // Close the add box if it's open
    setError(undefined);
    setEditingTagId(tag.id);
    setEditTag(tag);
    setEditTagName(tag.name);
    setSelectedEmoji(tag.iconUrl ? { imageUrl: tag.iconUrl } : null);
};

  // const handleAddClick = () => {
  //   if (!editingTagId) {
  //     setAdd(true);
  //   }
  // };

  const handleAddClick = () => {
    if (editingTagId) {
        setEditingTagId(null); // Clear the editing tag ID
        setEditTag(null);      // Clear the edit tag
        setEditTagName("");    // Clear the edit tag name
        setSelectedEmoji(null); // Clear the selected emoji
    }
    setAdd(true);
};

  const handleEditChange = (e) => {
    setEditTagName(e.target.value);
    if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(e.target.value)) {
      setError("Invalid tag name");
    } else {
      setError("");
    }
  };

  const handleEditSubmit = () => {
    const payload = {
      name: editTagName,
      color: editTag.color,
      iconUrl: selectedEmoji ? selectedEmoji.imageUrl : "",
    };

    updateTag(editTag.id, payload)
      .then((res) => {
        if (res.data?.code !== "RCI0023") {
          setError(res.data?.message);
        } else {
          if (tagIconFile !== null) {
            const formData = new FormData();
            formData.append("file", tagIconFile);
            uploadTagIconById(editTag.id, formData).then((res) => {
              resetOnEdit();
            });
          } else {
            resetOnEdit();
          }
        }
      })
      .catch((error) => {
        console.error("Error updating tag:", error);
        setError("An error occurred while updating the tag.");
      });
  };

  const resetOnEdit = () => {
    toast.success(t("RAL0196"));
    setEditingTagId(null);
    setEditTag(null);
    setError("");
    setEditTagName("");
    setSelectedEmoji(null);
    setFilter({ name: "" }); // Reload tags after editing
  };

  const [newTag, setNewTag] = useState({
    name: "",
    color: "",
    iconUrl: "",
  });
  const [error, setError] = useState("");

  // const handleAddChange = (e) => {
  //   setNewTag({ ...newTag, name: e.target.value });
  //   setError("");
  // };
  const handleAddChange = (e) => {
    const value = e.target.value;
    setNewTag({ ...newTag, name: value });
    if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)) {
      setError("Invalid tag name");
    } else {
      setError("");
    }
  };

  const handleFileError = (message) => {
    setError(message);
  };

  const handleFile = (event) => {
    handleFileError(""); // Resetting file error at the beginning

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (!/\/(jpeg|jpg|png)$/i.test(file.type)) {
      handleFileError(t("RAL0059"));
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      handleFileError(t("RAL0060"));
      event.target.value = "";
      return;
    }

    try {
      setTagIcon(URL.createObjectURL(file));
      setTagIconFile(file);
      handleFileError("");
    } catch (error) {
      console.error("Error during file upload", error);
      handleFileError(t("RAL0061"));
    }
    event.target.value = "";
  };

  const resetFilter = () => {
    setFilter({ name: "" });
    setNewTag({
      name: "",
      color: "",
      iconUrl: "",
    });
    setClearSearch(true);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: 4,
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: isXlScreen ? "12px 26px 12px 12px" : "9px 26px 7px 12px",
      backgroundColor: "transparent !important",
      opacity: 1,
      font: "normal normal normal 16px/20px Gotham",
      color: "#242424",
    },
  }));

  const NoMatchingTags = () => {
    console.log("No matching tags found.");

    return (
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        marginTop={"150px"}
      >
        <Box item md="12">
          {mode == "light" ? <EmptyTagList /> : <EmptyTagListDark />}
        </Box>
        <Typography
          sx={{
            color: "var(--text-color-6) !important",
            marginTop: "30px",
            font: "normal normal normal 14px/17px Gotham !important",
            letterSpacing: "0px",
          }}
          variant="h6"
          gutterBottom
        >
          No tags found with the selected search term.
        </Typography>
      </Box>
    );
  };

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      setFilter({ name: value });
      setClearSearch(false);
    }
  };

  const handleClearSearch = () => {
    setFilter({ name: "" });
    setClearSearch(true);
  };

  const actions = (
    <Box display={"flex"}>
      {/* SEARCH BAR */}
      <Box item pr={2.5} className="SearchTagFieldInput">
        <CustomSearchField
          id="btn-search-tag"
          name="btn-search-tag"
          clearSearch={clearSearch}
          onChange={(newValue) => handleSearch(newValue)}
          onClear={handleClearSearch}
          label={t("RAL0192")}
          value={filter.name}
          size={isXlScreen ? "large" : "md"}
          // onClick={(value) => setFilter({ ...filter, name: value })}
          onClick={(value) => {
            handleSearch(value);
          }}
        />
      </Box>

      {/* <Box mr={1.5}>
        <Box
          sx={{
            "& .export_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .export_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            className="svgBtn"
            id="btn-custom-add-member"
            name="btn-custom-add-member"
            title={t("RAL0193")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              <ExportIcon
                className="export_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <ExportIcon
                className="export_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={() => {
              tags?.length > 0 ? exportTags() : toast.error("No tags found");
            }}
          />
        </Box>
      </Box> */}

      {/* Refresh Button */}
      <Box mr={2.5}>
        <Box
          sx={{
            "& .refresh_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .refresh_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="btn-refresh-department"
            title={t("RAL0169")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              <RefreshIcon
                className="refresh_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <RefreshIcon
                className="refresh_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={() => resetFilter()}
          />
        </Box>
      </Box>
      {/* <Box mr={1.5}>
        <CustomIconButton
          id="btn-custom-add-member"
          name="btn-custom-add-member"
          title={t("RAL0193")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          type="contained"
          lightMode={<ExportIcon height={isXlScreen ? "18px" : "14px"} />}
          darkMode={<ExportIcon height={isXlScreen ? "18px" : "14px"} />}
          onClick={() => {
            tags?.length > 0 ? exportTags() : toast.error("No tags found");
          }}
        />
      </Box> */}

      {/* Add Button */}
      <CustomIconButton
        id="btn-add-tags"
        name="btn-add-tags"
        title={t("RAL0170")}
        value={newTagName}
        variant="square"
        size={isXlScreen ? "large" : "md"}
        type="contained"
        border={"none"}
        lightMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
        darkMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
        onClick={handleAddClick}
        onChange={(e) => setNewTagName(e.target.value)}
      />
    </Box>
  );

  const resetOnAdd = () => {
    toast.success(t("RAL0197"));
    setAdd(false);
    setError("");
    setNewTag({ name: "", color: "", emojiUrl: "" });
    setSelectedEmoji(null);
    setFilter({ name: "" });
  };

  const handleAddTag = () => {
    const payload = {
      name: newTag.name,
      color: newTag.color,
      iconUrl: selectedEmoji
        ? selectedEmoji.imageUrl
        : "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='17.998' viewBox='0 0 18 17.998'%3e%3cpath id='Subtraction_258' data-name='Subtraction 258' d='M17638.271%2c9060.5a2.493%2c2.493%2c0%2c0%2c1-1.781-.737l-7.795-7.785a.679.679%2c0%2c0%2c1-.2-.474v-8.332a.672.672%2c0%2c0%2c1%2c.67-.67h8.336a.654.654%2c0%2c0%2c1%2c.469.192l7.713%2c7.721v.046l.078.077a2.519%2c2.519%2c0%2c0%2c1%2c0%2c3.558l-5.713%2c5.667A2.506%2c2.506%2c0%2c0%2c1%2c17638.271%2c9060.5Zm-8.434-16.663h0v7.395l7.594%2c7.583a1.186%2c1.186%2c0%2c0%2c0%2c1.668%2c0l5.721-5.716a1.154%2c1.154%2c0%2c0%2c0%2c.342-.825%2c1.192%2c1.192%2c0%2c0%2c0-.342-.843l-7.594-7.594Zm3.5%2c4.629a1.13%2c1.13%2c0%2c1%2c1%2c1.129-1.13A1.135%2c1.135%2c0%2c0%2c1%2c17633.338%2c9048.465Z' transform='translate(-17628.5 -9042.501)' fill='%23a7a7a7'/%3e%3c/svg%3e", // Assuming selectedEmoji has the imageUrl property
    };

    createTag(payload).then((res) => {
      if (res.data?.code !== "RCI0022") {
        setError(res.data?.message);
      } else {
        if (tagIconFile !== null) {
          let id = res.data?.data?.id;
          if (id) {
            const formData = new FormData();
            formData.append("file", tagIconFile);
            uploadTagIconById(id, formData).then((res) => {
              resetOnAdd();
            });
          }
        } else {
          resetOnAdd();
        }
      }
    });
  };

  const addTag = () => {
    return (
      <>
        <Grid
          item
          xs={isLargeScreen ? 3 : isMediumScreen ? 4 : isSmallScreen ? 6 : 12}
        >
          <Grid item className="tag-box" mr={1} mb={1}>
            <Box
              mt={3}
              display="flex"
              alignItems="center"
              className="tag"
              justifyContent="center"
              flexDirection="row-reverse"
              marginLeft={2}
              width={325}
              height="60px"
              spacing={3}
              top="188px"
              left="360px"
              borderRadius="5px"
            >
              <Box
                // marginLeft={2}
                display={"flex"}
                alignItems={"center"}
                // className="new-tag"
                className={"new-tag " + (error ? "error-box" : "")}
                justifyContent={"center"}
                flexDirection={"row-reverse"}
              >
                <Box
                  display={"flex"}
                  flexGrow={1}
                  className="tag-name"
                  alignItems={"center"}
                >
                  <Box item flexGrow={1}>
                    <TagTextField
                      label={t("RAL0359")}
                      error={!!error}
                      helperText={error}
                      // onChange={(e) => handleAddChange(e)}
                      // inputProps={{
                      //   maxLength: 60,
                      // }}
                      onChange={(e) => handleAddChange(e)}
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    flexGrow={1}
                    alignItems={"center"}
                  >
                    <Box>
                      <CustomButton
                        disabled={error || !newTag.name}
                        size="small"
                        variant="contained"
                        onClick={() => handleAddTag()}
                      >
                        {t("RAL0170")}
                      </CustomButton>
                    </Box>

                    <Box
                      sx={{
                        height: "20px",
                        width: "20px",
                        background: "#FE5050 0% 0% no-repeat padding-box",
                        borderRadius: "4px",
                        opacity: "1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        marginLeft: "15px",
                      }}
                      onClick={() => {
                        setAdd(false);
                        setError("");
                        setNewTag({
                          name: "",
                          color: "",
                          iconUrl: "",
                        });
                        setSelectedEmoji(null); // Reset selected emoji
                      }}
                    >
                      <Tooltip title={t("RAL0177")}>
                        <CloseIconImg />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"20px"}
                  paddingInline={"1rem"}
                  className="tag-icon pointer"
                  {...bindTrigger(EmojiPopupState)}
                  // onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                >
                  <Box item flexGrow={1} className="DropdownIcon">
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFile}
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                    <Avatar
                      variant="square"
                      src={
                        selectedEmoji ? selectedEmoji.imageUrl : tagIcon // Use imageUrl of selectedEmoji
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                  </Box>
                  <ArrowDown width="14px" height="8px" />
                </Box>
              </Box>

              {/* EmojiPicker Popover */}
              <Popover
                open={showEmojiPicker}
                anchorEl={anchorEl}
                onClose={() => setShowEmojiPicker(false)}
                style={{
                  top: "200px",
                  left: "250px",
                }}
              >
                <Box p={2}>
                  <Emojipicker onEmojiSelect={handleEmojiSelect} />
                </Box>
              </Popover>
            </Box>
            {error && (
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{ position: "relative", top: "5px" }}
              >
                <Box item pr={1} pt={0.2}>
                  {<ErrorIcon height={14} width={16} />}
                </Box>
                <Box item>
                  <Typography className="helper-text">{error}</Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </>
    );
  };
  // Call addTag function to render the tag
  {
    add && addTag();
  }

  const editTagBox = (isEditing) => {
    return (
      <>
        <Box
          display={"flex"}
          alignItems={"center"}
          className="edit-tag"
          justifyContent={"center"}
          flexDirection={"row-reverse"}
          sx={{
            backgroundColor: "var(--background-color-19)",
          }}
        >
          <Box
            display={"flex"}
            flexGrow={1}
            className="tag-name"
            alignItems={"center"}
          >
            <Box item flexGrow={1}>
              <TagTextField
                id="btn-edit-tag"
                name="btn-edit-tag"
                label="Tag name"
                value={editTagName}
                onChange={handleEditChange}
                inputProps={{
                  maxLength: 20,
                }}
              />
            </Box>
            <Box
              display={"flex"}
              flexGrow={1}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Box>
                <CustomButton
                  disabled={error || !editTagName}
                  id="btn-update-tag"
                  name="btn-update-tag"
                  size="small"
                  variant="contained"
                  onClick={handleEditSubmit}
                >
                  {t("RAL0309")}
                </CustomButton>
              </Box>

              <Box
                sx={{
                  height: "20px",
                  width: "20px",
                  background: "#FE5050 0% 0% no-repeat padding-box",
                  borderRadius: "4px",
                  opacity: "1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginLeft: "15px",
                }}
                onClick={() => {
                  setEditTag(null);
                  setEditingTagId(null);
                  setError("");
                  setNewTag({
                    name: "",
                    color: "",
                    iconUrl: "",
                  });
                  setSelectedEmoji(null);
                }}
              >
                <Tooltip title={t("RAL0177")}>
                  <CloseIconImg />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            paddingInline={"1rem"}
            className="tag-icon pointer"
            // onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            {...bindTrigger(EmojiPopupState)}
          >
            <Box item flexGrow={1}>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFile}
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg"
              />
              <Avatar
                variant="square"
                src={
                  selectedEmoji ? selectedEmoji.imageUrl : tagIcon // Use imageUrl of selectedEmoji
                }
                sx={{ width: 24, height: 24 }}
              />
            </Box>
            <ArrowDown width="14px" height="8px" />
          </Box>
        </Box>

        {isEditing && error && (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ position: "relative", top: "5px" }}
          >
            <Box item pr={1} pt={0.2}>
              {<ErrorIcon height={14} width={16} />}
            </Box>
            <Box item>
              <Typography className="helper-text">{error}</Typography>
            </Box>
          </Box>
        )}

        {/* EmojiPicker Popover */}
        <Popover
          open={showEmojiPicker}
          anchorEl={anchorEl}
          onClose={() => setShowEmojiPicker(false)}
          style={{
            top: "200px",
            left: "250px",
          }}
        >
          <Box p={2}>
            <Emojipicker onEmojiSelect={handleEmojiSelect} />
          </Box>
        </Popover>
      </>
    );
  };
  {
    editTag && editTagBox();
  }

  const tag = (data) => {
    const isEditing = editingTagId === data.id;

    return (
      <Grid item className="tag-box" mr={1} mb={1}>
        {isEditing ? (
          editTagBox(isEditing)
        ) : (
          <Box
            mt={3}
            display="flex"
            alignItems="center"
            className="tag"
            justifyContent="center"
            flexDirection="row-reverse"
            marginLeft={2}
            width={325}
            height="60px"
            spacing={3}
            top="188px"
            left="360px"
            borderRadius="5px"
          >
            <>
              <Box width="80%">
                {/* Text field and edit icon */}
                <Box display="flex" alignItems="center" className="tag-name">
                  <Box flexGrow={1}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      textAlign="left"
                      sx={{
                        wordBreak: "break-word",
                      }}
                    >
                      {data?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <CustomEditIcon
                      id="btn-edit-tag"
                      name="btn-edit-tag"
                      width="18px"
                      title={t("RAL0172")}
                      className="edit-icon"
                      onClick={() => handleEditTag(data)}
                    ></CustomEditIcon>
                  </Box>

                  <Box sx={{ padding: "2px" }}>
                    <Tooltip title={t("RAL0173")}>
                      <IconButton sx={{ borderRadius: "5px" }}>
                        {mode == "dark" ? (
                          <ArchiveIconDark
                            id="btn-archive-tag"
                            name="btn-archive-tag"
                            width="18px"
                            className="archive-icon"
                            onClick={() => handleArchiveOpenTag(data.id)}
                          />
                        ) : (
                          <ArchiveIcon
                            id="btn-archive-tag"
                            name="btn-archive-tag"
                            width="18px"
                            className="archive-icon"
                            onClick={() => handleArchiveOpenTag(data.id)}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>

              <Divider
                orientation="vertical"
                flexItem
                className="vertical-divider"
              />

              {/* <Box width="20%">
             Emoji
            <Avatar
              variant="square"
              src={data?.iconUrl ? data.iconUrl : TagIcon}
              sx={{ width: "100%", height: "100%" }}
            />
          </Box> */}

              <Box
                width="20%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={"26px"}
                // marginInline={"1rem"}
              >
                {/* Emoji */}
                <Avatar
                  variant="square"
                  src={data?.iconUrl ? data.iconUrl : TagIcon}
                  sx={{ width: 24, height: 24 }}
                  type="emoji"
                />
                {/* <ArrowDown width="14px" height="8px" /> */}
              </Box>
            </>
          </Box>
        )}
      </Grid>
    );
  };

  return (
    <ContentLayout
      title={t("RAL0191")}
      description={t("RAL0344")}
      place="tags"
      type="form"
      actions={actions}
      navBack={false}
    >
      <Grid container spacing={2}>
        {/* Conditionally render tags or 'NoMatchingTags' component */}
        {isLoading ? (
          <Box
            display={"flex"}
            height={"100%"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            marginTop={"150px"}
          >
            <CircularProgress />
          </Box>
        ) : tags.length > 0 || add ? (
          <>
            {add && addTag()}
            {tags.map((t, index) => (
              <Grid
                item
                xs={
                  isLargeScreen
                    ? 3
                    : isMediumScreen
                    ? 4
                    : isSmallScreen
                    ? 6
                    : 12
                }
                key={index}
              >
                {/* Render individual tag component */}
                {tag(t)}
              </Grid>
            ))}
          </>
        ) : (
          <NoMatchingTags />
        )}
      </Grid>
      <ArchiveDialog
        open={openArchiveDialog}
        onClose={handleCloseDialog}
        onConfirm={dialogActionType === "complete" ? "" : handleArchiveTag}
        name="tag"
        type="Tag"
      />
      {/* EmojiPopupState */}
      <Popover
        {...bindPopover(EmojiPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          mt: 5,
        }}
        PaperProps={{
          style: {
            overflow: "visible",
            background:
              mode == "dark" ? "#1F1F1F" : "var(--popover-background)",
          },
        }}
      >
        <Box p={2}>
          <Emojipicker onEmojiSelect={handleEmojiSelect} />
        </Box>
      </Popover>
    </ContentLayout>
  );
}
