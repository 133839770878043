import React, { useContext, useState } from "react";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";
import GenerateSummary from "./GenerateSummary";
import AiIcon from "../../assets/images/ralvie/ai_icon_lg.svg";
import LavoroAiIcon from "../../assets/images/lavoro-ai.svg";
import LavoroAiDarkIcon from "../../assets/images/lavoro-ai-dark.svg";
import CommonUtil from "../../util/CommonUtils";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AiContext } from "../../util/context";
import Typewriter from "../../components/Typewriter";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomIconButton from "../../components/CustomIconButton";
import { ReactComponent as HistoryIcon } from "../../assets/images/ralvie/icon-history.svg";
import { ReactComponent as DarkCopyIcon } from "../../assets/images/copy_hover.svg";
import { ReactComponent as LightCopyIcon } from "../../assets/images/copy.svg";
import { ReactComponent as LightCancelIcon } from "../../assets/images/cancel.svg";
import { ReactComponent as DarkCancelIcon } from "../../assets/images/closeIcon.svg";
import { ReactComponent as CopyIconHover } from "../../assets/images/copy_hover.svg";
import { ReactComponent as CancelIconHover } from "../../assets/images/cancel_hover.svg";
import AppContext from "../../config/AppContext";
import { ReactComponent as RefreshIconHoverDark } from "../../assets/images/refresh_icon_hover_dark.svg";
import { ReactComponent as RefreshIconHover } from "../../assets/images/refresh_icon.svg";
import { toast } from "sonner";

export default function AiSummary() {
  const { t } = useTranslation();
  const [generatedSummary, setGenerateSummary] = useState(undefined);
  const navigate = useNavigate();
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [isHovered, setIsHovered] = useState(false);
  const [isCancelHovered, setCancelIsHovered] = useState(false);
  const { mode } = useContext(AppContext);

  const actions = (
    <Box sx={{ display: "flex" }}>
      <Box mr={1.5}>
        <CustomIconButton
          id="btn-refresh-department"
          title={t("RAL0169")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          lightMode={
            mode === "light" ? (
              <RefreshIconHover height={isXlScreen ? "18px" : "14px"} />
            ) : (
              <RefreshIconHoverDark height={isXlScreen ? "18px" : "14px"} />
            )
          }
          darkMode={
            mode === "light" ? (
              <RefreshIconHover height={isXlScreen ? "18px" : "14px"} />
            ) : (
              <RefreshIconHoverDark height={isXlScreen ? "18px" : "14px"} />
            )
          }
          // onClick={() => resetFilter()}
        ></CustomIconButton>
      </Box>
      <Box>
        <CustomIconButton
          type="contained"
          id="btn-custom-member"
          name="btn-custom-member"
          title={t("RAL0381")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          lightMode={<HistoryIcon height={isXlScreen ? "18px" : "14px"} />}
          darkMode={<HistoryIcon height={isXlScreen ? "18px" : "14px"} />}
          onClick={() => navigate("/ai/summary/history")}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <AiContext.Provider value={{ generatedSummary, setGenerateSummary }}>
        <ContentLayout title={t("RAL0345")} navBack={true} actions={actions}>
          <div
            className={css`
              display: flex;
              column-gap: 2px;
              width: 100%;
            `}
          >
            {generatedSummary && (
              <div
                className={css`
                  background: ${mode === "dark" ? "#101010" : "#ffffff"};
                  border-radius: 5px;
                  opacity: 1;
                  width: 70%;
                  height: 85vh !important;
                  overflow-y: auto;
                  padding: 16px;
                `}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  minWidth={"100%"}
                  columnGap={1}
                >
                  <Box
                    item
                    onMouseLeave={() => {
                      setIsHovered(false);
                    }}
                    onMouseOver={() => {
                      setIsHovered(true);
                    }}
                  >
                    {isHovered ? (
                      <CustomButton
                        variant="contained"
                        onClick={() => {
                          const summaryDiv = document.querySelector(".summary");
                          const generatedSummary =
                            summaryDiv.innerText || summaryDiv.textContent;
                          CommonUtil.copyToClipboard(generatedSummary);
                          toast.success(
                            "The summary has been successfully copied to the clipboard."
                          );
                        }}
                      >
                        <CopyIconHover
                          style={{
                            width: "17px",
                            height: "15px",
                            marginRight: "10px",
                          }}
                        />
                        Copy
                      </CustomButton>
                    ) : (
                      <CustomButton
                        class={`custom-button-copy ${
                          mode === "dark" ? "dark" : "light"
                        }`}
                        onClick={() => {
                          const summaryDiv = document.querySelector(".summary");
                          const generatedSummary =
                            summaryDiv.innerText || summaryDiv.textContent;
                          CommonUtil.copyToClipboard(generatedSummary);
                          toast.success(
                            "The summary has been successfully copied to the clipboard."
                          );
                        }}
                      >
                        {mode === "dark" ? (
                          <DarkCopyIcon
                            style={{
                              width: "17px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          <LightCopyIcon
                            style={{
                              width: "17px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          />
                        )}
                        Copy
                      </CustomButton>
                    )}
                  </Box>
                  <Box
                    item
                    onMouseLeave={() => {
                      setCancelIsHovered(false);
                    }}
                    onMouseOver={() => {
                      setCancelIsHovered(true);
                    }}
                  >
                    {isCancelHovered ? (
                      <CustomButton
                        class="custom-button-cancelHover"
                        onClick={() => setGenerateSummary(undefined)}
                      >
                        <CancelIconHover
                          style={{
                            width: "12px",
                            height: "12px",
                            marginRight: "10px",
                          }}
                        />
                        Cancel
                      </CustomButton>
                    ) : (
                      <CustomButton
                        class={`custom-button-cancel ${
                          mode === "dark" ? "dark" : "light"
                        }`}
                        onClick={() => setGenerateSummary(undefined)}
                      >
                        {mode === "dark" ? (
                          <DarkCancelIcon
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "10px",
                            }}
                          />
                        ) : (
                          <LightCancelIcon
                            style={{
                              width: "12px",
                              height: "12px",
                              marginRight: "10px",
                            }}
                          />
                        )}
                        Cancel
                      </CustomButton>
                    )}
                  </Box>
                </Box>
                <div
                  className={css`
                    height: 92% !important;
                    overflow-y: auto;
                  `}
                >
                  <Typewriter
                    text={generatedSummary?.replaceAll("**", "")}
                    delay={10}
                  />
                </div>
              </div>
            )}
            {!generatedSummary && (
              <div
                className={css`
                  background: ${mode === "dark" ? "#101010" : "#ffffff"};
                  border-radius: 5px;
                  opacity: 1;
                  width: 70%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  row-gap: 16px;
                  height: 85vh !important;
                  overflow-y: auto;
                `}
              >
                {/* <img src={AiIcon} alt="AI" height={"260"} width={"210"} />
                <Typography
                  variant="header2"
                  sx={{
                    color: "var( --text-secondary-80)",
                    font: "normal normal bold 18px/20px Gotham",
                    letterSpacing: "0px",
                  }}
                >
                  {t("RAL0039") + " " + CommonUtil.getLoggedUser()?.firstName}
                </Typography> */}

                {mode === "dark" ? <img src={LavoroAiDarkIcon} alt="AI" /> : 
                   <img src={LavoroAiIcon} alt="AI" />}

                <Typography
                  sx={{
                    color: "var(--text-secondary-80)",
                    font: "normal normal normal 33px/49px GothamMedium",
                    fontWeight: 600,
                    letterSpacing: "0px",
                    background:
                      "-webkit-linear-gradient(20deg, #442EB2 0%, #D8583D 51%, #FA9C2B 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {t("RAL0386")}
                </Typography>
                <Typography
                  sx={{
                    color: "var( --text-secondary-80)",
                    font: "normal normal normal 16px/28px Gotham;",
                    letterSpacing: "0px",
                  }}
                >
                  {t("RAL0355")}
                </Typography>
              </div>
            )}
            <div
              className={css`
                background: ${mode === "dark" ? "#101010" : "#ffffff"};
                border-radius: 5px;
                opacity: 1;
                width: 30%;
                padding: 16px;
                height: 85vh !important;
                overflow-y: auto;
                margin-bottom: 10px;
              `}
            >
              <GenerateSummary />
            </div>
          </div>
        </ContentLayout>
      </AiContext.Provider>
    </>
  );
}
