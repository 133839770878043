import axios from "axios";
import api from "../config/http-common";

export const departmentList = async (
  payload = {},
  pageNumber = 0,
  pageSize = 1000
) => {
  let url = `/departments?pageNumber=${pageNumber}&pageSize=${pageSize}`;

  if (payload?.toggleKeyToURL) {
    url += "&toggleKeyToURL=true";
  }
  if (payload?.id) {
    url += `&id=${payload.id}`;
  }
  if (payload?.name) {
    url += `&name=${payload.name}`;
  }
  return api.securedAxiosApi().get(url);
};

export const getAllDepartments = async () => {
  return api.securedAxiosApi().get("/departments");
};

export const downloadDepartmentImportTemplate = async () => {
  // return api.securedAxiosApi().get("/department/import-template");
  try {
    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        "/departments/import-template",
      {
        responseType: "blob", // Important to specify the response type as blob
        headers: {
          Authorization: localStorage.getItem("token"),
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "department.xlsx"); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export const createDepartment = async (payload) => {
  const { name } = payload;
  const data = {
    name,
  };
  console.log("Payload sent to createDepartment API:", data);
  return api.securedAxiosApi().post("/department", data);
};

export const updateDepartmentAPI = async (id, payload) => {
  const { name } = payload;
  const data = {
    name,
  };
  console.log("Payload sent to updateDepartment API:", data);
  return api.securedAxiosApi().put(`/departments/${id}`, data);
};

export const importDepartments = async (payload) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
    },
  };
  return api.securedAxiosApi().post(`/departments/import`, payload, config);
};

export const softDeleteDepartment = async (id) => {
  return api.securedAxiosApi().delete(`/departments/${id}`);
};
