import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React from "react";
import AppIcon from "../../components/AppIcon";

export default function EventDetails(props) {
  const getApplicationIcon = (data) => {
    return <AppIcon data={data} height={14} width={14} />;
  };

  function formatTime(seconds) {
    if (seconds < 60) {
      return Math.ceil(seconds) === 0 ? "1 sec" : Math.ceil(seconds) + " sec";
    } else if (seconds < 3600) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      // return minutes.toString() + "min," + remainingSeconds.toString() + "sec";
      return minutes.toString() + " min";
    } else {
      let hours = Math.floor(seconds / 3600);
      return hours.toString() + " hour";
    }
  }

  const groupEvents = () => {
    const groupedEvents = {};

    props.event.events
      ?.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      )
      .forEach((event) => {
        let title = event.data?.title?.replace("*", "");
        const key = `${event.data?.app}_${title}`;

        if (!groupedEvents[key]) {
          groupedEvents[key] = {
            id: event.id,
            timestamp: event.timestamp,
            duration: event.duration,
            data: {
              ...event.data,
              app: event.data.app,
              title: title,
            },
            start: event.start,
            end: event.end,
            event_id: event.event_id,
            title: title,
          };
        } else {
          groupedEvents[key].end = event.end;
          groupedEvents[key].duration += event.duration;
        }
      });

    return Object.values(groupedEvents);
  };
  function formatTimeDifference(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const secondsDifference = diffInMilliseconds / 1000;

    if (secondsDifference < 1) {
      return `01sec`;
    } else if (secondsDifference < 60) {
      const seconds = Math.floor(secondsDifference);
      return `${String(seconds).padStart(2, "0")}sec`;
    } else if (secondsDifference < 3600) {
      const minutes = Math.floor(secondsDifference / 60);
      return `${String(minutes).padStart(2, "0")}mins`;
    } else {
      const hours = Math.floor(secondsDifference / 3600);
      const remainingMinutes = Math.floor((secondsDifference % 3600) / 60);
      return `${String(hours).padStart(2, "0")}:${String(
        remainingMinutes
      ).padStart(2, "0")}hrs`;
    }
  }
  return (
    <div
      style={{
        backgroundColor: "white",
        borderLeft: "5px solid " + props.event.dark,
        py: "8px",
      }}
    >
      {groupEvents().map((app, index) => {
        let eventTime =
          app.start.toLocaleTimeString("en-US") +
          " - " +
          app.end.toLocaleTimeString("en-US");
        let appTitle = app?.data?.app?.toLowerCase().includes("lockapp")
          ? "Idle Time"
          : app?.title?.replace("*", "");
        if (!appTitle) appTitle = app?.data?.app?.split(".")[0];
        return (
          <>
            <Box display={"flex"} py={0.5}>
              <Box
                item
                px={1}
                pt={0.5}
                sx={{ width: "5%", flexWrap: "nowrap" }}
              >
                {getApplicationIcon(app?.data)}
              </Box>
              <Box item flexGrow={1} sx={{ width: "60%", flexWrap: "nowrap" }}>
                <Box display={"block"} alignItems={"flex-start"}>
                  <Box>
                    <Typography
                      textTransform={"capitalize"}
                      maxLength="20"
                      variant="text_12_17_1_1_m"
                    >
                      {appTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="text_10_10_1_1_m">
                      {eventTime}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box item sx={{ width: "30%", flexWrap: "nowrap" }}>
                <Box
                  display={"flex"}
                  sx={{ minWidth: "100%", justifyContent: "flex-end" }}
                >
                  <Box item>
                    <Typography variant="text_10_10_1_1_m" px={1}>
                      {formatTime(app?.duration)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {app?.data?.url !== null && app?.data?.url !== undefined && (
              <Box pl={3}>
                <Typography
                  variant="text_12_17_1_1_m"
                  px={1}
                  className="text_overflow"
                >
                  <span style={{ fontWeight: "bold" }}>url: </span>
                  <Tooltip title={app?.data?.url}>
                    {app?.data?.url?.substring(0, 30)}
                  </Tooltip>
                </Typography>
              </Box>
            )}

            <Divider></Divider>
          </>
        );
      })}
    </div>
  );
}
