import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Grid, Card, Tooltip, Chip, IconButton,} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { ReactComponent as EmptyProjectList } from "../../assets/images/ralvie/noProjects.svg";
import { ReactComponent as EmptyProjectListDark } from "../../assets/images/ralvie/noProjectsDark.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/EditIcon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import { ReactComponent as AddIcon } from "../../assets/images/ralvie/add-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./applicationsettings.scss";
import { listApplicationSettings } from "../../services/ApplicationSettingsService";
import profileLight from "../../assets/images/EmptyProfile.svg";
import profileDark from "../../assets/images/EmptyProfile_dark.svg";
import CustomSearchField from "../../components/CustomSearchField";
import { useContext } from "react";
import AppContext from "../../config/AppContext";
import CustomIconButton from "../../components/CustomIconButton";
import { useTranslation } from "react-i18next";

function ApplicationSettings() {
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [applicationId, setApplicationId] = useState(null);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [filteredApps, setFilteredApps] = useState([]);

  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = useCallback((event, applicationId) => {
    setAnchorEl(event.currentTarget);
    setApplicationId(applicationId);
  }, []);

  const navigateEdit = useCallback(() => {
    if (applicationId) {
      navigate(`/application-settings/${applicationId}`);
      handleClose();
    }
  }, [applicationId, navigate]);

  useEffect(() => {
    if (applicationId) {
      navigate(`/application-settings/${applicationId}`);
      handleClose();
    }
  }, [applicationId]);

  const fetchApplications = () => {
    setIsLoading(true);
    listApplicationSettings()
      .then((res) => {
        setApplications(res.data.data.applicationRules);
        setIsLoading(false);
      })
      .catch((res) => {
        console.error("Error fetching Applications:", res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const handleReset = () => {
    setSearch("");
    setApplications([]);
    fetchApplications();
  };

  useEffect(() => {
    const filteredApps = applications.filter((application) => {
      const appNameIncludes =
        !search ||
        application?.name?.toLowerCase().includes(search.toLowerCase());

      return appNameIncludes;
    });

    setFilteredApps(filteredApps);
  }, [search, filter, applications]);

  const noResultsMessage = () => {
    if (search) {
      return "No Applications found with the selected search term.";
    }
    return "The Application Settings list is empty.";
  };

  const actions = (
    <Box className="refreshButtonDiv" display="flex" alignItems="center">
      <Box mr={2.5}>
        <CustomSearchField
          id="btn-search-project"
          name="btn-search-project"
          width="350px"
          label="Search by application name"
          value={search}
          size={isXlScreen ? "large" : "md"}
          onChange={(newValue) => setSearch(newValue)}
          maxLength={25}
        />
      </Box>

      <Box
        sx={{
          marginRight: "20px !important",
          "& .refresh_svg": {
            transition: "color 0.3s ease",
            color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
          },
          "&:hover": {
            "& .hover-text, & .refresh_svg": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          },
        }}
      >
        <CustomIconButton
          id="btn-refresh-project"
          className="refreshButton"
          onClick={handleReset}
          title={t("RAL0169")}
          variant="square"
          lightMode={
            <RefreshIcon
              className="refresh_svg"
              height={isXlScreen ? "18px" : "14px"}
            />
          }
          darkMode={
            <RefreshIcon
              className="refresh_svg"
              height={isXlScreen ? "18px" : "14px"}
            />
          }
        />
      </Box>

      <CustomIconButton
        title={t("RAL0170")}
        id="btn-add-department"
        variant="square"
        size={isXlScreen ? "large" : "md"}
        type="contained"
        border={'none'}
        lightMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
        darkMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
        onClick={() => navigate("/settings/application/new")}
      ></CustomIconButton>
    </Box>
  );

  const displayNoApplications =
    applications?.length === 0 || filteredApps?.length === 0;

  return (
    <ContentLayout
      title={t("RAL0167")}
      actions={actions}
      isLoading={isLoading}
      subtitle={t("RAL0363")}
      type="form"
    >
      <Grid
        container
        alignItems={displayNoApplications ? "center" : "flex-start"}
        justifyContent={displayNoApplications ? "center" : "flex-start"}
      >
        {displayNoApplications ? (
          <Grid item xs={12}>
            <Box
              display={isLoading ? "none" : "flex"}
              flexDirection="column"
              alignItems="center"
              sx={{
                transform: "translate(0%, 30vh)",
              }}
            >
              {mode == "dark" ? <EmptyProjectListDark /> : <EmptyProjectList />}
              <Typography variant="h6" className="noApplicationError" mt={2}>
                {noResultsMessage()}
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {filteredApps?.map((appSettings, index) => (
              <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Card className="applicationListDiv">
                  {/* <Box
                    sx={{ backgroundColor: "var(--background-color-18)", }}
                    className="applicationList"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        className="apps-card-body"
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt={appSettings?.name}
                          className="app-image-rounded"
                          src={
                            appSettings?.icon ? appSettings.icon : profilePhoto
                          }
                          height="100"
                          width="100"
                        />
                      </Box>
                      <Button
                        className="applicationMenu"
                        onClick={(e) => handleClick(e, appSettings.id)}
                        value={appSettings.name}
                        sx={{
                          backgroundColor:
                            open && anchorEl.value === appSettings.name
                              ? "#E8E6F1"
                              : "",
                          marginTop: "-50px",
                        }}
                      >
                        <MoreIcon />
                      </Button>
                      <Menu
                        open={open && applicationId === appSettings.id}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={navigateEdit}>
                          <ListItemIcon>
                            {mode === 'dark' ? <EditIconDark /> : <EditIcon />}
                          </ListItemIcon>
                          Update
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Typography
                      className="applicationName"
                      sx={{
                        color: "var(--text-secondary)!important",
                        textAlign: "center",
                        fontWeight: "bold",
                        marginY: 1,
                      }}
                    >
                      {appSettings?.name.length > 12 ? (
                        <Tooltip title={appSettings.name} placement="top" arrow>
                          <span>{`${appSettings.name.substring(
                            0,
                            12
                          )}...`}</span>
                        </Tooltip>
                      ) : (
                        appSettings.name
                      )}
                    </Typography>
                    <Box display="flex" flexWrap="wrap" justifyContent="center">
                      {appSettings?.keywords?.length > 0 &&
                        appSettings.keywords
                          .filter((keyword) => keyword)
                          .map((keyword, idx) => (
                            <Chip
                              key={idx}
                              label={keyword}
                              size="medium"
                              sx={{ margin: "2px" }}
                            />
                          ))}
                    </Box>
                  </Box> */}

                  <Box className="applicationList">
                    <div className="appDivOuter">
                      <Box
                        // sx={{ backgroundColor: "var(--background-color-18)", }}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            className="apps-card-body"
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              alt={appSettings?.name}
                              className="app-image-rounded"
                              src={
                                appSettings?.icon
                                  ? appSettings.icon
                                  : mode === "dark"
                                  ? profileDark
                                  : profileLight
                              }
                              height="36px"
                              width="36px"
                            />
                          </Box>
                          <Typography
                            className="applicationName"
                            sx={{
                              color: "var(--text-secondary)!important",
                              textAlign: "center",
                              fontWeight: "bold",
                              marginY: 1,
                            }}
                          >
                            {appSettings?.name.length > 12 ? (
                              <Tooltip
                                title={appSettings.name}
                                placement="top"
                                arrow
                              >
                                <span>{`${appSettings.name.substring(
                                  0,
                                  12
                                )}...`}</span>
                              </Tooltip>
                            ) : (
                              appSettings.name
                            )}
                          </Typography>
                        </Box>

                        <Tooltip title={t("RAL0172")}>
                          <IconButton
                            sx={{ borderRadius: "5px" }}
                            onClick={(e) => {
                              handleClick(e, appSettings.id);
                            }}
                          >
                            {mode === "dark" ? (
                              <EditIconDark id="btn-edit-tag" />
                            ) : (
                              <EditIcon id="btn-edit-tag" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Box className="appBoxDiv">
                        {appSettings?.keywords?.length > 0 &&
                          appSettings.keywords
                            .filter((keyword) => keyword)
                            .map(
                              (keyword, idx) =>
                                idx <= 1 && (
                                  <Chip className="applicationBoxes"
                                    key={idx}
                                    label={
                                      keyword.length > 20
                                        ? `${keyword.slice(0, 20)}...`
                                        : keyword
                                    }
                                    size="medium"
                                    sx={{
                                      // width: "6rem",
                                      margin: "2px",
                                      color: "var(--text-primary)",
                                      padding: "10px 14px",
                                      borderRadius: "2px",
                                      backgroundColor:
                                        "var(--background-color-3)",
                                    }}
                                  />
                                )
                            )}
                        {appSettings.keywords?.length - 2 > 0 && (
                          <Chip className="applicationBoxesCount"
                            label={`+ ${appSettings.keywords?.length - 2}`}
                            size="medium"
                            sx={{
                              margin: "2px",
                              padding: "10px 14px",
                              borderRadius: "2px",
                              backgroundColor: "var(--background-color-3)",
                            }}
                          />
                        )}
                      </Box>
                    </div>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </ContentLayout>
  );
}

export default ApplicationSettings;
