import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import profile from "../../assets/images/EmptyProfile.svg";
import CustomButton from "../../components/CustomButton";
import CustomCheckBox from "../../components/CustomCheckBox";
import CustomTextField from "../../components/CustomTextField";
import ContentLayout from "../../components/layout-components/ContentLayout";
import { departmentList } from "../../services/DepartmentService";
import { designationList } from "../../services/DesignationService";
import {
  getUser,
  rolesList,
  teamLeadsList,
  updateMember,
  uploadUserProfilePhotoById,
} from "../../services/MemberService";
import CommonUtil from "../../util/CommonUtils";
import "./AddMember.scss";
import TitleBar from "../../components/layout-components/TitleBar";
import {
  removeUserProfile,
  uploadUserProfile,
} from "../../services/OnboardService";
import CancelDialog from "../project/CancelDialog";
import { sidebarContext } from "../../util/context";

export default function UpdateMember() {
  const [isLoading, setIsLoading] = useState(false);
  const loggedUserRole = CommonUtil.getRoleCode();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [roleList, setRoleList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [member, setMember] = useState([]);
  const [designations, setDesignations] = useState(null);
  const [designation, setDesignation] = useState(null);

  const [departments, setDepartments] = useState(null);
  const [department, setDepartment] = useState(null);

  const [teamLeaders, setTeamLeaders] = useState([]);
  const [teamLead, setTeamLead] = useState([]);
  const [selectedTeamLeads, setSelectedTeamLeads] = useState([]);

  const [role, setRole] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(profile.replace(/\\/g, "/"));
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const location = useLocation();
  const fromView = location.state ? location.state?.fromView : null;
  const { setProfilePic, userProfile, setUserProfile } = useContext(sidebarContext);
  const memberId = CommonUtil.getLoggedUser()?.userId;

  const [weekDays, setWeekDays] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });
  const defaultState = {
    roleId: "",
    designationId: "",
    departmentId: "",
    teamLeads: [],
    firstName: "",
    lastName: "",
    email: "",
    costPerHour: "",
    weeklyWorkCapacity: "",
  };
  const [payload, setPayload] = useState({
    roleId: "",
    designationId: "",
    departmentId: "",
    teamLeads: [],
    firstName: "",
    lastName: "",
    email: "",
    costPerHour: "",
    weeklyWorkCapacity: "",
    profilePhoto: "",
  });
  const [error, setError] = useState({
    roleId: "",
    designationId: "",
    departmentId: "",
    teamLeads: [],
    firstName: "",
    lastName: "",
    email: "",
    costPerHour: "",
    weeklyWorkCapacity: "",
    workingDays: "",
    profilePhoto: "",
  });

  useEffect(() => {
    if (id) {
      loadRoles();
      loadDesignations();
      // loadDepartments();
      loadDepartments();
      loadTeamLeads();
      loadUser(id);
    }
  }, []);

  useEffect(() => {
    setPayload({
      roleId: member?.roleId,
      designationId: member?.designationId,
      departmentId: member?.departmentId,
      teamLeads: member?.teamLeads,
      firstName: member?.memberName,
      email: member?.email,
      costPerHour: member?.costPerHour,
      weeklyWorkCapacity: member?.weeklyWorkCapacity,
      profilePhoto: member?.profilePhoto,
    });

    setProfilePhoto(member?.profilePhoto);
    setWeekDays({
      ...weekDays,
      mon: member?.workingDays?.some((str) => str.includes("mon")),
      tue: member?.workingDays?.some((str) => str.includes("tue")),
      wed: member?.workingDays?.some((str) => str.includes("wed")),
      thu: member?.workingDays?.some((str) => str.includes("thu")),
      fri: member?.workingDays?.some((str) => str.includes("fri")),
      sat: member?.workingDays?.some((str) => str.includes("sat")),
      sun: member?.workingDays?.some((str) => str.includes("sun")),
    });
    let rls = roles;
    let ownerRole = roleList.find(
      (r) => member?.roleId === r.id && r.name === "Owner"
    );
    if (ownerRole) {
      rls.push(ownerRole);
      setRoles(rls);
    }
    let role = roles?.filter((r) => r.id === member?.roleId);
    setRole(role.length > 0 ? role[0] : null);
  }, [member]);

  useEffect(() => {
    if (payload?.roleId && roles) {
      let role = roles?.filter((r) => r.id === payload.roleId);
      setRole(role.length > 0 ? role[0] : null);
    }
  }, [payload.roleId]);

  useEffect(() => {
    if (payload?.designationId && designations) {
      let desgn = designations?.filter((r) => r.id === payload.designationId);
      setDesignation(desgn.length > 0 ? desgn[0] : null);
    }
  }, [payload.designationId]);

  useEffect(() => {
    if (payload?.departmentId && departments) {
      let dept = departments?.filter((r) => r.id === payload.departmentId);
      setDepartment(dept.length > 0 ? dept[0] : null);
    }
  }, [payload.departmentId]);

  useEffect(() => {
    if (payload?.teamLeads && teamLeaders) {
      const selectedLeads = teamLeaders.filter((leader) =>
        payload.teamLeads.includes(leader.userId)
      );
      setSelectedTeamLeads(selectedLeads);
    }
  }, [payload.teamLeads, teamLeaders]);

  const loadUser = (id) => {
    getUser(id).then((res) => {
      if (res?.status === 200 && res?.data?.code === "UASI0033") {
        setMember(res?.data?.data);
      }
    });
  };

  const loadRoles = () => {
    rolesList().then((res) => {
      if (res?.status === 200 && res?.data?.code === "UASI0000") {
        setRoleList(res?.data?.data?.roles);
        let roles = res?.data?.data?.roles?.filter(
          (role) =>
            role.code === "EMPLOYEE" ||
            role.code === "TEAM LEAD" ||
            role.code === "ADMIN" ||
            role.code === "CLIENT"
        );
        setRoles(roles);
      }
    });
  };

  const loadDesignations = () => {
    const payload1 = {
      pageNumber: 1,
      pageSize: 1000,
    };
    designationList(payload1).then((res) => {
      if (res.data.code === "RCI0000") {
        setDesignations(res.data.data.designations);
      }
    });
  };

  // const loadDepartments = () => {
  //   getAllDepartments().then((res) => {
  //     // if (res.data.code === "RCI0000") {
  //     //   setDesignations(res.data.data.designations);
  //     // }
  //   });
  // };

  const loadDepartments = () => {
    const payload1 = {
      pageNumber: 1,
      pageSize: 1000,
    };
    departmentList(payload1).then((res) => {
      if (res.data.code === "RCI0000") {
        setDepartments(res.data.data.departments);
      }
    });
  };

  const loadTeamLeads = () => {
    const payload1 = {
      pageNumber: 1,
      pageSize: 1000,
    };
    teamLeadsList(payload1).then((res) => {
      if (res.data.code === "RCI0000") {
        const activeTeamLeads = res.data.data.teamLeads.filter(
          (teamLead) => teamLead.status !== "ARCHIVED"
        );
        setTeamLeaders(activeTeamLeads);
      }
    });
  };

  const reset = (openNew) => {
    setPayload({
      ...defaultState,
    });
    setWeekDays({
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    });
    setRole(null);
    setDesignation(null);
    setDepartment(null);
    setTeamLead([]);
    setProfileFile(null);
    setProfilePhoto(profile.replace(/\\/g, "/"));
    if (!openNew) {
      if (fromView === "list") {
        navigate("/members-list");
      } else {
        navigate("/members");
      }
    }
  };
  const handleWeekDays = (e) => {
    setWeekDays({
      ...weekDays,
      [e.target.name]: e.target.checked,
    });
    if (e.target.checked) {
      setError({
        ...error,
        workingDays: "",
      });
    }
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };
  const handleFileError = (message) => {
    setError({
      ...error,
      profilePhoto: message,
    });
  };
  const removeProfilePhoto = () => {
    setProfileFile(null);
    setProfilePhoto(profile.replace(/\\/g, "/"));
    setError({
      ...error,
      profilePhoto: "",
    });
    setPayload({
      ...payload,
      profilePhoto: "",
    });

    removeUserProfile(id)
      .then((res) => {
        if (res?.status === 200) {
          toast.success(t("Profile photo removed successfully"));
        } else {
          toast.error(t("Failed to remove profile photo"));
        }
      })
      .catch((err) => {
        toast.error(t("An error occurred while removing profile photo"));
      });
  };

  const handleFile = (event) => {
    handleFileError(""); // Resetting file error at the beginning

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (!/\/(jpeg|jpg|png)$/i.test(file.type)) {
      handleFileError(t("RAL0059"));
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      handleFileError(t("RAL0060"));
      event.target.value = "";
      return;
    }

    try {
      setProfilePhoto(URL.createObjectURL(file));
      setProfileFile(file);
      handleFileError("");
    } catch (error) {
      console.error("Error during file upload", error);
      handleFileError(t("RAL0061"));
    }
    event.target.value = "";
  };
  const validateForm = () => {
    if (!payload.firstName) {
      setError({
        ...error,
        firstName: t("RAL0001"),
      });
      return false;
    }

    if (!payload.email) {
      setError({
        ...error,
        email: t("RAL0001"),
      });
      return false;
    }

    if (!payload.roleId) {
      setError({
        ...error,
        roleId: t("RAL0001"),
      });
      return false;
    }

    let workingDays = [];

    for (const key in weekDays) {
      if (weekDays.hasOwnProperty(key)) {
        let value = weekDays[key];
        if (value) {
          workingDays.push(key);
        }
      }
    }

    if (workingDays.length < 1) {
      setError({
        ...error,
        workingDays: t("RAL0001"),
      });
      return false;
    }

    if (!payload.weeklyWorkCapacity) {
      setError({
        ...error,
        weeklyWorkCapacity: t("RAL0001"),
      });
      return false;
    }

    if (
      payload.weeklyWorkCapacity &&
      (Number(payload.weeklyWorkCapacity) > 168 ||
        Number(payload.weeklyWorkCapacity) < 1)
    ) {
      setError({
        ...error,
        weeklyWorkCapacity: t(
          "Please enter a value between 1 and 168 hours per week"
        ),
      });
      return false;
    }

    if (!payload.costPerHour) {
      setError({
        ...error,
        costPerHour: t("RAL0001"),
      });
      return false;
    }

    if (payload.costPerHour && Number(payload.costPerHour) < 1) {
      setError({
        ...error,
        costPerHour: t(
          "Please enter a cost per hour greater than or equal to 1"
        ),
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (profileFile !== null) {
      const formData = new FormData();
      formData.append("file", profileFile);
      uploadUserProfile(id, formData).then((uploadRes) => {
        if (uploadRes.data?.code === "RCI0000") {
          const uploadedPhotoUrl = uploadRes.data?.data?.profilePhoto;
          setProfilePhoto(uploadedPhotoUrl);
          setPayload((prevPayload) => ({
            ...prevPayload,
            profilePhoto: uploadedPhotoUrl,
          }));
          if (memberId === id) {
            localStorage.setItem("profilePic", uploadedPhotoUrl);
            setProfilePic(uploadedPhotoUrl);
          }
        }
      });
    }
  }, [profileFile]);

  const handleSubmit = (openNew) => {
    setError({
      ...error,
      profilePhoto: "",
    });
    let workingDays = [];
    if (role?.code !== "CLIENT") {
      if (!validateForm()) {
        return false;
      }
      // let workingDays = [];

      for (const key in weekDays) {
        if (weekDays.hasOwnProperty(key)) {
          let value = weekDays[key];
          if (value) {
            workingDays.push(key);
          }
        }
      }

      if (workingDays.length < 1) {
        setError({
          ...error,
          workingDays: t("RAL0001"),
        });
        return false;
      }
    }
    let state = {
      ...payload,
      costPerHour: Number(payload?.costPerHour),
      weeklyWorkCapacity: Number(payload?.weeklyWorkCapacity),
      workingDays: workingDays,
      profilePhoto: profileFile ? payload.email : null,

      deleteProfilePhoto: payload.profilePhoto ? false : true,
    };
    setIsLoading(true);
    updateMember(id, state).then((res) => {
      if (res.data?.code === "UASI0002") {
        toast.success(t("RAL0262"));
        reset(openNew);
        return false;
      } else if (res.data?.code === "UASE0021") {
        setError({
          ...error,
          email: res.data?.message,
        });
        setIsLoading(true);
        return false;
      } else {
        setIsLoading(true);
        toast.error(res.data?.message);
      }
    });
  };

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    if (fromView === "list") {
      navigate("/members-list");
    } else {
      navigate("/members");
    }
  };

  return (
    <>
      <TitleBar title={t("RAL0261")} navBack={true}></TitleBar>
      <Box
        height="calc(100vh - 110px)"
        overflow="auto"
        className="addMemberDiv"
      >
        <Grid item md={12} mb={1}>
          <Typography className="form-title">{t("RAL0238")}</Typography>
        </Grid>

        <Grid container item spacing={3} mb={3}>
          <Grid item md={"auto"} sm={12} xs={12}>
            <Avatar
              alt="Profile Avatar"
              sx={{
                width: { xl: 160, xs: 160 },
                height: { xl: 160, xs: 160 },
                marginBlock: "auto",
              }}
              src={profilePhoto}
            />
          </Grid>

          <Grid container item md={3} sm={12} xs={12} mt={2}>
            <Box
              sx={{
                marginBlock: "auto",
              }}
            >
              <Box className="jpgMessage">
                <Typography className="form-profile-upload-text">
                  {t("RAL0057")}
                </Typography>
                <Typography className="form-profile-upload-text">
                  {t("RAL0204")}
                </Typography>
              </Box>
              <Box display={"flex"} gap={"20px"} paddingTop={"20px"}>
                <Box width={"98px"} height={"40px"}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFile}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                  <CustomButton
                    place={"ps-upload"}
                    disabled={isLoading}
                    variant="contained"
                    size="small"
                    fullWidth="fullWidth"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <span> {t("RAL0064")}</span>
                  </CustomButton>
                </Box>
                <Box width={"98px"} height={"40px"}>
                  <CustomButton
                    place={"ps-remove"}
                    disabled={
                      isLoading ||
                      (profileFile === null &&
                        CommonUtil.isEmptyString(payload?.profilePhoto))
                    }
                    variant="outlined"
                    size="small"
                    fullWidth="fullWidth"
                    onClick={() => removeProfilePhoto()}
                    // sx={{
                    //   borderColor: profilePhoto ? "red" : "inherit", // Border color condition
                    //   color: profilePhoto ? "red" : "inherit",      // Text color condition
                    // }}
                  >
                    <span
                      id="btn-remove-img-member"
                      name="btn-remove-img-member"
                      // style={{ color: profilePhoto ? "#d60000" : "inherit" }}
                    >
                      {t("RAL0065")}
                    </span>
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          {error.profilePhoto && (
            <Grid item md="12">
              <Typography className="error-text">
                {error.profilePhoto}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container item md="12" spacing={3} mb={2}>
          <Grid item md={4} sm={12} xs={12}>
            <CustomTextField
              required={true}
              name="firstName"
              label={t("RAL0239")}
              placeholder={t("RAL0240")}
              error={!CommonUtil.isEmptyString(error.firstName)}
              helperText={error.firstName}
              value={payload.firstName}
              onChange={(e) => handleChange(e)}
              inputProps={{
                maxLength: 60,
              }}
            ></CustomTextField>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            {/* <CustomTextField
            name="lastName"
            label="Last Name"
            placeholder="Enter Last name"
            error={!CommonUtil.isEmptyString(error.lastName)}
            helperText={error.lastName}
            value={payload.lastName}
            onChange={(e) => handleChange(e)}
            inputProps={{
              maxLength: 30,
            }}
          ></CustomTextField> */}
            <CustomTextField
              required={true}
              disabled={true}
              name="email"
              label={t("RAL0241")}
              placeholder={t("RAL0242")}
              error={!CommonUtil.isEmptyString(error.email)}
              helperText={error.email}
              value={payload.email}
              onChange={(e) => handleChange(e)}
              validation="email"
              inputProps={{
                maxLength: 36,
              }}
            ></CustomTextField>
          </Grid>
        </Grid>
        {/* <Grid container item md="12" spacing={3} mb={1}>
        <Grid item md={8} sm={12} xs={12}>
          <CustomTextField
            name="email"
            label="Email"
            placeholder="Enter Email"
            error={!CommonUtil.isEmptyString(error.email)}
            helperText={error.email}
            value={payload.email}
            onChange={(e) => handleChange(e)}
            validation="email"
            inputProps={{
              maxLength: 36,
            }}
          ></CustomTextField>
        </Grid>
      </Grid> */}
        <Grid container item md="12" spacing={3} mb={2}>
          <Grid item md={4} sm={12} xs={12}>
            <Autocomplete
              disabled={
                loggedUserRole === "OWNER" &&
                id === CommonUtil.getLoggedUser()?.id
              }
              clearIcon={false}
              multiple={false}
              limitTags={2}
              id="dropdown-member-create-role"
              fullWidth
              size={"small"}
              getOptionLabel={(option) => option?.name || ""}
              clearText="Clear"
              disableClearable={true}
              value={role}
              options={roles || []}
              renderInput={(params) => (
                <CustomTextField
                  required={true}
                  dropdown={true}
                  onChange={(e) => console.log()}
                  placeholder={t("RAL0243")}
                  error={!CommonUtil.isEmptyString(error.roleId)}
                  helperText={error.roleId}
                  {...params}
                  label={t("RAL0243")}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setPayload({
                    ...payload,
                    roleId: newValue.id,
                  });
                } else {
                  setRole(null);
                  setPayload({
                    ...payload,
                    roleId: null,
                  });
                }
                setError({
                  ...error,
                  roleId: "",
                });
              }}
            />
          </Grid>
          {role && role?.code !== "CLIENT" ? (
            <>
              <Grid item md={4} sm={12} xs={12}>
                <Autocomplete
                  clearIcon={false}
                  id="dropdown-member-create-team lead"
                  fullWidth
                  size={"small"}
                  getOptionLabel={(option) => option?.name || ""}
                  clearText="Clear"
                  disableClearable={true}
                  value={designation}
                  options={designations || []}
                  renderInput={(params) => (
                    <CustomTextField
                      dropdown={true}
                      onChange={(e) => console.log()}
                      placeholder={t("RAL0244")}
                      error={!CommonUtil.isEmptyString(error.designationId)}
                      helperText={error.designationId}
                      {...params}
                      label={t("RAL0244")}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setPayload({
                        ...payload,
                        designationId: newValue.id,
                      });
                    } else {
                      setPayload({
                        ...payload,
                        designationId: null,
                      });
                    }
                    setError({
                      ...error,
                      designationId: "",
                    });
                  }}
                />
              </Grid>
            </>
          ) : null}
        </Grid>

        {!role || role.code !== "CLIENT" ? (
          <>
            <Grid container item md="12" spacing={3} mb={2}>
              <Grid item md={4} sm={12} xs={12}>
                <Autocomplete
                  fullWidth
                  size="small"
                  getOptionLabel={(option) => option?.name || ""}
                  clearText="Clear"
                  disableClearable={true}
                  value={department}
                  options={departments || []}
                  renderInput={(params) => (
                    <CustomTextField
                      className="departTextField"
                      id="dropdown-member-create-department"
                      name="dropdown-member-create-department"
                      dropdown={true}
                      onChange={(e) => console.log()}
                      placeholder={t("RAL0245")}
                      error={!CommonUtil.isEmptyString(error.departmentId)}
                      helperText={error.departmentId}
                      {...params}
                      label={t("RAL0245")}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setPayload({
                        ...payload,
                        departmentId: newValue.id,
                      });
                    } else {
                      setPayload({
                        ...payload,
                        departmentId: null,
                      });
                    }
                    setError({
                      ...error,
                      departmentId: "",
                    });
                  }}
                />
              </Grid>

              {!role ||
              (role.code !== "CLIENT" && role.code !== "TEAM LEAD") ? (
                <Grid item md={4} sm={12} xs={12}>
                  <Autocomplete
                    multiple
                    fullWidth
                    disableCloseOnSelect
                    size="small"
                    getOptionLabel={(option) =>
                      option && option.name ? option.name : ""
                    }
                    clearText="Clear"
                    disableClearable={true}
                    value={selectedTeamLeads}
                    options={teamLeaders || []}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#FFA23A",
                            },
                          }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <CustomTextField
                        id="dropdown-member-create-teamLeads"
                        name="dropdown-member-create-teamLeads"
                        dropdown={true}
                        placeholder={t("Team Lead")}
                        helperText={error.teamLeads}
                        {...params}
                        label={t("Team Lead")}
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      const displayedValues = value.slice(0, 2);
                      const remainingCount =
                        value.length - displayedValues.length;

                      return (
                        <>
                          {displayedValues.map((option, index) => (
                            <span {...getTagProps({ index })} key={option.id}>
                              {option.name}
                              {index < displayedValues.length - 1
                                ? ", "
                                : ""}{" "}
                            </span>
                          ))}
                          {remainingCount > 0 && (
                            <span>+ {remainingCount} more</span>
                          )}
                        </>
                      );
                    }}
                    onChange={(event, newValue) => {
                      setSelectedTeamLeads(newValue);
                      setPayload({
                        ...payload,
                        teamLeads: newValue.map((lead) => lead.userId),
                      });
                      setError({
                        ...error,
                        teamLeads: "",
                      });
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </>
        ) : null}

        {role && role?.code !== "CLIENT" ? (
          <Grid item md="12" spacing={3} my={2}>
            <Typography className={`form-subtitle`}>
              {t("RAL0246")}{" "}
              {error.workingDays && (
                <span className="error-text">{error.workingDays}</span>
              )}
            </Typography>

            <Grid container item md="12" spacing={3} mb={2}>
              <Grid item md={"auto"} sm={12} xs={12}>
                <CustomCheckBox
                  label={t("RAL0247")}
                  name="mon"
                  checked={weekDays?.mon}
                  onChange={(e) => handleWeekDays(e)}
                  error={error.workingDays}
                />
              </Grid>
              <Grid item md={"auto"} sm={12} xs={12}>
                <CustomCheckBox
                  label={t("RAL0248")}
                  name="tue"
                  checked={weekDays?.tue}
                  onChange={(e) => handleWeekDays(e)}
                  error={error.workingDays}
                />
              </Grid>
              <Grid item md={"auto"} sm={12} xs={12}>
                <CustomCheckBox
                  label={t("RAL0249")}
                  name="wed"
                  checked={weekDays?.wed}
                  onChange={(e) => handleWeekDays(e)}
                  error={error.workingDays}
                />
              </Grid>
              <Grid item md={"auto"} sm={12} xs={12}>
                <CustomCheckBox
                  label={t("RAL0250")}
                  name="thu"
                  checked={weekDays?.thu}
                  onChange={(e) => handleWeekDays(e)}
                  error={error.workingDays}
                />
              </Grid>
              <Grid item md={"auto"} sm={12} xs={12}>
                <CustomCheckBox
                  label={t("RAL0251")}
                  name="fri"
                  checked={weekDays?.fri}
                  onChange={(e) => handleWeekDays(e)}
                  error={error.workingDays}
                />
              </Grid>
              <Grid item md={"auto"} sm={12} xs={12}>
                <CustomCheckBox
                  label={t("RAL0252")}
                  name="sat"
                  checked={weekDays?.sat}
                  onChange={(e) => handleWeekDays(e)}
                  error={error.workingDays}
                />
              </Grid>
              <Grid item md={"auto"} sm={12} xs={12}>
                <CustomCheckBox
                  label={t("RAL0253")}
                  name="sun"
                  checked={weekDays?.sun}
                  onChange={(e) => handleWeekDays(e)}
                  error={error.workingDays}
                />
              </Grid>
            </Grid>

            <Grid container item md="12" spacing={3} mb={1}>
              <Grid item md={4} sm={12} xs={12}>
                <CustomTextField
                  required={true}
                  name="weeklyWorkCapacity"
                  label={t("RAL0254")}
                  placeholder={t("RAL0255")}
                  error={!CommonUtil.isEmptyString(error.weeklyWorkCapacity)}
                  helperText={error.weeklyWorkCapacity}
                  value={payload.weeklyWorkCapacity}
                  onChange={(e) => handleChange(e)}
                  validation="numericWithoutDot"
                  inputProps={{
                    maxLength: 3,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography className="form-placeholder">
                          {t("RAL0256")}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                ></CustomTextField>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <CustomTextField
                  required={true}
                  name="costPerHour"
                  label={t("RAL0257")}
                  placeholder={t("RAL0258")}
                  error={!CommonUtil.isEmptyString(error.costPerHour)}
                  helperText={error.costPerHour}
                  value={payload.costPerHour}
                  onChange={(e) => handleChange(e)}
                  validation="numericWithoutDot"
                  inputProps={{
                    maxLength: 5,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography className="form-placeholder">
                          {t("RAL0259")}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                ></CustomTextField>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid
          container
          item
          md="8"
          spacing={3}
          pt={2}
          className="memberBottomActionBtn"
        >
          <Grid container item md={6} sm={12} xs={12} spacing={3}>
            <Grid item md={"auto"} sm={12} xs={12}></Grid>
          </Grid>
          <Grid container item md={6} sm={12} xs={12} spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <CustomButton
                place="sp-cancel"
                disabled={isLoading}
                variant="outlined"
                size="large"
                fullWidth="fullWidth"
                onClick={handleCancelButton}
              >
                <span>{t("RAL0177")}</span>
              </CustomButton>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <CustomButton
                disabled={isLoading}
                variant="contained"
                size="large"
                fullWidth="fullWidth"
                onClick={() => handleSubmit(false)}
              >
                <span>{t("RAL0309")}</span>
              </CustomButton>
            </Grid>
          </Grid>
          <CancelDialog
            open={isCancelDialogOpen}
            onClose={handleCloseCancelDialog}
            onConfirm={handleConfirmCancel}
            header={t("RAL0377")}
            message={t("RAL0378")}
          />
        </Grid>
      </Box>
    </>
  );
}
