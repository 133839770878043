import React, { useEffect } from "react";
import OnboardLayout from "./OnboardLayout";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import OnboardService from "../../services/OnboardService";
import { useTheme } from "@mui/material/styles";
import CustomInput from "../../components/CustomInput";
import { enqueueSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PasswordField from "../../components/PasswordField";
import { toast } from "sonner";

export default function ResetPassword() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [loginMode, setLoginMode] = useState("local");

  const [payload, setPayload] = useState({
    code: "",
    otp: "",
    password: "",
  });

  const [error, setError] = useState({
    code: "",
    otp: "",
    password: "",
  });

  const CustomLinkButton = styled(Button)({
    "&:hover": {
      backgroundColor: "white",
      fontWeight: "bolder",
    },
  });

  var urlValue = window.location.href;
  var url = new URL(urlValue);
  var codeData = url.searchParams.get("code");

  const handleSubmit = (e) => {
    if (validate()) {
      var state = {
        ...payload,
        code: codeData,
      };
      const otp = payload.otp;
      OnboardService.validateOtpToResetPassword(otp, state).then((res) => {
        if (res.data.code === "RCI0016") {
          // enqueueSnackbar(res.data?.message, { variant: "success" });
          toast.success(res.data?.message);
          navigate("/");
        } else {
          toast.error(res.data?.message);
          // enqueueSnackbar(res.data?.message, { variant: "error" });
        }
      });
    }
  };

  const handleChange = (e) => {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validate = () => {
    if (!payload.otp) {
      setError({
        ...error,
        otp: t("RAL0001"),
      });
      return false;
    }
    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      return false;
    }
    return true;
  };

  return (
    <OnboardLayout>
      <Box
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "center",
          py: 2,
        }}
      >
        <Typography
          variant="text_28_42_0_1"
          fontSize="28px"
          sx={{
            fontSize: "18px",
            opacity: "1",
            font: "normal normal medium 28px/42px Poppins",
            width: "220px",
            height: "39px",
          }}
        >
          {t("RAL0076")}
        </Typography>
      </Box>

      <Grid
        container
        flexDirection={"column"}
        sx={{
          justifyContent: "center",
          alignContent: "center",
          minWidth: "100%",
        }}
        spacing={3}
      >
        <Grid item lg={12} minWidth="100%" py={1} style={{ marginTop: "20px" }}>
          <CustomInput
            required
            fullWidth
            label={t("RAL0078")}
            value={payload.otp}
            name="otp"
            onChange={handleChange}
            error={error.otp}
            helperText={error.otp}
            inputProps={{
              maxLength: 4,
            }}
            validation="numeric"
          />
        </Grid>

        <Grid item lg={12} minWidth="100%">
          <PasswordField
            required
            fullWidth
            id="text-reset-forgotpassword"
            label={t("RAL0004")}
            value={payload.password}
            name="password"
            onChange={handleChange}
            error={error.password}
            helperText={error.password}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>

        <Grid item lg={12} minWidth="100%">
          <Button
            variant="contained"
            fullWidth
            size="large"
            className="button-gradient"
            onClick={handleSubmit}
            id="brn_login_submit"
          >
            <Typography variant="text_16_28_032_1_white">
              {t("RAL0077")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid item lg={12} minWidth="100%">
        <Box
          display={"flex"}
          sx={{
            justifyContent: "center",
            alignContent: "center",
            minWidth: "100%",
            marginTop: "20px",
          }}
        >
          <Box item>
            <Typography variant="text_16_23_0_1">{t("RAL0072")}</Typography>
          </Box>
          <Box item>
            <CustomLinkButton
              variant="text"
              className="button-link"
              onClick={() => navigate("/")}
              id="brn_login_goto_signup"
            >
              {t("RAL0006")}
            </CustomLinkButton>
          </Box>
        </Box>
      </Grid>
    </OnboardLayout>
  );
}
