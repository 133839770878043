import { Grid, Tooltip } from "@mui/material";
import * as React from "react";
import "./Components.scss";
import "./CustomIconButton.scss";
import { useContext } from "react";
import AppContext from "../config/AppContext";

export default function CustomIconButton(props) {
  const { mode } = useContext(AppContext);
  const [styles, setStyle] = React.useState({
    borderColor: mode === "light" ? "#d9d9d9" : "#313131",
    variant: "default",
    size: "md",
    color: "var(--text-secondary-80)",
  });

  const [focus, setFocus] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  React.useEffect(() => {
    setStyle({
      ...styles,
      borderColor: props.borderColor
        ? props.borderColor
        : mode === "light"
        ? "#d9d9d9"
        : "#313131",
      variant: props.variant ? props.variant : "default",
      size: props.size ? props.size : "md",
      color: props.color ? props.color : "var(--text-secondary-80)",
    });
  }, [props]);

  const getSize = () => {
    if (props.height && props.width) {
      return { height: props.height, width: props.width };
    }
    return {
      height:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
      width:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
    };
  };

  const size = getSize();

  return (
    <>
      <Tooltip
        title={clicked ? null : props.disabled ? null : props.title}
        className="CustomIconbtns"
      >
        <Grid
          sx={{
            color: mode === "light" ? "#242424cc" : "#f8f8f880",
            "&:hover": {
              backgroundColor: props.disabled
                ? mode === "light"
                  ? "#f4f5f8"
                  : "#010101"
                : mode === "light"
                ? "#e8e6f1"
                : "#1f1f1f80",
              // backgroundColor: "var(--background-color-6)",
              // "&:hover": {
              //   backgroundColor: "var(--background-color-7)",
            },
            backgroundColor: props.activeState
              ? mode === "light"
                ? "#ffa23a4d"
                : "#ffc788"
              : mode === "light"
              ? (props?.bg ? props?.bg : '#f4f5f8')
              : (props?.bg ? props?.bg : '#010101'),
            borderColor: mode === "light" ? "#d9d9d9" : "#313131",
            cursor: props.disabled ? "not-allowed" : "pointer",
          }}
          id={props.id}
          name={props.name}
          onMouseOver={() => setFocus(true)}
          onMouseLeave={() => {
            setFocus(false);
            setClicked(false);
          }}
          container
          className={
            props.type === "contained" ? "custom-icon-contained" : "custom-icon"
          }
          // bgcolor={props.bgcolor ? `${props.bgcolor} !important` : ""}
          onClick={(e) => {
            props?.onClick(e);
            setClicked(true);
          }}
          border={
            props.border ? props.border : "1px solid " + styles.borderColor
          }
          borderRadius={styles.variant === "square" ? "5px" : "50%"}
          height={size.height}
          width={size.width}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="2px"
        >
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {mode === "dark" ? props.darkMode : props.lightMode}
          </Grid>
        </Grid>
      </Tooltip>
    </>
  );
}
export function HeaderCustomIconButton(props) {
  const [styles, setStyle] = React.useState({
    borderColor: "var(--right-left-arrow-border-color)",
    variant: "default",
    size: "md",
    color: "var(--text-secondary-80)",
  });

  const [focus, setFocus] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    setStyle({
      ...styles,
      borderColor: props.borderColor
        ? props.borderColor
        : "var(--right-left-arrow-border-color)",
      variant: props.variant ? props.variant : "default",
      size: props.size ? props.size : "md",
      color: props.color ? props.color : "var(--text-secondary-80)",
    });
  }, [props]);

  const getSize = () => {
    if (props.height && props.width) {
      return { height: props.height, width: props.width };
    }
    return {
      height:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
      width:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
    };
  };

  const size = getSize();

  return (
    <>
      <Tooltip title={clicked ? null : props.title} className="CustomIconbtns">
        <Grid
          sx={{
            backgroundColor: "var(--background-color-6)",
            "&:hover": {
              backgroundColor: "var(--background-color-7)",
            },
          }}
          id={props.id}
          name={props.name}
          onMouseOver={() => setFocus(true)}
          onMouseLeave={() => {
            setFocus(false);
            setClicked(false);
          }}
          container
          className={
            props.type === "contained" ? "custom-icon-contained" : "custom-icon"
          }
          // bgcolor={props.bgcolor ? `${props.bgcolor} !important` : ""}
          onClick={(e) => {
            props.onClick(e);
            setClicked(true);
          }}
          border={"1px solid " + styles.borderColor}
          borderRadius={styles.variant === "square" ? "5px" : "50%"}
          height={size.height}
          width={size.width}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="2px"
        >
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* {focus ? props.darkMode : props.lightMode} */}
            {props.mode === "dark" ? props.darkMode : props.lightMode}
          </Grid>
        </Grid>
      </Tooltip>
    </>
  );
}

export function CustomIconbtn(props) {
  const [styles, setStyle] = React.useState({
    borderColor: "#EBECF0",
    variant: "default",
    size: "md",
    color: "",
  });

  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    setStyle({
      ...styles,
      borderColor: props.borderColor
        ? props.borderColor
        : "var(--right-left-arrow-border-color)",
      variant: props.variant ? props.variant : "default",
      size: props.size ? props.size : "md",
      color: props.color ? props.color : "",
    });
  }, [props]);

  const getSize = () => {
    if (props.height && props.width) {
      return { height: props.height, width: props.width };
    }
    return {
      height:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
      width:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
    };
  };

  const size = getSize();

  return (
    <>
      <Tooltip title={props.title}>
        <Grid
          id={props.id}
          name={props.name}
          container
          className={"custom-switch-mode"}
          bgcolor={props.bgcolor ? `${props.bgcolor} !important` : ""}
          onClick={(e) => {
            props?.onClick(e);
            setClicked(true);
          }}
          border={"1px solid " + styles.borderColor}
          borderRadius={styles.variant === "square" ? "5px" : "50%"}
          height={size.height}
          width={size.width}
        >
          <Grid item>
            {props.mode === "dark" ? props.darkMode : props.lightMode}
          </Grid>
        </Grid>
      </Tooltip>
    </>
  );
}

export function CustomIconButtonWOB(props) {
  const { mode } = useContext(AppContext);
  const [styles, setStyle] = React.useState({
    variant: "default",
    size: "md",
    color: "var(--text-secondary-80)",
  });

  const [focus, setFocus] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  React.useEffect(() => {
    setStyle({
      ...styles,
      variant: props.variant ? props.variant : "default",
      size: props.size ? props.size : "md",
      color: props.color ? props.color : "var(--text-secondary-80)",
    });
  }, [props]);

  const getSize = () => {
    if (props.height && props.width) {
      return { height: props.height, width: props.width };
    }
    return {
      height:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
      width:
        styles.size === "sm" ? "30px" : styles.size === "md" ? "40px" : "50px",
    };
  };

  const size = getSize();

  return (
    <>
      <Tooltip
        title={clicked ? null : props.disabled ? null : props.title}
        className="CustomIconbtns"
      >
        <Grid
          sx={{
            color: mode === "light" ? "#242424cc" : "#f8f8f880",
            "& .arrow-svg": {
              transition: "color 0.3s ease",
              color:
                mode === "dark"
                  ? "var(--color-33-50) !important"
                  : "#24242480 !important",
            },
            "&:hover": {
              backgroundColor: props.disabled
                ? mode === "light"
                  ? "#f4f5f8"
                  : "#010101"
                : mode === "light"
                ? "#e8e6f1"
                : "#1f1f1f80",
              "& .arrow-svg": {
                color:
                  mode === "dark"
                    ? "#f8f8f8cc !important"
                    : "#242424 !important",
              },
            },
            backgroundColor: props.activeState
              ? mode === "light"
                ? "#ffa23a4d"
                : "#ffc788"
              : mode === "light"
              ? "#f4f5f8"
              : "#010101",
            borderColor: mode === "light" ? "#d9d9d9" : "#313131",
            cursor: props.disabled ? "not-allowed" : "pointer",
          }}
          id={props.id}
          name={props.name}
          onMouseOver={() => setFocus(true)}
          onMouseLeave={() => {
            setFocus(false);
            setClicked(false);
          }}
          container
          className={
            props.type === "contained"
              ? "custom-icon-contained"
              : "custom-icon arrow-svg"
          }
          onClick={(e) => {
            props?.onClick(e);
            setClicked(true);
          }}
          borderRadius={styles.variant === "square" ? "5px" : "50%"}
          height={size.height}
          width={size.width}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="2px"
        >
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {mode === "dark" ? props.darkMode : props.lightMode}
          </Grid>
        </Grid>
      </Tooltip>
    </>
  );
}
