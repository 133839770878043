import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../signup//Signup.scss";
import "./ForgotPassword.scss";
import OnboardTextField from "../../components/OnboardTextField";
import { enqueueSnackbar } from "notistack";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import CustomButton from "../../components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordField from "../../components/PasswordField";
import SignupLayout from "../signup/SignupLayout";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/edit_icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/ralvie/edit_icon_dark.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/ralvie/info-icon.svg";
import { ReactComponent as InfoDarkIcon } from "../../assets/images/ralvie/info_dark_icon.svg.svg";

import { toast } from "sonner";
import AppContext from "../../config/AppContext";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [otp, setOtp] = useState(new Array(5).fill(""));
  const [otpSent, setOtpSent] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const resendOtpRef = useRef(resendOtp);
  const [otpVerified, setOtpVerified] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const emailFromState = location.state ? location.state.email : null;
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(false);
  const { mode } = useContext(AppContext);
  const [encodedEmail, setEncodedEmail] = useState("");
  const otpInputRefs = useRef([]);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (emailFromState) {
      const encoded = btoa(emailFromState);
      setEncodedEmail(encoded);
    }
  }, [emailFromState]);

  const [payload, setPayload] = useState({
    otpType: "RESET_PASSWORD",
    status: "",
    code: emailFromState || "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    otpType: "",
    status: "",
    code: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setResendOtp(true);
      resendOtpRef.current = true;
    }, 60000);
    return () => clearTimeout(timer);
  }, [resendOtp]);

  const handleSendOtp = (e) => {
    setResendOtp(false);
    resendOtpRef.current = false;
    if (validate()) {
      const email = payload.code;
      OnboardService.sendOtpToResetPassword(email).then((res) => {
        if (res.data.code === "RCI0015") {
          toast.success(t("RAL0151"));
          setOtpSent(true);
          setTimeout(() => {
            setResendOtp(true);
            resendOtpRef.current = true;
          }, 60000);
        } else {
          toast.error(res.data?.message);
        }
      });
    }
  };

  const handleVerifyOtp = (otpValue) => {
    var state = {
      ...payload,
      code: encodedEmail,
      otp: otpValue,
    };

    OnboardService.verifyOtp(state).then((res) => {
      if (res.data.code === "RCI0019") {
        setOtpVerified(true);
        setTimeout(() => {
          setSuccess(true);
        }, 3000);
      } else {
        setErrors(true);
        setOtp(new Array(5).fill(""));
        const errorMessage =
          res.data.code === "RCE0101" ? t("RAL0153") : t("RAL0150");
        setError({
          ...error,
          errorMessage: errorMessage,
        });
        if (otpInputRefs.current[0]) {
          otpInputRefs.current[0].focus();
        }
        setTimeout(() => {
          setErrors(false);
        }, 3000);
      }
    });
  };

  const handleSubmit = (e) => {
    if (validateOtp()) {
      var state = {
        ...payload,
        code: encodedEmail,
      };
      OnboardService.validateOtpToResetPassword(state).then((res) => {
        if (res.data.code === "RCI0016") {
          navigate("/pages/password-success");
          toast.success(res.data?.message);
          // enqueueSnackbar(res.data?.message, { variant: "success" });
        } else if (res.data.code === "RCE0106") {
          setError({ ...error, password: res.data?.message });
        } else {
          setError({
            ...error,
            otp: res.data?.message,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (otpVerified && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [otpVerified]);

  const validateOtp = () => {
    if (!payload.otp) {
      setError({
        ...error,
        otp: t("RAL0001"),
      });
      return false;
    }
    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      return false;
    }

    if (!CommonUtil.isAlphaNumericPassword(payload.password)) {
      setError({
        ...error,
        password: t("RAL0010"),
      });
      return false;
    }

    if (!payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: t("RAL0001"),
      });
      return false;
    }

    if (payload.confirmPassword !== payload.password) {
      setError({
        ...error,
        confirmPassword: t("RAL0011"),
      });
      return false;
    }
    return true;
  };

  const validate = () => {
    if (!payload.code) {
      setError({
        ...error,
        code: t("RAL0001"),
      });
      return false;
    }
    if (!CommonUtil.isValidEmail(payload.code)) {
      setError({
        ...error,
        code: t("RAL0073"),
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const handleChangeOtp = (index, value) => {
    const newOtp = [...otp];
    if (value.length === 1) {
      newOtp[index] = value;
    } else if (value.length > 1) {
      const pastedValues = value.split("");
      pastedValues.forEach((char, idx) => {
        if (idx < otp.length) {
          newOtp[idx] = char;
        }
      });
    }
    setOtp(newOtp);

    const otpValue = newOtp.join("");
    setPayload({
      ...payload,
      otp: otpValue,
    });

    setError({
      ...error,
      otp: "",
    });

    if (value.length === 0 && index > 0) {
      const previousIndex = index - 1;
      const previousInput = document.getElementById(
        `outlined-basic-${previousIndex}`
      );
      if (previousInput) {
        previousInput.focus();
      }
    } else if (value.length === 1 && index < 5) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(`outlined-basic-${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    const isOtpEntered = newOtp.every((digit) => digit !== "");
    if (isOtpEntered && otpValue.length === 5) {
      handleVerifyOtp(otpValue);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <>
      <SignupLayout>
        <div className="ForgotPassword">
          <div className="w-100Email">
            {!otpVerified && <div className="mainHeading">{t("RAL0101")}</div>}

            {!otpVerified && (
              <div className="signup_email_sub_head">
                <span>{t("RAL0102")}</span>
              </div>
            )}

            {!otpVerified && (
              <div className="resEmail text-lowercase">
                <p
                  style={{
                    paddingTop: CommonUtil.getOS() === "MacOS" ? "11px" : "0px",
                  }}
                >
                  {payload.code}&nbsp;&nbsp;
                </p>
                <span
                  className="signup_email_verify_014"
                  onClick={() => navigate("/pages/verify-user")}
                >
                  <Tooltip title={t("RAL0172")}>
                    {mode === "dark" ? <EditIconDark /> : <EditIcon />}
                  </Tooltip>
                </span>
              </div>
            )}

            {otpVerified && <div className="mainHeading">{t("RAL0076")}</div>}

            {!otpVerified && (
              <Box className="otpInputs">
                {otp.map((value, index) => (
                  <OnboardTextField
                    autoComplete={true}
                    className="otpInputsBoxes"
                    key={index}
                    id={`outlined-basic-${index}`}
                    validation="numeric"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]",
                      maxLength: 1,
                      style: { textAlign: "center" },
                    }}
                    errors={errors}
                    success={success}
                    firstField={index === 0}
                    suceessMessage={t("RAL0149")}
                    errorMessage={error.errorMessage}
                    onChange={(e) => handleChangeOtp(index, e.target.value)}
                    value={value}
                    inputRef={(el) => (otpInputRefs.current[index] = el)}
                    onPaste={(e) => {
                      e.preventDefault();
                      const pastedData = e.clipboardData.getData("text");
                      handleChangeOtp(index, pastedData);
                    }}
                  />
                ))}
              </Box>
            )}

            {!resendOtp && !success && !errors && !otpVerified && (
              <div>
                <div className="signup_email_verify_015">
                  <span className="signup_email_verify_017">
                    {mode === "dark" ? <InfoDarkIcon /> : <InfoIcon />}
                  </span>
                  &nbsp;&nbsp;
                  <span className="signup_email_verify_016">
                    {t("RAL0146")} {t("RAL0147")}
                  </span>
                  &nbsp;
                </div>
              </div>
            )}

            {!otpVerified && (
              <Grid item sm={12} className="forgot_password_submitbutton_007">
                <CustomButton
                  id="btn_login_user_signin"
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={!resendOtp}
                  onClick={handleSendOtp}
                >
                  <span className="forgot_password_submitbutton_008">
                    {t("RAL0105")}
                  </span>
                </CustomButton>
              </Grid>
            )}

            {otpVerified && (
              <Grid item sm={12} pt={4}>
                <PasswordField
                  className="changePasswordError-1"
                  required
                  fullWidth
                  label={t("RAL0111")}
                  value={payload.password}
                  name="password"
                  onChange={handleChange}
                  error={error.password}
                  helperText={error.password}
                  inputRef={passwordRef} 
                  onKeyDown={(e)=>handleKeyDown(e)}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
              </Grid>
            )}
            {otpVerified && (
              <Grid item sm={12} pt={2}>
                <PasswordField
                  className="changePasswordError"
                  required
                  fullWidth
                  label={t("RAL0112")}
                  value={payload.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange}
                  error={error.confirmPassword}
                  helperText={error.confirmPassword}
                  onKeyDown={(e)=>handleKeyDown(e)}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
              </Grid>
            )}
            {otpVerified && (
              <Grid
                item
                lg={12}
                pt={2}
                minWidth="100%"
                className="submitButton"
              >
                <CustomButton
                  variant="contained"
                  size="medium"
                  fullWidth="fullWidth"
                  onClick={handleSubmit}
                >
                  <span className="signUp">{t("RAL0077")}</span>
                </CustomButton>
              </Grid>
            )}
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
