import axios from "axios";
import api from "../config/http-common";

// export const getAllProjectReports= async ()=>{
//     return api.securedAxios().get("/timesheet/projectreport")
// }

export const getAllProjectReports = async (startTime, endTime) => {
  return api.securedAxios().get("/report/project");
};

export const getProjectReportById = async (payload) => {
  let url = "//timesheet/projectreport";
  if (payload?.toggleKeyToURL) {
    url += "&toggleKeyToURL=true";
  }
  if (payload?.id) {
    url += `&id=${payload.id}`;
  }
  return api.securedAxios().get(url);
};

export const getProjectMembers = async (projectId) => {
  const url = `/report/project/members?projectId=${projectId}`;
  return api.securedAxios().get(url);
};

export const exportProjectReport = async (
  projects,
  clientId,
  member,
  startDate,
  endDate,
  tags,
  includeMemberContribution
) => {
  try {
    let api = "/reports/project/export?";
    if (startDate) {
      api += "&startDate=" + startDate;
    }
    if (endDate) {
      api += "&endDate=" + endDate;
    }
    if (projects) {
      api += "&projects=" + projects;
    }

    if (clientId) {
      api += "&clientId=" + clientId;
    }

    if (member) {
      api += "&member=" + member;
    } else {
      api += "&member=";
    }

    // Include isIncludeMemberContribution in the API call
    api += "&includeMemberContribution=" + includeMemberContribution;

    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") + api,
      {
        responseType: "blob", // Important to specify the response type as blob
        headers: {
          Authorization: localStorage.getItem("token"),
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "projects-report.xlsx"); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};
